@font-face {
  font-family: 'icomoon';
  /* src:  url('../fonts/icomoon.eot?vgd3ao');
  src:  url('../fonts/icomoon.eot?vgd3ao#iefix') format('embedded-opentype'),
    url('../fonts/icomoon.ttf?vgd3ao') format('truetype'),
    url('../fonts/icomoon.woff?vgd3ao') format('woff'),
    url('../fonts/icomoon.svg?vgd3ao#icomoon') format('svg'); */
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  font-size: 30px;
      position: relative;
    top: 9px;
    right: 14px;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  
}

.icon-activity:before {
  content: "\e900";
}
.icon-campaign:before {
  content: "\e901";
}
.icon-organization:before {
  content: "\e902";
}
/* .icon-logout:before {
  content: "\e903";
} */
.icon-user:before {
  content: "\e904";
}
.icon-volunteer:before {
  content: "\e905";
}