@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@200;300;400;500;600;700;800&display=swap");
.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

span {
  font-family: 'Roboto', sans-serif;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

::placeholder {
  color: #949494;
  font-style: normal;
  opacity: 1; /* Firefox */
}

:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: #949494;
}

::-ms-input-placeholder {
  /* Microsoft Edge */
  color: #949494;
}

.MuiOutlinedInput-input:-webkit-autofill {
  caret-color: #fff;
  border-radius: inherit;
  -webkit-box-shadow: 0 0 0 0 transparent !important;
  -webkit-text-fill-color: #000 !important;
}

.css-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input.css-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input.css-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input {
  font-family: "Poppins";
}

input[type="tel"] {
  font-family: "Poppins";
}

.css-qiwgdb.css-qiwgdb.css-qiwgdb {
  font-family: "Poppins" !important;
}
a {
  text-decoration: none;
}

.css-kk1bwy-MuiButtonBase-root-MuiMenuItem-root {
  font-family: "Poppins" !important;
}

.css-1km1ehz {
  font-family: "Poppins" !important;
}

.css-j204z7-MuiFormControlLabel-root .MuiFormControlLabel-label {
  font-family: "Poppins" !important;
}

.css-1w13o7u-MuiTypography-root {
  font-family: "Poppins" !important;
}

.css-bkrceb-MuiButtonBase-root-MuiPickersDay-root {
  font-family: "Poppins" !important;
}
.css-1v994a0 {
  font-family: "Poppins" !important;
}
.css-m1gykc {
  font-family: "Poppins" !important;
}

.tooltip {
  position: relative;
  display: inline-block;
}

.tooltip .tooltiptext {
  visibility: hidden;
  width: 120px;
  background-color: black;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 5px 5px;

  /* Position the tooltip */
  position: absolute;
  z-index: 1;
}

.tooltip:hover .tooltiptext {
  visibility: visible;
}

.css-sghohy-MuiButtonBase-root-MuiButton-root {
  font-family: "Poppins" !important;
}
.css-1hw9j7s {
  font-family: "Poppins" !important;
}

.css-9l3uo3 {
  font-family: "Poppins" !important;
}
