.gradient-heading {
	display: block;
	text-align: center;
	text-transform: uppercase;
	font-weight: bold;
	background: -webkit-linear-gradient(180deg, #f8b34c, #7c5a26);
	background-clip: text;
	-webkit-background-clip: text;
	-webkit-text-fill-color: transparent;
}
