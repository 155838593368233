@font-face {
  font-family: 'flaticon';
  src:  url('fonts/flaticon.eot');
  src:  url('fonts/flaticon.eot') format('embedded-opentype'),
    url('fonts/flaticon.ttf') format('truetype'),
    url('fonts/flaticon.woff') format('woff'),
    url('fonts/flaticon.svg') format('svg');
  font-weight: normal;
  font-style: normal;
}


[class^="flaticon-"], [class*=" flaticon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'flaticon' !important;
  speak: none;
  font-style: normal;
  display: inline-block;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.flaticon-down-arrow:before {
  content: "\f182";
}
.flaticon-down-arrow-1:before {
  content: "\e92e";
}
