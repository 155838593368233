* {
    margin: 0;
    padding: 0;
    /* -webkit-user-select: none;
       -moz-user-select: none;
        -ms-user-select: none;
            user-select: none; */
    box-sizing: border-box;
    font-family: 'Roboto', sans-serif;
}

body {
    font-family: 'Roboto', sans-serif;
    font-size: 16px;
    background-color: #f5f8fb !important;
}

body {
    height: 100vh;
}

.bannerImage {
    background-size: 100% 100%;
}

.nav-top {
    position: fixed;
    left: 0;
    /* z-index: 2; */
    z-index: 20;
    background: #ffffff;
    width: 100%;
    padding: 10px 20px;
    box-shadow: 0px 2px 15px 6px rgb(0 0 0 / 10%);

}

.parentStep .childStep:nth-child(2) div::before {
    animation: yourAnimationName 2s ease-in-out 0s forwards;
  }
 
  .parentStep .childStep:nth-child(3) div::before {  
    animation: yourAnimationName 2s ease-in-out 2s forwards; 
  }
 
  @keyframes yourAnimationName {
    0% {
        width: 0%;
        background-color: #e0e0e0;
    }
    100% {
        width: 100%;
        background-color: #00a5cf;
    }
}

footer {
    position: absolute;
    bottom: 0;
    width: 100% !important;
}
.leftColumn,.rightColumn {
    width:50%;
    overflow: visible !important;
    margin-top:10px;
  }

footerForSome {
    position: absolute;
    bottom: 0;
}


.containers>.content-wrap {
    padding: 0px !important;
    background-color: transparent !important;
    box-shadow: 0px 0px 0px 0px rgb(0 0 0 / 10%) !important;
}

.containers>.content-wrap .content-title {
    margin-top: 0px;
    color: #000;
    font-size: 36px;
    font-weight: 600;
}

.containers>.content .content-mrg {
    margin-top: 56px;
    margin-bottom: 35px;
}

.containers>.content .content-mrg-landing {
    /* margin-top: 56px; */
    margin-bottom: 35px;
}


.containersVideo>.content-wrap {
    padding: 0px !important;
    background-color: #000;
    box-shadow: 0px 0px 0px 0px rgb(0 0 0 / 10%) !important;
}

.containersVideo>.content-wrap .content-title {
    margin-top: 0px;
    color: #000;
    font-size: 36px;
    font-weight: 600;
}

.containersVideo>.content .content-mrg {
    margin-top: 90px;
}

.containersVideo>.content .content-mrg-landing {
    margin-top: 20px;
}


.fa.list-dic {
    font-size: 9px;
    top: -2px;
    position: relative;
    color: #000;
    padding: 10px 10px 10px 0px;
}

.sidebar>ul>li>a+ul>li>a:hover .fa.list-dic {
    color: #fff;
}

.sidebar>ul>li>a+ul>li.acitveChild a,
.sidebar ul li a.acitveChild {
    background-color: #00399a;
}

.sidebar>ul>li>a+ul>li.acitveChild a,
.sidebar>ul>li>a+ul>li.acitveChild>a .fa.list-dic,
.sidebar ul li a.acitveChild {
    color: #fff !important;
}

.wrapper {
    /* display: flex;background-color: #f5f8fb;*/
    /* display: grid;
    align-items: baseline;*/
    float: left;
    width: 100%;
    height: 100%;
    background-color: white;

}

.wrapperTeam {
    /* display: flex;background-color: #f5f8fb;*/
    /* display: grid;
     align-items: baseline;*/
    float: left;
    width: 100%;
    /* height: 100%; */
    background-color: white;

}

.wrapperNotificationArchive {
    /* display: flex;background-color: #f5f8fb;*/
    /* display: grid;
     align-items: baseline;*/
    float: left;
    width: 100%;
    /* height: 100%; */
    background-color: white;

}

.wrapperRegisterScreen {
    /* display: flex;background-color: #f5f8fb;*/
    /* display: grid;
     align-items: baseline;*/
    float: center;
    width: 75%;

}

.wrapperMyOngoingCampaign {
    /* display: flex;background-color: #f5f8fb;*/
    /* display: grid;
     align-items: baseline;*/
    float: left;
    width: 100%;
    background-color: black;

}

.sidebar {
    /* z-index: 3; */
    z-index: 25;
    background-color: #fff;
    height: 100%;
    position: fixed;
    left: 0;
    min-width: 250px;
    transition: all 0.5s;

}

.sidebar~.containers {
    float: left;
    margin-left: 250px;
    transition: all .5s;
    width: calc(100% - 250px);
    background-color: #fff;
}

.homesidebar~.containers {
    float: left;
    margin-left: 250px;
    transition: all .5s;
    width: calc(100% - 250px);
    background-color: #fff;
}

.sidebar~.containersVideo {
    float: left;
    margin-left: 250px;
    transition: all .5s;
    width: calc(100% - 250px);
    background-color: #fff;
}

.sidebar.hide {
    left: -250px;
}

.sidebar .text {
    color: white;
    line-height: 65px;
    background-color: #fff;
    letter-spacing: 1px;
    padding-left: 30px;
}

.sidebar>ul {
    height: 100%;
    width: 100%;
    list-style: none;
    /* overflow-y: scroll; */
    overflow-y: auto;
}

.sidebar>ul>li {
    line-height: 70px;
    border-bottom: 1px solid hsla(0, 0%, 50%, .2);
}

.sidebar>ul>li>a,
.sidebar>ul>li>a+ul>li>a {
    width: 100%;
    text-decoration: none;
    display: block;
    padding-left: 20px;
    border-left: 3px solid transparent;
    position: relative;
    color: #121111 !important;
}

.sidebar>ul>li>a:hover,
.sidebar>ul>li>a+ul>li>a:hover,
.sidebar>ul>li>a.active {
    color: #fff !important;
    background-color: #00399a;
    border-left-color: #f0990c;
    transition: 0.3s;
}

.sidebar>ul>li.acitveParent a.active,
.sidebar ul li.acitveParent a.active>span {
    background: none;
    color: #000 !important;
}

.sidebar>ul>li>ul {
    position: static;
    display: none;
    color: #b2ceff;
    font-size: 14px;
}

.sidebar>ul>li>a+ul>li {
    line-height: 42px;
}

.sidebar>ul>li>a+ul>li>a {
    padding-left: 32px;
    color: #000 !important;
}

.sidebar>ul>li>a>span {
    position: absolute;
    top: 50%;
    right: 19px;
    transform: translateY(-50%);
    transition: 0.3s;
    font-size: 20px;
}

.sidebar>ul>li>a:hover>span {
    color: #fff;
}

.sidebar>ul>li>a.active>span {
    color: #fff;
    transform: translateY(-50%) rotate(-180deg);
    font-size: 26px;
}

/* Menu style comment */
/* .sidebar > ul > li > a.active + ul > li > a {
    background-color: #00399a;
}
.sidebar li.acitveParent ul.feat-show li > a {
    background-color: #00399a;
} */


.sidebar>ul>li.acitveParent ul {
    display: block;
}

.sidebar~.containers {
    margin-left: 250px;
    transition: all 0.5s;
    margin-bottom: 100px;
}

.homesidebar~.containers {
    margin-left: 250px;
    transition: all 0.5s;
    margin-bottom: 100px;
}

.sidebar~.containersVideo {
    margin-left: 250px;
    transition: all 0.5s;
    margin-bottom: 100px;
}

footer {
    position: fixed;
    width: calc(100% - 250px);
    text-align: center;
    background-color: #fff;
    box-shadow: 0px 2px 15px 6px rgb(0 0 0 / 10%);
    padding-top: 10px;
    padding-bottom: 10px;
    z-index: 23;
    /* left: -5px; */
    left: 231px;

}

footerForSome {
    position: fixed;
    width: 100%;
    text-align: center;
    background-color: #fff;
    box-shadow: 0px 2px 15px 6px rgb(0 0 0 / 10%);
    padding-top: 10px;
    padding-bottom: 10px;
    z-index: 2;

}


footer-marketplace {
    position: fixed;
    width: 100%;
    text-align: center;
    /* background-color: #fff; */
    /* box-shadow: 0px 2px 15px 6px rgb(0 0 0 / 10%); */
    padding-top: 10px;
    padding-bottom: 10px;
    z-index: 2;

}

.sidebar.hide~.containers {
    margin-left: 0;
    transition: all 0.5s;
}

.sidebar~.containers footer span {
    left: -128px;
}

.sidebar.hide~.containers footer span {
    left: 0;
}

.sidebar.hide~.containersVideo {
    margin-left: 0;
    transition: all 0.5s;
}

.sidebar~.containersVideo footer span {
    left: -128px;
}

.sidebar.hide~.containersVideo footer span {
    left: 0;
}

.text img {
    width: 152px;
    max-height: 55px;
}

.textcamphome img {
    width: 152px;
    max-height: 55px;
}

.blink {
    /* animation: blinker .75s linear infinite; */
    animation: blinker 1.5s linear infinite;
    /* color: red;
    font-family: sans-serif; */
    /* background-color: "#228B22"; */
    /* color:"white"; */
}

@keyframes blinker {
    50% {
        opacity: 0;
    }
}

.customRow {
    /*display:flex;*/
    white-space: nowrap;
    overflow-x: scroll;
}

.sidebar>ul>li:last-child {
    margin-bottom: 60px;
}

.customWidth {
    padding-top: 2px;
    margin-bottom: 10px;
    width: 269px;
    display: inline-block;
}

.greenBackground {
    background: linear-gradient(0deg, #fff 70%, rgba(45, 122, 116, .9) 0);
}

.mobileNavBar .collapse {
    display: flex;
}

.fa-bars:before {
    color: #8c8c8c;
}

.mobileNavBar .collapse .navbar-nav.ml-auto {
    flex-direction: row;
}

.wrappingMenuToggle>.btns {
    border-radius: 4px;
    /* margin: 0; */
    text-align: center;
    cursor: pointer;
    transition: all 0.5s;
    position: fixed;
    left: 206px;
    top: 14px;
    /* z-index: 4; */
    z-index: 28;
    line-height: 10px;
    margin-top: 0px;
    margin-left: 15px;
    font-size: 26px;
}

.wrappingMenuToggle>.btns>span {
    color: #fff;
    line-height: 45px;
}

.containers>.content {
    margin: 16px;
    background-color: white;
    border-radius: 6px;
    box-shadow: 0px 2px 15px 6px rgb(0 0 0 / 10%);
    height: 100%;
    padding: 0 !important;
}

.containers>.content>h2 {
    color: #1e1e1e;
    line-height: 40px;
    padding: 30px 0;
}

.containers>.content>p {
    line-height: 24px;
    color: #999;
    padding-bottom: 15px;
}

.containers>.content-pad-0 {
    background-color: transparent;
    box-shadow: 0px 2px 15px 6px rgb(0 0 0 / 0%);
    padding: 0px;
}

.list-bg {
    transition: transform 0.2s ease;
    background-color: #fff;
    /* box-shadow: 0px 2px 15px 6px rgb(137 173 255 / 20%); */
    padding: 0px 0px 1px 0px;
    border-radius: 6px;
    border: 0;
    margin-bottom: 20px;
}

.video-container video {
    width: 100%;
}

.campaign-img .play-button-wrapper #circle-play-b {
    cursor: pointer;
    pointer-events: auto;
}

.campaign-img .play-button-wrapper #circle-play-b svg {
    width: 100px;
    height: 100px;
    fill: #fff;
    stroke: #fff;
    cursor: pointer;
    background-color: rgba(0, 0, 0, 0.2);
    border-radius: 50%;
    opacity: 0.9;
}

.campaign-img .video-container video {
    width: 100%;
    /* height: 100%; */
    /* height: 233px; */
    height: auto;
    border-radius: 4px;
    /* max-height: 169px; */
}

.campaign-img .video-container {
    width: 100%;
    border-radius: 4px;
    margin: 0 auto;
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    box-shadow: 0px 8px 20px rgb(0 0 0 / 40%);
}

.reg-banner {
    background-image: linear-gradient(to right, #db8e0f, white);
    /* padding: 56px 0px; */
    padding: 10px 0px;
    /* height: 45vh; */
    height: auto;
}

.reg-banner .slider-img img {
    width: 100%;
    height: 320px;
    margin-top: 0px;
}

.reg-banner p {
    color: white;
}



.containersVideo>.content {
    margin: 16px;
    background-color: black;
    border-radius: 6px;
    box-shadow: 0px 2px 15px 6px rgb(0 0 0 / 10%);
    height: 100%;
    padding: 0 !important;
}

.containersVideo>.content>h2 {
    color: #1e1e1e;
    line-height: 40px;
    padding: 30px 0;
}

.containersVideo>.content>p {
    line-height: 24px;
    color: #999;
    padding-bottom: 15px;
}

.chartMongo {
    margin-top: 20px;
    color: white;
    height: 500px;
    overflow-y: hidden;
    /* border:1px solid #ccc; */
    box-shadow: 0px 8px 20px rgb(0 0 0 / 40%);
}


.per-img {
    position: relative;
}

.per-img .one1 {
    /* width: 120px;
    border-radius: 35%;
    border: 3px solid #d8d8d8;
    position: relative; */

    width: 120px;
    height: 120px;
    border-radius: 50%;
    border: 3px solid #d8d8d8;
    position: relative;
    object-fit: cover;
}

.per-img .two2 {
    width: 37px;
    position: absolute;
    right: 84px;
    top: 138px;
}

.per-img .three3 {
    width: 37px;
    position: absolute;
    right: 84px;
    top: 138px;
}

.person-r-points {
    text-align: center;
    /* display: block; */
}

.reward-top-sec {
    background-image: linear-gradient(#da8d0f, white);
    padding: 45px 0px;
    margin-top: 20px;
}

.cyagg-sec-space {
    padding: 25px 0px;
    /* user-select: all; */
}

.t-reward-wrap .col-one img {
    background-color: white;
    border-radius: 50%;
    padding: 6px;
    width: 60px;
    border: 2px solid #c9c9c9;
}

.t-reward-wrap .col-two span {
    color: black;
    font-weight: 500;
    display: inline-block;
    margin-top: 7px;
    font-size: 18px;
}

.t-reward-wrap .col-two h5 {
    color: #01539d;
    font-weight: 700;
}

.t-reward-wrap .col-two {
    text-align: left;
}

.t-reward-wrap {
    display: inline-flex;
    margin-top: 50px;
}

.overall-wrap .col-one img {
    background-color: white;
    border-radius: 50%;
    padding: 6px;
    width: 60px;
    border: 2px solid #c9c9c9;
}

.overall-wrap .col-two span {
    color: black;
    font-weight: 500;
    display: inline-block;
    margin-top: 7px;
    font-size: 18px;
}

.overall-wrap .col-two h5 {
    color: #01539d;
    font-weight: 700;
}

.overall-wrap {
    display: inline-flex;
    margin-top: 50px;
}

.overall-wrap .col-two {
    text-align: left;
}

h4.site-title {
    color: black;
    font-family: 'Poppins', sans-serif;
}

h4.site-title-camp-direct {
    color: black;
    font-family: 'Poppins', sans-serif;
    text-align: center;
    margin-top: 20px;
    margin-bottom: 20px;
}

a.story-signin {
    background-color: #01539d;
    color: white !important;
    padding: 6px 12px;
    border-radius: 4px;
    font-size: 15px;
    font-weight: 400;
}

a.story-post-reg {
    background-color: #01539d;
    color: white !important;
    padding: 11px 13px;
    border-radius: 4px;
    font-size: 15px;
    font-weight: 400;
}


.top-player-box svg {
    width: 100%;
}

.leaderboard-left {
    background-color: #01539d;
    height: 100%;
    width: 100%;
    border-radius: 25px 0px 0px 25px;
}

.leaderboard-left img {
    width: 270px;
    border: 4px solid #b3b3b3;
    border-radius: 50%;
}

.leaderboard-left span {
    display: block;
    color: white;
}

.leaderboard-left .text-center {
    padding-top: 139px;
}

.leaderboard-left h5 {

    color: white;
}


.fixedHeader {
    overflow: auto;
    height: 385px;
}


.progressing-a-0-campaigns {
    border: 1px solid #dfdfdf;
    padding: 14px 12px;
    border-radius: 6px;
    margin-top: 10px;
    color: red;
}

.progressing-a-campaigns {
    border: 1px solid #dfdfdf;
    padding: 14px 12px;
    border-radius: 6px;
    margin-top: 10px;
}

.progressing-a-campaigns .content-col-1 h6 {
    color: black;
    margin-bottom: 2px;
}

.progressing-a-campaigns .content-col-1 span {
    font-size: 12px;
    color: #6f6f6f;
}

.campaigns-leaders-re a img {
    width: 43px;
    height: 43px;
    border-radius: 50%;
    border: 2px solid lightgrey;
}

.campaigns-leaders-re {
    display: flex;
}

/* .campaigns-leaders-re a:nth-child(2){
    z-index: 3;
} */
.campaigns-leaders-re a:nth-child(3) {
    margin-left: -16px;
    /* z-index: 2; */
}

.campaigns-leaders-re a:nth-child(4) {
    margin-left: -16px;
    z-index: 1;
}

.campaigns-leaders-re a:nth-child(5) {
    margin-left: -16px;
    z-index: 0;
}

.campaigns-leaders-re a:hover {
    transform: scale(1.2);
}

.chm .campaigns-leaders-re a img {
    width: 34px;
    height: 33px;
    border-radius: 50%;
    border: 2px solid lightgrey;
    object-fit: cover;
}

img.reward-imagehm {
    width: 100%;
    height: 150px;
    object-fit: cover;
}

.reward-details .card-body {
    height: 90px;
    overflow-y: scroll;
}

.reward-wrap {
    background: linear-gradient(to bottom, #db8d0f, #ffffff);
    padding: 10px;
    border-radius: 5px;
    box-shadow: 1px 1px 6px 3px #0000001a;
}

.reward-wrap-marketplace {
    background: linear-gradient(to bottom, #db8d0f, #ffffff);
    padding: 10px;
    border-radius: 5px;
    box-shadow: 1px 1px 0px 1px #0000001a;
    width: 300px;
    margin: 10px;
}

.newreward-wrap-marketplace {
    background: linear-gradient(to bottom, #db8d0f, #ffffff);
    /* padding: 10px; */
    border-radius: 5px;
    box-shadow: 1px 1px 0px 1px #0000001a;
    width: 200px;
    margin: 10px;
}

.campimpact-wrap-marketplace {
    background: linear-gradient(to bottom, #db8d0f, #ffffff);
    padding: 10px;
    border-radius: 5px;
    box-shadow: 1px 1px 0px 1px #0000001a;
    /* width:125px; */
    margin: 15px;
}

.camp-wrap-campo {
    background: linear-gradient(to bottom, #db8d0f, #ffffff);
    padding: 10px;
    border-radius: 5px;
    box-shadow: 1px 1px 6px 3px #0000001a;
    width: 300px;
    margin: 10px;
}

div#toggleAccordion {
    margin-top: 14px;
}

.flaticon-down-arrow:before {
    content: "\f182";
}
.flaticon-user-plus:before{
    content:"\f234";
}

/* scrollbar */
.u-custom-scrollbar::-webkit-scrollbar {
    width: 8px;
    /* width of the entire scrollbar */
}


.u-custom-scrollbar-one::-webkit-scrollbar {
    width: 8px;
    /* width of the entire scrollbar */
}

.u-custom-scrollbar-one::-webkit-scrollbar-track {
    background: transparent;
    /* color of the tracking area */
}

.u-custom-scrollbar-one::-webkit-scrollbar-thumb {
    background-color: #01539d;
    /* color of the scroll thumb */
    border-radius: 20px;
    /* roundness of the scroll thumb */
    border: 3px solid transparent;
    /* creates padding around scroll thumb */
}

.u-custom-scrollbar-one {
    max-height: 100px;
    overflow-y: auto;
}

.u-custom-scrollbar-for-rewards::-webkit-scrollbar {
    width: 8px;
    /* width of the entire scrollbar */
}

.u-custom-scrollbar-for-rewards::-webkit-scrollbar-track {
    background: transparent;
    /* color of the tracking area */
}

.u-custom-scrollbar-for-rewards::-webkit-scrollbar-thumb {
    /* background-color: #db8e0f; */
    /* color of the scroll thumb */
    border-radius: 20px;
    /* roundness of the scroll thumb */
    border: 3px solid transparent;
    /* creates padding around scroll thumb */
}

.u-custom-scrollbar-for-rewards {
    max-height: 270px;
    overflow-y: scroll;
    overflow-x: hidden;
}

@media only screen and (max-width: 992px) {
    .hide-on-spec {
        display: none;
    }

    .add-full-width {
        max-width: 100%;
        flex: 100%;
    }

    .category-btn {
        margin: 10px;
    }
}

@media only screen and (max-width: 400px) {
    .congrats-abs {
        width: 100%;
        position: absolute;
        bottom: 0px;
        left: 0px;
        right: 0px;
        top: 760px !important;
    }

    .congrats-logo {
        width: 50%;
        /* height:100px; */
        /* position: absolute; */
        /* bottom: 0px;
    left: 0px;
    right: 0px;
    top: 678px; */
    }

    .filecard input {
        margin: 0 auto;
        max-width: 100%;
    }

    .filecard-participate input {
        margin: 0 auto;
        max-width: 100%;
    }
}

@media only screen and (max-width: 630px) {

    .nofooterinmob {
        display: none;
    }
    .landingPageWrapper .nofooterinmob footer {
        position: fixed;
        margin-bottom:0px;
        bottom:0;
    }
    .landingPageWrapper {
        padding-bottom: 100px !important;
    }
    .landingPageWrapper .nofooterinmob, .footerFullWidth .nofooterinmob {
        display: block !important;
    }
    .landingPageWrapper footer ul li.termsCondition,.footerFullWidth footer ul li.termsCondition {
        margin-right: 0px;
    }
    .landingPageWrapper .nofooterinmob footer,.footerFullWidth .nofooterinmob footer {
        left: 0;
    }
    .landingPageWrapper .nofooterinmob ul li,.footerFullWidth .nofooterinmob ul li {
        width:100%;
    }
   
    .marketplaceSearch {
        /* width: 300px; */
        margin-bottom: 5px;
        /* max-width: 100%; */
    }

    .mobileView {
        display: block;
        /* Show mobile view content */
    }

    .desktopView {
        display: none;
        /* Hide desktop view content */
    }

    img.reward-imagehm {
        width: 100%;
        height: 255px !important;
        object-fit: cover;
    }


    /* reward mob poits sec stars here */
    a.story-signin {
        font-size: 10px;
    }

    a.story-post-reg {
        font-size: 10px;
    }

    .campaigns-leaders-re a img {
        width: 35px !important;
        height: 35px !important;

        margin-top: 4px;
    }

    .progressing-a-campaigns .content-col-1 h6 {
        color: black;
        margin-bottom: 2px;
        font-size: 14px;
    }

    h4.site-title {
        color: black;
        font-size: 20px;
    }

    /* h4.site-title-camp-direct {
        color: black;
        font-size: 16px;
    } */
    .t-reward-wrap {
        display: inline-flex;
        margin-top: 20px !important;
        margin-bottom: 20px;
    }

    .t-reward-wrap .col-two span {
        font-size: 14px !important;
    }

    .overall-wrap {
        display: inline-flex;
        margin-top: 20px !important;
        margin-bottom: 20px;
    }

    .overall-wrap .col-two span {
        font-size: 14px !important;
    }

    .reward-top-sec {
        padding: 20px 0px !important;
    }




    .re-points-wrap .p1 span {
        color: #ffffff !important;
        font-size: 25px !important;
    }

    .re-points-wrap .p2 span {
        color: #ffffff !important;
        font-size: 25px !important;
    }

    .re-points-wrap .p1 {
        display: flex;
    }

    .re-points-wrap .p2 {
        display: flex;
    }

    .per-main-wrap {
        display: inline-block;
        text-align: center;
    }

    .per-img .one1 {
        /* width: 50px!important; */
        margin-top: 2px;
        /* height: 50px!important; */
    }

    /* .per-img .one1 {
        width: 50px!important;
        margin-top: -10px;
    } */
    /* .person-r-points {
        text-align: left!important;
        display: inline-flex;
    } */
    .person-r-points {
        text-align: center !important;
        display: block;
    }

    .re-points-wrap .p1 img {
        width: 35px;
        margin-top: 0px !important;
        height: 35px;
    }

    .re-points-wrap .p2 img {
        width: 35px;
        margin-top: 0px !important;
        height: 35px;
    }

    .per-main-name {
        /* color: white !important; */
        font-size: 14px;
        margin-left: 5px;
        margin-top: 6px;
    }

    .order-on-mob-2 {
        -ms-flex-order: 2;
        order: 2;
    }

    .hide-on-mobile {
        display: none;
    }

    .testi-sl .owl-nav {
        display: none;
    }

    .testi-content h2 {
        font-size: 23px;
        color: black;
    }

    .testi-desc {
        width: 100%;
        margin-left: 10px;
    }

    img.test-per-img {
        width: 80px !important;
        height: 80px;
    }

    .succ-story-wrap {
        padding: 45px 10px;
    }

    .succ-story-badge-wrap {
        padding: 45px 10px;
    }

    .testi-img {
        display: none;
    }

    .testimonial-wrap {
        padding: 10px 30px;
    }

    .banner-left-content {
        text-align: center !important;
    }

    .item.slide1 p {
        color: white;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        text-overflow: ellipsis;
        overflow: hidden;
        margin-bottom: 15px;
        height: 60px;
    }

    .landtext {
        text-align: justify;
        /* font-size: 14; */
    }

    figcaption {
        /* background-color:#01539d; */
        color: #000;
        /* font-style: italic; */
        padding: 3px;
        /* cursor:not-allowed; */
        font-size: 10px;
        /* text-align: right; */
    }

    .cyaag-sec-space p {
        text-align: justify;
    }

    /* .item.slideaboutus1 {
        background-image: linear-gradient(to right, #db8e0f , white);
        padding: 0px 0px;
        height: 72vh;
        text-overflow: ellipsis;
    }
    .item.slideaboutus2 {
        background-image: linear-gradient(to right, #db8e0f , white);
        padding: 0px 0px;
        height: 72vh;
    }
    .item.slideaboutus3 {
        background-image: linear-gradient(to right, #db8e0f , white);
        padding: 0px 0px;
        height: 72vh;
    } */
    .item.slideaboutus1 p {
        /* color: white; */
        display: -webkit-box;
        /* -webkit-line-clamp: 2;
        -webkit-box-orient: vertical; */
        text-overflow: ellipsis;
        overflow: hidden;
        margin-bottom: 15px;
        /* height: 60px; */
        /* background-image: linear-gradient(to right, #db8e0f , white);
        padding: 0px 0px;
        height: 72vh; */
    }

    .item.slideaboutus2 p {
        /* color: white; */
        display: -webkit-box;
        /* -webkit-line-clamp: 2;
        -webkit-box-orient: vertical; */
        text-overflow: ellipsis;
        overflow: hidden;
        margin-bottom: 15px;
        /* height: 60px; */
        /* background-image: linear-gradient(to right, #db8e0f , white);
        padding: 0px 0px;
        height: 72vh; */
    }

    .item.slideaboutus3 p {
        /* color: white; */
        display: -webkit-box;
        /* -webkit-line-clamp: 2;
        -webkit-box-orient: vertical; */
        text-overflow: ellipsis;
        overflow: hidden;
        margin-bottom: 15px;
        /* height: 60px; */
        /* background-image: linear-gradient(to right, #db8e0f , white);
        padding: 0px 0px;
        height: 72vh; */
    }

    .slider-img img {
        width: 100%;
        height: 266px;
    }

    /* .top-banner-space
    {
        margin-top: 75px!important;
    } */
    .nav-tabs .nav-link {
        font-size: 10px;
        padding: 7px 8px;
        margin: 2px !important;
    }

    /* #animateLine i {
        display:none;
    } */
    a.streak-view-more {
        margin-top: 12px;
        padding: 7px 15px;
        font-size: 14px;
        font-weight: 600;
        display: inline-block;
    }

    .more-p-btn {
        background-color: #01539D;
        color: white !important;
        padding: 8px 10px;
        border-radius: 5px;
        display: inline-block;
        margin-top: 10px;
        position: relative;
        font-size: 11px;
    }

    .progressing-a-campaigns .content-col-1 h6 {
        color: black;
        margin-bottom: 2px;
        font-size: 14px;
    }

    .campaigns-leaders-re a img {
        width: 35px !important;
        height: 35px !important;

        margin-top: 4px;
    }
}

.list-img {
    text-align: center;
    height: 103px;
    overflow: hidden;
    height: 125px;
    padding-top: 30px;
}

.centerAlignPadding {
    text-align: center;
}

.earn {
    padding: 0px 5px;
    display: block;
    margin-top: 10px;
}

.list-img img {
    width: 130px;
    height: auto;
    margin: 0 auto;
    border-radius: 100%;
}

.dropdown-item {
    cursor: pointer;
}

.inline {
    display: inline-flex;
}

.btn.submit-btn {
    box-shadow: 0px 0px 4px 2px rgb(0 0 0 / 10%);
    background-color: #f28800;
    color: #fff;
    border: solid 1px #f28800;
    border-radius: 6px;
    margin: 0px 15px;
    padding: 10px 40px;
    font-size: 16px;
    font-weight: 500;
    outline: none !important;
    cursor: pointer;
}

.btn.save-btn {
    box-shadow: 0px 0px 4px 2px rgb(0 0 0 / 10%);
    background-color: #fff;
    color: #f28800;
    border: solid 1px #f28800;
    border-radius: 6px;
    margin: 0px 15px;
    padding: 10px 40px;
    margin-bottom: 50px;
    font-size: 16px;
    font-weight: 500;
    color: #f28800 !important;
    cursor: pointer;
    outline: none !important;
}

.btn.cancel-btn {
    box-shadow: 0px 0px 4px 2px rgb(0 0 0 / 10%);
    background-color: #fff;
    border: solid 1px #393836;
    border-radius: 6px;
    margin: 0px 15px;
    padding: 10px 40px;
    margin-bottom: 50px;
    font-size: 16px;
    font-weight: 500;
    color: #161615 !important;
    cursor: pointer;
    outline: none !important;
}

.btn.home-btn {
    box-shadow: 0px 0px 4px 2px rgb(0 0 0 / 10%);
    background-color: #fff;
    color: #f28800;
    border: solid 1px #f28800;
    border-radius: 6px;
    margin: 0px 15px;
    padding: 10px 40px;
    margin-bottom: 10px;
    font-size: 16px;
    font-weight: 500;
    color: #f28800 !important;
    cursor: pointer;
    outline: none !important;
}

.btn.verify-btn {
    box-shadow: 0px 0px 4px 2px rgb(0 0 0 / 10%);
    background-color: #fff;
    color: #f28800;
    border: solid 1px #f28800;
    border-radius: 6px;
    margin: 10px 95px;
    padding: 10px 40px;
    margin-bottom: 50px;
    font-size: 16px;
    font-weight: 500;
    color: #f28800 !important;
    cursor: pointer;
    outline: none !important;
}

.btn.save-pause-btn {
    box-shadow: 0px 0px 4px 2px rgb(0 0 0 / 10%);
    background-color: #fff;
    color: #f28800;
    border: solid 1px #f28800;
    border-radius: 6px;
    margin: 0px 15px;
    padding: 10px 40px;
    font-size: 16px;
    font-weight: 500;
    color: #f28800 !important;
    cursor: pointer;
    outline: none !important;
}

.btn.save-code-btn {
    box-shadow: 0px 0px 4px 2px rgb(0 0 0 / 10%);
    background-color: #fff;
    color: #f28800;
    border: solid 1px #f28800;
    border-radius: 6px;
    margin: 0px 6px;
    padding: 2px 9px;
    font-size: 16px;
    font-weight: 500;
    color: #f28800 !important;
    cursor: pointer;
    outline: none !important;
}

.btn.bulk-btn {
    box-shadow: 0px 0px 4px 2px rgb(0 0 0 / 10%);
    background-color: #007473;
    color: #cdffff !important;
    border: solid 1px #007473;
    border-radius: 6px;
    margin: 0px 15px;
    padding: 12px 25px;
    font-size: 16px;
    font-weight: 500;
    outline: none !important;
    cursor: pointer;
    text-transform: uppercase;
}

.btn.download-btn {
    box-shadow: 0px 0px 4px 2px rgb(0 0 0 / 10%);
    background-color: #fff;
    color: #f28800;
    border: solid 1px #f28800;
    border-radius: 6px;
    margin: 0px 6px;
    padding: 2px 9px;
    font-size: 14px;
    font-weight: 500;
    color: #f28800 !important;
    cursor: pointer;
    outline: none !important;
}

button.btn.bulk-btn:last-child {
    margin-right: 0px;
}

.btn.creat-btn {
    box-shadow: 0px 0px 4px 2px rgb(0 0 0 / 10%);
    background-color: #cdffff;
    color: #007473;
    border: solid 1px #007473;
    border-radius: 6px;
    margin: 0px 15px;
    padding: 12px 25px;
    font-size: 16px;
    font-weight: 500;
    color: #007473 !important;
    cursor: pointer;
    outline: none !important;
    text-transform: uppercase;
}

button.btn.creat-btn:first-child {
    margin-left: 0px;
}

.coman-icon {
    float: right;
    padding: 0px 4px;
}

.coman-icon img {
    width: 30px;
    height: auto;
    float: right;
    padding: 0px 4px;
}

/* .list-title { min-height: 110px;max-height: 110px;} */
.list-title {
    min-height: 30px;
    max-height: 110px;
}

.list-title h1 {
    margin: 0;
    padding: 2px 0px;
    font-size: 14px;
    color: #000;
}

.list-title p {
    margin: 0;
    padding: 0;
    padding-bottom: 20px;
    font-size: 16px;
    color: #878686;
    height: 116px;
    overflow: hidden;
}

.next-list {
    color: #f39011;
    text-decoration: none;
}

.view-list {
    color: #000;
    font-size: 16px;
    text-decoration: none;
}

a.next-list:hover {
    color: #f39011;
    text-decoration: none;
}

a.view-list:hover {
    color: #000;
    font-size: 16px;
    text-decoration: none;
}

.hr {
    background-color: #c3c2c2;
    height: 1px;
    width: 100%;
}

.form-bg {
    background-color: #fff;
    box-shadow: 0px 2px 15px 6px rgb(137 173 255 / 20%);
    border-radius: 10px;
    padding: 80px;
}

label.label-title {
    color: #252d3b;
    font-size: 16px;
}

.form-control.form-inputs {
    font-size: 14px;
    height: 48px;
    border: solid 1px #eaeaea;
    border-radius: 6px;
    box-shadow: 0px 0px 4px 2px rgb(0 0 0 / 4%);
}

.form-control.form-inputs:focus {
    color: #000000;
    background-color: #fff;
    border-color: transparent;
    outline: 0;
    box-shadow: 0px 0px 4px 2px rgb(0 0 0 / 10%);
    ;
}

.prof-wrap {
    position: relative;
}

.prof-img {
    width: 140px;
    ;
}

.prof-icon {
    position: absolute;
    right: -12px;
    top: -12px;
    background: #f28800;
    border-radius: 100%;
    padding: 8px;
    width: 42px;
    height: 42px;
    border: solid 3px #fff;
}

.prof-title h1 {
    padding: 0;
    margin: 0;
    font-size: 24px;
    font-weight: 600;
}

.prof-title h2 {
    padding: 0;
    margin: 0;
    font-size: 22px;
    font-weight: 400;
    padding-top: 10px;
}

.prof-icon img {
    width: 100%;
    height: auto;
    margin-top: -5px;
}

.prof-img img {
    border-radius: 20px;
    width: 100%;
    height: auto;
    background-color: #fff;
    box-shadow: 0px 2px 15px 6px rgb(137 173 255 / 20%);
    border: solid 4px #fff;
}

.profile-edit {
    border: dashed 1px #f28800;
    display: initial;
    border-radius: 50px;
    padding: 6px 14px;
    color: #f28800;
    font-size: 12px;
}

.profile-edit img {
    width: 16px;
    height: auto;
}

.form-group.form-group-mrg {
    margin-bottom: 40px;
}

.clc-icon {
    background: url(../public/assests/images/clc-icon.png) no-repeat 90% center;
}

.select-drop {
    background: url(../public/assests/images/dropdown.png)
}

.form-control.selectdrop {
    min-height: 50px;
    border-radius: 6px;
}

.form-control.frmselect {
    height: 48px !important;
    border-radius: 6px !important;
    padding: 6px 12px;
}

.mycusInput_bform select {
    border: solid 1px #eaeaea;
    border-radius: 6px;
    box-shadow: 0px 0px 4px 2px rgb(0 0 0 / 4%);
}

.mycusInput_bform select {
    color: #959595;
    width: 100%;
    background: rgba(0, 0, 0, 0) url(../public/assests/images/dropdown.png) no-repeat scroll right center;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    display: block;
    width: 100%;
    height: 38px;
    line-height: 28px !important;
    font-size: 14px;
    color: #1e1c66;
    background-color: #ffffff;
    -webkit-box-shadow: 0px 0px 4px 2px rgb(0 0 0 / 4%);
    box-shadow: 0px 0px 4px 2px rgb(0 0 0 / 4%);

}

.form-control.frmselect:focus {
    color: #000000;
    background-color: #fff;
    border-color: transparent;
    outline: 0;
    box-shadow: 0px 0px 4px 2px rgb(0 0 0 / 10%);
}

.table-list {
    width: 100%;
    text-align: center;
    max-width: 100%;
    margin-bottom: 1rem;
    background-color: transparent;
}

.table-list .thead-light th {
    color: #495057;
    background-color: transparent;
    border-color: #dee2e6;
    font-size: 16px;
    font-weight: 600 !important;
}

.table-list thead th {
    vertical-align: bottom;
    border-bottom: 1px solid #dee2e6;
}

.table-list td,
.table-list th {
    padding: 18px 10px;
    vertical-align: top;
    border-bottom: 1px solid #dee2e6;
    border-top: 1px solid #dee2e6;
    color: #898989;
    font-size: 16px;
    font-weight: 400;
}

.review {
    background: #fff;
    border: solid 1px #ffa800;
    color: #ffa800;
    font-size: 12px;
    border-radius: 3px;
    padding: 3px 6px;
    display: inline;
}

.approved {
    background: #fff;
    border: solid 1px #4da400;
    color: #4da400;
    font-size: 12px;
    border-radius: 3px;
    padding: 3px 6px;
    display: inline;
}

.reject {
    background: #fff;
    border: solid 1px #ff0000;
    color: #ff0000;
    font-size: 12px;
    border-radius: 3px;
    padding: 3px 6px;
    display: inline;
}

.modal-header-bg {
    position: relative;
    background: #ffa800;
    color: #fff;
    text-align: center;
    width: 100%;
    font-size: 22px;
    padding: 8px 6px;
    font-weight: 500;
    border-radius: 4px;
}

.modal-header.modal-header-pad {
    padding: 4px;
}

.close.cls-btn {
    position: absolute;
    right: 0;
    color: #fff !important;
    opacity: 1 !important;
    font-weight: 200;
    margin: 0 !important;
    z-index: 1;
    padding: 5px 16px !important;
    outline: none !important;
}

.dropdown-toggle::after {
    display: none !important;
}

.icon-clr {
    color: #d1d2e4;
    font-size: 15px !important;
    top: 4px !important;
}

.mt-0 {
    margin-top: 0;
}

.mt-10 {
    margin-top: 10px;
}

.mt-15 {
    margin-top: 15px;
}

.mt-20 {
    margin-top: 20px;
}

.mt-25 {
    margin-top: 25px;
}

.mt-30 {
    margin-top: 30px;
}

.mb-0 {
    margin-bottom: 0;
}

.mb-10 {
    margin-bottom: 10px;
}

.mb-15 {
    margin-bottom: 15px;
}

.mb-20 {
    margin-bottom: 20px;
}

.mb-25 {
    margin-bottom: 25px;
}

.mb-30 {
    margin-bottom: 30px;
}

.list-content {
    /* padding: 0px 0px 20px 0px;  */
    padding: 0px;
}

.paddingLeftRight {
    padding-left: 20px !important;
    padding-right: 20px !important;
}

.list-content.removeTopPadding {
    padding-top: 0px;
}

.wrappingMenuToggle>.btns.click {
    left: -4px;
}

.wrappingMenuToggle>.btns span {
    color: white;
    line-height: normal;
}

.wrappingMenuToggle>.btns.click span:before {
    content: '\f00d';
}

.navbar {
    position: relative;
    min-height: 64px;
    margin-bottom: 20px;
    border: 1px solid transparent;
}

.navbar-herader {
    display: inline-block;
}

.navbar button:focus {
    outline: none;
}

.navbar button {
    border: none;
    width: 50px;
    cursor: pointer;
    height: 50px;
    color: white;
    padding: 11px 15px;
    font-size: 21px;
    border-radius: 100%;
    background: #4284f4;
    margin-top: 5px;
    position: relative;
}

.hoursWrapper {
    display: inline-block;
    text-align: center;
    /* margin-right:7px; */
    margin-right: 3px;
    font-size: 10px;
}

.category-btn:hover {
    background-color: #f0990c !important;
    color: white !important;
}

.category-btn:hover span {
    color: white !important;
}

.category-btn {
    font-weight: bold !important;
    text-transform: capitalize;
    display: flex;
    align-items: center;
    justify-content: start;
    cursor: pointer;
    background: transparent;
    outline: none;
    border: none;

    padding: 7px 15px;
    margin: 10px 0px;
    border-radius: 20px;
    transition: all 0.3s ease;
}

.btn.save-btn {
    background-color: #fff;
    ;
}

.btn.save-btn:hover {
    background-color: #f28800;
}

.btn.save-btn:hover span {
    color: #fff;
}

.btn.cancel-btn {
    background-color: #fff;
    ;
}

.btn.cancel-btn:hover {
    background-color: #161615;
}

.btn.cancel-btn:hover span {
    color: #fff;
}


.btn.home-btn {
    background-color: #fff;
    ;
}

.btn.home-btn:hover {
    background-color: #f28800;
}

.btn.home-btn:hover span {
    color: #fff;
}

.centerAlign {
    text-align: center;
}

.content-title {
    margin-bottom: 20px;
}

.hoursCount {
    display: block;
    font-size: 20px;
    box-shadow: 1px 1px 2px #00000012;
}

.list-content .text-left p {
    display: inline-block;
}

.text-left {
    font-size: 14px;
    /* padding-top: 10px; */
    padding-top: 9px;
}

/* Ripple magic */
.navbar button {
    position: relative;
    overflow: hidden;
}

.navbar button:after {
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    width: 5px;
    height: 5px;
    background: rgba(255, 255, 255, .5);
    opacity: 0;
    border-radius: 100%;
    transform: scale(1, 1) translate(-50%);
    transform-origin: 50% 50%;
}

.react-player {
    height: 60vh !important;
    width: 100% !important;
}

.react-player-volstate {
    height: 15vh !important;
    /* width: 40% !important; */
    width: 9vw !important;
    margin: 0 auto;
    margin-bottom: 5px;
}

.chats-page {
    position: absolute;
    top: 0px;
    left: 0px;
    width: 100vw;
    height: 100vh;
}

.nav-bar {
    width: 100%;
    height: 66px;
    background-color: #002766;
}

.logo-tab {
    position: absolute;
    left: 22px;
    top: 12px;
    font-size: 32px;
    font-weight: 700;
    color: white;
}

.tooltip {
    position: relative;
    display: inline-block;
    border-bottom: 1px dotted black;
    /* If you want dots under the hoverable text */
}

/* Tooltip text */
.tooltip .tooltiptext {
    visibility: hidden;
    width: 120px;
    background-color: black;
    color: #fff;
    text-align: center;
    padding: 5px 0;
    border-radius: 6px;

    /* Position the tooltip text - see examples below! */
    position: absolute;
    /* z-index: 1; */
}

/* Show the tooltip text when you mouse over the tooltip container */
.tooltip:hover .tooltiptext {
    visibility: visible;
}

#content>.intertoons.container {
    padding: 0 30px;
    max-width: none !important
}

.widget-content-area {
    padding: 20px;
    position: relative;
    background-color: #fff;
    border-bottom-left-radius: 6px;
    border-bottom-right-radius: 6px;
    /* -webkit-box-shadow: 0px 2px 4px rgba(126,142,177,0.12);
    box-shadow: 0px 2px 4px rgba(126,142,177,0.12); */
}

@keyframes ripple {
    0% {
        transform: scale(0, 0);
        opacity: 1;
    }

    20% {
        transform: scale(25, 25);
        opacity: 1;
    }

    100% {
        opacity: 0;
        transform: scale(40, 40);
    }
}

.navbar button:focus:not(:active)::after {
    animation: ripple 1s ease-out;
}

.admin-chat-logo img {
    width: 50%;
}

.notification-btn {
    color: #fff;
    background-color: transparent;
    border-color: transparent;
    font-size: 21px;
    margin-top: 10px;
}

.btn:hover,
.btn:focus,
.btn.focus {
    color: #fff;
    text-decoration: none;
}

.btn:active,
.btn.active {
    box-shadow: none;
}

.notification-btn .badge {
    color: #010101;
    background-color: #fff;
}

button .badge {
    position: relative;
    top: -40px;
    right: -10px;
}

.new-pro-main-btn {
    /* margin-top: 70px; */
    text-align: end;
}

.new-pro-message {
    /* background-color: #dc921c; */
    background-color: #605d57;
    /* border: 1px solid #dc921c; */
    border: 1px solid #605d57;
    color: white !important;
    padding: 6px 11px;
    border-radius: 3px;
    font-size: 13px;
    font-weight: 600;
    pointer-events: none;

}

.second-new-pro-btn {
    background-color: white;
    /* color: #db8d0f!important; */
    color: #605d57 !important;
    padding: 6px 11px;
    border-radius: 3px;
    font-size: 13px;
    font-weight: 600;
    border: 1px solid #da8c10;
    margin-left: 7px;
    pointer-events: none;
}

#horz-list ul li {
    display: inline-block;
}

#horz-list ul li a {
    text-decoration: none;
    padding: 0.2em 1.1em;
    border-right: 1px solid rgba(255, 255, 255, 0.8);
    margin: 0 0 0 -6px;
}

#horz-list ul li:last-child a {
    border: none;
}

#horz-list ul li a:hover {
    background: rgba(255, 255, 255, 0.8);
    color: #1a1a1a;
}

#horz-list ul {
    padding-left: 0px;
    margin-bottom: 8px;
}

.basic-detail ul li svg {
    color: #5d5d5d;
    width: 16px;
    margin-top: -2px;
    margin-right: 5px;
}

.new-pro-name-sec h4 {
    font-size: 22px;
    margin-bottom: 4px;
}

.new-pro-name-sec {
    margin-top: 10px;
}

.new-pro-name-sec h4 {
    font-size: 20px;
    margin-bottom: 6px;
}

.new-pro-name-sec span {
    font-size: 13px;
}

.new-profile {
    /* margin-top: 50px; */
}

img.img-fluid.new-profile-image {
    width: 130px;
    height: 130px;
    object-fit: cover;
    border-radius: 50%;
    border: 4px solid white;
    box-shadow: 1px 1px 4px #0000001c;
}

svg.feather.feather-edit {
    width: 18px;
}

.float-r {
    float: right;
}

.pers-social .fa-facebook {
    color: #3B5998;
    font-size: 20px;
    margin-right: 5px;
}

.pers-social .fa-twitter {
    color: #55ACEE;
    font-size: 20px;
    margin-right: 5px;
}

.pers-social .fa-linkedin {
    color: #007bb5;
    font-size: 20px;
    margin-right: 5px;
}

.pers-social .fa-google {
    color: #dd4b39;
    font-size: 20px;
    margin-right: 5px;
}

.skills {
    position: relative;
}

.skills {
    font-size: 12px;
    color: black;
    background-color: #db8e104a;
    display: inline-block;
    padding: 6px 10px;
    border-radius: 4px;
    box-shadow: 1px 1px 2px #00000026;
    margin-right: 10px;
    margin-bottom: 10px;
}

.interestareas {
    font-size: 12px;
    color: black;
    background-color: #31b30d4a;
    display: inline-block;
    padding: 6px 10px;
    border-radius: 4px;
    box-shadow: 1px 1px 2px #00000026;
    margin-right: 10px;
    margin-bottom: 10px;
}

.pro-points-counter {
    /* display: flex; */
    justify-content: center;
    /* margin-top: 30px; */
    width: 100%;
}

.points-rank span {
    color: white;
    margin-bottom: 5px;
    display: inline-block;
    min-height: 47px;
}

.pro-page-count-sec {
    padding: 15px 20px;
    border: 1px solid #f2f2f2;
    box-shadow: 1px 1px #00000012;
    border-radius: 4px;
    margin-bottom: 20px;
    background-color: white;
    background-image: linear-gradient(to right, white, #ffe0ad);
}

.gray-text {
    color: #1b1b1b !important;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow: hidden;
}

.cam-curr-point {
    background-color: #01539d;
    color: white;
    width: 100px;
    padding: 7px;
    margin: 0px auto;
    border-radius: 5px;
    font-size: 25px;
}

.pp-p-text {
    color: white;
    margin-bottom: 5px;
    display: inline-block;
}

.new-pro-space h5 {
    font-size: 18px;
    font-weight: 600 !important;
}

.new-job-pro-right-wrap {
    padding: 15px 20px;
    border: 1px solid #f2f2f2;
    box-shadow: 1px 1px #00000012;
    border-radius: 4px;
    margin-bottom: 20px;
}

.clr-f {
    float: left;
    width: 100%;
    margin-bottom: 5px;
}

.float-l {
    float: left;
}

.m-acti-wrap .sec-1 {
    display: inline-flex;
    width: 100%;
}

.m-acti-wrap .two h6 {
    color: #525252;
    font-size: 12px;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    text-overflow: ellipsis;
    overflow: hidden;
   
}

.m-acti-wrap .two span {
    color: #1a1a1a;
    font-weight: 500;
    font-size: 14px;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    text-overflow: ellipsis;
    overflow: hidden;
}

.m-acti-wrap .three h6 {
    color: #525252;
    font-size: 12px;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    text-overflow: ellipsis;
    overflow: hidden;
}

.m-acti-wrap .three span {
    color: #1a1a1a;
    font-weight: 500;
    font-size: 14px;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    text-overflow: ellipsis;
    overflow: hidden;
}

.m-acti-wrap {
    padding: 5px 0px;
}

.m-acti-wrap .sec-1 .ones {
    width: 30%;
}

.m-acti-wrap .sec-1 .two {
    width: 70%;
}

.m-acti-wrap .sec-1 .three {
    width: 100%;
}

.m-acti-wrap .sec-1 .ones img {
    width: 60px;
    height: 60px;
    margin: 0px auto;
    display: block;
    border-radius: 50%;
}

.m-acti-wrap {
    padding: 5px 0px;
}

.m-acti-wrap .sec-1 .ones {
    width: 30%;
}

.m-acti-wrap .sec-1 .two {
    width: 70%;
}

.m-acti-wrap .sec-1 .three {
    width: 100%;
}

.m-acti-wrap .sec-1 .ones img {
    width: 60px;
    height: 60px;
    margin: 0px auto;
    display: block;
    border-radius: 50%;
}

@media (min-width: 768px) {

    .container>.navbar-header,
    .container-fluid>.navbar-header,
    .container>.navbar-collapse,
    .container-fluid>.navbar-collapse {
        margin-right: 0;
        margin-left: -10px;
    }

    /* .marketplaceSearch {
    padding: "0px 30px 0px 0px";
    width: 300px;
    margin-bottom: 5px;
    max-width: 100%;
} */


    .fixed-bottom-sec-mob {
        display: none;
    }

    .pro-points-counter {
        /* display: flex; */
        justify-content: center;
        /* margin-top: 30px; */
    }

    .reg-m-bttn {
        width: 50% !important;
    }

    .reg-m-bttn-disabled {
        width: 50% !important;
        background-color: #717171;
        cursor: default;

    }

    .per-main-name {
        color: black;
        font-weight: 500;
    }

    .new-pro-main-btn {
        margin-top: 25px;
        text-align: center;
    }


}


/*********notification*********/

.pull-right>.dropdown-menu {
    right: 0;
    left: auto;
    top: 60px;
}

.dropdown-menu {
    position: absolute;
    top: 107%;
    left: 0;
    z-index: 1000;
    display: none;
    float: left;
    min-width: 280px;
    padding: 0px;
    margin: 2px 0 0;
    font-size: 14px;
    text-align: left;
    list-style: none;
    background-color: #fff;
    -webkit-background-clip: padding-box;
    background-clip: padding-box;
    border: none;
    border: 0px solid;
    border-radius: 0px;
    -webkit-box-shadow: 0 6px 12px rgba(0, 0, 0, .175);
    box-shadow: 0 6px 12px rgba(0, 0, 0, .175);
}

.dropdown-menu:after {
    border-bottom: 6px solid #ffffff;
    border-left: 6px solid rgba(0, 0, 0, 0);
    border-right: 6px solid rgba(0, 0, 0, 0);
    content: "";
    display: inline-block;
    right: 24px;
    position: absolute;
    top: -6px;
}

.dropdown-menu>li>a:hover,
.dropdown-menu>li>a:focus {
    color: #262626;
    text-decoration: none;
    background-color: transparent;
}

.card-title-text {
    padding-left: 15px;
}

.list-group-item {
    position: relative;
    display: block;
    padding: 5px 0px;
    margin-bottom: 0px;
    background-color: #fff;
    border: solid 0px;
    border-bottom: 1px solid #ddd;
}

.media-object {
    display: block;
    border-radius: 50%;
}

.dropdown-menu>li>a {
    display: block;
    padding: 3px 15px;
}

.notifications_title {
    font-size: 15px;
    color: #4CAF50;
    text-align: right;
    padding: 3px;
    padding-right: 10px;
}

/* .customRow .customWidth {
    margin-right: 27px;
    margin-left: 18px;
} */

.customRow .customWidth {
    margin-right: 27px;
    margin-left: 3px;
}

/*******************TOP NAVBAR FIXED NAVBAR END*******************/


/*=======================SIDEBAR NAV START=======================*/
.hit_sidebar {
    position: relative;
    display: block;
    min-height: 100%;
    overflow-y: auto;
    overflow-x: hidden;
    overflow: hidden;
    border: none;
    transition: all .3s cubic-bezier(.55, 0, .1, 1);
    padding-top: 64px;
    background: #fff;
    width: 280px;
    background-color: #1f2f46;
    /*    transform: translate3d(-280px,0,0);*/


}

.small_sidebar {
    width: 80px;
    min-width: 64px;
    transform: translate3d(0px, 0px, 0px);
    -webkit-transform: translate3d(0, 0, 0);
    -moz-transform: translate3d(0, 0, 0);

}

.sidebar-open {
    min-width: 274px;
    width: 274px;
    transform: translate3d(0, 0, 0);
}

.sidebar-slide-push {
    position: fixed;
    top: 0;
    bottom: 0;
    z-index: 999;
    left: -15px;
}

/*upload files*/
#customFile .custom-file-input:lang(en)::after {
    content: "Select file...";
}

#customFile .custom-file-input:lang(en)::before {
    content: "Click me";
}

/*when a value is selected, this class removes the content */
.custom-file-input.selected:lang(en)::after {
    content: "" !important;
}

.custom-file {
    overflow: hidden;
}

.custom-file-input {
    white-space: nowrap;
}

.custom-file.custom-upload {
    height: 48px;
    box-shadow: 0px 0px 4px 2px rgb(0 0 0 / 4%);
}

.custom-file-label.upload-inputs {
    font-size: 14px;
    height: 48px;
    border: solid 1px #eaeaea;
    border-radius: 6px;
    line-height: 36px;
    color: #b8b8b8;
}

.custom-file-input.file-input {
    height: 48px;
}

/*upload files*/
.multiple-group {
    width: 100%;
    background: rgba(0, 0, 0, 0) url(../public/assests/images/dropdown.png) no-repeat scroll right center;
    text-align: left;
    border: solid 1px #eaeaea;
    border-radius: 6px;
    box-shadow: 0px 0px 4px 2px rgb(0 0 0 / 4%);
    height: 48px;
}

.multiselect.multiselect-drop {
    width: 100%;
    background: transparent;
    color: #b8b8b8 !important;
    text-align: left;
}

.multiselect-container.multiselect-container-bg::-webkit-scrollbar-track,
.address::-moz-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    border-radius: 10px;
    background-color: #F5F5F5;
}

.multiselect-container.multiselect-container-bg::-webkit-scrollbar {
    width: 7px;
    background-color: #F5F5F5;
}

.multiselect-container.multiselect-container-bg::-webkit-scrollbar-thumb {
    border-radius: 10px;
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, .3);
    background-color: #D3D3D3;
}

.multiselect-container.multiselect-container-bg:-webkit-scrollbar-thumb {
    background-color: #a7a9ac;
    border-radius: 4px;
}

.multiselect-container.multiselect-container-bg {
    width: 100%;
    padding: 10px 0px;
    overflow-y: scroll;
    height: 200px;
    overflow-x: hidden;
}

.multiselect-container>li>a>label {
    padding: 3px 20px 3px 20px !important;
    width: 100%;
    color: #0948b3;
    font-size: 14px;
}

.multiselect-container>li>a>label>input[type=checkbox] {
    position: relative;
    top: 2px;
    margin-right: 8px;
}

.custom-file-input:lang(en)~.custom-file-label::after {
    content: "Upload" !important;
    height: 48px;
    line-height: 45px;
    border-radius: 4px;
    border: none;
    padding: 0px 30px;
    font-size: 14px;
    font-weight: 500;
    text-transform: uppercase;
    ;
}

/*tabs*/
.nav-tabs.inline-flex {
    display: inline-flex;
    margin: 0 auto;
}

.list-tabs-content {
    border: 1px solid #c4c6c9;
    border-radius: 10px;
    padding: 16px 7px 9px;
}

.nav-tabs.projecttab {
    border-bottom: 0px solid #dee2e6;
}

.nav-tabs.projecttab .nav-item.project-itam.show .nav-link,
.project-link,
.nav-tabs.projecttab .nav-link.active.project-link.active {
    color: #017477;
    background-color: #fff;
    border-color: #dee2e6 #dee2e6 #fff;
    margin-bottom: 10px;
}

.nav-link.project-link {
    display: block;
    padding: 0.5rem 4px;
    /* margin: 0px 20px; */
    margin: 0px 8px;
}

.project-tab>.nav-tabs.projecttab .nav-link.project-link {
    border: 0px solid transparent;
    background: transparent;
    font-size: 17px;
}

.project-tab {
    padding: 0;
    margin-top: 0;
}

.project-tab #tabs {
    background: #007b5e;
    color: #eee;
}

.project-tab #tabs h6.section-title {
    color: #eee;
}

.nav-link.project-link:first-child {
    margin-left: 0px;
}

.nav-link.project-link:last-child {
    margin-right: 0px;
}

.project-tab>.nav-tabs .nav-link {
    border: 0px solid transparent;
}

.project-tab #tabs .nav-tabs .nav-item.show .nav-link,
.nav-tabs .nav-link.active {
    color: #8c8c8c;
    background-color: transparent !important;
    border-color: transparent transparent #f3f3f3;
    border-bottom: 3px solid !important;
    font-size: 22px;
    font-weight: bold;
    border-top: none !important;
}

.project-tab .nav-link {
    border: 1px solid transparent;
    border-top-left-radius: .25rem;
    border-top-right-radius: .25rem;
    color: #8c8c8c;
    font-size: 16px;
    font-weight: 400;
}

.project-tab .nav-link:hover {
    border: none;
}

.project-tab thead {
    background: #f3f3f3;
    color: #333;
}

.project-tab a {
    text-decoration: none;

}

.iframeContentWrapper {
    float: left;
    width: 50%;
    margin-bottom: 100px;
    height: 300px;
}

.innerSpace {
    margin-right: 25px;
    margin-left: 25px;
}

.chartWrapper1 {
    padding: 3;
    background-color: #E9E9E9;
    border-style: solid;
    border-width: 1.5px;
    margin: 4;
    border-radius: 4;
    width: 500px;
    height: 280px;
    /* height: 100%; */

}
::placeholder {
    opacity: 0.5;
}
.backBtnSOAdmin {
    display: "flex";
    align-items: "center";
    /* padding: "160px 0px 0px 260px"; */
    padding: "80px 0px 0px 260px";

}

#SOADashboardScreen {
    padding: 4px;
}

.marketplaceSearch {
    padding: 0px 30px 0px 0px;
    width: 300px;
    margin-bottom: 5px;
    /* max-width: 100%; */
}

.marketplaceSearchTitle {
    color: #000;
}
.profileLeftIcon svg {
    width: 25px;
    margin: 1px;
    margin-top: 5px;
}

/*tabs*/
@media (min-width: 280px) and (max-width: 370px) { 
    .leftColumn, .rightColumn {
        width:100% !important;
        margin-top:10px;
    }
    .cardWrapperMain {
        display:block !important;
    }
    .boxContainerWrapper
    {
        white-space: nowrap;
        width: 100%;
        overflow-x: auto;
    }
    .boxContainerWrapperDesktop
    {
        white-space: nowrap;
        width: 100%;
        overflow-x: auto;
    }
    .boxContent {
        display: inline-block;
        float: initial !important;
    }
}
@media (min-width: 300px) and (max-width: 390px) { 
    .svgResponsiveGraph svg {
        height: auto !important;
        width: 80% !important;
        overflow: visible !important;
    }
    .svgResponsiveGraph div {
        overflow: visible !important;
    }
    .svgResponsiveGraph .highcharts-container svg {
        overflow: visible !important;
        margin-left:18px;
    }
    .boxContainerWrapper
    {
        white-space: nowrap;
        width: 100%;
        overflow-x: auto;
    }
    .boxContainerWrapperDesktop
    {
        white-space: nowrap;
        width: 100%;
        overflow-x: auto;
    }
    .boxContent {
        display: inline-block;
        float: initial !important;
    }
}
/*tabs*/
@media (min-width: 280px) and (max-width: 1100px) { 
    .leftColumn, .rightColumn {
        width:100% !important;
        margin-top:10px;
    }
    .cardWrapperMain {
        display:block !important;
    }
    .actionWrapperConent {
        width:100% !important;
        max-width:100% !important;
        flex:0 0 100% !important;
    }
    .columnFirst {
        max-width:100% !important;
        width:100% !important;
        flex:0 0 100% !important;
    }
  .fullWidthTableContainer > div, .fullWidthTableContainer{
    padding-left:0px !important;
    padding-right: 0px !important;
  }
}
@media (min-width: 768px) and (max-width: 1350px) { 
    .leftColumn, .rightColumn {
        width:100% !important;
        margin-top:10px;
    }
    .cardWrapperMain {
        display:block !important;
    }
    .columnFirst {
        max-width:100% !important;
        width:100% !important;
        flex:0 0 100% !important;
    }
    .actionWrapperConent {
        width:100% !important;
        max-width:100% !important;
        flex:0 0 100% !important;
    }
    .headerWelcome {
        margin-right:40px !important;
    }
}
@media (min-width: 768px) and (max-width: 1100px) 
{
    .headerWelcome {
        margin-right:40px !important;
    }
}
@media (min-width: 280px) and (max-width: 767px) { 
    .leftColumn, .rightColumn {
        width:100% !important;
        margin-top:10px;
    }
    .cardWrapperMain {
        display:block !important;
    }
    .actionWrapperConent {
        width:100% !important;
        max-width:100% !important;
        flex:0 0 100% !important;
    }
    .activityWrapper,.activityInnerWidth {
        min-height: auto !important;
        height:auto !important;
    }
    .fullWidthContent {
        height: auto !important;
        min-height: auto !important;
    }
    .activityHeight {
        max-height: 360px;
    }
    .actionTableWrapper button {
        margin-bottom: 3px;
    }
    .svgResponsive {
        display: block !important;
    }
}
@media (min-width: 280px) and (max-width: 575px) {
  
    .marketplaceSearch {
        /* width: 300px; */
        margin-bottom: 5px;
        /* max-width: 100%; */
    }
    .leader-b-table td {
        padding: 9px 4px !important;
    }
    .blockWrapperContainer {
        text-align:left !important;
    }

    .marketplaceSearchTitle {
        color: #000;
        text-align: center;
    }

    .hoursWrapper:last-child {
        margin-right: 0px;
    }

    .list-content .text-left {
        max-width: 50%;
        padding-left: 0px;
    }

    section.containers {
        /* min-height: 82%;
        height: 82%; */
    }

    .new-job-pro-right-wrap {
        padding: 15px 0px;
    }

    .item.slide1 h2 {
        font-size: 28px !important;
    }

    .list-content .text-right {
        max-width: 50%
    }

    .notificationWrapper,
    .messageHeader {
        width: 200px !important;
    }

    .iframeContentWrapper {
        width: 100%;
        margin-bottom: 50px;
        height: 250px;
    }

    .chartWrapper1 {
        padding: 3;
        background-color: #E9E9E9;
        border-style: solid;
        border-width: 1.5px;
        margin: 4;
        border-radius: 4;
        width: 500px;
        height: 280px;
        /* height: 100%; */

    }

    .backBtnSOAdmin {
        display: "flex";
        align-items: "center";
        padding: "160px 0px 0px 0px";

    }

    .react-player-volstate {
        height: 15vh !important;
        /* width: 40% !important; */
        width: 42vw !important;
    }

    .innerCampaign i.material-icons,
    .innerCampaign li a svg {
        display: none;
    }

    .containers footer,
    .landingPageWrapper footer,.footerFullWidth footer{
        position: relative;
        float: left;
        margin-bottom: 77px;
    }

    .containers .marketplaceCopyright footer,
    .landingPageWrapper .marketplaceCopyright footer {
        position: fixed;
        bottom: 0px;
    }

    .containers footerForSome {
        /* position: relative; */
        float: left;
    }

    .sidebar~.containers {
        margin-bottom: 100px !important;
    }

    .homesidebar~.containers {
        margin-bottom: 0px !important;
    }

    .content-mrg .playerWrapper {
        max-width: 100%;
        margin-left: 0px;
        margin-right: 0px;
    }

    .content-mrg .playerWrapper video {
        max-width: 100%;
    }

    .pointsMobileWrapper {
        position: relative;
        min-height: 150px;
    }

    .leftAbsolute {
        position: absolute !important;
        top: 80px;
        left: 0;
    }

    .rightAbsolute {
        position: absolute !important;
        top: 80px;
        right: 0;
    }

    /* .owl-stage-outer .owl-stage, .owl-stage-outer .owl-stage .owl-item {
        width: 100% !important;
    } */
    .truncateTwoLine {
        overflow: visible !important;
    }

    .truncateTwoLine {
        text-align: left !important;
    }

    .truncateTwoLine::before {
        opacity: 1 !important;
        background-image: none !important;
    }

    .responsiveViewCenter {
        margin-left: 0px !important;
    }

    .responsiveViewCampaignTemplate {
        margin-left: 0px !important;
        width: "274px"
    }

    .wrapper .sidebar~.containers {
        width: 100% !important;
    }

    /* Override owl carousel for mobile view */
    .hasScreen .owl-carousel .owl-stage,
    .hasScreen .owl-carousel .owl-item.active {
        width: 100% !important;
        max-width: 100% !important;
    }

    .hasScreen .owl-carousel .owl-stage {
        transform: translate3d(0px, 0px, 0px) !important;
    }

    .hasScreen .owl-carousel.owl-drag .owl-item {
        display: none !important;
        width: 100% !important;
    }

    .hasScreen .owl-carousel .owl-item.active {
        display: block !important;
    }

    .homeIcon,
    .SearchIcon {
        display: none;
    }

    .flaticon-home-line:before {
        color: #fff !important;
        -webkit-text-stroke: 0px #fff !important;
        text-stroke: 0px #fff !important;
    }

    .col-xs-6 {
        width: 50% !important;
    }

    .customMobieFullWidth .owl-stage-outer .owl-stage,
    .customMobieFullWidth .owl-stage-outer .owl-stage .owl-item.active {
        width: 100% !important;
    }

    .customMobieView .cyagg-sec-space.reg-camp-sec {
        border: 1px solid #ccc !important;
        box-shadow: 0px 3px 6px #00000029 !important;
        /* user-select: all; */
    }

    .customMobieView .campaigns-wrap.campaigns-wrap-new {
        box-shadow: none !important;
        border: none !important;
    }

    .customShareMobile .text-center.mob-space {
        padding: 15px;
        border: 1px solid #c4c4c4 !important
    }

    .customCampaignParticipateMobile {
        padding: 0px 0px;

    }

    .slick-list .slick-track:first-child {
        transform: translate3d(0px, 0px, 0px) !important;
    }

    .slick-slide {
        display: none !important;
    }

    .slick-slide.slick-active.slick-current,
    .slick-slide.slick-current {
        display: block !important;
    }

    .topSpace {
        margin-top: 30px;
    }
    .per-img .one1 {
        width:70px !important;
        height:70px !important;
    }
    .t-reward-wrap .col-one img,.overall-wrap .col-one img {
        width:45px !important;
        margin-right:5px;
        margin-top:5px;
    }
    .rightAbsolute .overall-wrap {
        float:right !important;
    }
    .askAgni {
        display:none;
    }
    .askAgni.mobileView {
        display:block !important;
    }
    .boxContainerWrapper
    {
        white-space: nowrap;
        width: 100%;
        overflow-x: auto;
    }
    .boxContainerWrapperDesktop
    {
        white-space: nowrap;
        width: 100%;
        overflow-x: auto;
    }
    .boxContent {
        display: inline-block;
        float: initial !important;
    }
}

@media only screen and (max-width : 767px) {
    .containers>.btns.click {
        left: 206px;
    }

    .truncateTwoLine {
        overflow: visible !important;
    }

    .truncateTwoLine {
        text-align: left !important;
    }

    .truncateTwoLine::before {
        opacity: 1 !important;
    }

    .marketplaceSearch {
        /* width: 300px; */
        margin-bottom: 5px;
        /* max-width: 100%; */
    }

    .containers>.btns {
        left: 0;
    }

    .containersVideo>.btns.click {
        left: 206px;
    }

    .containersVideo>.btns {
        left: 0;
    }

    .iframeContentWrapper {
        width: 100%;
    }

}

@media (max-width:768px) {
    .sidebar {
        left: -250px;
    }

    .sidebar.hide {
        left: 0;
    }

    .campaign-img .video-container video {
        width: 100%;
        /* height: 100%; */
        /* height: 300px; */
        /* height: 220px; */
        height: auto;
        border-radius: 4px;
    }

    .new-job-pro-right-wrap {
        padding: 15px 0px;
    }

    .sidebar~.containers {
        margin-left: 0;
        width: 100% !important;
    }

    .sidebar.hide~.containers>.btn {
        margin-left: 250px;
        transition: all 0.5s;
    }

    .sidebar~.containersVideo {
        margin-left: 0;
        width: 100% !important;
    }

    .sidebar.hide~.containersVideo>.btn {
        margin-left: 250px;
        transition: all 0.5s;
    }

    .wrappingMenuToggle>.btns {
        left: 0;
    }

    .sidebar.hide~.containers .wrappingMenuToggle>.btns {
        left: 206px;
    }

    .sidebar.hide~.containersVideo .wrappingMenuToggle>.btns {
        left: 206px;
    }
}

/* @media (min-width: 768px) and (max-width: 890px) {
    .customWidth, .customFieldWidth {
        flex: 0 0 100% !important;
        max-width: 100% !important;
    }
}
@media (min-width: 891px) and (max-width: 1239px) {
    .customFieldWidth {
        flex: 0 0 50% !important;
        max-width: 50% !important;
    }
}
@media (min-width: 1240px) and (max-width: 1500px) {
    .customWidth {
        flex: 0 0 33.33333333% !important;
        max-width: 33.33333333% !important;
    }
}
@media (min-width: 1501px) {
    .customWidth, .customFieldWidth {
        flex: 0 0 25% !important;
        max-width: 25% !important;
    }
} */
.feat-btn i img,
.feat-btn i svg {
    width: 27px;
    height: 27px;
    vertical-align: middle;
    bottom: 2px;
    position: relative;
    margin-right: 10px;
}

a.removePadding {
    padding-top: 0;
    padding-bottom: 0;
}

/*Newly added css */

.d-flex {
    display: flex;
}

.justify-content-center {
    justify-content: center;
}

.align-items-center {
    align-items: center;
}

/*---------signup-step-------------*/
.bg-color {
    background-color: #333;
}

.signup-step-container {
    padding: 55px 0px;
    padding-bottom: 0px;
}




.wizard .nav-tabs {
    position: relative;
    margin-bottom: 0;
    border-bottom-color: transparent;
}

.wizard>div.wizard-inner {
    position: relative;
    margin-bottom: 38px;
    text-align: center;
}

.connecting-line {
    height: 2px;
    background: #e0e0e0;
    position: absolute;
    width: 75%;
    margin: 0 auto;
    left: 0;
    right: 0;
    top: 15px;
    z-index: 1;
}

.wizard .nav-tabs>li.active>a,
.wizard .nav-tabs>li.active>a:hover,
.wizard .nav-tabs>li.active>a:focus {
    color: #555555;
    cursor: default;
    border: 0;
    border-bottom-color: transparent;
}

span.round-tab {
    width: 30px;
    height: 30px;
    line-height: 30px;
    display: inline-block;
    border-radius: 50%;
    background: #fff;
    z-index: 2;
    position: absolute;
    left: 0;
    text-align: center;
    font-size: 16px;
    color: #0e214b;
    font-weight: 500;
    border: 1px solid #ddd;
}

span.round-tab i {
    color: #555555;
}

.wizard li.active span.round-tab {
    background: #db8d10;
    color: #fff;
    border-color: #db8e0f;
}

.wizard li.active span.round-tab i {
    color: #5bc0de;
}

.wizard .nav-tabs>li.active>a i {
    color: #db8e0f;
}

.wizard .nav-tabs>li {
    width: 25%;
}

.wizard li:after {
    content: " ";
    position: absolute;
    left: 46%;
    opacity: 0;
    margin: 0 auto;
    bottom: 0px;
    border: 5px solid transparent;
    border-bottom-color: red;
    transition: 0.1s ease-in-out;
}



.wizard .nav-tabs>li a {
    width: 30px;
    height: 30px;
    margin: 20px auto;
    border-radius: 100%;
    padding: 0;
    background-color: transparent;
    position: relative;
    top: 0;
}

.wizard .nav-tabs>li a i {
    position: absolute;
    top: -15px;
    font-style: normal;
    font-weight: 400;
    white-space: nowrap;
    left: 50%;
    transform: translate(-50%, -50%);
    font-size: 12px;
    font-weight: 700;
    color: #000;
    left: 12px;
}

.wizard .nav-tabs>li a:hover {
    background: transparent;
}

.wizard .tab-pane {
    position: relative;
    padding-top: 0px;
}


.wizard h3 {
    margin-top: 0;
}

.prev-step,
.next-step {
    font-size: 13px;
    padding: 8px 24px;
    border: none;
    border-radius: 4px;
    margin-top: 30px;
}

.next-step {
    background-color: #0db02b;
}

.skip-btn {
    background-color: #cec12d;
}

.step-head {
    font-size: 20px;
    text-align: center;
    font-weight: 500;
    margin-bottom: 20px;
}

.term-check {
    font-size: 14px;
    font-weight: 400;
}

.custom-file {
    position: relative;
    display: inline-block;
    width: 100%;
    height: 40px;
    margin-bottom: 0;
}

.custom-file-input {
    position: relative;
    z-index: 2;
    width: 100%;
    height: 40px;
    margin: 0;
    opacity: 0;
}

.custom-file-label {
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    z-index: 1;
    height: 40px;
    padding: .375rem .75rem;
    font-weight: 400;
    line-height: 2;
    color: #495057;
    background-color: #fff;
    border: 1px solid #ced4da;
    border-radius: .25rem;
}

.custom-file-label::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    z-index: 3;
    display: block;
    height: 38px;
    padding: .375rem .75rem;
    line-height: 2;
    color: #495057;
    content: "Browse";
    background-color: #e9ecef;
    border-left: inherit;
    border-radius: 0 .25rem .25rem 0;
}

.footer-link {
    margin-top: 30px;
}

.all-info-container {}

.list-content {
    margin-bottom: 10px;
}

.list-content a {
    padding: 10px 15px;
    width: 100%;
    display: inline-block;
    background-color: #f5f5f5;
    position: relative;
    color: #565656;
    font-weight: 400;
    border-radius: 4px;
}

.list-content a[aria-expanded="true"] i {
    transform: rotate(180deg);
}

.list-content a i {
    text-align: right;
    position: absolute;
    top: 15px;
    right: 10px;
    transition: 0.5s;
}

.form-control[disabled],
.form-control[readonly],
fieldset[disabled] .form-control {
    background-color: #fdfdfd;
}

.list-box {
    padding: 10px;
}

.signup-logo-header .logo_area {
    width: 200px;
}

.signup-logo-header .nav>li {
    padding: 0;
}

.signup-logo-header .header-flex {
    display: flex;
    justify-content: center;
    align-items: center;
}

.list-inline li {
    display: inline-block;
}

.pull-right {
    float: right;
}

/*-----------custom-checkbox-----------*/
/*----------Custom-Checkbox---------*/
input[type="checkbox"] {
    position: relative;
    display: inline-block;
    margin-right: 5px;
}

input[type="checkbox"]::before,
input[type="checkbox"]::after {
    position: absolute;
    content: "";
    display: inline-block;
}

input[type="checkbox"]::before {
    height: 16px;
    width: 16px;
    border: 1px solid #999;
    left: 0px;
    top: 0px;
    background-color: #fff;
    border-radius: 2px;
}

input[type="checkbox"]::after {
    height: 5px;
    width: 9px;
    left: 4px;
    top: 4px;
}

input[type="checkbox"]:checked::after {
    content: "";
    border-left: 1px solid #fff;
    border-bottom: 1px solid #fff;
    transform: rotate(-45deg);
}

input[type="checkbox"]:checked::before {
    background-color: #18ba60;
    border-color: #18ba60;
}






@media (max-width: 767px) {
    .sign-content h3 {
        font-size: 40px;
    }

    /* .wizard .nav-tabs > li a i{
      display: none;
    } */
    .signup-logo-header .navbar-toggle {
        margin: 0;
        margin-top: 8px;
    }

    .signup-logo-header .logo_area {
        margin-top: 0;
    }

    .signup-logo-header .header-flex {
        display: block;
    }
}

a.campaign-signin {
    display: none;
}


/* Structure css */

#content {
    margin-top: 92px;
    margin-left: 125px;
    width: 100%;
    width: 50%;
    flex-grow: 8;
    margin-bottom: 67px;
    transition: all .24s ease-in-out;
}

.dropdown-item {
    line-height: 1.8;
    padding: 0.625rem 1rem;
}

/*
  ===============
      Desktop Nav
  ===============
  */

.desktop-nav.header {
    background-color: #fff;
    padding: 15px 10px;
    z-index: 1040;
    box-shadow: 126px 0px 20px -2px rgba(22, 30, 42, 0.16);
    box-shadow: 0 1px 15px rgba(0, 0, 0, .04), 0 1px 6px rgba(0, 0, 0, .04);
}

.desktop-nav.header .nav-logo a.sidebarCollapse i {
    font-size: 31px;
    vertical-align: middle;
    color: #acb0c3;
}

.desktop-nav.header .nav-logo a span.navbar-brand-name {
    font-size: 20px;
    vertical-align: middle;
    color: #1a73e9;
    font-weight: 600;
}

.desktop-nav.header .nav-logo-land-direct a.sidebarCollapse i {
    font-size: 31px;
    vertical-align: middle;
    color: #acb0c3;
}

.desktop-nav.header .nav-logo-land-direct a span.navbar-brand-name {
    font-size: 20px;
    vertical-align: middle;
    color: #1a73e9;
    font-weight: 600;
}

.desktop-nav.header .nav-logo-land-direct figcaption {
    font-size: 10px;
    margin-left: 5px;
    /* padding: 8px; */
}

/*      Search Form       */
.desktop-nav.header .navbar-nav .form-inline.search {
    padding: 11px 0;
    position: relative;
}

.desktop-nav.header .navbar-nav .form-inline.search:before {
    position: absolute;
    font-family: flaticon;
    left: 0px;
    content: "";
    top: 17px;
    z-index: 6;
    font-size: 21px;
    color: rgb(211, 211, 211);
    pointer-events: none;
}

.desktop-nav.header .navbar-nav .form-inline.search input {
    font-size: 17px;
    background-color: transparent;
    padding-left: 31px;
    padding-top: 9px;
    cursor: pointer;
    border: none;
    -webkit-border-radius: 10em;
    -moz-border-radius: 10em;
    border-radius: 10em;
    color: #1f212d;
}

.desktop-nav.header .navbar-nav .form-inline.search input::-webkit-input-placeholder {
    /* Chrome/Opera/Safari */
    color: #d3d3d3;
}

.desktop-nav.header .navbar-nav .form-inline.search input::-moz-placeholder {
    /* Firefox 19+ */
    color: #d3d3d3;
}

.desktop-nav.header .navbar-nav .form-inline.search input:-ms-input-placeholder {
    /* IE 10+ */
    color: #d3d3d3;
}

.desktop-nav.header .navbar-nav .form-inline.search input:-moz-placeholder {
    /* Firefox 18- */
    color: #d3d3d3;
}

/*   Language   */

.desktop-nav.header .navbar-nav .language-dropdown {
    border-left: 1px solid #e9ecef;
    border-right: 1px solid #e9ecef;
}

.desktop-nav.header .navbar-nav .language-dropdown.show a {
    color: #4f5163;
}

.desktop-nav.header .navbar-nav .language-dropdown a:hover {
    color: #4f5163;
}

.desktop-nav.header .navbar-nav .language-dropdown a span {
    font-size: 14px;
    font-weight: 600;
}

.desktop-nav.header .navbar-nav .language-dropdown a:after {
    display: none;
}

.desktop-nav.header .navbar-nav .language-dropdown a img {
    margin-right: 8px;
}

/*   Language Dropdown  */
.desktop-nav.header .navbar-nav .language-dropdown .dropdown-menu {
    border: none;
    border-radius: 4px;
    min-width: 7rem;
    box-shadow: 0px 0px 15px 1px rgba(113, 106, 202, 0.2);
    top: 63px;
}

.desktop-nav.header .navbar-nav .language-dropdown .dropdown-menu .dropdown-item:hover {
    border-radius: 15px;
    background-color: transparent;
}

.desktop-nav.header .navbar-nav .language-dropdown .dropdown-menu .dropdown-item.active,
.desktop-nav.header .navbar-nav .language-dropdown .dropdown-menu .dropdown-item:active {
    background-color: transparent;
}

.desktop-nav.header .navbar-nav .language-dropdown .dropdown-menu .dropdown-item {
    color: #1f212d;
    font-weight: 600;
    font-size: 14px;
    padding: 5px 10px;
}

.desktop-nav.header .navbar-nav .language-dropdown .dropdown-menu .dropdown-item span {
    color: #3b3f5c;
    font-size: 14px;
    font-weight: 600;
    padding-bottom: 20px;
    width: 60%;
}

.desktop-nav.header .navbar-nav .language-dropdown .dropdown-menu .dropdown-item:hover span {
    color: #000;
}

/* User Profile */

.desktop-nav.header .navbar-nav .user-profile-dropdown a.user:after {
    display: none;
}

.desktop-nav.header .navbar-nav .user-profile-dropdown a.user img {
    border-radius: 50%;
    width: 40px;
    box-shadow: 0 0 15px 2px #c2d5ff;
}

.desktop-nav.header .navbar-nav .user-profile-dropdown a.user i {
    font-size: 23px;
    color: #1a73e9;
}

.desktop-nav.header .navbar-nav .user-profile-dropdown a.user .user-profile {
    position: relative;
}

.desktop-nav.header .navbar-nav .user-profile-dropdown a.user .user-profile:before {
    position: absolute;
    content: '';
    height: 10px;
    width: 10px;
    background-color: #1abc9c;
    border-radius: 50%;
    bottom: 0;
    right: 0;
    z-index: 1;
    border: 2px solid #fff;
}

/* User Profile Dropdown */
.desktop-nav.header .navbar-nav .user-profile-dropdown .dropdown-menu {
    border: none;
    border-top: 3px solid #ee3d50;
    border-radius: 15px;
    min-width: 12rem;
    box-shadow: 0px 0px 15px 1px rgba(113, 106, 202, 0.2);
}

.desktop-nav.header .navbar-nav .user-profile-dropdown .dropdown-menu .dropdown-item.active,
.desktop-nav.header .navbar-nav .user-profile-dropdown .dropdown-menu .dropdown-item:active {
    background-color: transparent;
}

.desktop-nav.header .navbar-nav .user-profile-dropdown .dropdown-menu div.dropdown-item {
    padding: 10px 0 0;
}

.desktop-nav.header .navbar-nav .user-profile-dropdown .dropdown-menu div.dropdown-item:hover {
    border-radius: 15px;
    background-color: transparent;
}

.desktop-nav.header .navbar-nav .user-profile-dropdown .dropdown-menu div.dropdown-item p {
    color: #9297af;
    font-size: 11px;
    font-weight: 600;
}

.desktop-nav.header .navbar-nav .user-profile-dropdown .dropdown-menu div.dropdown-item div i {
    color: #9297af;
    font-weight: 600;
    font-size: 14px;
}

.desktop-nav.header .navbar-nav .user-profile-dropdown .dropdown-menu a.dropdown-item {
    padding: 4px 8px 4px;
    line-height: 1.5;
}

.desktop-nav.header .navbar-nav .user-profile-dropdown .dropdown-menu a.dropdown-item:hover {
    background-color: transparent;
}

.desktop-nav.header .navbar-nav .user-profile-dropdown .dropdown-menu a.dropdown-item i {
    padding: 6px;
    border-radius: 50%;
    border: 1px solid #5c52c2;
    color: #5c52c2;
    font-size: 19px;
}

.desktop-nav.header .navbar-nav .user-profile-dropdown .dropdown-menu a.dropdown-item span {
    color: #1f212d;
    font-size: 14px;
}

.desktop-nav.header .navbar-nav .user-profile-dropdown .dropdown-menu a.dropdown-item:hover span {
    color: #5247bd;
}

.desktop-nav.header .navbar-nav .user-profile-dropdown .dropdown-menu div.dropdown-item-btn {
    padding: 13px 20px;
}

.desktop-nav.header .navbar-nav .user-profile-dropdown .dropdown-menu div.dropdown-item-btn a {
    background: #1b1c36;
    border-radius: 30px;
    padding: 3px 10px;
}

.desktop-nav.header .navbar-nav .user-profile-dropdown .dropdown-menu div.dropdown-item-btn a i {
    color: #e7515a;
    font-weight: 600;
    font-size: 19px;
}

.desktop-nav.header .navbar-nav .user-profile-dropdown .dropdown-menu div.dropdown-item-btn a span {
    color: #fff;
    font-size: 14px;
}


/* Message */

.desktop-nav.header .navbar-nav .message-dropdown a:after {
    display: none;
}

.desktop-nav.header .navbar-nav .message-dropdown a span.icon {
    display: block;
    font-size: 23px;
    color: #1f212d;
}

.desktop-nav.header .navbar-nav .message-dropdown a span.badge {
    position: absolute;
    top: 50%;
    margin-top: -17px;
    right: -13px;
    border-radius: 50%;
    background-color: #1a73e9;
    padding: 1px;
    border: 2px solid #1a73e9;
    font-size: 11px;
}

.desktop-nav.header .navbar-nav .message-dropdown.show a span.badge {
    background-color: #c2d5ff;
    border: 2px solid #c2d5ff;
    color: #1a73e9;
}

/* Message Dropdown */
.desktop-nav.header .navbar-nav .message-dropdown .dropdown-menu {
    border: none;
    border-radius: 4px;
    min-width: 18rem;
    box-shadow: 0px 0px 15px 1px rgba(113, 106, 202, 0.2);
    right: 0;
    left: auto;
    top: 63px;
}

.desktop-nav.header .navbar-nav .message-dropdown .dropdown-menu a.dropdown-item {
    cursor: pointer;
}

.desktop-nav.header .navbar-nav .message-dropdown .dropdown-menu a.dropdown-item:hover {
    background-color: #f1f3f1;
}

.desktop-nav.header .navbar-nav .message-dropdown .dropdown-menu .dropdown-item.active,
.desktop-nav.header .navbar-nav .message-dropdown .dropdown-menu .dropdown-item:active {
    background-color: transparent;
}

.desktop-nav.header .navbar-nav .message-dropdown .dropdown-menu a.dropdown-item>div .media.notification-new .usr-img,
.desktop-nav.header .navbar-nav .message-dropdown .dropdown-menu a.dropdown-item>div .media.notification-new .notification-icon {
    position: relative;
}

.desktop-nav.header .navbar-nav .message-dropdown .dropdown-menu a.dropdown-item>div .media.notification-new .usr-img:before,
.desktop-nav.header .navbar-nav .message-dropdown .dropdown-menu a.dropdown-item>div .media.notification-new .notification-icon:before {
    position: absolute;
    content: '';
    height: 10px;
    width: 10px;
    background-color: #ff6f00;
    border-radius: 50%;
    bottom: 0;
    right: 0;
    z-index: 1;
    border: 2px solid #fff;
}

.desktop-nav.header .navbar-nav .message-dropdown .dropdown-menu a.dropdown-item>div .media .usr-img img {
    width: 35px;
    height: 35px;
    border: none;
}

.desktop-nav.header .navbar-nav .message-dropdown .dropdown-menu a.dropdown-item>div .media .media-body {
    flex: auto;
}

.desktop-nav.header .navbar-nav .message-dropdown .dropdown-menu a.dropdown-item>div .media .media-body p.meta-user-name,
.desktop-nav.header .navbar-nav .message-dropdown .dropdown-menu a.dropdown-item>div .media .media-body p.meta-title {
    color: #1f212d;
    font-weight: 600;
    font-size: 14px;
}

.desktop-nav.header .navbar-nav .message-dropdown .dropdown-menu a.dropdown-item>div .media .media-body p.meta-time {
    color: #888ea8;
    font-weight: 600;
    font-size: 11px;
}

.desktop-nav.header .navbar-nav .message-dropdown .dropdown-menu a.dropdown-item>div .media .media-body p.message-text {
    color: #888ea8;
    font-size: 13px;
    white-space: normal;
}

.desktop-nav.header .navbar-nav .message-dropdown .dropdown-menu a.dropdown-item>div .media .notification-icon i {
    font-size: 22px;
    background: #c2d5ff;
    border-radius: 50%;
    padding: 7px;
    color: #1a73e9;
}

/* Notification */

.desktop-nav.header .navbar-nav .notification-dropdown a:after {
    display: none;
}

.desktop-nav.header .navbar-nav .notification-dropdown a span.icon {
    display: block;
    font-size: 23px;
    color: #1f212d;
}

.desktop-nav.header .navbar-nav .notification-dropdown a span.badge {
    position: absolute;
    top: 50%;
    margin-top: -17px;
    right: -13px;
    border-radius: 50%;
    background-color: #1a73e9;
    padding: 1px;
    border: 2px solid #1a73e9;
    font-size: 11px;
}

.desktop-nav.header .navbar-nav .notification-dropdown.show a span.badge {
    background-color: #c2d5ff;
    border: 2px solid #c2d5ff;
    color: #1a73e9;
}

/* Notification Dropdown */

.desktop-nav.header .navbar-nav .notification-dropdown .dropdown-menu {
    border: none;
    border-radius: 4px;
    min-width: 195px;
    min-width: 14rem;
    max-width: 316px;
    padding: 8px 5px;
    box-shadow: 0px 0px 15px 1px rgba(113, 106, 202, 0.2);
    right: 0;
    left: auto;
    top: 63px;
}

.desktop-nav.header .navbar-nav .notification-dropdown .dropdown-menu .notification-scroll {}

.desktop-nav.header .navbar-nav .notification-dropdown .dropdown-menu .dropdown-item.active,
.desktop-nav.header .navbar-nav .notification-dropdown .dropdown-menu .dropdown-item:active {
    background-color: transparent;
}

.desktop-nav.header .navbar-nav .notification-dropdown .dropdown-menu .dropdown-item {
    padding: 10px 12px !important
}

.desktop-nav.header .navbar-nav .notification-dropdown .dropdown-menu .dropdown-item a .media .user-profile {
    position: relative;
}

.desktop-nav.header .navbar-nav .notification-dropdown .dropdown-menu .dropdown-item a .media .user-profile:before {
    position: absolute;
    content: '';
    height: 10px;
    width: 10px;
    background-color: #1abc9c;
    border-radius: 50%;
    bottom: 0;
    right: 23px;
    z-index: 1;
    border: 2px solid #fff;
}

.desktop-nav.header .navbar-nav .notification-dropdown .dropdown-menu .dropdown-item a .media img {
    width: 38px;
    height: 38px;
    border-radius: 50%;
    margin-right: 23px;
    border: none;
}

.desktop-nav.header .navbar-nav .notification-dropdown .dropdown-menu .dropdown-item a .media-body {
    align-self: center;
}

.desktop-nav.header .navbar-nav .notification-dropdown .dropdown-menu .dropdown-item a .media-body span {
    color: #1f212d;
    font-weight: 600;
    font-size: 14px;
}

.desktop-nav.header .navbar-nav .notification-dropdown .dropdown-menu .dropdown-item:hover a .media-body span {
    color: #3232b7;
}

.desktop-nav.header .navbar-nav .notification-dropdown .dropdown-menu div.dropdown-item:hover {
    border-radius: 0;
    background-color: transparent;
}

/*  
      ======================
          Profile Sidebar
      ======================
  */
.profile-sidebar {
    position: fixed;
    top: 91px;
    padding: 10px 4px;
    display: flex;
    height: 100vh;
    right: -375px;
    width: auto;
    z-index: 1038;
    background: #fcfdfe;
    -webkit-transition: right .3s ease-in-out;
    -o-transition: right .3s ease-in-out;
    transition: right .3s ease-in-out;
    -webkit-box-shadow: -1px 1px 6px 0px rgba(113, 106, 202, 0.13);
    -moz-box-shadow: -1px 1px 6px 0px rgba(113, 106, 202, 0.13);
    box-shadow: -1px 1px 6px 0px rgba(113, 106, 202, 0.13);
}

.profile-sidebar.profile-sidebar-open {
    right: 0;
}

.profile-sidebar .profile-content {
    width: 295px;
    padding: 0px 25px;
}

.profile-sidebar .profile-content .usr-profile {
    margin: 6px 0;
}

.profile-sidebar .profile-content .usr-profile img {
    border-radius: 50%;
    box-shadow: 0px 3px 15px 2px #c2d5ff;
}

.profile-sidebar .profile-content .user-name {
    color: #1a73e9;
    font-weight: 600;
    font-size: 16px;
}

.profile-sidebar .profile-content .accordion .card {
    border: none;
    background-color: #fcfdfe;
}

.profile-sidebar .profile-content .accordion .card-header {
    background-color: transparent;
    border: none;
    border-bottom: 1px solid #e6e6e6;
    cursor: pointer;
}

.profile-sidebar .profile-content .accordion .card-header h6 {
    font-weight: 600;
    color: #1f212d;
    font-size: 14px;
}

.profile-sidebar .profile-content .accordion .card-header h6 i {
    font-size: 16px;
}

.profile-sidebar .profile-content .accordion .card-header h6 i.flaticon-down-arrow {
    font-size: 10px;
    font-weight: 600;
}

.profile-sidebar .profile-content .accordion .card .card-body {
    padding: 0;
}

.profile-sidebar .profile-content .accordion .card .card-body ul {
    border-bottom: 1px solid #e6e6e6;
}

.profile-sidebar .profile-content .accordion .card .card-body ul li {
    padding: 10px;
    font-weight: 600;
}

.profile-sidebar .profile-content .accordion .card .card-body ul li a {
    position: relative;
    padding-left: 93px;
    color: #888ea8;
}

.profile-sidebar .profile-content .accordion .card .card-body ul li a:before {
    position: absolute;
    content: '';
    background-color: #000;
    height: 10px;
    width: 10px;
    border-radius: 50%;
    left: 66px;
    top: 4px;
}

.profile-sidebar .profile-content .accordion .card .card-body ul li.status-online a:before {
    background-color: #1abc9c;
    border: 3px solid #b6fff1;
}

.profile-sidebar .profile-content .accordion .card .card-body ul li.status-away a:before {
    background-color: #e9b02b;
    border: 3px solid #ffeccb;
}

.profile-sidebar .profile-content .accordion .card .card-body ul li.status-no-disturb a:before {
    background-color: #f8538d;
    border: 3px solid #fbccdd;
}

.profile-sidebar .profile-content .accordion .card .card-body ul li.status-invisible a:before {
    background-color: #00b1f4;
    border: 3px solid #bae7ff;
}

.profile-sidebar .profile-content .accordion .card .card-body ul li.status-offline a:before {
    background-color: #4f5163;
    border: 3px solid #acb0c3;
}

.profile-sidebar .profile-content .user-links li {
    padding: 10px 0 12px 0;
}

.profile-sidebar .profile-content .user-links li:last-child {
    margin: 14px 0 16px 0;
    border-top: 1px solid #e6e6e6;
    padding-top: 18px;
}

.profile-sidebar .profile-content .user-links li a {
    padding-left: 71px;
    font-weight: 600;
    color: #888ea8;
}

.profile-sidebar .profile-content .user-links li:hover a {
    color: #1a73e9;
}

.profile-sidebar .profile-content .user-links li a i {
    font-size: 19px;
    font-weight: 600;
    margin-right: 10px;
}

.ps-overlay.show {
    display: block;
    opacity: .7;
}

.ps-overlay {
    display: none;
    position: fixed;
    width: 100vw;
    height: 100vh;
    background: #e6e6e6;
    z-index: 1032;
    opacity: 0;
    transition: all 0.5s ease-in-out;
}


/*
      ====================
          Footer
      ====================
  */

.footer-section {
    background-color: #fff;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-box-pack: start;
    -ms-flex-pack: start;
    justify-content: flex-start;
    width: 100%;
}

.footer-section .footer-section-2 {
    width: 50%;
    background: #fefefe;
    flex-grow: 8;
}

.footer-section .links {
    margin: 15.3px 0;
}

.footer-section .links a {
    color: #494949;
    font-size: 15px;
}

.footer-section .bottom-footer {
    margin: 0px;
    color: #494949;
    font-size: 14px;
    padding: 15.5px 0;
}

.footer-section .bottom-footer a {
    color: #1a74e9;
    border: none;
}

.scrollTop i {
    color: #181722;
    font-size: 29px;
    background: #d3d3d3;
    border-radius: 50%;
    cursor: pointer;
}

/*
      ====================
          Modern
      ====================
  */

.main-container.hide-sub .modernSidebar-nav.header nav#modernSidebar ul.menu-categories li.menu>.submenu {
    width: 0;
    left: -90px;
}

.modernSidebar-nav.header {
    position: fixed;
    background-color: #fff;
    padding: 0;
    top: 91px;
    width: 125px;
    z-index: 1030;
    left: 0;
    box-shadow: 1px 1px 20px -2px rgba(22, 30, 42, 0.16);
    transition: all .24s ease-in-out;
}

.modernSidebar-nav.header>div {
    height: 100vh;
}

.sidebar-closed>.modernSidebar-nav {
    left: -135px;
}

.sidebar-closed>#content {
    margin-left: 0;
}

.modernSidebar-nav.header nav#modernSidebar ul.menu-categories li.menu .submenu.collapsing {
    -webkit-transition: none;
    transition: none;
    display: none;
}

.modernSidebar-nav.header nav#modernSidebar {
    text-align: center;
    width: 125px;
    height: 100vh;
}

.modernSidebar-nav.header nav#modernSidebar ul.menu-categories li.menu {
    position: relative;
    list-style: none;
}

.modernSidebar-nav.header nav#modernSidebar ul.menu-categories li.menu>a {
    height: 110px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 13px;
    font-style: normal;
    font-weight: 400;
    border-bottom: 1px solid #f1f3f1;
    color: #212121;
    transition: color .3s;
    transition: background .3s;
}

.modernSidebar-nav.header nav#modernSidebar ul.menu-categories li.menu>a[aria-expanded="true"] div i {
    position: relative;
}

.modernSidebar-nav.header nav#modernSidebar ul.menu-categories li.menu>a[aria-expanded="true"] div i:after {
    position: absolute;
    content: '';
    height: 12px;
    width: 12px;
    background-color: #1a73e9;
    border-radius: 50%;
    top: -3px;
    right: -3px;
    z-index: 1;
    border: 2px solid #e9ecef;

}

.modernSidebar-nav.header nav#modernSidebar ul.menu-categories li.menu a:after {
    display: none;
}

.modernSidebar-nav.header nav#modernSidebar ul.menu-categories li.menu>a i {
    display: block;
    font-size: 25px;
    color: #1f212d;
    display: inline-block;
}

.modernSidebar-nav.header nav#modernSidebar ul.menu-categories li.menu>a span {
    display: block;
    font-size: 13px;
    color: #1f212d;
    margin-top: 10px;
}

.modernSidebar-nav.header nav#modernSidebar ul.menu-categories li.menu .submenu {
    position: fixed;
    top: 91px;
    background-color: #fff;
    color: #1f212d;
    text-align: left;
    box-shadow: 0px 0px 15px 1px rgba(113, 106, 202, 0.2);
    margin-right: auto;
    margin-left: auto;
    left: 125px;
    width: 255px;
    height: 100vh;
    -webkit-animation-duration: 0.3s;
    animation-duration: 0.3s;
    -webkit-animation-fill-mode: both;
    animation-fill-mode: both;
}

.modernSidebar-nav.header nav#modernSidebar ul.menu-categories li.menu .submenu .submenu-scroll {
    height: 100vh;
    padding: 0;
}

.modernSidebar-nav.header nav#modernSidebar ul.menu-categories li.menu .submenu .sub-submenu>li>a {
    padding: 14px 14px 14px 14px;
    display: block;
    color: #1f212d;
    font-weight: 600;
}

.modernSidebar-nav.header nav#modernSidebar ul.menu-categories li.menu .submenu .sub-submenu>li.active>a {
    color: #3232b7
}

.modernSidebar-nav.header nav#modernSidebar ul.menu-categories li.menu .submenu .list-unstyled>li:first-child>a {
    padding-top: 0;
    margin-top: 28px;
}

.modernSidebar-nav.header nav#modernSidebar ul.menu-categories li.menu .submenu .list-unstyled>li>a {
    padding: 10px 14px 0 14px;
    display: block;
    color: #1f212d;
    font-weight: 600;
}

.modernSidebar-nav.header nav#modernSidebar ul.menu-categories li.menu .submenu .list-unstyled>li>a {
    display: flex;
    justify-content: space-between;
    font-size: 13px;
    font-weight: 100;
}

.modernSidebar-nav.header nav#modernSidebar ul.menu-categories li.menu .submenu .list-unstyled>li>a.dropdown-toggle {
    margin-bottom: 28px;
}

.modernSidebar-nav.header nav#modernSidebar ul.menu-categories li.menu .submenu .list-unstyled>li>a.dropdown-toggle span {
    display: inline-block;
    line-height: 1.4;
    border-radius: 30px;
    padding: 3px 6px;
    color: #4f5163;
    background-color: transparent;
    border: 1px solid #4f5163;
    font-size: 11px;
    font-weight: 700;
}

.modernSidebar-nav.header nav#modernSidebar ul.menu-categories li.menu .submenu .list-unstyled>li>a>div>i {
    font-size: 18px;
    margin-right: 8px;
    vertical-align: middle;
    padding: 9px;
    border-radius: 50%;
}

.modernSidebar-nav.header nav#modernSidebar ul.menu-categories li.menu .submenu .list-unstyled>li>.sub-submenu a {
    padding: 20px 14px 0px 22px;
    margin-top: 0;
    color: #888ea8;
    font-size: 13px;
}

.modernSidebar-nav.header nav#modernSidebar ul.menu-categories li.menu .submenu .list-unstyled>li>.sub-submenu li:first-child a {
    padding-top: 0;
}

.modernSidebar-nav.header nav#modernSidebar ul.menu-categories li.menu .submenu .list-unstyled>li>.sub-submenu li:last-child a,
.modernSidebar-nav.header nav#modernSidebar ul.menu-categories li.menu .submenu .list-unstyled>li>.sub-submenu li:last-child a[aria-expanded="false"] {
    padding-bottom: 40px;
}

.modernSidebar-nav.header nav#modernSidebar ul.menu-categories li.menu .submenu .list-unstyled>li>.sub-submenu li:last-child a[aria-expanded="true"] {
    padding-bottom: 0;
}

.modernSidebar-nav.header nav#modernSidebar ul.menu-categories li.menu .submenu .list-unstyled>li>.sub-submenu li.active a {
    color: #3232b7;
}

.modernSidebar-nav.header nav#modernSidebar ul.menu-categories li.menu .submenu .list-unstyled>li>.sub-submenu a[aria-expanded="true"] i {
    transform: rotate(90deg);
}

.modernSidebar-nav.header nav#modernSidebar ul.menu-categories li.menu .submenu .list-unstyled>li>.sub-submenu a i {
    font-size: 9px;
    font-weight: 600;
    align-self: center;
}

.modernSidebar-nav.header nav#modernSidebar ul.menu-categories li.menu .submenu .list-unstyled>li>.sub-submenu li.sub-sub-submenu-list ul.sub-submenu li a {
    position: relative;
    padding: 20px 14px 0px 24px;
    margin-left: 10px;
    margin-top: 0;
    color: #4f5163;
    font-size: 12px;
}

.modernSidebar-nav.header nav#modernSidebar ul.menu-categories li.menu .submenu .list-unstyled>li>.sub-submenu li.sub-sub-submenu-list ul.sub-submenu.eq-animated {
    -webkit-animation-duration: 0.6s;
    animation-duration: 0.35s;
    -webkit-animation-fill-mode: both;
    animation-fill-mode: both;
}

.modernSidebar-nav.header nav#modernSidebar ul.menu-categories li.menu .submenu .list-unstyled:last-child>li>.sub-submenu li.sub-sub-submenu-list:last-child ul.sub-submenu li:last-child a {
    padding-bottom: 40px;
}

.modernSidebar-nav.header nav#modernSidebar ul.menu-categories li.menu .submenu .list-unstyled>li>.sub-submenu li.sub-sub-submenu-list a.dropdown-toggle {
    margin-bottom: 0;
}

.modernSidebar-nav.header nav#modernSidebar ul.menu-categories li.menu .submenu .list-unstyled>li>.sub-submenu li.sub-sub-submenu-list ul.sub-submenu li.active a {
    color: #3232b7;
}

.modernSidebar-nav.header nav#modernSidebar ul.menu-categories li.menu .submenu .list-unstyled>li>.sub-submenu li.sub-sub-submenu-list ul.sub-submenu li a:before {
    content: '';
    background-color: #4f5163;
    position: absolute;
    top: 27px;
    left: 13px;
    border-radius: 50%;
    padding: 2px
}

.modernSidebar-nav.header nav#modernSidebar ul.menu-categories li.menu .submenu .list-unstyled>li>.sub-submenu li.sub-sub-submenu-list ul.sub-submenu li.active a:before {
    background-color: #3232b7;
}

.eq-animated {
    -webkit-animation-duration: 0.6s;
    animation-duration: 0.6s;
    -webkit-animation-fill-mode: both;
    animation-fill-mode: both
}

@-webkit-keyframes eq-fadeInUp {
    0% {
        opacity: 0;
        margin-top: 10px;
    }

    100% {
        opacity: 1;
        margin-top: 0;
    }

}

@keyframes eq-fadeInUp {
    0% {
        opacity: 0;
        margin-top: 10px;
    }

    100% {
        opacity: 1;
        margin-top: 0;
    }
}

.eq-fadeInUp {
    -webkit-animation-name: eq-fadeInUp;
    animation-name: eq-fadeInUp
}

/*  
      ======================
          MEDIA QUERIES
      ======================
  */

@media (min-width: 992px) {
    .desktop-nav.header .navbar-nav .user-profile-dropdown {
        border-left: 1px solid #e9ecef;
    }
}

@media (max-width: 991px) {

    /*
      ===============
          Desktop Nav
      ===============
      */
    .desktop-nav.header .navbar-nav .message-dropdown a span.icon {
        border-radius: 0;
        border: none;
        font-size: 21px;
        padding: 12px 12px 12px;
    }

    .desktop-nav.header .navbar-nav .notification-dropdown a span.icon {
        border-radius: 0;
        border: none;
        font-size: 21px;
        padding: 12px 12px 12px;
    }

    .desktop-nav.header .navbar-nav .message-dropdown a span.badge {
        margin-top: -20px;
        right: -7px;
        padding: 2px;
    }

    .desktop-nav.header .navbar-nav .notification-dropdown a span.badge {
        margin-top: -20px;
        right: -4px;
        padding: 2px;
    }

    .desktop-nav.header .navbar-nav .message-dropdown .dropdown-menu {
        right: auto;
        left: -120px;
        max-width: 18rem;
    }

    .desktop-nav.header .navbar-nav .notification-dropdown .dropdown-menu {
        right: auto;
        left: -120px;
        max-width: 18rem;
    }

    .desktop-nav.header .navbar-nav .cs-toggle a span.icon {
        font-size: 21px;
        padding: 16px 12px 16px
    }

    .desktop-nav.header .navbar-nav .form-inline.search {
        width: 0;
        padding: 11px 0;
        position: inherit;
    }

    .desktop-nav.header .navbar-nav .form-inline.search i {
        font-size: 21px;
        padding: 4px 12px 4px;
        color: #1a73e9;
    }

    .desktop-nav.header .navbar-nav .form-inline.search:before {
        display: none;
    }

    .desktop-nav.header .navbar-nav .form-inline.search input {
        display: none;
    }

    .desktop-nav.header .navbar-nav .form-inline.animated-search.search .form-control:focus {
        width: 100%;
    }

    .desktop-nav.header .navbar-nav .form-inline.search.input-focused {
        position: absolute;
        bottom: 0;
        top: 0;
        background: #fff;
        height: 100%;
        width: 100%;
        left: 0;
        right: 0;
        z-index: 32;
        border-radius: 0;
    }

    .desktop-nav.header .navbar-nav .form-inline.search.input-focused i {
        display: none !important;
    }

    .desktop-nav.header .navbar-nav .form-inline.search.input-focused:before {
        display: block;
        top: 32px;
    }

    .desktop-nav.header .navbar-nav .form-inline.search:before {
        position: absolute;
        font-family: flaticon;
        right: 20px;
        left: auto;
        content: "";
        top: 17px;
        z-index: 6;
        font-size: 21px;
        color: #1a73e9;
        pointer-events: none;
    }

    .desktop-nav.header .navbar-nav .form-inline.search.input-focused input {
        display: inline-block;
        width: 100%;
        color: #1a73e9;
    }

    .desktop-nav.header .navbar-nav .form-inline.search input::-webkit-input-placeholder {
        /* Chrome/Opera/Safari */
        color: #1a73e9;
    }

    .desktop-nav.header .navbar-nav .form-inline.search input::-moz-placeholder {
        /* Firefox 19+ */
        color: #1a73e9;
    }

    .desktop-nav.header .navbar-nav .form-inline.search input:-ms-input-placeholder {
        /* IE 10+ */
        color: #1a73e9;
    }

    .desktop-nav.header .navbar-nav .form-inline.search input:-moz-placeholder {
        /* Firefox 18- */
        color: #1a73e9;
    }

    .desktop-nav.header .navbar-nav .form-inline.animated-search.search .form-control.input-focused {
        width: 100%;
    }

    .search-overlay {
        display: none;
        position: fixed;
        width: 100vw;
        height: 100vh;
        background: #e6e6e6 !important;
        z-index: 998 !important;
        opacity: 0;
        transition: all 0.5s ease-in-out;
    }

    .search-overlay.show {
        display: block;
        opacity: .7;
    }

    /*
      ======================
          Control Sidebar
      ======================
      */

    .control-sidebar {
        top: 84px;
    }

    /*
      ====================
          Layout Modern
      ====================
      */
    .sidebar-noneoverflow {
        overflow: hidden;
    }

    .overlay {
        display: none;
        position: fixed;
        width: 100vw;
        height: 100vh;
        background: #e6e6e6 !important;
        z-index: 998 !important;
        opacity: 0;
        transition: all 1s ease-in-out;
    }

    .overlay.show {
        display: block;
        opacity: .7;
        transition: all 1s ease-in-out;
    }

    .main-container:not(.sbar-open) .modernSidebar-nav {
        width: 0;
        left: -112px;
    }

    .modernSidebar-nav.header {
        top: 91px;
    }

    .modernSidebar-nav.header nav#modernSidebar ul.menu-categories li.menu .submenu {
        top: 91px;
    }

    #content {
        margin-top: 91px;
        margin-left: 0;
    }
}

@media (max-width: 575px) {

    .desktop-nav.header .nav-logo img {
        width: 115px;
        /* padding: 8px; */
    }

    .desktop-nav.header .nav-logo a.sidebarCollapse {
        padding: 8px 8px;
    }

    .desktop-nav.header .nav-logo-land-direct img {
        width: 85px;
        /* padding: 8px; */
    }

    .desktop-nav.header .navbar-nav .nav-item {
        flex-direction: column;
        text-align: left;
        font-size: 12px;
    }



    .desktop-nav.header .nav-logo-land-direct figcaption {
        font-size: 7px;
        margin-left: 1px;
        /* padding: 8px; */
    }

    .desktop-nav.header .nav-logo-land-direct a.sidebarCollapse {
        padding: 8px 8px;
    }

    .desktop-nav.header .navbar-nav .message-dropdown a span.icon {
        border-radius: 0;
        border: none;
        font-size: 21px;
    }

    .desktop-nav.header .navbar-nav .notification-dropdown a span.icon {
        border-radius: 0;
        border: none;
        font-size: 21px;
    }

    .modernSidebar-nav.header nav#modernSidebar ul.menu-categories li.menu .submenu {
        width: 190px;
    }
}

.campaign-img img {
    max-height: 198px;
}

.rightAlign {
    text-align: right;
}

.marketplaceHeader {
    margin: 0;
    font-size: 26px;
    text-transform: uppercase;
    background: #000;
    color: #fff;
    min-height: 150px;
    text-align: center;
    width: 100%;
}

.reward-wrap-overwrite {
    margin: 5px;
    padding: 5px;
    border: 1px solid #000;
}

.marketplaceOuter {
    position: relative;
}

.marketplaceTitle {
    font-size: 14px;
    text-align: center;
    padding: 10px 0px 0px;
    color: gray;
}

.sponsor-title {
    font-size: 14px;
    padding: 3px 0px 0px;
    text-align: center;
    display: block;
    width: 100%;
    color: black;
}

.campaign-hashtags a {
    display: inline-block;
    margin-right: 3px;
}

.campaign-hashtags {
    /* min-height:50px; */
    min-height: 20px;
    overflow: auto;
    white-space: nowrap;
    /* overflow-x: scroll; */
}

.campaign-goal a {
    display: inline-block;
    margin-right: 3px;
}

.campaign-goal-landing a {
    display: inline-block;
    margin-right: 3px;
}

.campaign-goal {
    /* min-height:50px; */
    min-height: 20px;
    /* overflow-x: scroll; */
}

.rewardPointWrapper span {
    display: block;
}

.landingPageWrapper {
    background: #fff;
}




/* Intertoons */
@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

html {
    min-height: 100%;
    scroll-behavior: smooth;
    overflow: auto !important;
}

body {
    color: #888da8;
    height: 100%;
    font-size: 0.875rem;
    background: #ffffff;
    overflow-x: hidden;
    overflow-y: auto;
    letter-spacing: 0.0312rem;
    /* font-family: "Open Sans", "Helvetica Neue", Helvetica, Arial, sans-serif */
    font-family: 'Poppins', sans-serif;
}

:focus {
    outline: none;
}

select.form-custom::-ms-expand {
    display: none;
}

.custom-file-input:focus~.custom-file-label {
    border: 1px solid #ced4da;
    box-shadow: none;
}

.custom-file-input:focus~.custom-file-label::after {
    border: none;
    border-left: 1px solid #ced4da;
}

.lead a.btn.btn-primary.btn-lg {
    margin-top: 15px;
    border-radius: 4px;
}

.jumbotron {
    background-color: #f1f3f9;
}

.mark,
mark {
    background-color: #ffeccb
}

p {
    margin-top: 0;
    margin-bottom: 0.625rem;
}

hr {
    margin-top: 20px;
    margin-bottom: 20px;
    border-top: 1px solid #f1f3f1;
}

strong {
    font-weight: 600;
}

/*blockquote*/
blockquote.blockquote {
    color: #010407;
    padding: 30px 26px 30px 20px;
    font-size: 0.875rem;
    background-color: #ffffff;
    border-left: 4px solid #3862f5;
    border-bottom-right-radius: 8px;
    border-top-right-radius: 8px;
    border-bottom-left-radius: 8px;
    border-top-left-radius: 8px;
    box-shadow: 0px 0px 15px 1px rgba(113, 106, 202, 0.2);
    margin-bottom: 3rem !important;
}

blockquote.blockquote>p {
    margin-bottom: 0;
}

blockquote.place-right {
    border: 0;
    border-right: 4px solid #3862f5;
    padding-right: .625rem;
    text-align: right;
    float: right !important;
}

blockquote .small:before,
blockquote footer:before,
blockquote small:before {
    content: '\2014 \00A0';
}

blockquote .small,
blockquote footer,
blockquote small {
    display: block;
    font-size: 80%;
    line-height: 1.42857143;
    color: #777;
}

/*  Basic Blockquote  */

blockquote.basic-blockquote {
    background: #fff;
    padding: 20px 20px 20px 50px;
    box-sizing: border-box;
    position: relative;
    min-height: 100px;
    border-left: none;
}

blockquote.basic-blockquote:before {
    content: "“";
    position: absolute;
    top: 0;
    left: 10px;
    font-size: 50px;
    color: #f58b22;
    font-weight: 400;
}

blockquote.basic-blockquote p {
    font-size: 22px;
    line-height: 1.5;
    margin: 0;
    max-width: 90%;
}

blockquote.basic-blockquote cite {
    font-size: 16px;
    margin-top: 10px;
    display: block;
    font-weight: 400;
    color: #f58b22;
}

blockquote.media-object.m-o-border-right {
    border-right: 4px solid #3862f5;
    border-left: none;
}

blockquote.media-object .media .usr-img img {
    width: 55px;
}

a {
    color: #4f5163;
    outline: none;
}

a:hover {
    color: #494949;
    text-decoration: none
}

a:focus {
    outline: none;
    text-decoration: none
}

button:focus,
textarea,
textarea:focus {
    outline: none;
}

.btn-link:hover {
    text-decoration: none
}

span.blue {
    color: #54728c
}

span.green {
    color: #94b86e
}

span.red {
    color: #e25856
}

.form-control {
    height: auto;
}

select.form-control.form-custom {
    display: inline-block;
    width: 100%;
    height: calc(2.25rem + 2px);
    vertical-align: middle;
    /* background: #fff url(../assets/arrow-down.png) no-repeat right .75rem center; */
    background-size: 13px 14px;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
}

.form-control:focus {
    box-shadow: none;
}

.custom-select:focus {
    border-color: rgba(0, 0, 0, .075);
    box-shadow: none;
}

.tabMobileHeader {}

.custom-control-input:focus~.custom-control-label::before {
    box-shadow: none;
}

.dropdown-toggle:after {
    font-family: flaticon;
    font-weight: normal;
    font-style: normal;
    text-decoration: inherit;
    -webkit-font-smoothing: antialiased;
    content: "\f182";
    color: #fff;
    vertical-align: middle;
    font-size: 8px;
    border: none;
    height: auto;
    width: auto;
    margin-top: 0;
    margin-left: 2px;
    font-weight: 600;
}

.dropup .dropdown-toggle::after {
    font-family: flaticon;
    font-weight: normal;
    font-style: normal;
    text-decoration: inherit;
    -webkit-font-smoothing: antialiased;
    content: "\f181";
    color: #fff;
    vertical-align: middle;
    font-size: 8px;
    border: none;
    height: auto;
    width: auto;
    margin-top: 0;
    margin-left: 2px;
    font-weight: 600;
}

.dropright .dropdown-toggle::after {
    font-family: flaticon;
    font-weight: normal;
    font-style: normal;
    text-decoration: inherit;
    -webkit-font-smoothing: antialiased;
    content: "\f191";
    color: #fff;
    vertical-align: middle;
    font-size: 8px;
    border: none;
    height: auto;
    width: auto;
    margin-top: 0;
    margin-left: 2px;
    font-weight: 600;
}

.dropleft .dropdown-toggle::before {
    font-family: flaticon;
    font-weight: normal;
    font-style: normal;
    text-decoration: inherit;
    -webkit-font-smoothing: antialiased;
    content: "\f192";
    color: #fff;
    vertical-align: middle;
    font-size: 8px;
    border: none;
    height: auto;
    width: auto;
    margin-top: 0;
    margin-left: 2px;
    font-weight: 600;
}


#content>.container {
    padding: 0 30px;
    max-width: none !important
}

.main-container-fluid {
    position: relative;
    display: block;
    float: left;
    width: 100%;
    min-height: 100%;
    overflow: hidden;
    background-color: #eaeef1
}

.main-container {
    min-height: 100vh;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;

    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;

    -ms-flex-wrap: wrap;
    flex-wrap: wrap;

    -webkit-box-pack: start;
    -ms-flex-pack: start;
    justify-content: flex-start;
}

#container.fixed-header {
    margin-top: 56px
}

#content {
    /*width: 50%;*/
    /*flex-grow: 8;*/
    /*margin-top: 59px;*/
    margin-bottom: 67px;
}

.main-container-fluid>.main-content>.container {
    float: left;
    width: 100%
}

.slimScrollBar {
    background: rgb(26, 210, 114) none repeat scroll 0% 0% !important;
    opacity: 0 !important;
    width: 8px !important;
    right: 0px !important;
    height: 200px !important
}

.scroller {
    padding-right: 8px
}

.scroll {
    overflow: hidden;
    height: 99%;
}

.scroll-content {
    height: 387px;
}

.invoice-content {
    margin-left: 0px !important
}

.invoice-border {
    border: 1px solid rgb(239, 239, 239);
    margin-top: 10px
}

.invoice-bg {
    background-color: #fff
}

#content>.wrapper {
    -webkit-transition: margin ease-in-out .1s;
    -moz-transition: margin ease-in-out .1s;
    -o-transition: margin ease-in-out .1s;
    transition: margin ease-in-out .1s;
    position: relative
}

.page-header {
    border: 0;
    margin: 0;
    *zoom: 1
}

.page-header:before,
.page-header:after {
    display: table;
    content: "";
    line-height: 0
}

.page-header:after {
    clear: both
}

.page-title {
    float: left;
    margin-bottom: 35px;
    margin-top: 35px;
}

.page-title h3 {
    margin: 0;
    margin-bottom: 10px;
    font-size: 20px;
    color: #1f212d;
    font-weight: 600;
}

.page-title span {
    display: block;
    font-size: 11px;
    color: #888;
    font-weight: normal
}

.page-stats {
    float: right;
    padding: 25px 0;
    margin-left: 0;
    list-style: none
}

.page-stats li {
    float: left;
    display: block;
    margin-left: 20px;
    padding-left: 20px;
    border-left: 1px solid #d9d9d9
}

.page-stats li:first-child {
    border: 0
}

.page-stats li .summary {
    float: left;
    margin-right: 18px
}

.page-stats li .summary span {
    color: #888;
    font-weight: 600;
    font-size: 11px;
    text-transform: uppercase
}

.page-stats li .summary h3 {
    color: #555;
    margin: 0;
    font-size: 20px;
    line-height: 20px;
    font-weight: 600
}

.page-stats li .graph {
    float: right
}

.page-stats li .sparkline {
    margin-top: 4px
}


/*----alignment classes----*/

.align-center {
    text-align: center !important;
    margin: 0 auto;
}

.align-left {
    text-align: left !important;
}

.align-right {
    text-align: right !important;
}

.align-justify {
    text-align: justify !important;
}

.align-right-invoice {
    text-align: right !important
}

.full-width-fix {
    width: 100% !important
}

.total {
    color: #1F3892;
    font-size: 18px;
    text-transform: uppercase
}

/*----padding----*/

.padding0 {
    padding: 0;
}

.padding10 {
    padding: 10px;
}

.padding20 {
    padding: 20px;
}

.padding30 {
    padding: 30px;
}

.padding40 {
    padding: 40px;
}

.padding50 {
    padding: 50px;
}

.padding60 {
    padding: 60px;
}

.padding70 {
    padding: 70px;
}

.padding80 {
    padding: 80px;
}

.padding90 {
    padding: 90px;
}

.padding100 {
    padding: 100px;
}

.padding35 {
    padding: 35px 0px
}

.padding-top-5px {
    padding-top: 5px
}

.padding-top-10px {
    padding-top: 10px
}

.padding-top-50px {
    padding-top: 50px !important
}

.padding-bottom-5px {
    padding-bottom: 5px
}

.padding-bottom-10px {
    padding-bottom: 10px
}

.padding-bottom-50px {
    padding-bottom: 50px !important
}

.padding-left-5px {
    padding-left: 5px
}

.padding-left-10px {
    padding-left: 10px
}

.padding-right-5px {
    padding-right: 5px
}

.padding-right-10px {
    padding-right: 10px
}

/*----Margin-----*/

.margin0 {
    margin: 0px;
}

.magin10 {
    margin: 10px;
}

.margin20 {
    margin: 20px;
}

.margin30 {
    margin: 30px;
}

.margin40 {
    margin: 40px;
}

.margin50 {
    margin: 50px;
}

.margin60 {
    margin: 60px;
}

.margin70 {
    margin: 70px;
}

.margin80 {
    margin: 80px;
}

.margin90 {
    margin: 90px;
}

.margin100 {
    margin: 100px;
}

.margin-top-0px {
    margin-top: 0;
}

.margin-bottom-15 {
    margin-bottom: 15px;
}

.margin-bottom-20 {
    margin-bottom: 20px;
}

.margin-bottom-26 {
    margin-bottom: 26px;
}

.margin-bottom-32 {
    margin-bottom: 32px;
}

.margin-bottom-37 {
    margin-bottom: 37px;
}

.margin-bottom-44 {
    margin-bottom: 44px;
}

.margin-bottom-50 {
    margin-bottom: 50px
}

.margin-bottom-90 {
    margin-bottom: 90px
}

.margin-bottom-120 {
    margin-bottom: 120px
}

.margin-bottom-145 {
    margin-bottom: 145px
}

.margin-bottom-154 {
    margin-bottom: 154px
}

.margin-bottom-337 {
    margin-bottom: 337px
}

/*List styles*/

ul.list-icon i {
    vertical-align: text-top;
    font-size: 20px;
}

ul.inline-ordered-list {
    counter-reset: hcount;
}

ul.inline-ordered-list li:before {
    counter-increment: hcount;
    content: counter(hcount)'.';
    color: #4d4d4d;
    font-size: 14px;
    margin-right: 5px;
}

.simple-list,
.numeric-list {
    counter-reset: li;
    padding-left: 0;
    list-style: none;
}

.simple-list li,
.numeric-list li {
    position: relative;
    color: #515365;
    padding-left: 20px;
}

.simple-list li:before,
.numeric-list li:before {
    content: '';
    position: absolute;
    top: 12px;
    left: 5px;
    color: #4073FF;
    font-size: 2rem;
    width: 6px;
    height: 6px;
    background-color: #e9ecef;
    border-radius: 50%;
}

/*Simple List*/
.simple-list li {
    padding: 4px 12px 4px 19px;
}

.simple-list.large-bullet li {
    padding: 4px 12px 4px 25px;
}

.simple-list.large-bullet li:before {
    width: 12px;
    height: 12px;
    top: 9px;
}

/*Numberic List*/

.numeric-list li {
    padding: 4px 12px 4px 25px;
}

.numeric-list>li:before {
    width: 20px;
    height: 20px;
    left: 0;
    top: 16px;
    content: counter(li);
    counter-increment: li;
    font-size: 0.8125rem;
    color: #4f5163;
    border-radius: 50%;
    text-align: center;
    margin-top: -0.65rem;
}

.numeric-list.square-marker li:before {
    border-radius: 0;
}

.numeric-list.large-bullet li:before {
    width: 26px;
    height: 26px;
    font-size: 15px;
    line-height: 25px;
}

.numeric-list.large-bullet li {
    padding: 6px 12px 6px 35px;
}

/*  Icon List   */
.icon-list li {
    padding: 4px 12px 4px 6px;
}

.icon-list li:before {
    display: none;
}

.icon-list li i {
    font-size: 20px;
    color: #aaaea8;
    padding: 7px;
    vertical-align: middle;
}

/*      List Colors     */

.bullet-primary>li:before {
    background-color: #1a73e9;
    color: #fff;
}

.bullet-success>li:before {
    background-color: #1abc9c;
    color: #fff;
}

.bullet-info>li:before {
    background-color: #00b1f4;
    color: #fff;
}

.bullet-danger>li:before {
    background-color: #e7515a;
    color: #fff;
}

.bullet-warning>li:before {
    background-color: #e9b02b;
    color: #fff;
}

.bullet-secondary>li:before {
    background-color: #805dca;
    color: #fff;
}

.bullet-dark>li:before {
    background-color: #4f5163;
    color: #fff;
}

.spacing-10px {
    display: block;
    padding-top: 10px
}

.subtitle {
    display: block;
    font-weight: 600;
    padding-bottom: 5px
}

.fill-nav-space {
    height: 52px
}


/*      Breadcrumb    */

.crumbs .breadcrumb {
    display: inline-flex;
    padding: 8px;
    background-color: #f1f3f1;
}

.crumbs .breadcrumb li {
    font-size: 12px
}

.crumbs .breadcrumb li a {
    color: #555
}

.crumbs .breadcrumb li a:hover {
    text-decoration: none;
    color: #4d7496
}

.crumbs .breadcrumb li i {
    color: #6f6f6f;
    padding: 0 5px;
    font-size: 12px;
    vertical-align: baseline;
}

.crumbs .breadcrumb li.active a {
    color: #949494 !important
}

.crumbs .crumb-buttons {
    list-style: none;
    margin-right: 21px;
    margin-left: 0;
    height: 0
}

.crumbs .crumb-buttons>li {
    font-size: 12px;
    border-right: 1px solid #d9d9d9;
    position: relative
}

.crumbs .crumb-buttons>li:first-child,
.crumbs .crumb-buttons>li.first {
    border-left: 1px solid #d9d9d9
}

.crumbs .crumb-buttons>li>a {
    color: #555;
    text-decoration: none;
    white-space: nowrap;
    padding: 0 10px;
    line-height: 39px;
    display: inline-block;
    border-left: 1px solid #fff;
    border-right: 1px solid #fff
}

.crumbs .crumb-buttons>li>a:hover {
    background: #fff
}

.crumbs .crumb-buttons>li>a:active {
    border-left: 1px solid transparent;
    border-right: 1px solid transparent;
    background: #ebebeb
}

.crumbs .crumb-buttons>li>i,
.crumbs .crumb-buttons>li>a>i {
    padding-right: 5px
}

.crumbs .crumb-buttons>li>i.left-padding,
.crumbs .crumb-buttons>li>a>i.left-padding {
    padding-right: 0;
    padding-left: 5px
}

.crumbs .crumb-buttons>li.open {
    background: #fff
}

.breadcrumb>li+li:before {
    font-family: flaticon;
    font-weight: normal;
    font-style: normal;
    text-decoration: inherit;
    -webkit-font-smoothing: antialiased;
    content: "\f191";
    display: inline-block;
    padding-right: .5rem;
    padding-left: .5rem;
    color: #000000;
    vertical-align: middle;
    font-size: 9px;
}

/*  Breadcrumb 2  */

.crumbs.breadcrumbs-style-2 .breadcrumb {
    background-color: transparent;
    padding: 0;
}

.crumbs.breadcrumbs-style-2 ul li a i {
    border-radius: 50%;
    background-color: #4f5163;
    padding: 6px;
    color: #fff;
}

.crumbs.breadcrumbs-style-2 .breadcrumb>li+li:before {
    content: "\e9e2";
    vertical-align: sub;
    color: #4f5163;
}

.crumbs.breadcrumbs-style-2 .breadcrumb li a {
    color: #4f5163;
    vertical-align: sub;
    display: inline-block;
}

.crumbs.breadcrumbs-style-2 .breadcrumb li:not(:first-child) a {
    color: #4f5163 !important;
    border: 1px solid #4f5163;
    border-radius: 30px;
    padding: 3px 10px;
}

.crumbs.breadcrumbs-style-2 .breadcrumb li.active a {
    color: #fff !important;
    background: #4f5163;
}

.widget {
    padding: 0;
    margin-top: 0;
    margin-bottom: 0;
}

.layout-spacing {
    margin-bottom: 40px;
}

.widget.box .widget-header {
    background: #fff;
    padding: 0px 8px 0px;
    border-top-right-radius: 6px;
    border-top-left-radius: 6px;
}

.row [class*="col-"] .widget .widget-header h4 {
    color: #3b3f5c;
    font-size: 16px;
    font-weight: 600;
    margin: 0;
    padding: 25px 15px;
}

.widget.box .widget-header.widget-heading {
    background: transparent;
    box-shadow: none;
    margin-top: 20px;
}

.widget.box .widget-header.widget-heading h4 {
    font-size: 21px;
    padding-top: 0;
    color: #3862f5;
}

.widget .widget-header {
    border-bottom: 0px solid #ececec;
    *zoom: 1
}

.widget .widget-header:before,
.widget .widget-header:after {
    display: table;
    content: "";
    line-height: 0
}

.widget .widget-header:after {
    clear: both
}

.widget-content-area {
    padding: 20px;
    position: relative;
    background-color: #fff;
    border-bottom-left-radius: 6px;
    border-bottom-right-radius: 6px;
    /* -webkit-box-shadow: 0px 2px 4px rgba(126,142,177,0.12);
    box-shadow: 0px 2px 4px rgba(126,142,177,0.12); */
}

.btn,
.ui-state-default,
.ui-widget-content .ui-state-default,
.ui-widget-header .ui-state-default,
.ColVis_MasterButton,
.fc-state-default {
    padding: 0.4375rem 1.25rem;
    text-shadow: none;
    font-size: 14px;
    color: #3b3f5c;
    font-weight: normal;
    white-space: normal;
    word-wrap: break-word;
    transition: .2s ease-out;
    touch-action: manipulation;
    cursor: pointer;
    background-color: #e9ecef;
    box-shadow: 0px 5px 20px 0 rgba(0, 0, 0, 0.1);
    will-change: opacity, transform;
    transition: all 0.3s ease-out;
    -webkit-transition: all 0.3s ease-out;
}

.btn.rounded-circle {
    padding: 6px 6px;
}

.flaticon-circle-p {
    font-size: 16px;
    padding: 5px;
}

.btn:hover,
.ui-state-default:hover,
.ui-widget-content .ui-state-default:hover,
.ui-widget-header .ui-state-default:hover,
.ColVis_MasterButton:hover,
.btn:focus,
.ui-state-default:focus,
.ui-widget-content .ui-state-default:focus,
.ui-widget-header .ui-state-default:focus,
.ColVis_MasterButton:focuss {
    color: #3b3f5c;
    background-color: #f4f4f4;
    border-color: #d3d3d3;
    -webkit-box-shadow: 0 2px 5px 0 rgba(0, 0, 0, .16), 0 2px 10px 0 rgba(0, 0, 0, .12);
    -moz-box-shadow: 0 2px 5px 0 rgba(0, 0, 0, .16), 0 2px 10px 0 rgba(0, 0, 0, .12);
    box-shadow: 0 2px 5px 0 rgba(0, 0, 0, .16), 0 2px 10px 0 rgba(0, 0, 0, .12);

}

.btn.disabled,
.ui-state-default.disabled,
.ui-widget-content .ui-state-default.disabled,
.ui-widget-header .ui-state-default.disabled,
.ColVis_MasterButton.disabled,
.fc-state-default.disabled,
.btn.btn[disabled],
.ui-state-default.btn[disabled],
.ui-widget-content .ui-state-default.btn[disabled],
.ui-widget-header .ui-state-default.btn[disabled],
.ColVis_MasterButton.btn[disabled],
.fc-state-default.btn[disabled] {
    background-color: #f9f9f9;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    box-shadow: none;
    border: 1px solid rgba(0, 0, 0, 0.13)
}

.btn.disabled:hover,
.ui-state-default.disabled:hover,
.ui-widget-content .ui-state-default.disabled:hover,
.ui-widget-header .ui-state-default.disabled:hover,
.ColVis_MasterButton.disabled:hover,
.fc-state-default.disabled:hover,
.btn.btn[disabled]:hover,
.ui-state-default.btn[disabled]:hover,
.ui-widget-content .ui-state-default.btn[disabled]:hover,
.ui-widget-header .ui-state-default.btn[disabled]:hover,
.ColVis_MasterButton.btn[disabled]:hover,
.fc-state-default.btn[disabled]:hover {
    cursor: not-allowed
}

.btn .caret,
.ui-state-default .caret,
.ui-widget-content .ui-state-default .caret,
.ui-widget-header .ui-state-default .caret,
.ColVis_MasterButton .caret,
.fc-state-default .caret {
    border-top-color: #333;
    margin-top: 0;
    margin-left: 3px;
    vertical-align: middle
}

.btn+.caret,
.ui-state-default+.caret,
.ui-widget-content .ui-state-default+.caret,
.ui-widget-header .ui-state-default+.caret,
.ColVis_MasterButton+.caret,
.fc-state-default+.caret,
.btn+.dropdown-toggle .caret,
.ui-state-default+.dropdown-toggle .caret,
.ui-widget-content .ui-state-default+.dropdown-toggle .caret,
.ui-widget-header .ui-state-default+.dropdown-toggle .caret,
.ColVis_MasterButton+.dropdown-toggle .caret,
.fc-state-default+.dropdown-toggle .caret {
    margin-left: 0
}

.btn-group>.btn,
.btn-group .btn {
    padding: 8px 14px;
}

.btn-group-lg>.btn,
.btn-group-lg .btn {
    font-size: 1.125rem;
}

.btn-group-lg>.btn,
.btn-lg,
.btn-group>.btn.btn-lg,
.btn-group .btn.btn-lg {
    padding: .625rem 1.5rem;
    font-size: 16px;
}

.btn-group-lg>.btn,
.btn-group-lg .btn {
    font-size: 1.125rem;
}

.btn-group-sm>.btn,
.btn-sm,
.btn-group>.btn.btn-sm,
.btn-group .btn.btn-sm {
    font-size: 0.6875rem;
}

.btn-primary:not(:disabled):not(.disabled).active:focus,
.btn-primary:not(:disabled):not(.disabled):active:focus,
.show>.btn-primary.dropdown-toggle:focus,
.btn-secondary:not(:disabled):not(.disabled).active:focus,
.btn-secondary:not(:disabled):not(.disabled):active:focus,
.show>.btn-secondary.dropdown-toggle:focus,
.btn-tertiary:not(:disabled):not(.disabled).active:focus,
.btn-tertiary:not(:disabled):not(.disabled):active:focus,
.show>.btn-tertiary.dropdown-toggle:focus,
.btn-success:not(:disabled):not(.disabled).active:focus,
.btn-success:not(:disabled):not(.disabled):active:focus,
.show>.btn-success.dropdown-toggle:focus,
.btn-info:not(:disabled):not(.disabled).active:focus,
.btn-info:not(:disabled):not(.disabled):active:focus,
.show>.btn-info.dropdown-toggle:focus,
.btn-danger:not(:disabled):not(.disabled).active:focus,
.btn-danger:not(:disabled):not(.disabled):active:focus,
.show>.btn-danger.dropdown-toggle:focus,
.btn-warning:not(:disabled):not(.disabled).active:focus,
.btn-warning:not(:disabled):not(.disabled):active:focus,
.show>.btn-warning.dropdown-toggle:focus,
.btn-secondary:not(:disabled):not(.disabled).active:focus,
.btn-secondary:not(:disabled):not(.disabled):active:focus,
.show>.btn-secondary.dropdown-toggle:focus,
.btn-dark:not(:disabled):not(.disabled).active:focus,
.btn-dark:not(:disabled):not(.disabled):active:focus,
.show>.btn-dark.dropdown-toggle:focus,

.btn-outline-primary:not(:disabled):not(.disabled).active:focus,
.btn-outline-primary:not(:disabled):not(.disabled):active:focus,
.show>.btn-outline-primary.dropdown-toggle:focus,
.btn-outline-success:not(:disabled):not(.disabled).active:focus,
.btn-outline-success:not(:disabled):not(.disabled):active:focus,
.show>.btn-outline-success.dropdown-toggle:focus,
.btn-outline-info:not(:disabled):not(.disabled).active:focus,
.btn-outline-info:not(:disabled):not(.disabled):active:focus,
.show>.btn-outline-info.dropdown-toggle:focus,
.btn-outline-danger:not(:disabled):not(.disabled).active:focus,
.btn-outline-danger:not(:disabled):not(.disabled):active:focus,
.show>.btn-outline-danger.dropdown-toggle:focus,
.btn-outline-warning:not(:disabled):not(.disabled).active:focus,
.btn-outline-warning:not(:disabled):not(.disabled):active:focus,
.show>.btn-outline-warning.dropdown-toggle:focus,
.btn-outline-secondary:not(:disabled):not(.disabled).active:focus,
.btn-outline-secondary:not(:disabled):not(.disabled):active:focus,
.show>.btn-outline-secondary.dropdown-toggle:focus,
.btn-outline-dark:not(:disabled):not(.disabled).active:focus,
.btn-outline-dark:not(:disabled):not(.disabled):active:focus,
.show>.btn-outline-dark.dropdown-toggle:focus {
    box-shadow: none;
}

.btn.focus,
.btn:focus,
.btn-default:focus,
.btn-primary:focus,
.btn-success:focus,
.btn-info:focus,
.btn-danger:focus,
.btn-warning:focus,
.btn-secondary:focus,
.btn-dark:focus,
.btn-tertiary:focus,
.btn-dark:focus,
.btn-outline-default:focus,
.btn-outline-primary:focus,
.btn-outline-success:focus,
.btn-outline-info:focus,
.btn-outline-danger:focus,
.btn-outline-warning:focus,
.btn-outline-secondary:focus,
.btn-outline-dark:focus .btn-light-default:focus,
.btn-light-primary:focus,
.btn-light-success:focus,
.btn-light-info:focus,
.btn-light-danger:focus,
.btn-light-warning:focus,
.btn-light-secondary:focus,
.btn-light-dark:focus {
    box-shadow: none;
}


/*      Default Buttons       */

.btn-default:hover,
.btn-default:focus {
    color: #3b3f5c !important;
    background-color: #dfdfdf;
    box-shadow: none;
}

.btn-default:active,
.btn-default.active {
    background-color: #dfdfdf;
    border-top: 1px solid #dfdfdf
}

.btn-primary {
    color: #fff !important;
    background-color: #1a73e9 !important;
    border-color: #1a73e9;
}

.btn-primary:hover,
.btn-primary:focus {
    color: #fff !important;
    background-color: #3258db;
    box-shadow: none;
}

.btn-primary:active,
.btn-primary.active {
    background-color: #3258db;
    border-top: 1px solid #3258db
}

.btn-primary.disabled,
.btn-primary.btn[disabled],
.btn-primary:disabled {
    background-color: #1a73e9;
    border-color: #1a73e9;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    box-shadow: none
}

.btn-primary.active.focus,
.btn-primary.active:focus,
.btn-primary.active:hover,
.btn-primary.focus:active,
.btn-primary:active:focus,
.btn-primary:active:hover,
.open>.dropdown-toggle.btn-primary.focus,
.open>.dropdown-toggle.btn-primary:focus,
.open>.dropdown-toggle.btn-primary:hover {
    color: #fff !important;
    background-color: #2aebcb;
    border-color: #2aebcb;
}

.btn-primary:not(:disabled):not(.disabled).active,
.btn-primary:not(:disabled):not(.disabled):active,
.show>.btn-primary.dropdown-toggle {
    color: #fff !important;
    background-color: #3258db;
    border-color: #3258db;
}

.btn-primary .caret {
    border-top-color: #fff
}

.btn-group.open .btn-primary.dropdown-toggle {
    background-color: #335eb2
}

.btn-secondary {
    color: #fff !important;
    background-color: #805dca;
    border-color: #805dca;
}

.btn-tertiary {
    color: #000000 !important;
    background-color: #FFA500;
    border-color: #805dca;
}

.btn-secondary:hover,
.btn-secondary:focus {
    color: #fff !important;
    background-color: #db8d0f;
    box-shadow: none;
    border-color: #db8e0f;
}

.btn-tertiary:hover,
.btn-tertiary:focus {
    color: #fff !important;
    background-color: #db8d0f;
    box-shadow: none;
    border-color: #db8e0f;
}

.btn-secondary:active,
.btn-secondary.active {
    background-color: #714cbd;
    border-top: 1px solid #714cbd
}

.btn-tertiary:active,
.btn-tertiary.active {
    background-color: #db8d0f;
    border-top: 1px solid #714cbd
}

.btn-secondary:not(:disabled):not(.disabled).active,
.btn-secondary:not(:disabled):not(.disabled):active,
.show>.btn-secondary.dropdown-toggle {
    color: #fff !important;
    background-color: #714cbd;
    border-color: #714cbd;
}

.btn-tertiary:not(:disabled):not(.disabled).active,
.btn-tertiary:not(:disabled):not(.disabled):active,
.show>.btn-tertiary.dropdown-toggle {
    color: #000000 !important;
    background-color: #db8d0f;
    border-color: #714cbd;
}

.btn-secondary.disabled,
.btn-secondary.btn[disabled],
.btn-secondary:disabled {
    background-color: #805dca;
    border-color: #805dca;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    box-shadow: none
}

.btn-tertiary.disabled,
.btn-tertiary.btn[disabled],
.btn-tertiary:disabled {
    background-color: #805dca;
    border-color: #805dca;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    box-shadow: none
}

.btn-secondary .caret {
    border-top-color: #fff
}

.btn-tertiary .caret {
    border-top-color: #fff
}

.btn-info {
    color: #fff !important;
    background-color: #00b1f4;
    border-color: #00b1f4
}

.btn-info:hover,
.btn-info:focus {
    color: #fff !important;
    background-color: #209dff;
    box-shadow: none;
    border-color: #209dff;
}

.btn-info:active,
.btn-info.active {
    background-color: #209dff;
    border-top: 1px solid #209dff
}

.btn-info:not(:disabled):not(.disabled).active,
.btn-info:not(:disabled):not(.disabled):active,
.show>.btn-info.dropdown-toggle {
    color: #fff !important;
    background-color: #209dff;
    border-color: #209dff;
}

.btn-info.disabled,
.btn-info.btn[disabled],
.btn-info:disabled {
    background-color: #00b1f4;
    border-color: #00b1f4;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    box-shadow: none
}

.btn-info.active.focus,
.btn-info.active:focus,
.btn-info.active:hover,
.btn-info.focus:active,
.btn-info:active:focus,
.btn-info:active:hover,
.open>.dropdown-toggle.btn-info.focus,
.open>.dropdown-toggle.btn-info:focus,
.open>.dropdown-toggle.btn-info:hover {
    color: #fff !important;
    background-color: #209dff;
    border-color: #209dff
}

.btn-info .caret {
    border-top-color: #fff
}

.btn-group.open .btn-info.dropdown-toggle {
    background-color: #2a85a0
}

.btn-warning {
    color: #fff !important;
    background-color: #e9b02b;
    border-color: #e9b02b;
}

.btn-warning:hover,
.btn-warning:focus {
    color: #fff !important;
    background-color: #dea82a;
    box-shadow: none;
    border-color: #dea82a;
}

.btn-warning:active,
.btn-warning.active {
    background-color: #dea82a;
    border-top: 1px solid #dea82a
}

.btn-warning:not(:disabled):not(.disabled).active,
.btn-warning:not(:disabled):not(.disabled):active,
.show>.btn-warning.dropdown-toggle {
    color: #212529;
    background-color: #dea82a;
    border-color: #dea82a;
}

.btn-warning.disabled,
.btn-warning.btn[disabled],
.btn-warning:disabled {
    background-color: #e9b02b;
    border-color: #e9b02b;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    box-shadow: none
}

.btn-warning.active.focus,
.btn-warning.active:focus,
.btn-warning.active:hover,
.btn-warning.focus:active,
.btn-warning:active:focus,
.btn-warning:active:hover,
.open>.dropdown-toggle.btn-warning.focus,
.open>.dropdown-toggle.btn-warning:focus,
.open>.dropdown-toggle.btn-warning:hover {
    color: #fff !important;
    background-color: #f80;
    border-color: #f80
}

.btn-warning .caret {
    border-top-color: #fff
}

.btn-group.open .btn-warning.dropdown-toggle {
    background-color: #df8505
}

.btn-danger {
    color: #fff !important;
    background-color: #e7515a;
    border-color: #e7515a;
}

.btn-danger:hover,
.btn-danger:focus {
    color: #fff !important;
    background-color: #ff3743;
    box-shadow: none;
    border-color: #ff3743;
}

.btn-danger:active,
.btn-danger.active {
    background-color: #df303b;
    border-top: 1px solid #df303b;
}

.btn-danger:not(:disabled):not(.disabled).active,
.btn-danger:not(:disabled):not(.disabled):active,
.show>.btn-danger.dropdown-toggle {
    color: #fff !important;
    background-color: #df303b;
    border-color: #df303b;
}

.btn-danger.disabled,
.btn-danger.btn[disabled],
.btn-danger:disabled {
    background-color: #e7515a;
    border-color: #e7515a;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    box-shadow: none
}

.btn-danger.active.focus,
.btn-danger.active:focus,
.btn-danger.active:hover,
.btn-danger.focus:active,
.btn-danger:active:focus,
.btn-danger:active:hover,
.open>.dropdown-toggle.btn-danger.focus,
.open>.dropdown-toggle.btn-danger:focus,
.open>.dropdown-toggle.btn-danger:hover {
    color: #fff !important;
    background-color: #c00;
    border-color: #c00
}

.btn-danger .caret {
    border-top-color: #fff
}

.btn-group.open .btn-danger.dropdown-toggle {
    background-color: #a9302a
}

.btn-dark {
    color: #fff !important;
    background-color: #4f5163;
    border-color: #4f5163;
}

.btn-dark:hover,
.btn-dark:focus {
    color: #fff !important;
    background-color: #454656;
    box-shadow: none;
    border-color: #454656;
}

.btn-dark:active,
.btn-dark.active {
    background-color: #454656;
    border-top: 1px solid #454656
}

.btn-dark:not(:disabled):not(.disabled).active,
.btn-dark:not(:disabled):not(.disabled):active,
.show>.btn-dark.dropdown-toggle {
    color: #fff !important;
    background-color: #454656;
    border-color: #454656;
}

.btn-dark.disabled,
.btn-dark.btn[disabled],
.btn-dark:disabled {
    background-color: #4f5163;
    border-color: #4f5163;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    box-shadow: none
}

.btn-dark .caret {
    border-top-color: #fff
}

.btn-group.open .btn-dark.dropdown-toggle {
    background-color: #484848
}

.btn-success {
    color: #fff !important;
    background-color: #1abc9c;
    border-color: #1abc9c
}

.btn-success:hover,
.btn-success:focus {
    color: #fff !important;
    background-color: #2ea37d;
    box-shadow: none;
    border-color: #2ea37d;
}

.btn-success:active,
.btn-success.active {
    background-color: #2ea37d;
    border-top: 1px solid #2ea37d
}

.btn-success:not(:disabled):not(.disabled).active,
.btn-success:not(:disabled):not(.disabled):active,
.show>.btn-success.dropdown-toggle {
    color: #fff !important;
    background-color: #2ea37d;
    border-color: #2ea37d;
}

.btn-success.disabled,
.btn-success.btn[disabled],
.btn-success:disabled {
    background-color: #1abc9c;
    border-color: #1abc9c;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    box-shadow: none
}

.btn-success.active.focus,
.btn-success.active:focus,
.btn-success.active:hover,
.btn-success.focus:active,
.btn-success:active:focus,
.btn-success:active:hover,
.open>.dropdown-toggle.btn-success.focus,
.open>.dropdown-toggle.btn-success:focus,
.open>.dropdown-toggle.btn-success:hover {
    color: #fff !important;
    background-color: #17c678;
    border-color: #17c678
}

.btn-success .caret {
    border-top-color: #fff
}


/*-----Button Light Colors------*/

.btn-light-primary {
    background: #fff;
    color: #1a73e9;
}

.btn-light-primary:hover,
.btn-light-primary:focus {
    border: 1px solid #3258db;
    color: #3258db;
}

.btn-light-primary:not(:disabled):not(.disabled).active,
.btn-light-primary:not(:disabled):not(.disabled):active,
.show>.btn-light-primary.dropdown-toggle {
    border: 1px solid #3258db;
    color: #3258db;
}

.btn-light-success {
    background: #fff;
    color: #1abc9c;
}

.btn-light-success:hover,
.btn-light-success:focus {
    border: 1px solid #2ea37d;
    color: #2ea37d;
}

.btn-light-success:not(:disabled):not(.disabled).active,
.btn-light-success:not(:disabled):not(.disabled):active,
.show>.btn-light-success.dropdown-toggle {
    border: 1px solid #2ea37d;
    color: #2ea37d;
}

.btn-light-warning {
    background: #fff;
    color: #e9b02b;
}

.btn-light-warning:hover,
.btn-light-warning:focus {
    border: 1px solid #dea82a;
    color: #dea82a;
}

.btn-light-warning:not(:disabled):not(.disabled).active,
.btn-light-warning:not(:disabled):not(.disabled):active,
.show>.btn-light-warning.dropdown-toggle {
    border: 1px solid #dea82a;
    color: #dea82a;
}

.btn-light-danger {
    background: #fff;
    color: #e7515a;
}

.btn-light-danger:hover,
.btn-light-danger:focus {
    border: 1px solid #ff3743;
    color: #ff3743;
}

.btn-light-danger:not(:disabled):not(.disabled).active,
.btn-light-danger:not(:disabled):not(.disabled):active,
.show>.btn-light-danger.dropdown-toggle {
    border: 1px solid #ff3743;
    color: #ff3743;
}

.btn-light-info {
    background: #fff;
    color: #00b1f4;
}

.btn-light-info:hover,
.btn-light-info:focus {
    border: 1px solid #209dff;
    color: #209dff;
}

.btn-light-info:not(:disabled):not(.disabled).active,
.btn-light-info:not(:disabled):not(.disabled):active,
.show>.btn-light-info.dropdown-toggle {
    border: 1px solid #209dff;
    color: #209dff;
}

.btn-light-dark {
    background: #fff;
    color: #4f5163;
}

.btn-light-dark:hover,
.btn-light-dark:focus {
    border: 1px solid #454656;
    color: #454656;
}

.btn-light-dark:not(:disabled):not(.disabled).active,
.btn-light-dark:not(:disabled):not(.disabled):active,
.show>.btn-light-dark.dropdown-toggle {
    border: 1px solid #454656;
    color: #454656;
}

.btn-light-secondary {
    background: #fff;
    color: #805dca;
}

.btn-light-secondary:hover,
.btn-light-secondary:focus {
    border: 1px solid #6f51ea;
    color: #6f51ea;
}

.btn-light-secondary:not(:disabled):not(.disabled).active,
.btn-light-secondary:not(:disabled):not(.disabled):active,
.show>.btn-light-secondary.dropdown-toggle {
    border: 1px solid #6f51ea;
    color: #6f51ea;
}

/*-----/Button Light Colors------*/

.btn.box-shadow-none {
    border: none;
}

.btn.box-shadow-none:hover,
.btn.box-shadow-none:focus {
    border: none;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    box-shadow: none;
    background-color: transparent;
}

.box-shadow-none {
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}

.btn.box-shadow-none:not(:disabled):not(.disabled).active,
.btn.box-shadow-none:not(:disabled):not(.disabled):active,
.show>.btn.box-shadow-none.dropdown-toggle {
    border: none;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    box-shadow: none;
    background-color: transparent;
}

.btn-group.open .btn-success.dropdown-toggle {
    background-color: #499249
}

.btn-dismiss {
    color: #333;
    background-color: #fff !important;
    border-color: #fff;
    padding: 3px 7px;
}

.btn-dismiss:hover,
.btn-dismiss:focus {
    color: #333;
    background-color: #fff;
}

.btn-dismiss:active,
.btn-dismiss.active {
    background-color: #fff;
    border-top: 1px solid #fff
}

.btn-group>.btn i {
    margin-right: 3px
}

.btn-group>.btn:first-child:not(:last-child):not(.dropdown-toggle) {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    -webkit-box-shadow: 0 0px 0px 0 rgba(0, 0, 0, .16), 0 0px 0px 0 rgba(0, 0, 0, .12);
    -moz-box-shadow: 0 0px 0px 0 rgba(0, 0, 0, .16), 0 0px 0px 0 rgba(0, 0, 0, .12);
    box-shadow: 0 0px 0px 0 rgba(0, 0, 0, .16), 0 0px 0px 0 rgba(0, 0, 0, .12)
}

.btn-group>.btn+.dropdown-toggle {
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    box-shadow: none
}

.btn .badge.badge-align-right {
    position: absolute;
    top: -1px;
    right: 8px;
}

.dropup .btn .caret {
    border-bottom-color: #333
}

.btn-outline-primary:not(:disabled):not(.disabled).active,
.btn-outline-primary:not(:disabled):not(.disabled):active,
.show>.btn-outline-primary.dropdown-toggle {
    background-color: #1a73e9;
    color: #fff !important;
}

.btn-outline-success:not(:disabled):not(.disabled).active,
.btn-outline-success:not(:disabled):not(.disabled):active,
.show>.btn-outline-success.dropdown-toggle {
    background-color: #1abc9c;
    color: #fff !important;
}

.btn-outline-info:not(:disabled):not(.disabled).active,
.btn-outline-info:not(:disabled):not(.disabled):active,
.show>.btn-outline-info.dropdown-toggle {
    background-color: #00b1f4;
    color: #fff !important;
}

.btn-outline-danger:not(:disabled):not(.disabled).active,
.btn-outline-danger:not(:disabled):not(.disabled):active,
.show>.btn-outline-danger.dropdown-toggle {
    background-color: #e7515a;
    color: #fff !important;
}

.btn-outline-warning:not(:disabled):not(.disabled).active,
.btn-outline-warning:not(:disabled):not(.disabled):active,
.show>.btn-outline-warning.dropdown-toggle {
    background-color: #e9b02b;
    color: #fff !important;
}

.btn-outline-secondary:not(:disabled):not(.disabled).active,
.btn-outline-secondary:not(:disabled):not(.disabled):active,
.show>.btn-outline-secondary.dropdown-toggle {
    background-color: #805dca;
    color: #fff !important;
}

.btn-outline-dark:not(:disabled):not(.disabled).active,
.btn-outline-dark:not(:disabled):not(.disabled):active,
.show>.btn-outline-dark.dropdown-toggle {
    background-color: #4f5163;
    color: #fff !important;
}

.show>.btn-outline-primary.dropdown-toggle:after,
.show>.btn-outline-success.dropdown-toggle:after,
.show>.btn-outline-info.dropdown-toggle:after,
.show>.btn-outline-danger.dropdown-toggle:after,
.show>.btn-outline-warning.dropdown-toggle:after,
.show>.btn-outline-secondary.dropdown-toggle:after,
.show>.btn-outline-dark.dropdown-toggle:after {
    color: #fff !important;
}

.show>.btn-outline-primary.dropdown-toggle:before,
.show>.btn-outline-success.dropdown-toggle:before,
.show>.btn-outline-info.dropdown-toggle:before,
.show>.btn-outline-danger.dropdown-toggle:before,
.show>.btn-outline-warning.dropdown-toggle:before,
.show>.btn-outline-secondary.dropdown-toggle:before,
.show>.btn-outline-dark.dropdown-toggle:before {
    color: #fff !important;
}

.btn-outline-default {
    border: 1px solid #e9ecef !important;
    color: #3b3f5c !important;
    background-color: transparent;
    box-shadow: none;
}

.btn-outline-primary {
    border: 1px solid #1a73e9 !important;
    color: #1a73e9 !important;
    background-color: transparent;
    box-shadow: none;
}

.btn-outline-info {
    border: 1px solid #00b1f4 !important;
    color: #00b1f4 !important;
    background-color: #fff;
    box-shadow: none;
}

.btn-outline-warning {
    border: 1px solid #e9b02b !important;
    color: #e9b02b !important;
    background-color: transparent;
    box-shadow: none;
}

.btn-outline-success {
    border: 1px solid #1abc9c !important;
    color: #1abc9c !important;
    background-color: transparent;
    box-shadow: none;
}

.btn-outline-danger {
    border: 1px solid #e7515a !important;
    color: #e7515a !important;
    background-color: transparent;
    box-shadow: none;
}

.btn-outline-secondary {
    border: 1px solid #805dca !important;
    color: #805dca !important;
    background-color: transparent;
    box-shadow: none;
}

.btn-outline-dark {
    border: 1px solid #4f5163 !important;
    color: #4f5163 !important;
    background-color: transparent;
    box-shadow: none;
}

.btn-outline-primary:hover,
.btn-outline-info:hover,
.btn-outline-warning:hover,
.btn-outline-success:hover,
.btn-outline-danger:hover,
.btn-outline-secondary:hover,
.btn-outline-dark:hover {
    box-shadow: 0px 5px 20px 0 rgba(0, 0, 0, 0.1)
}

.btn-outline-default:hover {
    background-color: #e9ecef
}

.btn-outline-primary:hover {
    color: #fff !important;
    background-color: #1a73e9;
}

.btn-outline-info:hover {
    color: #fff !important;
    background-color: #00b1f4
}

.btn-outline-warning:hover {
    color: #fff !important;
    background-color: #e9b02b;
}

.btn-outline-success:hover {
    color: #fff !important;
    background-color: #1abc9c;
}

.btn-outline-danger:hover {
    color: #fff !important;
    background-color: #e7515a;
}

.btn-outline-secondary:hover {
    color: #fff !important;
    background-color: #805dca;
}

.btn-outline-dark:hover {
    color: #fff !important;
    background-color: #4f5163
}

/*      Dropdown Toggle       */

.btn-outline-default.dropdown-toggle:after {
    color: #f2f6f9
}

.btn-outline-primary.dropdown-toggle:after {
    color: #1a73e9 !important;
}

.btn-outline-info.dropdown-toggle:after {
    color: #00b1f4 !important;
}

.btn-outline-warning.dropdown-toggle:after {
    color: #e9b02b !important;
}

.btn-outline-success.dropdown-toggle:after {
    color: #1abc9c !important;
}

.btn-outline-danger.dropdown-toggle:after {
    color: #e7515a !important;
}

.btn-outline-secondary.dropdown-toggle:after {
    color: #805dca !important;
}

.btn-outline-dark.dropdown-toggle:after {
    color: #4f5163 !important;
}

.btn-outline-default.dropdown-toggle:before {
    color: #f2f6f9
}

.btn-outline-primary.dropdown-toggle:before {
    color: #1a73e9 !important;
}

.btn-outline-info.dropdown-toggle:before {
    color: #00b1f4 !important;
}

.btn-outline-warning.dropdown-toggle:before {
    color: #e9b02b !important;
}

.btn-outline-success.dropdown-toggle:before {
    color: #1abc9c !important;
}

.btn-outline-danger.dropdown-toggle:before {
    color: #e7515a !important;
}

.btn-outline-secondary.dropdown-toggle:before {
    color: #805dca !important;
}

.btn-outline-dark.dropdown-toggle:before {
    color: #4f5163 !important;
}

/*      Dropdown Toggle       */

.btn-outline-default.dropdown-toggle:hover:after,
.btn-outline-primary.dropdown-toggle:hover:after,
.btn-outline-info.dropdown-toggle:hover:after,
.btn-outline-warning.dropdown-toggle:hover:after,
.btn-outline-success.dropdown-toggle:hover:after,
.btn-outline-danger.dropdown-toggle:hover:after,
.btn-outline-secondary.dropdown-toggle:hover:after,
.btn-outline-dark.dropdown-toggle:hover:after {
    color: #fff !important
}

.btn-outline-default.dropdown-toggle:hover:before,
.btn-outline-primary.dropdown-toggle:hover:before,
.btn-outline-info.dropdown-toggle:hover:before,
.btn-outline-warning.dropdown-toggle:hover:before,
.btn-outline-success.dropdown-toggle:hover:before,
.btn-outline-danger.dropdown-toggle:hover:before,
.btn-outline-secondary.dropdown-toggle:hover:before,
.btn-outline-dark.dropdown-toggle:hover:before {
    color: #fff !important
}

.btn[class*="btn-gradient"] {
    border: none;
}

.btn-gradient-default {
    color: #4f5163;
    background: linear-gradient(to bottom, #D5DEE7 0%, #E8EBF2 50%, #E2E7ED 100%), linear-gradient(to bottom, rgba(0, 0, 0, 0.02) 50%, rgba(255, 255, 255, 0.02) 61%, rgba(0, 0, 0, 0.02) 73%), linear-gradient(33deg, rgba(255, 255, 255, 0.20) 0%, rgba(0, 0, 0, 0.20) 100%);
    background-blend-mode: normal, color-burn;
}

.btn-gradient-primary {
    color: #fff;
    background-image: linear-gradient(to top, #4481eb 0%, #04befe 100%);
    background-color: #04befe;
}

.btn-gradient-success {
    color: #fff;
    background-image: linear-gradient(-20deg, #00cdac 0%, #8ddad5 100%);
    background-color: #8ddad5;
}

.btn-gradient-info {
    color: #fff !important;
    background-image: linear-gradient(-225deg, #7DE2FC 0%, #B9B6E5 100%);
    background-color: #B9B6E5;
}

.btn-gradient-danger {
    color: #fff;
    background-image: linear-gradient(to right, #f78ca0 0%, #f9748f 19%, #fd868c 60%, #fe9a8b 100%);
    background-color: #fe9a8b;
}

.btn-gradient-warning {
    color: #fff;
    background-image: linear-gradient(-20deg, #fc6076 0%, #ff9a44 100%);
    background-color: #ff9a44;
}

.btn-gradient-secondary {
    color: #fff;
    background-image: linear-gradient(to top, #5f72bd 0%, #9b23ea 100%);
    background-color: #9b23ea;
}

.btn-gradient-dark {
    color: #fff !important;
    background-image: linear-gradient(to right, #243949 0%, #517fa4 100%);
    background-color: #517fa4;
}

.btn-gradient-default:not(:disabled):not(.disabled).active,
.btn-gradient-default:not(:disabled):not(.disabled):active,
.show>.btn-gradient-default.dropdown-toggle {
    background: linear-gradient(to bottom, #D5DEE7 0%, #E8EBF2 50%, #E2E7ED 100%), linear-gradient(to bottom, rgba(0, 0, 0, 0.02) 50%, rgba(255, 255, 255, 0.02) 61%, rgba(0, 0, 0, 0.02) 73%), linear-gradient(33deg, rgba(255, 255, 255, 0.20) 0%, rgba(0, 0, 0, 0.20) 100%);
    background-blend-mode: normal, color-burn;
}

.btn-gradient-primary:not(:disabled):not(.disabled).active,
.btn-gradient-primary:not(:disabled):not(.disabled):active,
.show>.btn-gradient-primary.dropdown-toggle {
    background-image: linear-gradient(to top, #4481eb 0%, #04befe 100%);
}

.btn-gradient-success:not(:disabled):not(.disabled).active,
.btn-gradient-success:not(:disabled):not(.disabled):active,
.show>.btn-gradient-success.dropdown-toggle {
    background-image: linear-gradient(-20deg, #00cdac 0%, #8ddad5 100%);
}

.btn-gradient-info:not(:disabled):not(.disabled).active,
.btn-gradient-info:not(:disabled):not(.disabled):active,
.show>.btn-gradient-info.dropdown-toggle {
    background-image: linear-gradient(-225deg, #7DE2FC 0%, #B9B6E5 100%);
}

.btn-gradient-danger:not(:disabled):not(.disabled).active,
.btn-gradient-danger:not(:disabled):not(.disabled):active,
.show>.btn-gradient-danger.dropdown-toggle {
    background-image: linear-gradient(to right, #f78ca0 0%, #f9748f 19%, #fd868c 60%, #fe9a8b 100%);
}

.btn-gradient-warning:not(:disabled):not(.disabled).active,
.btn-gradient-warning:not(:disabled):not(.disabled):active,
.show>.btn-gradient-warning.dropdown-toggle {
    background-image: linear-gradient(-20deg, #fc6076 0%, #ff9a44 100%);
}

.btn-gradient-secondary:not(:disabled):not(.disabled).active,
.btn-gradient-secondary:not(:disabled):not(.disabled):active,
.show>.btn-gradient-secondary.dropdown-toggle {
    background-image: linear-gradient(to top, #5f72bd 0%, #9b23ea 100%);
}

.btn-gradient-dark:not(:disabled):not(.disabled).active,
.btn-gradient-dark:not(:disabled):not(.disabled):active,
.show>.btn-gradient-dark.dropdown-toggle {
    background-image: linear-gradient(to right, #243949 0%, #517fa4 100%);
}

.btn-gradient-default:hover,
.btn-gradient-default:focus,
.btn-gradient-default:active {
    color: #4f5163;
    box-shadow: 0px 5px 20px 0 rgba(0, 0, 0, 0.3);
}

.btn-gradient-primary:hover,
.btn-gradient-primary:focus,
.btn-gradient-primary:active {
    color: #fff;
    box-shadow: 0px 5px 20px 0 rgba(0, 0, 0, 0.3);
}

.btn-gradient-success:hover,
.btn-gradient-success:focus,
.btn-gradient-success:active {
    color: #fff;
    box-shadow: 0px 5px 20px 0 rgba(0, 0, 0, 0.3);
}

.btn-gradient-info:hover,
.btn-gradient-info:focus,
.btn-gradient-info:active {
    color: #fff;
    box-shadow: 0px 5px 20px 0 rgba(0, 0, 0, 0.3);
}

.btn-gradient-danger:hover,
.btn-gradient-danger:focus,
.btn-gradient-danger:active {
    color: #fff;
    box-shadow: 0px 5px 20px 0 rgba(0, 0, 0, 0.3);
}

.btn-gradient-warning:hover,
.btn-gradient-warning:focus,
.btn-gradient-warning:active {
    color: #fff;
    box-shadow: 0px 5px 20px 0 rgba(0, 0, 0, 0.3);
}

.btn-gradient-secondary:hover,
.btn-gradient-secondary:focus,
.btn-gradient-secondary:active {
    color: #fff;
    box-shadow: 0px 5px 20px 0 rgba(0, 0, 0, 0.3);
}

.btn-gradient-dark:hover,
.btn-gradient-dark:focus,
.btn-gradient-dark:active {
    color: #fff;
    box-shadow: 0px 5px 20px 0 rgba(0, 0, 0, 0.3);
}

.form-control-file {
    width: 100%;
    color: #6f7489;
}

.form-control-file::-webkit-file-upload-button {
    padding: 9px 8px;
    text-shadow: none;
    font-size: 12px;
    color: #fff;
    font-weight: normal;
    white-space: normal;
    word-wrap: break-word;
    transition: .2s ease-out;
    touch-action: manipulation;
    cursor: pointer;
    background-color: #bdc1d2;
    box-shadow: 0px 0px 15px 1px rgba(113, 106, 202, 0.2);
    will-change: opacity, transform;
    transition: all 0.3s ease-out;
    -webkit-transition: all 0.3s ease-out;
    border-radius: 2px;
    border: transparent;
    outline: none;

}

.form-control-file::-ms-file-upload-button {
    padding: 0.4375rem 1.25rem;
    text-shadow: none;
    font-size: 14px;
    color: #fff;
    font-weight: normal;
    white-space: normal;
    word-wrap: break-word;
    transition: .2s ease-out;
    touch-action: manipulation;
    cursor: pointer;
    background-color: #bdc1d2;
    box-shadow: 0px 0px 15px 1px rgba(113, 106, 202, 0.2);
    will-change: opacity, transform;
    transition: all 0.3s ease-out;
    -webkit-transition: all 0.3s ease-out;
    border-radius: 2px;
    border: transparent;
    outline: none;
}

.form-control-file.form-control-file-rounded::-webkit-file-upload-button {
    -webkit-border-radius: 1.875rem !important;
    -moz-border-radius: 1.875rem !important;
    -ms-border-radius: 1.875rem !important;
    -o-border-radius: 1.875rem !important;
    border-radius: 1.875rem !important;
}

select.form-control-rounded.form-control[multiple],
.custom-select {
    border: 1px solid #f1f1f1;
}

select.form-control-rounded.form-control[multiple]:focus option:checked,
.custom-select:focus option:checked {
    background: #3862f5 linear-gradient(0deg, #3862f5 0%, #3862f5 100%);
    color: #fff;
}

select.form-control-rounded.form-control[multiple] option:checked,
.custom-select option:checked {
    background: #f1f2f3 linear-gradient(0deg, #f1f2f3 0%, #f1f2f3 100%);
}

.custom-select {
    border: inset 0 0px 1px rgba(0, 0, 0, .075), 0 0 1px rgba(0, 0, 0, .075);
}

.input-group-text {
    background-color: #f1f3f1;
}

.form-control-rounded-left {
    -webkit-border-top-left-radius: 1.875rem !important;
    -webkit-border-bottom-left-radius: 1.875rem !important;
    -moz-border-top-left-radius: 1.875rem !important;
    -moz-border-bottom-left-radius: 1.875rem !important;
    -ms-border-top-left-radius: 1.875rem !important;
    -ms-border-bottom-left-radius: 1.875rem !important;
    -o-border-top-left-radius: 1.875rem !important;
    -o-border-bottom-left-radius: 1.875rem !important;
    border-top-left-radius: 1.875rem !important;
    border-bottom-left-radius: 1.875rem !important;
}

.form-control-rounded-right {
    -webkit-border-top-right-radius: 1.875rem !important;
    -webkit-border-bottom-right-radius: 1.875rem !important;
    -moz-border-top-right-radius: 1.875rem !important;
    -moz-border-bottom-right-radius: 1.875rem !important;
    -ms-border-top-right-radius: 1.875rem !important;
    -ms-border-bottom-right-radius: 1.875rem !important;
    -o-border-top-right-radius: 1.875rem !important;
    -o-border-bottom-right-radius: 1.875rem !important;
    border-top-right-radius: 1.875rem !important;
    border-bottom-right-radius: 1.875rem !important;
}

.form-control-rounded {
    -webkit-border-radius: 1.875rem !important;
    -moz-border-radius: 1.875rem !important;
    -ms-border-radius: 1.875rem !important;
    -o-border-radius: 1.875rem !important;
    border-radius: 1.875rem !important;
}

.custom-file-label.custom-file-rounded {
    -webkit-border-radius: 1.875rem !important;
    -moz-border-radius: 1.875rem !important;
    -ms-border-radius: 1.875rem !important;
    -o-border-radius: 1.875rem !important;
    border-radius: 1.875rem !important;
    border-radius: 1.875rem !important;
}

.custom-file-label.custom-file-rounded::after {
    -webkit-border-top-right-radius: 1.875rem !important;
    -webkit-border-bottom-right-radius: 1.875rem !important;
    -moz-border-top-right-radius: 1.875rem !important;
    -moz-border-bottom-right-radius: 1.875rem !important;
    -ms-border-top-right-radius: 1.875rem !important;
    -ms-border-bottom-right-radius: 1.875rem !important;
    -o-border-top-right-radius: 1.875rem !important;
    -o-border-bottom-right-radius: 1.875rem !important;
    border-top-right-radius: 1.875rem !important;
    border-bottom-right-radius: 1.875rem !important;
}

.btn-rounded {
    -webkit-border-radius: 1.875rem !important;
    -moz-border-radius: 1.875rem !important;
    -ms-border-radius: 1.875rem !important;
    -o-border-radius: 1.875rem !important;
    border-radius: 1.875rem !important;
}

.custom-select.is-valid,
.form-control.is-valid,
.was-validated .custom-select:valid,
.was-validated .form-control:valid {
    border-color: #1abc9c;
}

.valid-tooltip {
    background-color: #1abc9c;
}

.custom-select.is-invalid,
.form-control.is-invalid,
.was-validated .custom-select:invalid,
.was-validated .form-control:invalid {
    border-color: #e7515a;
}

.invalid-tooltip {
    background-color: #e7515a;
}

.btn-classic {
    -webkit-border-radius: 0 !important;
    -moz-border-radius: 0 !important;
    border-radius: 0 !important;
}

.btn-button-1 {
    color: #fff !important;
    background-color: #211b42 !important;
    border-color: #211b42;
}

.btn-button-2 {
    background: #3232b7 !important;
    color: #fff !important;
    border-color: #3232b7
}

.btn-button-3 {
    background: #f8538d !important;
    color: #fff !important;
    border-color: #f8538d
}

.btn-button-4 {
    color: #fff !important;
    background: #07dabf !important;
    border-color: #07dabf;
}

.btn-button-5 {
    background: #e95f2b !important;
    color: #fff !important;
    border-color: #e95f2b
}

.btn-button-6 {
    background: #888ea8 !important;
    color: #fff !important;
    border-color: #888ea8
}

.btn-button-7 {
    background: #24ccda !important;
    color: #fff !important;
    border-color: #24ccda
}

.btn-button-8 {
    color: #fff !important;
    background: #f58b22 !important;
    border-color: #f58b22;
}

.btn-button-9 {
    color: #fff !important;
    background: #C0CCD7 !important;
    border-color: #C0CCD7;
}

.btn-button-10 {
    background: #FFB88E !important;
    color: #fff !important;
    border-color: #FFB88E;
}

.btn-button-11 {
    background: #3b3f5c !important;
    color: #fff !important;
    border-color: #3b3f5c
}

.btn-button-12 {
    background: #445792 !important;
    color: #fff !important;
    border-color: #445792
}

.btn-button-13 {
    color: #fff !important;
    background: #AD6894 !important;
    border-color: #AD6894;
}

.btn-button-14 {
    background: #DBBDA7 !important;
    color: #fff !important;
    border-color: #DBBDA7
}

.btn-button-15 {
    background: #F49499 !important;
    color: #fff !important;
    border-color: #F49499
}

.btn-button-16 {
    background: #6B98EA !important;
    color: #fff !important;
    border-color: #6B98EA
}

/*
==================
    Switches
==================
*/

/* The switch - the box around the slider */
.switch {
    position: relative;
    display: inline-block;
    width: 35px;
    height: 18px;
}

/* Hide default HTML checkbox */
.switch input {
    display: none;
}

/* The slider */
.switch .slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #e9ecef;
    -webkit-transition: .4s;
    transition: .4s;
}

.switch .slider:before {
    position: absolute;
    content: "";
    background-color: white;
    -webkit-transition: .4s;
    -ms-transition: .4s;
    transition: .4s;
    height: 14px;
    width: 14px;
    left: 2px;
    bottom: 2px;
    box-shadow: 0 1px 15px 1px rgba(52, 40, 104, 0.34);
}

.switch input:checked+.slider:before {
    -webkit-transform: translateX(17px);
    -ms-transform: translateX(17px);
    transform: translateX(17px)
}

/* Rounded Slider Switches */
.switch .slider.round {
    border-radius: 34px;
}

.switch .slider.round:before {
    border-radius: 50%;
}

/* Solid Switches*/

.switch.s-default input:checked+.slider {
    background-color: #d3d3d3;
}

.switch.s-default input:focus+.slider {
    box-shadow: 0 0 1px #e9ecef;
}

.switch.s-primary input:checked+.slider {
    background-color: #1a73e9;
}

.switch.s-primary input:focus+.slider {
    box-shadow: 0 0 1px #1a73e9;
}

.switch.s-success input:checked+.slider {
    background-color: #1abc9c;
}

.switch.s-success input:focus+.slider {
    box-shadow: 0 0 1px #1abc9c;
}

.switch.s-warning input:checked+.slider {
    background-color: #e9b02b;
}

.switch.s-warning input:focus+.slider {
    box-shadow: 0 0 1px #e9b02b;
}

.switch.s-danger input:checked+.slider {
    background-color: #e7515a;
}

.switch.s-danger input:focus+.slider {
    box-shadow: 0 0 1px #e7515a;
}

.switch.s-secondary input:checked+.slider {
    background-color: #805dca;
}

.switch.s-secondary input:focus+.slider {
    box-shadow: 0 0 1px #805dca;
}

.switch.s-info input:checked+.slider {
    background-color: #00b1f4;
}

.switch.s-info input:focus+.slider {
    box-shadow: 0 0 1px #00b1f4;
}

.switch.s-dark input:checked+.slider {
    background-color: #4f5163;
}

.switch.s-dark input:focus+.slider {
    box-shadow: 0 0 1px #4f5163;
}

/* Outline Switches */
.switch.s-outline .slider {
    border: 2px solid #ebedf2;
    background-color: transparent;
    width: 36px;
    height: 19px;
}

.switch.s-outline .slider:before {
    height: 13px;
    width: 13px;
}

.switch.s-outline[class*="s-outline-"] .slider:before {
    bottom: 1px;
    left: 1px;
    border: 2px solid #dfe2ea;
    background-color: #dfe2ea;
    color: #f7f8fa;
    box-shadow: 0 1px 15px 1px rgba(52, 40, 104, 0.25);
}

.switch.s-icons.s-outline-default {
    color: #d3d3d3;
}

.switch.s-icons.s-outline-primary {
    color: #1a73e9;
}

.switch.s-icons.s-outline-success {
    color: #1abc9c;
}

.switch.s-icons.s-outline-warning {
    color: #e9b02b;
}

.switch.s-icons.s-outline-danger {
    color: #e7515a;
}

.switch.s-icons.s-outline-secondary {
    color: #805dca;
}

.switch.s-icons.s-outline-info {
    color: #00b1f4;
}

.switch.s-icons.s-outline-dark {
    color: #4f5163;
}

.switch.s-outline-default input:checked+.slider {
    border: 2px solid #ebedf2;
}

.switch.s-outline-default input:checked+.slider:before {
    border: 2px solid #d3d3d3;
    background-color: #d3d3d3;
    box-shadow: 0 1px 15px 1px rgba(52, 40, 104, 0.25);
}

.switch.s-outline-default input:focus+.slider {
    box-shadow: 0 0 1px #e9ecef;
}

.switch.s-outline-primary input:checked+.slider {
    border: 2px solid #1a73e9;
}

.switch.s-outline-primary input:checked+.slider:before {
    border: 2px solid #1a73e9;
    background-color: #1a73e9;
    box-shadow: 0 1px 15px 1px rgba(52, 40, 104, 0.34);
}

.switch.s-outline-primary input:focus+.slider {
    box-shadow: 0 0 1px #1a73e9;
}

.switch.s-outline-success input:checked+.slider {
    border: 2px solid #1abc9c;
}

.switch.s-outline-success input:checked+.slider:before {
    border: 2px solid #1abc9c;
    background-color: #1abc9c;
    box-shadow: 0 1px 15px 1px rgba(52, 40, 104, 0.34);
}

.switch.s-outline-success input:focus+.slider {
    box-shadow: 0 0 1px #1abc9c;
}

.switch.s-outline-warning input:checked+.slider {
    border: 2px solid #e9b02b;
}

.switch.s-outline-warning input:checked+.slider:before {
    border: 2px solid #e9b02b;
    background-color: #e9b02b;
    box-shadow: 0 1px 15px 1px rgba(52, 40, 104, 0.34);
}

.switch.s-outline-warning input:focus+.slider {
    box-shadow: 0 0 1px #e9b02b;
}

.switch.s-outline-danger input:checked+.slider {
    border: 2px solid #e7515a;
}

.switch.s-outline-danger input:checked+.slider:before {
    border: 2px solid #e7515a;
    background-color: #e7515a;
    box-shadow: 0 1px 15px 1px rgba(52, 40, 104, 0.34);
}

.switch.s-outline-danger input:focus+.slider {
    box-shadow: 0 0 1px #e7515a;
}

.switch.s-outline-secondary input:checked+.slider {
    border: 2px solid #805dca;
}

.switch.s-outline-secondary input:checked+.slider:before {
    border: 2px solid #805dca;
    background-color: #805dca;
    box-shadow: 0 1px 15px 1px rgba(52, 40, 104, 0.34);
}

.switch.s-outline-secondary input:focus+.slider {
    box-shadow: 0 0 1px #805dca;
}

.switch.s-outline-info input:checked+.slider {
    border: 2px solid #00b1f4;
}

.switch.s-outline-info input:checked+.slider:before {
    border: 2px solid #00b1f4;
    background-color: #00b1f4;
    box-shadow: 0 1px 15px 1px rgba(52, 40, 104, 0.34);
}

.switch.s-outline-info input:focus+.slider {
    box-shadow: 0 0 1px #00b1f4;
}

.switch.s-outline-dark input:checked+.slider {
    border: 2px solid #4f5163;
}

.switch.s-outline-dark input:checked+.slider:before {
    border: 2px solid #4f5163;
    background-color: #4f5163;
    box-shadow: 0 1px 15px 1px rgba(52, 40, 104, 0.34);
}

.switch.s-outline-dark input:focus+.slider {
    box-shadow: 0 0 1px #4f5163;
}


/*  Icons Switches */

.switch.s-icons {
    width: 57px;
    height: 30px;
}

.switch.s-icons .slider {
    width: 48px;
    height: 25px;
}

.switch.s-icons .slider:before {
    font-family: 'flaticon' !important;
    content: "\e942";
    vertical-align: sub;
    color: #3b3f5c;
    height: 19px;
    width: 19px;
    font-size: 11px;
    line-height: 1.3;
}

.switch.s-icons input:checked+.slider:before {
    font-family: 'flaticon' !important;
    content: "\f2e2";
    vertical-align: sub;
    color: #fff;
    font-size: 11px;
    line-height: 1.4;
}

.switch.s-icons input:checked+.slider:before {
    -webkit-transform: translateX(23px);
    -ms-transform: translateX(23px);
    transform: translateX(23px);
}

/*      Material Switches   */

.custom-control.material-switch {
    color: #1abc9c;
    padding-left: 0;
}

.custom-control.material-switch .material-switch-control-input {
    display: none;
}

.custom-control.material-switch .material-switch-control-input:checked~.material-switch-control-indicator::after {
    left: 20px;
}

.custom-control.material-switch .material-switch-control-indicator {
    display: inline-block;
    position: relative;
    margin: 0 10px;
    top: 4px;
    width: 34px;
    height: 16px;
    background: #ebedf2;
    transition: .3s;
    cursor: pointer;
}

.custom-control.material-switch .material-switch-control-indicator::after {
    content: '';
    display: block;
    position: absolute;
    width: 18px;
    height: 18px;
    transition: .3s;
    top: -1px;
    left: -1px;
    background: #fdfdfd;
    box-shadow: 0 1px 15px 1px rgba(52, 40, 104, 0.25);
}

.custom-control.material-switch.material-default .material-switch-control-input:checked~.material-switch-control-indicator::after {
    background-color: #f2f6f9;
}

.custom-control.material-switch.material-primary .material-switch-control-input:checked~.material-switch-control-indicator::after {
    background-color: #1a73e9;
}

.custom-control.material-switch.material-success .material-switch-control-input:checked~.material-switch-control-indicator::after {
    background-color: #1abc9c;
}

.custom-control.material-switch.material-warning .material-switch-control-input:checked~.material-switch-control-indicator::after {
    background-color: #e9b02b;
}

.custom-control.material-switch.material-danger .material-switch-control-input:checked~.material-switch-control-indicator::after {
    background-color: #e7515a;
}

.custom-control.material-switch.material-secondary .material-switch-control-input:checked~.material-switch-control-indicator::after {
    background-color: #805dca;
}

.custom-control.material-switch.material-info .material-switch-control-input:checked~.material-switch-control-indicator::after {
    background-color: #00b1f4;
}

.custom-control.material-switch.material-dark .material-switch-control-input:checked~.material-switch-control-indicator::after {
    background-color: #4f5163;
}

.custom-control.material-switch.material-s-rounded .material-switch-control-indicator {
    border-radius: 16px;
}

.custom-control.material-switch.material-s-rounded .material-switch-control-indicator::after {
    border-radius: 50%;
}

/*
    ===========================
        Data Marker ( dot )
    ===========================
*/
.data-marker {
    padding: 2px;
    border-radius: 50%;
    font-size: 18px;
    display: inline-flex;
    width: 10px;
    height: 10px;
    border-radius: 50%;
    align-items: center;
    justify-content: center;
}

.data-marker-success {
    background-color: #1abc9c;
}

.data-marker-warning {
    background-color: #e9b02b;
}

.data-marker-danger {
    background-color: #e7515a;
}

.data-marker-info {
    background-color: #e7515a;
}

.data-marker-dark {
    background-color: #e7515a;
}

.dot-badge {
    padding: 0;
    line-height: 6px;
    min-height: 6px;
    min-width: 6px;
    height: 6px;
    width: 6px;
    display: inline-block;
    -webkit-border-radius: 100%;
    -moz-border-radius: 100%;
    -ms-border-radius: 100%;
    -o-border-radius: 100%;
    border-radius: 100%;
}

.dot-badge.dot-primary {
    background-color: #1a73e9;
}

.dot-badge.dot-success {
    background-color: #1abc9c;
}

.dot-badge.dot-warning {
    background-color: #e9b02b;
}

.dot-badge.dot-info {
    background-color: #26b0ff;
}

.dot-badge.dot-danger {
    background-color: #e7515a;
}

.dot-badge.dot-dark {
    background-color: #4f5163;
}

.badge.badge-enabled {
    background-color: #1abc9c;
    color: #fff;
}

.badge.badge-disable {
    background-color: #e7515a;
    color: #fff;
}

.badge {
    font-weight: 600;
    line-height: 1.4;
    padding: 3px 6px;
    font-size: 12px;
    font-weight: 600;
}

.badge-pills {
    border-radius: 30px;
}

.badge-classic {
    border-radius: 0;
}

.badge-collapsed-img img {
    width: 40px;
    height: 40px;
    border-radius: 20px;
    border: 2px solid #ffffff;
    box-shadow: 0px 0px 15px 1px rgba(113, 106, 202, 0.3);
    margin-left: -21px;
}

.badge-collapsed-img.badge-tooltip img {
    width: 40px;
    height: 40px;
    border-radius: 20px;
    border: 2px solid #ffffff;
    box-shadow: 0px 0px 15px 1px rgba(113, 106, 202, 0.3);
    margin-left: -21px;
    -webkit-transition: all 0.35s ease;
    transition: all 0.35s ease;
}

.badge-collapsed-img.badge-tooltip img:hover {
    -webkit-transform: translateY(-5px) scale(1.02);
    transform: translateY(-5px) scale(1.02);
}

.badge-collapsed-img.translateY-axis img {
    -webkit-transition: all 0.35s ease;
    transition: all 0.35s ease;
}

.badge-collapsed-img.translateY-axis img:hover {
    -webkit-transform: translateY(-5px) scale(1.02);
    transform: translateY(-5px) scale(1.02);
}

.badge-collapsed-img.rectangle-collapsed img {
    width: 45px;
    height: 32px;
}

.badge-collapsed-img.translateX-axis img {
    -webkit-transition: all 0.35s ease;
    transition: all 0.35s ease;
}

.badge-collapsed-img.translateX-axis img:hover {
    -webkit-transform: translateX(5px) scale(1.02);
    transform: translateX(5px) scale(1.02);
}

.badge-default {
    color: #515365;
    border: 1px solid #d3d3d3;
    background-color: #f2f6f9;
}

.badge-primary {
    color: #fff;
    background-color: #1a73e9;
}

.badge-info {
    color: #fff;
    background-color: #00b1f4;
}

.badge-success {
    color: #fff;
    background-color: #1abc9c;
}

.badge-danger {
    color: #fff;
    background-color: #e7515a;
}

.badge-warning {
    color: #fff;
    background-color: #e9b02b;
}

.badge-dark {
    color: #fff;
    background-color: #4f5163;
}

.badge-secondary {
    background-color: #805dca;
}

.badge-new {
    color: #fff;
    background-color: #f8538d;
}

.badge-primary:focus,
.badge-primary:hover {
    background-color: #3258db;
}

.badge-secondary:focus,
.badge-secondary:hover {
    color: #fff;
    background-color: #6f51ea;
}

.badge-success:focus,
.badge-success:hover {
    color: #fff;
    background-color: #2ea37d;
}

.badge-danger:focus,
.badge-danger:hover {
    color: #fff;
    background-color: #ff3743;
}

.badge-warning:focus,
.badge-warning:hover {
    color: #fff;
    background-color: #dea82a;
}

.badge-info:focus,
.badge-info:hover {
    color: #fff;
    background-color: #009eda;
}

.badge-default:focus,
.badge-default:hover {
    color: #333;
    background-color: #e9ecef;
}

.badge-dark:focus,
.badge-dark:hover {
    color: #fff;
    background-color: #454656;
}

.badge-new:focus,
.badge-new:hover {
    color: #fff;
    background-color: #e64d83;
}

.outline-badge-default {
    color: #3b3f5c;
    border: 1px solid #e9ecef;
    background-color: transparent;
}

.outline-badge-primary {
    color: #1a73e9;
    background-color: transparent;
    border: 1px solid #1a73e9;
}

.outline-badge-info {
    color: #00b1f4;
    background-color: transparent;
    border: 1px solid #00b1f4;
}

.outline-badge-success {
    color: #1abc9c;
    background-color: transparent;
    border: 1px solid #1abc9c;
}

.outline-badge-danger {
    color: #e7515a;
    background-color: transparent;
    border: 1px solid #e7515a;
}

.outline-badge-warning {
    color: #e9b02b;
    background-color: transparent;
    border: 1px solid #e9b02b;
}

.outline-badge-dark {
    color: #4f5163;
    background-color: transparent;
    border: 1px solid #4f5163;
}

.outline-badge-secondary {
    color: #805dca;
    background-color: transparent;
    border: 1px solid #805dca;
}

.outline-badge-new {
    color: #f8538d;
    background-color: transparent;
    border: 1px solid #f8538d;
}

.outline-badge-primary:focus,
.outline-badge-primary:hover {
    background-color: #c2d5ff;
    color: #1a73e9;
}

.outline-badge-secondary:focus,
.outline-badge-secondary:hover {
    color: #805dca;
    background-color: #dccff7;
}

.outline-badge-success:focus,
.outline-badge-success:hover {
    color: #1abc9c;
    background-color: #b6fff1;
}

.outline-badge-danger:focus,
.outline-badge-danger:hover {
    color: #e7515a;
    background-color: #ffe1e2;
}

.outline-badge-warning:focus,
.outline-badge-warning:hover {
    color: #e9b02b;
    background-color: #ffeccb;
}

.outline-badge-info:focus,
.outline-badge-info:hover {
    color: #00b1f4;
    background-color: #bae7ff;
}

.outline-badge-default:focus,
.outline-badge-default:hover {
    color: #333;
    background-color: #f5f5f5;
}

.outline-badge-dark:focus,
.outline-badge-dark:hover {
    color: #4f5163;
    background-color: #acb0c3;
}

.outline-badge-new:focus,
.outline-badge-new:hover {
    color: #f8538d;
    background-color: #fbccdd;
}

/*      Link     */

.badge[class*="link-badge-"] {
    cursor: pointer;
}

.link-badge-default {
    color: #d3d3d3;
    border: 1px solid transparent;
    background-color: transparent;
}

.link-badge-primary {
    color: #1a73e9;
    background-color: transparent;
    border: 1px solid transparent;
}

.link-badge-info {
    color: #00b1f4;
    background-color: transparent;
    border: 1px solid transparent;
}

.link-badge-success {
    color: #1abc9c;
    background-color: transparent;
    border: 1px solid transparent;
}

.link-badge-danger {
    color: #e7515a;
    background-color: transparent;
    border: 1px solid transparent;
}

.link-badge-warning {
    color: #e9b02b;
    background-color: transparent;
    border: 1px solid transparent;
}

.link-badge-dark {
    color: #4f5163;
    background-color: transparent;
    border: 1px solid transparent;
}

.link-badge-secondary {
    color: #805dca;
    background-color: transparent;
    border: 1px solid transparent;
}

.link-badge-new {
    color: #f8538d;
    background-color: transparent;
    border: 1px solid transparent;
}

.link-badge-primary:focus,
.link-badge-primary:hover {
    color: #3258db;
    background-color: transparent;
}

.link-badge-secondary:focus,
.link-badge-secondary:hover {
    color: #6f51ea;
    background-color: transparent;
}

.link-badge-success:focus,
.link-badge-success:hover {
    color: #2ea37d;
    background-color: transparent;
}

.link-badge-danger:focus,
.link-badge-danger:hover {
    color: #ff3743;
    background-color: transparent;
}

.link-badge-warning:focus,
.link-badge-warning:hover {
    color: #dea82a;
    background-color: transparent;
}

.link-badge-info:focus,
.link-badge-info:hover {
    color: #009eda;
    background-color: transparent;
}

.link-badge-default:focus,
.link-badge-default:hover {
    color: #b3b3b3;
    background-color: transparent;
}

.link-badge-dark:focus,
.link-badge-dark:hover {
    color: #454656;
    background-color: transparent;
}

.link-badge-new:focus,
.link-badge-new:hover {
    color: #e64d83;
    background-color: transparent;
}

/* Custom Dropdown*/

.custom-dropdown a.dropdown-toggle,
.custom-dropdown-icon a.dropdown-toggle {
    padding: 8px 10px;
    border-radius: 50%;
    height: 40px;
    width: 40px;
}

.custom-dropdown a.dropdown-toggle:hover,
.custom-dropdown-icon a.dropdown-toggle:hover {
    background-color: #f2f6f9;
    color: #e95f2b
}

.custom-dropdown .dropdown-toggle::after,
.custom-dropdown-icon .dropdown-toggle::after {
    display: none
}

.custom-dropdown .dropdown-toggle::before,
.custom-dropdown-icon .dropdown-toggle::before {
    display: none
}

.custom-dropdown .dropdown-menu,
.custom-dropdown-icon .dropdown-menu {
    min-width: 10rem;
    border-radius: 6px;
    border: none;
    box-shadow: 0px 0px 15px 1px rgba(113, 106, 202, 0.2);
}

.custom-dropdown .dropdown-item.active,
.custom-dropdown .dropdown-item:active,
.custom-dropdown .dropdown-item:hover,
.custom-dropdown-icon .dropdown-item.active,
.custom-dropdown-icon .dropdown-item:active,
.custom-dropdown-icon .dropdown-item:hover {
    color: #000;
    background-color: transparent;
}

.custom-dropdown .dropdown-item,
.custom-dropdown-icon .dropdown-item {
    font-size: 12px;
    color: #3b3f5c;
    display: block;
    font-weight: 600;
}

.custom-dropdown-icon .dropdown-menu .dropdown-item i {
    font-size: 14px;
    margin-right: 3px;
}

.custom-dropdown .dropdown-item.active i,
.custom-dropdown .dropdown-item:active i,
.custom-dropdown .dropdown-item:hover i,
.custom-dropdown-icon .dropdown-item.active i,
.custom-dropdown-icon .dropdown-item:active i,
.custom-dropdown-icon .dropdown-item:hover i {
    color: #6156ce;
}

.status.rounded-tooltip .tooltip-inner {
    border-radius: 20px;
    padding: 8px 20px;
}

.tooltip-inner {
    -webkit-border-radius: 0;
    -moz-border-radius: 0;
    border-radius: 0
}

.popover {
    z-index: 999;
    -webkit-border-radius: 0;
    -moz-border-radius: 0;
    border-radius: 0;
    -webkit-box-shadow: 0 1px 3px rgba(0, 0, 0, 0.2);
    -moz-box-shadow: 0 1px 3px rgba(0, 0, 0, 0.2);
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.2);
    border-bottom-color: #b3b3b3
}

.progress {
    -webkit-border-radius: 0;
    -moz-border-radius: 0;
    border-radius: 0;
    background-color: #e9ecef;
    margin-bottom: 1.25rem;
}

.progress-sm {
    height: 1px;
}

.progress-md {
    height: 10px;
}

.progress-lg {
    height: 20px;
}

.progress-xl {
    height: 30px;
}

.progress-vertical {
    position: relative;
    width: 30px;
    height: 200px;
    display: inline-block;
    margin-right: 10px;
    background-color: #e9ecef;
}

.progress-vertical>.progress-bar {
    width: 100%;
    position: absolute;
    bottom: 0
}

.progress-striped .progress-bar {
    background-image: -webkit-gradient(linear, 0 100%, 100% 0, color-stop(0.25, rgba(255, 255, 255, 0.15)), color-stop(0.25, transparent), color-stop(0.5, transparent), color-stop(0.5, rgba(255, 255, 255, 0.15)), color-stop(0.75, rgba(255, 255, 255, 0.15)), color-stop(0.75, transparent), to(transparent));
    background-image: -webkit-linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
    background-image: -moz-linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
    background-image: -o-linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
    background-image: linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent)
}

input[disabled],
select[disabled],
textarea[disabled],
input[readonly],
select[readonly],
textarea[readonly] {
    cursor: not-allowed;
    background-color: #f9f9f9;
    color: #adadad
}

.help-block,
.help-inline {
    color: #686868
}

.controls {
    position: relative
}

.search-form-control {
    border-radius: .25rem;
}


/*  Table   */

.table-bordered {
    border: 1px solid #f1f3f1;
}

.table-striped tbody tr:nth-of-type(odd) {
    background-color: #f2f6f9 !important;
}

.table>thead>tr>th {
    color: #07dabf;
    font-weight: 600;
}

.table>tbody>tr>td .usr-img-frame {
    background-color: #d3d3d3;
    padding: 2px;
    width: 35px;
    height: 35px;
}

.table>tbody>tr>td .usr-img-frame img {
    width: 35px;
    margin: 0;
}

.table>tbody>tr>td .progress {
    width: 135px;
    height: 6px;
}

.table>tbody>tr>td {
    vertical-align: middle;
}

.table>tbody>tr>td i.icon {
    cursor: pointer;
}

.table>tbody>tr>td .t-dot {
    background-color: #000;
    height: 11px;
    width: 11px;
    border-radius: 50%;
    cursor: pointer;
    margin: 0 auto;
}

.table>tbody>tr>td i.t-icon {
    padding: 5px;
    border-radius: 50%;
    font-size: 11px;
    vertical-align: sub;
    cursor: pointer;
}

.table>tbody>tr>td i.t-icon.t-hover-icon:hover {
    background-color: #e7515a;
    color: #fff;
}

.table-bordered td {
    border: 1px solid #f1f3f1;
}

.table-bordered th {
    border: 1px solid #f1f3f1;
}

.table-bordered.dataTable th {
    border: 1px solid transparent;
    border-right: 1px solid #f1f3f1;
}

.table thead th {
    vertical-align: bottom;
    border-bottom: none;
}

.table-hover:not(.table-dark) tbody tr:hover {
    background-color: #f1f3f9 !important;
}

.table-controls>li>a i {
    color: #25d5e4;
}

.bg-default {
    background-color: #ffffff !important;
}

.table tr td .custom-dropdown.t-custom-dropdown a.dropdown-toggle,
.table tr td .custom-dropdown-icon.t-custom-dropdown a.dropdown-toggle {
    border-radius: 5px;
    border: 1px solid #d3d3d3;
}


/*  Table Dark      */

.table-dark {
    background-color: #4f5163;
}

.table-dark.table-hover tbody tr {
    background-color: #4f5163;
}

.table-dark td,
.table-dark th,
.table-dark thead th {
    border-color: #32383e !important;
}

.table-dark.table-hover tbody tr:hover {
    background-color: #454656;
}

.table.table-dark>tbody>tr>td i.t-icon {
    padding: 5px;
    border-radius: 50%;
    font-size: 14px;
    vertical-align: sub;
    cursor: pointer;
    color: #191e22 !important;
}

table .badge-success,
table .badge-primary,
table .badge-warning,
table .badge-danger,
table .badge-info,
table .badge-secondary,
table .badge-dark {
    box-shadow: 0px 5px 20px 0 rgba(0, 0, 0, 0.2);
    will-change: opacity, transform;
    transition: all 0.3s ease-out;
    -webkit-transition: all 0.3s ease-out;
}

.table>tfoot>tr>th {
    color: #3b3f5c;
}

/*Table Colors*/

.table-default>td,
.table-default>th {
    background-color: #f5f5f5;
    color: #3b3f5c;
}

.table-primary>td,
.table-primary>th {
    background-color: #c2d5ff;
    color: #1a73e9;
}

.table-secondary>td,
.table-secondary>th {
    background-color: #dccff7;
    color: #805dca;
}

.table-success>td,
.table-success>th {
    background-color: #b6fff1;
    color: #1abc9c;
}

.table-danger>td,
.table-danger>th {
    background-color: #ffe1e2;
    color: #e7515a;
}

.table-warning>td,
.table-warning>th {
    background-color: #ffeccb;
    color: #e9b02b;
}

.table-info>td,
.table-info>th {
    background-color: #bae7ff;
    color: #00b1f4;
}

.table-light>td,
.table-light>th {
    background-color: #ffffff;
    color: #888da8;
}

.table-dark>td,
.table-dark>th {
    background-color: #acb0c3;
    color: #4f5163;
    border-color: #fff !important;
}

/*Datatable*/
.dataTables_info {
    margin-top: 1.5rem !important;
}

.dataTables_paginate {
    margin-top: 1.5rem !important;
}

@media all and (-ms-high-contrast:none) {
    .dataTables_info {
        margin-bottom: 1.5rem !important;
    }

    .dataTables_paginate {
        margin-bottom: 1.5rem !important;
    }
}

.dataTables_length {
    margin-bottom: 1.5rem !important;
}

.dataTables_filter {
    margin-bottom: 1.5rem !important;
}

.dataTables_wrapper.dt-bootstrap4 .table thead th {
    vertical-align: bottom;
    border-bottom: 1px solid transparent;
}

.product-list-img {
    display: inline-block;
    width: 60px;
    height: 60px;
    border: 1px solid #eceff4;
    -webkit-border-radius: 3px;
    border-radius: 3px;
    background: #ffffff;
    text-align: center
}

.product-list-img img {
    max-width: 100%;
    min-height: 100%;
    max-height: 100%
}

.table-controls {
    padding: 0;
    margin: 0;
    list-style: none
}

.table-controls>li {
    display: inline-block;
    margin: 0 2px;
    line-height: 1
}

.table-controls>li>a {
    display: inline-block
}

.table-controls>li>a i {
    margin: 0;
    color: #555;
    font-size: 16px;
    display: block
}

.table-controls>li>a i:hover {
    text-decoration: none
}

.table .progress {
    margin-bottom: 0
}

.table-vertical-align tr,
.table-vertical-align th,
.table-vertical-align td {
    vertical-align: middle !important
}

.statbox .widget-content:before,
.statbox .widget-content:after {
    display: table;
    content: "";
    line-height: 0;
    clear: both
}

.nav-tabs>li>a {
    -webkit-border-radius: 0 !important;
    -moz-border-radius: 0 !important;
    border-radius: 0 !important
}

.btn-toolbar {
    margin-left: 0px;
}

.panel-default>.panel-heading.color-accordion {
    color: #fff;
    background-color: #0088CC;
    border-color: #0088CC
}

.panel-default>.panel-heading.color-accordion>h3>a:hover,
.panel-default>.panel-heading.color-accordion>h3>a:focus,
.panel-default>.panel-heading.color-accordion>h3>a:active {
    color: #fff;
}

.spin {
    -webkit-animation: spin 2s infinite linear;
    animation: spin 2s infinite linear;
}

.pulse {
    -webkit-animation: spin 1s infinite steps(8);
    animation: spin 1s infinite steps(8);
}

@keyframes spin {
    0% {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg)
    }

    100% {
        -webkit-transform: rotate(359deg);
        transform: rotate(359deg)
    }
}

@-webkit-keyframes spin {
    0% {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg)
    }

    100% {
        -webkit-transform: rotate(359deg);
        transform: rotate(359deg)
    }
}


/*  
    ==========================
        Background Colors  
    ==========================
*/


/*  
    Default  
*/

.bg-default {
    background-color: #e9ecef !important;
    border-color: #e9ecef;
    color: #494949 !important;
}

.bg-primary {
    background-color: #1a73e9 !important;
    border-color: #1a73e9;
    color: #fff
}

.bg-approved {
    background-color: #00FF00 !important;
    border-color: #00FF00;
    color: #fff
}

.bg-success {
    background-color: #1abc9c !important;
    border-color: #1abc9c;
    color: #fff
}

.bg-info {
    background-color: #00b1f4 !important;
    border-color: #00b1f4;
    color: #fff
}

.bg-warning {
    background-color: #e9b02b !important;
    border-color: #e9b02b;
    color: #fff
}

.bg-danger {
    background-color: #e7515a !important;
    border-color: #e7515a;
    color: #fff
}

.bg-secondary {
    background-color: #805dca !important;
    border-color: #805dca;
    color: #fff;
}

.bg-dark {
    background-color: #fff;
    border-color: #4f5163;
    color: #fff
}


/*  
    Gradient  
*/

.bg-gradient-default {
    color: #4f5163;
    background: linear-gradient(to bottom, #D5DEE7 0%, #E8EBF2 50%, #E2E7ED 100%), linear-gradient(to bottom, rgba(0, 0, 0, 0.02) 50%, rgba(255, 255, 255, 0.02) 61%, rgba(0, 0, 0, 0.02) 73%), linear-gradient(33deg, rgba(255, 255, 255, 0.20) 0%, rgba(0, 0, 0, 0.20) 100%);
    background-blend-mode: normal, color-burn;
}

.bg-gradient-primary {
    color: #fff;
    background-image: linear-gradient(to top, #4481eb 0%, #04befe 100%);
}

.bg-gradient-success {
    color: #fff;
    background-image: linear-gradient(-20deg, #00cdac 0%, #8ddad5 100%);
}

.bg-gradient-info {
    color: #fff;
    background-image: linear-gradient(-225deg, #7DE2FC 0%, #B9B6E5 100%);
}

.bg-gradient-warning {
    color: #fff;
    background-image: linear-gradient(-20deg, #fc6076 0%, #ff9a44 100%);
}

.bg-gradient-danger {
    color: #fff;
    background-image: linear-gradient(to right, #f78ca0 0%, #f9748f 19%, #fd868c 60%, #fe9a8b 100%);
}

.bg-gradient-secondary {
    color: #fff;
    background-image: linear-gradient(to top, #5f72bd 0%, #9b23ea 100%);
}

.bg-gradient-dark {
    color: #fff;
    background-image: linear-gradient(to right, #243949 0%, #517fa4 100%);
}

.bg-light-default {
    background-color: #f5f5f5 !important;
    border-color: #f5f5f5;
    color: #494949 !important;
}

.bg-light-primary {
    background-color: #c2d5ff !important;
    border-color: #c2d5ff;
    color: #1a73e9
}

.bg-light-success {
    background-color: #b6fff1 !important;
    border-color: #b6fff1;
    color: #1abc9c
}

.bg-light-info {
    background-color: #bae7ff !important;
    border-color: #bae7ff;
    color: #00b1f4
}

.bg-light-warning {
    background-color: #ffeccb !important;
    border-color: #ffeccb;
    color: #e9b02b
}

.bg-light-danger {
    background-color: #ffe1e2 !important;
    border-color: #ffe1e2;
    color: #e7515a
}

.bg-light-secondary {
    background-color: #dccff7 !important;
    border-color: #dccff7;
    color: #805dca;
}

.bg-light-dark {
    background-color: #acb0c3;
    border-color: #acb0c3;
    color: #fff
}

.custom-bg-color-1 {
    background-color: #3232b7 !important;
    border-color: #3232b7;
    color: #fff
}


/*      Media Object      */

.media .media-body .media-heading {
    color: #1a73e9;
    font-size: 1.125rem;
}

.media .media-body .media-text {
    color: #888da8;
}

/*      Alert       */

.alert {
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    border-radius: 5px;
    margin-bottom: 15px;
    padding: 0.9375rem;
}

.alert .btn {
    padding: 3px 12px;
}

.alert .alert-icon i {
    font-size: 32px;
    vertical-align: middle;
}

.alert .close {
    font-size: 14px;
    line-height: 22px;
    color: #fff;
    text-shadow: none;
    opacity: 1;
}

/*Default Alerts*/

.alert-default {
    color: #333;
    background-color: #f2f6f9;
    border-color: #f2f6f9;
}

.alert-primary {
    color: #fff;
    background-color: #1a73e9;
    border-color: #1a73e9;
}

.alert-warning {
    color: #fff;
    background-color: #e9b02b;
    border-color: #e9b02b;
}

.alert-success {
    color: #fff;
    background-color: #1abc9c;
    border-color: #1abc9c;
}

.alert-info {
    color: #fff;
    background-color: #00b1f4;
    border-color: #00b1f4;
}

.alert-danger {
    color: #fff;
    background-color: #e7515a;
    border-color: #e7515a;
}

.alert-dark {
    color: #fff;
    background-color: #4f5163;
    border-color: #4f5163;
}

/*Outline Alerts*/

.alert-outline-default {
    border-color: #f2f6f9;
    border-radius: 5px;
    background: transparent;
}

.alert-outline-primary {
    border-color: #3258db;
    border-radius: 5px
}

.alert-outline-warning {
    border-color: #dea82a;
    border-radius: 5px
}

.alert-outline-success {
    border-color: #2ea37d;
    border-radius: 5px
}

.alert-outline-info {
    border-color: #009eda;
    border-radius: 5px
}

.alert-outline-danger {
    border-color: #ff3743;
    border-radius: 5px
}

.alert-outline-dark {
    border-color: #454656;
    border-radius: 5px;
}

.alert.alert-light .close {
    color: #333;
}

.alert.solid-alert-3 .close {
    color: #000;
}

.alert.solid-alert-4 .close {
    color: #000;
}

.hide-default {
    display: none;
}


/*      Light Alert         */

.alert-light-default {
    color: #4f5163;
    background-color: #f5f5f5;
    border-color: #e6ecf5;
}

.alert-light-default i.close {
    color: #4f5163;
}

.alert-light-primary {
    color: #1a73e9;
    background-color: #c2d5ff;
    border-color: #1a73e9;
}

.alert-light-primary i.close {
    color: #1a73e9;
}

.alert-light-warning {
    color: #e9b02b;
    background-color: #ffeccb;
    border-color: #e9b02b;
}

.alert-light-warning i.close {
    color: #e9b02b;
}

.alert-light-success {
    color: #1abc9c;
    background-color: #b6fff1;
    border-color: #1abc9c;
}

.alert-light-success i.close {
    color: #1abc9c;
}

.alert-light-info {
    color: #00b1f4;
    background-color: #bae7ff;
    border-color: #00b1f4;
}

.alert-light-info i.close {
    color: #00b1f4;
}

.alert-light-danger {
    color: #e7515a;
    background-color: #ffe1e2;
    border-color: #e7515a;
}

.alert-light-danger i.close {
    color: #e7515a;
}

.alert-light-dark {
    color: #4f5163;
    background-color: #acb0c3;
    border-color: #4f5163;
}

.alert-light-dark i.close {
    color: #4f5163;
}


/*  Background Alerts      */

.alert-background {
    color: #fff;
    /* background: #fff url(../img/ab-1.jpeg)no-repeat center center; */
    background-size: cover;
}

/*  Gradient Alerts      */

.alert-gradient {
    color: #fff;
    border: none;
    background-size: cover;
    background-image: linear-gradient(135deg, #bc1a4e 0%, #004fe6 100%);
}

/* Custom Alerts */

/* Default */

.custom-alert-1 {
    background-color: #7d56ce;
    border-color: #6156ce;
    border-radius: 5px;
    color: #fff;
}

.custom-alert-2 {
    background-color: #24ccda;
    border-color: #24ccda;
    border-radius: 5px;
    color: #fff;
}

.custom-alert-3 {
    background-color: #0081e6;
    border-color: #0081e6;
    border-radius: 5px;
    color: #fff;
}

/*Outline*/
.custom-alert-outline-1 {
    border-color: #5247bd;
    border-radius: 5px;
    color: #3b3f5c;
}

.custom-alert-outline-2 {
    border-color: #24ccda;
    border-radius: 5px;
    color: #3b3f5c;
}

.custom-alert-outline-3 {
    border-radius: 5px;
    color: #3b3f5c;
    border: solid 1px #0081e6;
}

/*  Alert with Icon */
.alert-icon-left {
    border-left: 64px solid;
}

.alert-icon-left i:not(.close) {
    color: #FFF;
    width: 4rem;
    left: -4rem;
    text-align: center;
    position: absolute;
    top: 50%;
    margin-top: -10px;
    font-size: 1.25rem;
    font-weight: 400;
    line-height: 1;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.alert-icon-right {
    border-right: 64px solid;
}

.alert-icon-right i:not(.close) {
    color: #FFF;
    width: 4rem;
    right: -4rem;
    text-align: center;
    position: absolute;
    top: 50%;
    margin-top: -10px;
    font-size: 1.25rem;
    font-weight: 400;
    line-height: 1;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.alert-icon-right i {
    float: left;
    margin-right: 7px;
}

.alert[class*=alert-arrow-]:before {
    content: '';
    display: inline-block;
    position: absolute;
    top: 50%;
    left: 0;
    border-left: 8px solid;
    border-top: 8px solid transparent;
    border-bottom: 8px solid transparent;
    border-left-color: inherit;
    margin-top: -8px;
}

.alert.alert-arrow-right:before {
    left: auto;
    right: 0;
    border-left: 0;
    border-right: 8px solid;
    border-right-color: inherit;
}

.br-0 {
    border-radius: 0 !important;
}

.br-4 {
    border-radius: 4px !important;
}

.br-6 {
    border-radius: 6px !important;
}

.br-30 {
    border-radius: 30px !important;
}

.br-50 {
    border-radius: 50px !important;
}

.br-left-30 {
    border-top-left-radius: 30px !important;
    border-bottom-left-radius: 30px !important;
}

.br-right-30 {
    border-top-right-radius: 30px !important;
    border-bottom-right-radius: 30px !important;
}

.bx-top-6 {
    border-top-right-radius: 6px !important;
    border-top-left-radius: 6px !important;
}

.bx-bottom-6 {
    border-bottom-right-radius: 6px !important;
    border-bottom-left-radius: 6px !important;
}

.badge.counter {
    position: absolute;
    z-index: 2;
    right: 0;
    top: -10px;
    font-weight: 600;
    width: 1.50rem;
    height: 1.50rem;
    border-radius: 50%;
    padding: 0.3em 0.2em 0.3em;
    font-size: 12px;
    padding-top: 4px;
}

.badge-chip {
    display: inline-block;
    padding: 0 25px;
    font-size: 16px;
    line-height: 42px;
    border-radius: 25px;
}

.badge-chip img {
    float: left;
    margin: 0px 10px 0px -26px;
    height: 44px;
    width: 44px;
    border-radius: 50%;
}

.badge-chip .closebtn {
    color: #f1f3f1;
    font-weight: bold;
    float: right;
    font-size: 20px;
    cursor: pointer;
}

.badge-chip .closebtn:hover {
    color: #fff;
}

.ul.pagination {
    display: inline-block;
    padding: 0;
    margin: 0
}

ul.pagination li {
    display: inline
}

ul.pagination li a {
    float: left;
    padding: 5px 12px;
    text-decoration: none;
    border: 0px solid #ddd;
    font-size: 15px;
    margin-left: 0px;
    transition: all .3s linear;
    -webkit-box-shadow: 0 0px 0px 0 rgba(0, 0, 0, .16), 0 0px 0px 0 rgba(0, 0, 0, .12);
    -moz-box-shadow: 0 0px 0px 0 rgba(0, 0, 0, .16), 0 0px 0px 0 rgba(0, 0, 0, .12);
    box-shadow: 0 0px 0px 0 rgba(0, 0, 0, .16), 0 0px 0px 0 rgba(0, 0, 0, .12)
}

ul.pagination li a.page-link:focus {
    box-shadow: none;
}

ul.pagination li a.active {
    background-color: #1a73e9;
    color: #fff;
    border-color: #1a73e9
}

.pagination>.disabled>a.page-link,
.pagination>.disabled>a.page-link:focus,
.pagination>.disabled>a.page-link:hover,
.pagination>.disabled>span,
.pagination>.disabled>span:focus,
.pagination>.disabled>span:hover {
    color: #494949;
    cursor: not-allowed;
    background-color: #f1f3f9;
    border-color: #f1f3f1;
}

ul.pagination li a:hover:not(.active) {
    background-color: #1a73e9;
    color: #fff;
}

ul.pagination>.disabled>a,
.pagination>.disabled>a:hover {
    cursor: not-allowed;
    color: #888ea8;
}

ul.pagination-sm>li>a {
    padding: 4px 10px;
    font-size: 12px
}

ul.pagination-lg>li>a {
    padding: 7px 15px;
    font-size: 20px
}

.pagination {
    -webkit-border-radius: 0;
    -moz-border-radius: 0;
    border-radius: 0;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    box-shadow: none;
    margin: 1.25rem 0;
}

.pagination>li:first-child>a,
.pagination>li:first-child>span,
.pagination>li:last-child>a,
.pagination>li:last-child>span {
    -webkit-border-radius: 0;
    -moz-border-radius: 0;
    border-radius: 0
}

.pagination.pagination-bordered>li>a,
.pagination.pagination-bordered>li>span {
    color: #1a73e9;
}

.pagination>.active>a,
.pagination>.active>span,
.pagination>.active>a:hover,
.pagination>.active>span:hover,
.pagination>.active>a:focus,
.pagination>.active>span:focus {
    background-color: #3b3f5c;
    border-color: #3b3f5c;
    color: #fff;
}

.pagination.pagination-rounded li a:hover {
    border-radius: 22px;
}

.pagination.pagination-rounded li a.active {
    border-radius: 22px;
}

.pagination.pagination-semi-rounded li a {
    border-radius: 6px;
}

.pagination.pagination-bordered li a {
    border: 1px solid #e6e6e6;
}

/*  Pagination Style 1  */

ul.pagination.pagination-style-1 li a {
    background-color: #fff;
    color: #6156ce;
}

ul.pagination.pagination-style-1 li a:hover:not(.active) {
    background-color: #6156ce;
    color: #fff;
}

ul.pagination.pagination-style-1 li a.active {
    background-color: #6156ce;
    color: #fff;
    border-color: #6156ce;
}

/*  Pagination Style 2  */

ul.pagination.pagination-style-2 li a {
    background-color: #fff;
    color: #00d1c1;
}

ul.pagination.pagination-style-2 li a:hover:not(.active) {
    background-color: #00d1c1;
    color: #fff;
}

ul.pagination.pagination-style-2 li a.active {
    background-color: #00d1c1;
    color: #fff;
    border-color: #00d1c1;
}

/*  Pagination Style 3     */

ul.pagination.pagination-style-3 li a {
    background-color: #fff;
    color: #f8538d;
}

ul.pagination.pagination-style-3 li a:hover:not(.active) {
    background-color: #f8538d;
    color: #fff;
}

ul.pagination.pagination-style-3 li a.active {
    background-color: #f8538d;
    color: #fff;
    border-color: #f8538d;
}

/*  Pagination Style 4  */

ul.pagination.pagination-style-4 li a {
    background-color: #fff;
    color: #ee3d50;
}

ul.pagination.pagination-style-4 li a:hover:not(.active) {
    background-color: #ee3d50;
    color: #fff;
}

ul.pagination.pagination-style-4 li a.active {
    background-color: #ee3d50;
    color: #fff;
    border-color: #ee3d50;
}

/*  Pagination Style 5  */

ul.pagination.pagination-style-5 li a {
    background-color: #fff;
    color: #3862f5;
}

ul.pagination.pagination-style-5 li a:hover:not(.active) {
    background-color: #3862f5;
    color: #fff;
}

ul.pagination.pagination-style-5 li a.active {
    background-color: #3862f5;
    color: #fff;
    border-color: #3862f5;
}

/*  Pagination Style 6  */

ul.pagination.pagination-style-6 li a {
    background-color: #fff;
    color: #c3ab76;
}

ul.pagination.pagination-style-6 li a:hover:not(.active) {
    background-color: #c3ab76;
    color: #fff;
}

ul.pagination.pagination-style-6 li a.active {
    background-color: #c3ab76;
    color: #fff;
    border-color: #c3ab76;
}

/*  Pagination Style 7  */

ul.pagination.pagination-style-7 li a {
    background-color: #fff;
    color: #25d5e4;
}

ul.pagination.pagination-style-7 li a:hover:not(.active) {
    background-color: #25d5e4;
    color: #fff;
}

ul.pagination.pagination-style-7 li a.active {
    background-color: #25d5e4;
    color: #fff;
    border-color: #25d5e4;
}

/*  Pagination Style 8  */

ul.pagination.pagination-style-8 li a {
    background-color: #fff;
    color: #e9b02b;
}

ul.pagination.pagination-style-8 li a:hover:not(.active) {
    background-color: #e9b02b;
    color: #fff;
}

ul.pagination.pagination-style-8 li a.active {
    background-color: #e9b02b;
    color: #fff;
    border-color: #e9b02b;
}

/*  Pagination Style 9  */

ul.pagination.pagination-style-9 li a {
    background-color: #fff;
    color: #1abc9c;
}

ul.pagination.pagination-style-9 li a:hover:not(.active) {
    background-color: #1abc9c;
    color: #fff;
}

ul.pagination.pagination-style-9 li a.active {
    background-color: #1abc9c;
    color: #fff;
    border-color: #1abc9c;
}

/*  Pagination Style 10  */

ul.pagination.pagination-style-10 li a {
    background-color: #fff;
}

ul.pagination.pagination-style-10 li a:hover:not(.active) {
    background-color: #888ea8;
    color: #fff;
}

ul.pagination.pagination-style-10 li a.active {
    background-color: #888ea8;
    color: #fff;
    border-color: #888ea8;
}

/*  Pagination Style 11     */

ul.pagination.pagination-style-11 li a {
    background-color: #fff;
}

ul.pagination.pagination-style-11 li a:hover:not(.active) {
    background-color: #888ea8;
    color: #fff;
}

ul.pagination.pagination-style-11 li a.active {
    background-color: #888ea8;
    color: #fff;
    border-color: #888ea8;
}

/*  Pagination Style 12     */

ul.pagination.pagination-style-12 li a {
    background-color: #fff;
}

ul.pagination.pagination-style-12 li a:hover:not(.active) {
    background-color: #888ea8;
    color: #fff;
}

ul.pagination.pagination-style-12 li a.active {
    background-color: #888ea8;
    color: #fff;
    border-color: #888ea8;
}

/*  Pagination Style 13     */

ul.pagination.pagination-style-13 li a {
    background-color: #fff;
    color: #ee3d50;
}

ul.pagination.pagination-style-13 li a:hover:not(.active) {
    background-color: #ee3d50;
    color: #fff;
    border-color: #ee3d50;
}

ul.pagination.pagination-style-13 li a.active {
    background-color: #ee3d50;
    color: #fff;
    border-color: #ee3d50;
}

/*  Pagination Style 14     */

ul.pagination.pagination-style-14 li a {
    background-color: #fff;
    color: #9960ea;
}

ul.pagination.pagination-style-14 li a:hover:not(.active) {
    background-color: #9960ea;
    color: #fff;
}

ul.pagination.pagination-style-14 li a.active {
    background-color: #9960ea;
    color: #fff;
    border-color: #9960ea;
}

/*  Pagination Style 15     */

ul.pagination.pagination-style-15 li a {
    background-color: #fff;
    color: #f58b22;
}

ul.pagination.pagination-style-15 li a:hover:not(.active) {
    background-color: #f58b22;
    color: #fff;
}

ul.pagination.pagination-style-15 li a.active {
    background-color: #f58b22;
    color: #fff;
    border-color: #f58b22;
}

/*  Pagination Style 16     */

ul.pagination.pagination-style-16 li a {
    background-color: #fff;
    color: #18d17f;
}

ul.pagination.pagination-style-16 li a:hover:not(.active) {
    background-color: #18d17f;
    color: #fff;
}

ul.pagination.pagination-style-16 li a.active {
    background-color: #18d17f;
    color: #fff;
    border-color: #18d17f;
}

/*  Pagination Style 17     */

ul.pagination.pagination-style-17 li a {
    background-color: #fff;
    color: #00b1f4;
}

ul.pagination.pagination-style-17 li a:hover:not(.active) {
    background-color: #00b1f4;
    color: #fff;
}

ul.pagination.pagination-style-17 li a.active {
    background-color: #00b1f4;
    color: #fff;
    border-color: #00b1f4;
}

/*  Pagination Style 18     */

ul.pagination.pagination-style-18 li a {
    background-color: #fff;
    color: #AD6894;
}

ul.pagination.pagination-style-18 li a:hover:not(.active) {
    background-color: #AD6894;
    color: #fff;
}

ul.pagination.pagination-style-18 li a.active {
    background-color: #AD6894;
    color: #fff;
    border-color: #AD6894;
}


/*  Pagination Style 19     */

ul.pagination.pagination-style-19 li a {
    background-color: #fff;
    color: #3232b7
}

ul.pagination.pagination-style-19 li a:hover:not(.active) {
    background-color: #3232b7;
    color: #fff
}

ul.pagination.pagination-style-19 li a.active {
    background-color: #3232b7;
    color: #fff;
    border-color: #3232b7
}


/*  Pagination Style 20     */

ul.pagination.pagination-style-20 li a {
    background-color: #fff;
    color: #3232b7
}

ul.pagination.pagination-style-20 li a:hover:not(.active) {
    background-color: #3232b7;
    color: #fff
}

ul.pagination.pagination-style-20 li a.active {
    background-color: #3232b7;
    color: #fff;
    border-color: #3232b7
}


/*  Pagination Style 21     */


ul.pagination.pagination-style-21 li a {
    background-color: #fff;
    color: #ff3743
}

ul.pagination.pagination-style-21 li a:hover:not(.active) {
    background-color: #ff3743;
    color: #fff
}

ul.pagination.pagination-style-21 li a.active {
    background-color: #ff3743;
    color: #fff;
    border-color: #ff3743
}

/*  Pagination Style 22     */


ul.pagination.pagination-style-22 li a {
    background-color: #fff;
    color: #ff3743;
}

ul.pagination.pagination-style-22 li a:hover:not(.active) {
    background-color: #ff3743;
    color: #fff;
}

ul.pagination.pagination-style-22 li a.active {
    background-color: #ff3743;
    color: #fff;
    border-color: #ff3743;
}


/*-------text-colors------*/

.text-default {
    color: #e9ecef !important;
}

.text-primary {
    color: #1a73e9 !important;
}

.text-success {
    color: #1abc9c !important;
}

.text-info {
    color: #00b1f4 !important;
}

.text-danger {
    color: #e7515a !important;
}

.text-warning {
    color: #e9b02b !important;
}

.text-secondary {
    color: #805dca !important;
}

.text-dark {
    color: #4f5163 !important;
}

.text-muted {
    color: #888ea8 !important
}

.text-white {
    color: #fff !important
}

.text-black {
    color: #000 !important
}


/*-----border main------*/
.border {
    border: 1px solid !important
}

.border-bottom {
    border-bottom: 1px solid !important
}

.border-top {
    border-top: 1px solid !important
}

.border-right {
    border-right: 1px solid !important
}

.border-left {
    border-left: 1px solid !important
}

.border-default {
    border-color: #e9ecef !important
}

.border-primary {
    border-color: #1a73e9 !important
}

.border-info {
    border-color: #00b1f4 !important
}

.border-warning {
    border-color: #e9b02b !important
}

.border-success {
    border-color: #1abc9c !important
}

.border-danger {
    border-color: #e7515a !important
}

.border-secondary {
    border-color: #805dca !important
}

.border-dark {
    border-color: #4f5163 !important
}

/*-----border style------*/
.border-dotted {
    border-style: dotted !important;
}

.border-dashed {
    border-style: dashed !important;
}

.border-solid {
    border-style: solid !important;
}

.border-double {
    border-style: double !important;
}

/*-----border width------*/
.border-width-1px {
    border-width: 1px !important;
}

.border-width-2px {
    border-width: 2px !important;
}

.border-width-3px {
    border-width: 3px !important;
}

.border-width-4px {
    border-width: 4px !important;
}

.border-width-5px {
    border-width: 5px !important;
}

.border-width-6px {
    border-width: 6px !important;
}

/*------Font Size-----*/

.fs-10 {
    font-size: 10px !important;
}

.fs-15 {
    font-size: 15px !important;
}

.fs-16 {
    font-size: 16px !important;
}

.fs-17 {
    font-size: 17px !important;
}

.fs-18 {
    font-size: 18px !important;
}

.fs-20 {
    font-size: 20px !important;
}

.fs-25 {
    font-size: 25px !important;
}

.fs-30 {
    font-size: 30px !important;
}

.fs-35 {
    font-size: 35px !important;
}

.fs-40 {
    font-size: 40px !important;
}

/*------Theme Shadow-----*/

.theme-shadow-1 {
    -webkit-box-shadow: 0px 5px 20px 0 rgba(0, 0, 0, 0.1);
    -moz-box-shadow: 0px 5px 20px 0 rgba(0, 0, 0, 0.1);
    -o-box-shadow: 0px 5px 20px 0 rgba(0, 0, 0, 0.1);
    box-shadow: 0px 5px 20px 0 rgba(0, 0, 0, 0.1);

}

.theme-shadow-2 {
    -webkit-box-shadow: 0 0px 0px 0 rgba(0, 0, 0, .16), 0 0px 0px 0 rgba(0, 0, 0, .12);
    -moz-box-shadow: 0 0px 0px 0 rgba(0, 0, 0, .16), 0 0px 0px 0 rgba(0, 0, 0, .12);
    -o-box-shadow: 0 0px 0px 0 rgba(0, 0, 0, .16), 0 0px 0px 0 rgba(0, 0, 0, .12);
    box-shadow: 0 0px 0px 0 rgba(0, 0, 0, .16), 0 0px 0px 0 rgba(0, 0, 0, .12);
}

.theme-shadow-3 {
    -webkit-box-shadow: 0px 0px 15px 1px rgba(113, 106, 202, 0.2);
    -moz-box-shadow: 0px 0px 15px 1px rgba(113, 106, 202, 0.2);
    -o-box-shadow: 0px 0px 15px 1px rgba(113, 106, 202, 0.2);
    box-shadow: 0px 0px 15px 1px rgba(113, 106, 202, 0.2);
}

.theme-shadow-4 {
    -webkit-box-shadow: 0px 3px 25px 0px rgba(113, 106, 202, 0.2);
    -moz-box-shadow: 0px 3px 25px 0px rgba(113, 106, 202, 0.2);
    -o-box-shadow: 0px 3px 25px 0px rgba(113, 106, 202, 0.2);
    box-shadow: 0px 3px 25px 0px rgba(113, 106, 202, 0.2);
}

.theme-shadow-5 {
    -webkit-box-shadow: 0px 5px 20px 0 rgba(0, 0, 0, 0.2);
    -moz-box-shadow: 0px 5px 20px 0 rgba(0, 0, 0, 0.2);
    -o-box-shadow: 0px 5px 20px 0 rgba(0, 0, 0, 0.2);
    box-shadow: 0px 5px 20px 0 rgba(0, 0, 0, 0.2);
}

.theme-shadow-6 {
    -webkit-box-shadow: 0px 2px 20px 0 rgba(0, 0, 0, 0.2);
    -moz-box-shadow: 0px 2px 20px 0 rgba(0, 0, 0, 0.2);
    -o-box-shadow: 0px 2px 20px 0 rgba(0, 0, 0, 0.2);
    box-shadow: 0px 2px 20px 0 rgba(0, 0, 0, 0.2);
}

.theme-shadow-7 {
    -webkit-box-shadow: 0px 5px 20px 0 rgba(0, 0, 0, 0.3);
    -moz-box-shadow: 0px 5px 20px 0 rgba(0, 0, 0, 0.3);
    -o-box-shadow: 0px 5px 20px 0 rgba(0, 0, 0, 0.3);
    box-shadow: 0px 5px 20px 0 rgba(0, 0, 0, 0.3);
}

.theme-shadow-8 {
    -webkit-box-shadow: 0 0 60px 0 rgba(0, 0, 0, .07);
    -moz-box-shadow: 0 0 60px 0 rgba(0, 0, 0, .07);
    -o-box-shadow: 0 0 60px 0 rgba(0, 0, 0, .07);
    box-shadow: 0 0 60px 0 rgba(0, 0, 0, .07);
}

.theme-shadow-9 {
    -webkit-box-shadow: 0 1px 15px 1px rgba(52, 40, 104, 0.15);
    -moz-box-shadow: 0 1px 15px 1px rgba(52, 40, 104, 0.15);
    -o-box-shadow: 0 1px 15px 1px rgba(52, 40, 104, 0.15);
    box-shadow: 0 1px 15px 1px rgba(52, 40, 104, 0.15);
}

.theme-shadow-10 {
    -webkit-box-shadow: 0px 5px 15px 0px rgba(0, 0, 0, 0.3);
    -moz-box-shadow: 0px 5px 15px 0px rgba(0, 0, 0, 0.3);
    -o-box-shadow: 0px 5px 15px 0px rgba(0, 0, 0, 0.3);
    box-shadow: 0px 5px 15px 0px rgba(0, 0, 0, 0.3);
}

.theme-shadow-11 {
    -webkit-box-shadow: 0 0 45px rgba(0, 0, 0, .09);
    -moz-box-shadow: 0 0 45px rgba(0, 0, 0, .09);
    -o-box-shadow: 0 0 45px rgba(0, 0, 0, .09);
    box-shadow: 0 0 45px rgba(0, 0, 0, .09);
}

.theme-shadow-12 {
    -webkit-box-shadow: 0 0 10px rgba(200, 204, 227, 0.23);
    -moz-box-shadow: 0 0 10px rgba(200, 204, 227, 0.23);
    -o-box-shadow: 0 0 10px rgba(200, 204, 227, 0.23);
    box-shadow: 0 0 10px rgba(200, 204, 227, 0.23);
}

.theme-shadow-13 {
    -webkit-box-shadow: 0 10px 60px 0 rgba(29, 29, 31, .09);
    -moz-box-shadow: 0 10px 60px 0 rgba(29, 29, 31, .09);
    -o-box-shadow: 0 10px 60px 0 rgba(29, 29, 31, .09);
    box-shadow: 0 10px 60px 0 rgba(29, 29, 31, .09);
}

.theme-shadow-14 {
    -webkit-box-shadow: 0px 0px 10px 0px rgba(113, 106, 202, 0.2);
    -moz-box-shadow: 0px 0px 10px 0px rgba(113, 106, 202, 0.2);
    -o-box-shadow: 0px 0px 10px 0px rgba(113, 106, 202, 0.2);
    box-shadow: 0px 0px 10px 0px rgba(113, 106, 202, 0.2);
}

.theme-shadow-hover {
    -webkit-transition: box-shadow .55s;
    -moz-transition: box-shadow .55s;
    -o-transition: box-shadow .55s;
    transition: box-shadow .55s;
    background-image: linear-gradient(to top, #fbc2eb 0%, #a6c1ee 100%);
}

.theme-shadow-hover:hover {
    -webkit-transition: box-shadow .45s;
    -moz-transition: box-shadow .45s;
    -o-transition: box-shadow .45s;
    transition: box-shadow .45s;
    -webkit-box-shadow: 0px 5px 20px 0 rgba(0, 0, 0, 0.3);
    -moz-box-shadow: 0px 5px 20px 0 rgba(0, 0, 0, 0.3);
    -o-box-shadow: 0px 5px 20px 0 rgba(0, 0, 0, 0.3);
    box-shadow: 0px 5px 20px 0 rgba(0, 0, 0, 0.3);
}

/*-----rotation------*/

.t-rotate45 {
    -webkit-transform: rotate(45deg);
    transform: rotate(45deg)
}

.t-rotate90 {
    -webkit-transform: rotate(90deg);
    transform: rotate(90deg)
}

.t-rotate135 {
    -webkit-transform: rotate(135deg);
    transform: rotate(135deg)
}

.t-rotate180 {
    -webkit-transform: rotate(180deg);
    transform: rotate(180deg)
}

.t-rotate255 {
    -webkit-transform: rotate(255deg);
    transform: rotate(255deg)
}

.t-rotate270 {
    -webkit-transform: rotate(270deg);
    transform: rotate(270deg)
}

.t-rotate360 {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg)
}

/*-----transform-position------*/

.position-absolute {
    position: absolute;
}

.position-static {
    position: static;
}

.position-fixed {
    position: fixed;
}

.position-inherit {
    position: inherit;
}

.position-initial {
    position: initial;
}

.position-relative {
    position: relative;
}

/*-----indentation-----*/

.indent {
    text-indent: 2em;
}

/*-----text-sizes-----*/
.indent {
    font-size: 0.875rem !important;
}

.t-major {
    font-size: 1.0625rem !important;
}

.t-secondary {
    font-size: 0.8125rem !important;
}

.t-small {
    font-size: 0.75rem !important;
}

.t-dashed {
    border: 0;
    border-bottom: 1px #d3d3d3 dashed;
    display: inline
}

/*-----text-transformation-----*/

.t-lowercase {
    text-transform: lowercase;
}

.t-uppercase {
    text-transform: uppercase;
}

.t-capital {
    text-transform: capitalize;
}

/*------line-height-------*/

.t-lh2em {
    line-height: 2em;
}

.t-lh3em {
    line-height: 3em;
}

/*------dropcap------*/

.dropcap:first-letter {
    font-size: 3em;
    float: left;
    margin-top: .15em;
    border-radius: 6px;
    margin-right: 15px;
    padding: 3px 22px;
}

.dropcap.dc-square:first-letter {
    border-radius: 0;
}

.dropcap.dc-circle:first-letter {
    border-radius: 50%;
}

/*  dropcap sizes     */

.dropcap.dc-large:first-letter {
    font-size: 4em;
    padding: 3px 34px;
}

.dropcap.dc-large.dc-circle:first-letter {
    padding: 7px 34px;
}

.dropcap.dc-small:first-letter {
    font-size: 2em;
    padding: 3px 17px;
}

/*  dropcap outline     */

.dropcap.dc-outline-default:first-letter {
    border: 1px solid #e9ecef
}

.dropcap.dc-outline-primary:first-letter {
    border: 1px solid #1a73e9
}

.dropcap.dc-outline-success:first-letter {
    border: 1px solid #1abc9c
}

.dropcap.dc-outline-info:first-letter {
    border: 1px solid #00b1f4
}

.dropcap.dc-outline-danger:first-letter {
    border: 1px solid #e7515a
}

.dropcap.dc-outline-warning:first-letter {
    border: 1px solid #e9b02b
}

.dropcap.dc-outline-secondary:first-letter {
    border: 1px solid #805dca
}

.dropcap.dc-outline-dark:first-letter {
    border: 1px solid #4f5163
}

/*  dropcap colors  */

.dropcap.dc-bg-default:first-letter {
    background-color: #e9ecef;
    color: #3b3f5c
}

.dropcap.dc-bg-primary:first-letter {
    background-color: #1a73e9;
    color: #fff
}

.dropcap.dc-bg-success:first-letter {
    background-color: #1abc9c;
    color: #fff
}

.dropcap.dc-bg-info:first-letter {
    background-color: #00b1f4;
    color: #fff
}

.dropcap.dc-bg-danger:first-letter {
    background-color: #e7515a;
    color: #fff
}

.dropcap.dc-bg-warning:first-letter {
    background-color: #e9b02b;
    color: #fff
}

.dropcap.dc-bg-secondary:first-letter {
    background-color: #805dca;
    color: #fff
}

.dropcap.dc-bg-dark:first-letter {
    background-color: #4f5163;
    color: #fff
}

/*
    =====================
        FIX FOR FIREFOX
    =====================
*/

@-moz-document url-prefix() {
    .dropcap:first-letter {
        padding: 20px 23px;
    }
}

/*------list group-----*/

/*Icon*/

.list-group.icons-list-group i {
    font-size: 18px;
    color: #717171;
}

/*Badge*/

.list-group.badge-list-group .list-group-item.active span.badge[class*="badge-"] {
    border-color: #fff;
    color: #ffffff;
}

/*Contextual Classes*/

.list-group-item-primary {
    color: #fff;
    background-color: #1a73e9
}

.list-group-item-secondary {
    color: #fff;
    background-color: #805dca
}

.list-group-item-success {
    color: #fff;
    background-color: #1abc9c
}

.list-group-item-danger {
    color: #fff;
    background-color: #e7515a
}

.list-group-item-warning {
    color: #fff;
    background-color: #e9b02b
}

.list-group-item-info {
    color: #fff;
    background-color: #00b1f4
}

.list-group-item-light {
    color: #4f5163;
    background-color: #fefefa
}

.list-group-item-dark {
    color: #fff;
    background-color: #4f5163
}

.list-group-item-primary.list-group-item-action:focus,
.list-group-item-primary.list-group-item-action:hover {
    color: #fff;
    background-color: #3258db;
}

.list-group-item-secondary.list-group-item-action:focus,
.list-group-item-secondary.list-group-item-action:hover {
    color: #fff;
    background-color: #6f51ea;
}

.list-group-item-success.list-group-item-action:focus,
.list-group-item-success.list-group-item-action:hover {
    color: #fff;
    background-color: #2ea37d;
}

.list-group-item-danger.list-group-item-action:focus,
.list-group-item-danger.list-group-item-action:hover {
    color: #fff;
    background-color: #ff3743;
}

.list-group-item-warning.list-group-item-action:focus,
.list-group-item-warning.list-group-item-action:hover {
    color: #fff;
    background-color: #dea82a;
}

.list-group-item-info.list-group-item-action:focus,
.list-group-item-info.list-group-item-action:hover {
    color: #fff;
    background-color: #009eda;
}

.list-group-item-light.list-group-item-action:focus,
.list-group-item-light.list-group-item-action:hover {
    color: #454656;
    background-color: #dfdfdf;
}

.list-group-item-dark.list-group-item-action:focus,
.list-group-item-dark.list-group-item-action:hover {
    color: #fff;
    background-color: #454656;
}

/*Contextual Classes Light*/

.list-group-item-light-primary {
    color: #1a73e9;
    background-color: #c2d5ff
}

.list-group-item-light-secondary {
    color: #805dca;
    background-color: #dccff7
}

.list-group-item-light-success {
    color: #1abc9c;
    background-color: #b6fff1
}

.list-group-item-light-danger {
    color: #e7515a;
    background-color: #ffe1e2
}

.list-group-item-light-warning {
    color: #e9b02b;
    background-color: #ffeccb
}

.list-group-item-light-info {
    color: #00b1f4;
    background-color: #bae7ff
}

.list-group-item-light-light {
    color: #4f5163;
    background-color: #fbccdd
}

.list-group-item-light-dark {
    color: #4f5163;
    background-color: #acb0c3
}

.list-group-item-light-primary.list-group-item-action:focus,
.list-group-item-light-primary.list-group-item-action:hover {
    color: #fff;
    background-color: #1a73e9;
}

.list-group-item-light-secondary.list-group-item-action:focus,
.list-group-item-light-secondary.list-group-item-action:hover {
    color: #fff;
    background-color: #805dca;
}

.list-group-item-light-success.list-group-item-action:focus,
.list-group-item-light-success.list-group-item-action:hover {
    color: #fff;
    background-color: #1abc9c;
}

.list-group-item-light-danger.list-group-item-action:focus,
.list-group-item-light-danger.list-group-item-action:hover {
    color: #fff;
    background-color: #e7515a;
}

.list-group-item-light-warning.list-group-item-action:focus,
.list-group-item-light-warning.list-group-item-action:hover {
    color: #fff;
    background-color: #e9b02b;
}

.list-group-item-light-info.list-group-item-action:focus,
.list-group-item-light-info.list-group-item-action:hover {
    color: #fff;
    background-color: #00b1f4;
}

.list-group-item-light-light.list-group-item-action:focus,
.list-group-item-light-light.list-group-item-action:hover {
    color: #454656;
    background-color: #dfdfdf;
}

.list-group-item-light-dark.list-group-item-action:focus,
.list-group-item-light-dark.list-group-item-action:hover {
    color: #fff;
    background-color: #4f5163;
}

/*------vertical align-----*/
.v-a-top {
    vertical-align: top;
}

.v-a-bottom {
    vertical-align: bottom;
}

.v-a-baseline {
    vertical-align: baseline;
}

.v-a-middle {
    vertical-align: middle;
}

.v-a-sub {
    vertical-align: sub;
}

.v-a-super {
    vertical-align: super;
}

.v-a-top-txt {
    vertical-align: text-top;
}

.v-a-top-txt {
    vertical-align: text-bottom;
}

/*------Bootstrap checkbox------*/

.custom-control-label {
    cursor: pointer;
}

label.custom-control-label {
    margin-bottom: .5rem;
    margin-bottom: 16px;
}

.custom-control-label::before {
    background-color: #dee2e6;
    border: none;
}

/*
    ======================
        Rounded Checkbox
    ======================
*/

.custom-checkbox.rounded-chk .custom-control-label::before {
    border-radius: 50%;
}

/*
    ======================
        Classic Checkbox
    ======================
*/

.custom-checkbox.classic-chk .custom-control-label::before {
    border-radius: 0;
}

/*
    ============================
        Line Through Checkbox
    ============================ 
*/

.custom-checkbox.checkbox-line-through .custom-control-input:checked~.custom-control-label {
    text-decoration: line-through;
}

/*
    ============================
        Background Checkbox
    ============================
*/

.custom-checkbox.checkbox-default .custom-control-input:checked~.custom-control-label::before {
    background-color: #a2a3a3;
    border: none;
}

.custom-checkbox.checkbox-primary .custom-control-input:checked~.custom-control-label::before {
    background-color: #1a73e9;
    border: none;
}

.custom-checkbox.checkbox-success .custom-control-input:checked~.custom-control-label::before {
    background-color: #1abc9c;
    border: none;
}

.custom-checkbox.checkbox-info .custom-control-input:checked~.custom-control-label::before {
    background-color: #00b1f4;
    border: none;
}

.custom-checkbox.checkbox-warning .custom-control-input:checked~.custom-control-label::before {
    background-color: #e9b02b;
    border: none;
}

.custom-checkbox.checkbox-danger .custom-control-input:checked~.custom-control-label::before {
    background-color: #e7515a;
    border: none;
}

.custom-checkbox.checkbox-secondary .custom-control-input:checked~.custom-control-label::before {
    background-color: #805dca;
    border: none;
}

.custom-checkbox.checkbox-dark .custom-control-input:checked~.custom-control-label::before {
    background-color: #4f5163;
    border: none;
}


/*------Radio------*/

/*
    ======================
        Square Checkbox
    ======================
*/

.custom-radio.square-radio .custom-control-label::before {
    border-radius: 0;
}

/*
    ============================
        Background Radio
    ============================
*/


/*      Default         */

.custom-radio.radio-default .custom-control-input:checked~.custom-control-label::before {
    background-color: #a2a3a3;
    border: none;
}

/*      Primary         */

.custom-radio.radio-primary .custom-control-input:checked~.custom-control-label::before {
    background-color: #1a73e9;
    border: none;
}

/*      Success         */

.custom-radio.radio-success .custom-control-input:checked~.custom-control-label::before {
    background-color: #1abc9c;
    border: none;
}

/*      Info         */

.custom-radio.radio-info .custom-control-input:checked~.custom-control-label::before {
    background-color: #00b1f4;
    border: none;
}

/*      Warning         */

.custom-radio.radio-warning .custom-control-input:checked~.custom-control-label::before {
    background-color: #e9b02b;
    border: none;
}

/*      Danger         */

.custom-radio.radio-danger .custom-control-input:checked~.custom-control-label::before {
    background-color: #e7515a;
    border: none;
}

/*      Secondary         */

.custom-radio.radio-secondary .custom-control-input:checked~.custom-control-label::before {
    background-color: #805dca;
    border: none;
}

/*      Dark         */

.custom-radio.radio-dark .custom-control-input:checked~.custom-control-label::before {
    background-color: #4f5163;
    border: none;
}


/*----------Theme checkbox---------*/

.new-control {
    position: relative;
    display: -ms-inline-flexbox;
    display: inline-flex;
    padding-left: 1.5rem;
    margin-right: 1rem;
}

.new-control-input {
    position: absolute;
    z-index: -1;
    opacity: 0;
}

.new-control.new-checkbox .new-control-indicator {
    position: absolute;
    top: .25rem;
    left: 0;
    display: block;
    width: 1rem;
    height: 1rem;
    pointer-events: none;
    /* -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none; */
    background-color: #dee2e6;
    background-repeat: no-repeat;
    background-position: center center;
    background-size: 50% 50%;
    border-radius: 2px;
}

.new-control.new-checkbox {
    cursor: pointer;
}

.new-control.new-checkbox>input:checked~span.new-control-indicator {
    background: #a2a3a3;
}

.new-control.new-checkbox>input:checked~span.new-control-indicator:after {
    display: block;
}

.new-control.new-checkbox span.new-control-indicator:after {
    border: solid #fff;
}

.new-control.new-checkbox span.new-control-indicator:after {
    top: 50%;
    left: 50%;
    margin-left: -2px;
    margin-top: -6px;
    width: 5px;
    height: 10px;
    border-width: 0 2px 2px 0 !important;
    transform: rotate(45deg);
    content: '';
    position: absolute;
    display: none;
}

/*rounded checkbox*/

.new-checkbox-rounded span.new-control-indicator {
    border-radius: 50% !important;
}

/*classic checkbox*/

.new-checkbox-classic span.new-control-indicator {
    border-radius: 0 !important;
}

/*line through*/

.new-control.new-checkbox.new-checkbox-line-through>input:checked~span.new-chk-content {
    text-decoration: line-through;
}

/*  line through colors   */
.new-control.new-checkbox.new-checkbox-line-through.>input:checked~span.new-chk-content,
.new-control.new-checkbox.new-checkbox-line-through.checkbox-outline-default>input:checked~span.new-chk-content {
    color: #a2a3a3;
}

.new-control.new-checkbox.new-checkbox-line-through.checkbox-primary>input:checked~span.new-chk-content,
.new-control.new-checkbox.new-checkbox-line-through.checkbox-outline-primary>input:checked~span.new-chk-content {
    color: #1a73e9;
}

.new-control.new-checkbox.new-checkbox-line-through.checkbox-success>input:checked~span.new-chk-content,
.new-control.new-checkbox.new-checkbox-line-through.checkbox-outline-success>input:checked~span.new-chk-content {
    color: #1abc9c;
}

.new-control.new-checkbox.new-checkbox-line-through.checkbox-info>input:checked~span.new-chk-content,
.new-control.new-checkbox.new-checkbox-line-through.checkbox-outline-info>input:checked~span.new-chk-content {
    color: #00b1f4;
}

.new-control.new-checkbox.new-checkbox-line-through.checkbox-warning>input:checked~span.new-chk-content,
.new-control.new-checkbox.new-checkbox-line-through.checkbox-outline-warning>input:checked~span.new-chk-content {
    color: #e9b02b;
}

.new-control.new-checkbox.new-checkbox-line-through.checkbox-danger>input:checked~span.new-chk-content,
.new-control.new-checkbox.new-checkbox-line-through.checkbox-outline-danger>input:checked~span.new-chk-content {
    color: #e7515a;
}

.new-control.new-checkbox.new-checkbox-line-through.checkbox-secondary>input:checked~span.new-chk-content,
.new-control.new-checkbox.new-checkbox-line-through.checkbox-outline-secondary>input:checked~span.new-chk-content {
    color: #805dca;
}

.new-control.new-checkbox.new-checkbox-line-through.checkbox-dark>input:checked~span.new-chk-content,
.new-control.new-checkbox.new-checkbox-line-through.checkbox-outline-dark>input:checked~span.new-chk-content {
    color: #4f5163;
}


/*  Color   */
.new-control.new-checkbox.checkbox-primary>input:checked~span.new-control-indicator {
    background: #1a73e9;
}

.new-control.new-checkbox.checkbox-success>input:checked~span.new-control-indicator {
    background: #1abc9c;
}

.new-control.new-checkbox.checkbox-info>input:checked~span.new-control-indicator {
    background: #00b1f4;
}

.new-control.new-checkbox.checkbox-warning>input:checked~span.new-control-indicator {
    background: #e9b02b;
}

.new-control.new-checkbox.checkbox-danger>input:checked~span.new-control-indicator {
    background: #e7515a;
}

.new-control.new-checkbox.checkbox-secondary>input:checked~span.new-control-indicator {
    background: #805dca;
}

.new-control.new-checkbox.checkbox-dark>input:checked~span.new-control-indicator {
    background: #4f5163;
}


/*   Outline checkbox   */
.new-control.new-checkbox[class*="checkbox-outline-"]>input:checked~span.new-control-indicator {
    background-color: transparent;
}

.new-control.new-checkbox.checkbox-outline-default>input:checked~span.new-control-indicator {
    border: 1px solid #a2a3a3;
}

.new-control.new-checkbox.checkbox-outline-primary>input:checked~span.new-control-indicator {
    border: 1px solid #1a73e9;
}

.new-control.new-checkbox.checkbox-outline-success>input:checked~span.new-control-indicator {
    border: 1px solid #1abc9c;
}

.new-control.new-checkbox.checkbox-outline-info>input:checked~span.new-control-indicator {
    border: 1px solid #00b1f4;
}

.new-control.new-checkbox.checkbox-outline-warning>input:checked~span.new-control-indicator {
    border: 1px solid #e9b02b;
}

.new-control.new-checkbox.checkbox-outline-danger>input:checked~span.new-control-indicator {
    border: 1px solid #e7515a;
}

.new-control.new-checkbox.checkbox-outline-secondary>input:checked~span.new-control-indicator {
    border: 1px solid #805dca;
}

.new-control.new-checkbox.checkbox-outline-dark>input:checked~span.new-control-indicator {
    border: 1px solid #4f5163;
}

.new-control.new-checkbox.checkbox-outline-default>input:checked~span.new-control-indicator:after {
    border-color: #a2a3a3;
}

.new-control.new-checkbox.checkbox-outline-primary>input:checked~span.new-control-indicator:after {
    border-color: #1a73e9;
}

.new-control.new-checkbox.checkbox-outline-success>input:checked~span.new-control-indicator:after {
    border-color: #1abc9c;
}

.new-control.new-checkbox.checkbox-outline-info>input:checked~span.new-control-indicator:after {
    border-color: #00b1f4;
}

.new-control.new-checkbox.checkbox-outline-warning>input:checked~span.new-control-indicator:after {
    border-color: #e9b02b;
}

.new-control.new-checkbox.checkbox-outline-danger>input:checked~span.new-control-indicator:after {
    border-color: #e7515a;
}

.new-control.new-checkbox.checkbox-outline-secondary>input:checked~span.new-control-indicator:after {
    border-color: #805dca;
}

.new-control.new-checkbox.checkbox-outline-dark>input:checked~span.new-control-indicator:after {
    border-color: #4f5163;
}


/*----------Theme Radio---------*/

.new-control.new-radio .new-control-indicator {
    position: absolute;
    top: .25rem;
    left: 0;
    display: block;
    width: 1rem;
    height: 1rem;
    pointer-events: none;
    /* -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none; */
    background-color: #dee2e6;
    background-repeat: no-repeat;
    background-position: center center;
    background-size: 50% 50%;
    border-radius: 50%;
}

.new-control.new-radio {
    cursor: pointer;
}

.new-control.new-radio>input:checked~span.new-control-indicator {
    background: #a2a3a3;
}

.new-control.new-radio>input:checked~span.new-control-indicator:after {
    display: block;
}

/*.new-control.new-radio span.new-control-indicator:after { border: solid #fff; }*/
.new-control.new-radio span.new-control-indicator:after {
    top: 50%;
    left: 50%;
    margin-left: -3px;
    margin-top: -3px;
    content: '';
    position: absolute;
    display: none;
    border-radius: 50%;

    height: 6px;
    width: 6px;
    background-color: #fff;
}

/*square radio*/
.new-control.new-radio.square-radio .new-control-indicator {
    border-radius: 0;
}

.new-control.new-radio.square-radio span.new-control-indicator:after {
    border-radius: 0;
}

/*line through*/

.new-control.new-radio.new-radio-line-through>input:checked~span.new-radio-content {
    text-decoration: line-through;
}


/*  Color   */
.new-control.new-radio.radio-primary>input:checked~span.new-control-indicator {
    background: #1a73e9;
}

.new-control.new-radio.radio-success>input:checked~span.new-control-indicator {
    background: #1abc9c;
}

.new-control.new-radio.radio-info>input:checked~span.new-control-indicator {
    background: #00b1f4;
}

.new-control.new-radio.radio-warning>input:checked~span.new-control-indicator {
    background: #e9b02b;
}

.new-control.new-radio.radio-danger>input:checked~span.new-control-indicator {
    background: #e7515a;
}

.new-control.new-radio.radio-secondary>input:checked~span.new-control-indicator {
    background: #805dca;
}

.new-control.new-radio.radio-dark>input:checked~span.new-control-indicator {
    background: #4f5163;
}



/*   Outline Radio   */
.new-control.new-radio[class*="radio-classic-"]>input:checked~span.new-control-indicator {
    background-color: transparent;
}

.new-control.new-radio.radio-classic-default>input:checked~span.new-control-indicator {
    border: 1px solid #a2a3a3;
}

.new-control.new-radio.radio-classic-primary>input:checked~span.new-control-indicator {
    border: 1px solid #1a73e9;
}

.new-control.new-radio.radio-classic-success>input:checked~span.new-control-indicator {
    border: 1px solid #1abc9c;
}

.new-control.new-radio.radio-classic-info>input:checked~span.new-control-indicator {
    border: 1px solid #00b1f4;
}

.new-control.new-radio.radio-classic-warning>input:checked~span.new-control-indicator {
    border: 1px solid #e9b02b;
}

.new-control.new-radio.radio-classic-danger>input:checked~span.new-control-indicator {
    border: 1px solid #e7515a;
}

.new-control.new-radio.radio-classic-secondary>input:checked~span.new-control-indicator {
    border: 1px solid #805dca;
}

.new-control.new-radio.radio-classic-dark>input:checked~span.new-control-indicator {
    border: 1px solid #4f5163;
}

.new-control.new-radio.radio-classic-default>input:checked~span.new-control-indicator:after {
    background-color: #a2a3a3;
}

.new-control.new-radio.radio-classic-primary>input:checked~span.new-control-indicator:after {
    background-color: #1a73e9;
}

.new-control.new-radio.radio-classic-success>input:checked~span.new-control-indicator:after {
    background-color: #1abc9c;
}

.new-control.new-radio.radio-classic-info>input:checked~span.new-control-indicator:after {
    background-color: #00b1f4;
}

.new-control.new-radio.radio-classic-warning>input:checked~span.new-control-indicator:after {
    background-color: #e9b02b;
}

.new-control.new-radio.radio-classic-danger>input:checked~span.new-control-indicator:after {
    background-color: #e7515a;
}

.new-control.new-radio.radio-classic-secondary>input:checked~span.new-control-indicator:after {
    background-color: #805dca;
}

.new-control.new-radio.radio-classic-dark>input:checked~span.new-control-indicator:after {
    background-color: #4f5163;
}

/*  line through colors   */
.new-control.new-radio.new-radio-line-through.>input:checked~span.new-radio-content,
.new-control.new-radio.new-radio-line-through.radio-classic-default>input:checked~span.new-radio-content {
    color: #a2a3a3;
}

.new-control.new-radio.new-radio-line-through.radio-primary>input:checked~span.new-radio-content,
.new-control.new-radio.new-radio-line-through.radio-classic-primary>input:checked~span.new-radio-content {
    color: #1a73e9;
}

.new-control.new-radio.new-radio-line-through.radio-success>input:checked~span.new-radio-content,
.new-control.new-radio.new-radio-line-through.radio-classic-success>input:checked~span.new-radio-content {
    color: #1abc9c;
}

.new-control.new-radio.new-radio-line-through.radio-info>input:checked~span.new-radio-content,
.new-control.new-radio.new-radio-line-through.radio-classic-info>input:checked~span.new-radio-content {
    color: #00b1f4;
}

.new-control.new-radio.new-radio-line-through.radio-warning>input:checked~span.new-radio-content,
.new-control.new-radio.new-radio-line-through.radio-classic-warning>input:checked~span.new-radio-content {
    color: #e9b02b;
}

.new-control.new-radio.new-radio-line-through.radio-danger>input:checked~span.new-radio-content,
.new-control.new-radio.new-radio-line-through.radio-classic-danger>input:checked~span.new-radio-content {
    color: #e7515a;
}

.new-control.new-radio.new-radio-line-through.radio-secondary>input:checked~span.new-radio-content,
.new-control.new-radio.new-radio-line-through.radio-classic-secondary>input:checked~span.new-radio-content {
    color: #805dca;
}

.new-control.new-radio.new-radio-line-through.radio-dark>input:checked~span.new-radio-content,
.new-control.new-radio.new-radio-line-through.radio-classic-dark>input:checked~span.new-radio-content {
    color: #4f5163;
}

.mCS-minimal-dark.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar {
    background-color: #dedfdf !important;
    width: 5px;
}

/*  Media Queries   */

@media(min-width: 1200px) {
    .custom-col-xl-12by5 {
        padding-right: 15px;
        padding-left: 15px;
        -ms-flex: 0 0 20%;
        flex: 0 0 20%;
        max-width: 19.9444444444%;
    }
}



/* toons */
.submenu-scroll {
    overflow-y: scroll;
}

.cy-reg-btn {
    background-color: #01539D;
    color: white !important;
    padding: 6px 10px;
    border-radius: 3px;
}

.campaigns-wrap {
    background-color: white;
    padding: 8px;
    border-radius: 5px;
    box-shadow: 1px 1px 3px #0000001c;
    border: 1px solid #f2f2f2;
    width: 100%;

}

.earn-upto-div {
    position: relative;
    box-shadow: 1px 1px 4px #00000021;
    width: 100px;
    border-radius: 10px;
    text-align: center;
    margin: 0px auto;
    padding-top: 50px;
    margin-top: 20px;
    margin-bottom: 15px;
    padding-bottom: 8px;
    background-color: #F9F9F9;
}

.earn-upto-div img {
    position: absolute;
    transform: translate(-50%, -50%);
    top: 3px;
    left: 49px;
}

.upto-points {
    color: green;
    font-weight: 700;
}

.upto-points-new {
    color: green;
    display: block;
    font-size: 20px;
    box-shadow: 1px 1px 2px #00000012;
    /* font-weight: 700; */
}

.campaign-title {
    color: black;
    font-size: 20px;
    padding-top: 8px;
    padding-bottom: 0px;
    padding-left: 3px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    text-align: center;
}

span.hoursWrapper.leftAlignWrapper {
    width: 100%;
}

.campaign-goal {
    color: gray;
    font-size: 16px;
    /* padding-top: 24px; */
    padding-top: 5px;
    padding-bottom: 0px;
    /* padding-left: 3px; */
}

.campaign-goal-landing {
    color: gray;
    font-size: 16px;
    padding-top: 20px;
    padding-bottom: 0px;
    padding-left: 3px;
}

.campaign-signin {
    color: white !important;
    background-color: #01539D;
    padding: 10px 20px;
    border-radius: 5px;
    position: relative;
    margin-top: 11px;
    display: inline-block;
    font-size: 15px;
    font-weight: 500;
}

.mr-15 {
    margin-bottom: 15px;
}

.progress-txt {
    text-align: center;
    display: block;
    font-size: 12px;
}

.campaign-progress {
    margin-bottom: 0px !important;
}

.item.slide1 {
    /* background-image: linear-gradient(to right, #db8e0f , white); */
    background-image: linear-gradient(to right, #db8e0f, #f4ddb7);
    padding: 0px 0px;
    height: auto;
}

.item.slideaboutus1 {
    background-image: linear-gradient(to right, #db8e0f, #ffd999);
    padding: 15px 15px 0px 15px;
    margin-bottom: 30px;
    margin-left: 15px;
    /* height: 72vh; */
}

.item.slideaboutus2 {
    /* background-image: linear-gradient(to right, #db8e0f , white); */
    background-image: linear-gradient(to right, #ffd999, #db8e0f);
    padding: 15px 15px 0px 15px;
    margin-bottom: 30px;
    margin-left: 15px;
    /* height: 72vh; */
}

.item.slideaboutus3 {
    background-image: linear-gradient(to right, #db8e0f, #ffd999);
    padding: 15px 15px 0px 15px;
    margin-bottom: 30px;
    margin-left: 15px;
}

.item.slide1 h2,
.item.slide1 p {
    color: white;
}

.item.slide1 h4 {
    color: #1976d2;
    /* color: #ADD8E6; */
}

.streak-wrap {
    border: 1px solid #f7f7f7;
    border-radius: 5px;
    padding: 22px;
    box-shadow: 1px 2px 2px #00000012;
    margin: 4px;
}

.more-p-btn {
    background-color: #01539D;
    color: white !important;
    padding: 7px 20px;
    border-radius: 5px;
    display: inline-block;
    margin-top: 10px;
    position: relative;
}

.more-p-btn img {
    position: absolute;
    top: -11px;
    width: 20px !important;
    right: 5px;
}

span.streak-point {
    color: black;
    font-weight: 600;
}

a.streak-view-more {
    background-color: white;
    font-size: 11px;
    padding: 4px 8px;
    color: black !important;
    border-radius: 25px;
    margin-top: 56px;
    display: inline-block;
    border: 1px solid #c1c1c1;
}

.streak-point-sec {
    display: flex;
}

.streak-point-sec h4 {
    font-size: 26px;
    color: black;
    font-weight: 700;
}

.streak-point-sec img {
    width: 30px !important;
    height: 30px;
}

.reward-wrap {
    background: linear-gradient(to bottom, #db8d0f, #ffffff);
    padding: 10px;
    border-radius: 5px;
    box-shadow: 1px 1px 6px 3px #0000001a;
}

.reward-desc-title {
    font-size: 12px;
    color: black;
}

div#toggleAccordion {
    margin-top: 14px;
}

.sponsor-title {
    color: black;
    font-size: 13px;
    margin-top: 8px;
}

.succ-story-wrap {
    background-image: linear-gradient(to right, #db8e0f, white);
    box-shadow: 1px 1px 6px 0px #00000026;
    border-radius: 6px;
    padding: 15px 10px;
}
.payment-gw-wrap {
    background-image: linear-gradient(to right, #db8e0f, white);
    box-shadow: 1px 1px 6px 0px #00000026;
    border-radius: 6px;
    padding: 15px 10px;
}

.succ-story-badge-wrap {
    background-image: linear-gradient(to right, #db8e0f, white);
    box-shadow: 1px 1px 6px 0px #00000026;
    border-radius: 6px;
    padding: 15px 10px;
}

.succ-story-wrap img {
    border-radius: 50%;
    width: 100px !important;
    height: 100px;
    /* object-fit: contain; */
    border: 4px solid white;
    margin: 10px auto;
    display: block;
}

.payment-gw-wrap img {
    /* border-radius: 50%; */
    /* width: 100px !important; */
    /* height: 100px; */
    /* object-fit: contain; */
    border: 4px solid white;
    margin: 10px auto;
    display: block;
}

.succ-story-badge-wrap img {
    border-radius: 50%;
    width: 100px !important;
    height: 100px;
    object-fit: contain;
    border: 4px solid white;
    margin: 10px auto;
    display: block;
}

.succ-story-details h6 {
    color: black;
}

.succ-story-details p {
    color: black;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    text-overflow: ellipsis;
    overflow: hidden;
}

.text-center.succ-story-details {
    margin-top: 12px;
}

.testi-content h2 {
    font-size: 40px;
    color: black;
}

.testimonial-wrap {
    box-shadow: 1px 1px 4px #0000004a;
    padding: 50px 30px;
    border-radius: 5px;
}

.testi-img {
    width: 75%;
    margin: 0px auto;
    display: block;
    margin-top: -40px;
}

img.test-per-img {
    width: 90px !important;
    height: 90px;
    object-fit: cover;
    border-radius: 50%;
    border: 3px solid gray;
}

.they-said-wrap {
    display: flex;

    margin-top: 30px;

}

.testi-desc {
    width: 60%;
    margin-left: 10px;
}

.testi-desc h6 {
    color: black;
}

.testi-desc p {
    color: black;
    font-size: 13px;
}

.testi-content {
    margin-top: 10px;
}

.view-more-btn {
    background-color: white;
    color: black !important;
    border-radius: 18px;
    box-shadow: 1px 1px 4px #00000040;
    border: 1px solid #8080803b;
}

.testi-sl .owl-nav {
    position: absolute;
    top: 40px;
    right: 70px;
}

.testi-sl .owl-nav button {
    color: white !important;
    background-color: #01539d !important;
    padding: 10px !important;
    border: none !important;
    font-size: 30px !important;
    border-radius: 50% !important;
    height: 36px !important;
    width: 36px !important;
    position: relative;
}

.testi-sl .owl-nav button span {
    position: absolute;
    z-index: 9999;
    top: -7px;
    right: 13px;
}

a.story-signin {
    background-color: #01539d;
    color: white !important;
    padding: 6px 1px;
    border-radius: 4px;
    font-size: 15px;
    font-weight: 400;
}

a.story-post-reg {
    background-color: #01539d;
    color: white !important;
    padding: 11px 13px;
    border-radius: 4px;
    font-size: 15px;
    font-weight: 400;
}

h4.site-title {
    color: black;
}

h4.site-title-camp-direct {
    color: black;
}

.cyagg-sec-space {
    padding: 8px 0px;
    /* user-select: all; */

}

.cyagg-camp-space {
    /* padding: 32px 0px; */
    padding: 0px 0px;
}

.slider-img img {
    width: 100%;
    height: 300px;
}

a.hash-styles {
    background-color: #DAE8FD;
    color: #3082F7 !important;
    padding: 2px 8px;
    border-radius: 3px;
    font-size: 14px;
    margin-bottom: 2px;
    display: inline-block;
}

a.hash-styles-sdg {
    background-color: #3082F7;
    color: white !important;
    padding: 2px 8px;
    border-radius: 3px;
    font-size: 14px;
    margin-bottom: 2px;
    display: inline-block;
}

a.hash-styles-goal {
    padding: 2px 0px;
    border-radius: 3px;
    margin-bottom: 0px;
    display: inline-block;
    font-size: 16px;
}

.owl-theme .owl-nav [class*='owl-'] {
    margin: 11px;
}

.progress-semi {
    position: relative;
    margin: 4px;
    float: left;
    text-align: center;
}

.barOverflow {
    /* Wraps the rotating .bar */
    position: relative;
    overflow: hidden;
    /* Comment this line to understand the trick */
    width: 90px;
    height: 45px;
    /* Half circle (overflow) */
    margin-bottom: -14px;
    /* bring the numbers up */
}

.bar {
    position: absolute;
    top: 0;
    left: 0;
    width: 90px;
    height: 90px;
    /* full circle! */
    border-radius: 50%;
    box-sizing: border-box;
    border: 5px solid #eee;
    /* half gray, */
    border-bottom-color: #0bf;
    /* half azure */
    border-right-color: #0bf;
}

.campaign-time-title {
    color: black;
}

.cam-cols {
    margin-top: 5px;
}

.progress-bar.bg-primary {
    background-color: #db8e10 !important;
    border-color: #db8e10;
}

.progress-bar.bg-approved {
    background-color: #00FF00 !important;
    border-color: #db8e10;
}

.p-bar h6 {
    margin-bottom: 0px;
    color: #01539d;
    font-weight: 700;
}

.campaigns-tabs .nav-tabs .nav-item.show .nav-link,
.nav-tabs .nav-link.active {
    color: #ffffff;
    background-color: #fff;
    border-color: #dee2e6 #dee2e6 #fff;
    background-color: #db8d0f;
    border-radius: 24px !important;
}

.campaigns-tabs .nav-tabs .nav-link:focus,
.nav-tabs .nav-link:hover {
    border-color: #e9ecef #e9ecef #dee2e6;
    border-radius: 24px !important;
}

@media only screen and (max-width: 600px) {

    .campaigns-tabs .nav-tabs .nav-item.show .nav-link,
    .nav-tabs .nav-link.active {
        color: #ffffff;
        background-color: #fff;
        border-color: #dee2e6 #dee2e6 #fff;
        background-color: #db8d0f;
        border-radius: 16px !important;
    }
    
    .campaigns-tabs .nav-tabs .nav-link:focus,
    .nav-tabs .nav-link:hover {
        border-color: #e9ecef #e9ecef #dee2e6;
        border-radius: 16px !important;
    }


}

.navigationS {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    /* margin-top: 90px; */
    margin-top: 40px;
    margin-bottom: 20px;

}
.profilenavigationS {
    position: relative;
}

/* .navigationS::after {
    display: none;
    
} */
.menuToggleS {
    position: relative;
    width: 60px;
    height: 60px;
    background-color: #fff;
    border-radius: 70px;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    box-shadow: 0 15px 25px rgba(0, 0, 0, 0.15);
}
.profileToggleS {
    position: relative;
    width: 40px;
    height: 40px;
    background-color: #fff;
    border-radius: 70px;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    box-shadow: 0 0px 15px rgba(0, 0, 0, 0.15);
}


.menuToggleS.active::before {
    transform: rotate(315deg);
}
.profileToggleS.active::before {
    transform: rotate(315deg);
}


.menuToggleSTeams {
    position: relative;
    width: 60px;
    height: 60px;
    background-color: #fff;
    border-radius: 70px;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    box-shadow: 0 15px 25px rgba(0, 0, 0, 0.15);
}


.menuToggleSTeams.active::before {
    transform: rotate(315deg);
}

/* ORIGINAL */
/* .menuS {
    position: absolute;
    width: 30px;
    height: 30px;
    background: #fff;
    border-radius: 70px;
    z-index: -1;
    transition: transform 0.5s, width 0.5s, height 0.5s;
    transition-delay: 1s, 0.5s, 0.5s;
    transition-timing-function: cubic-bezier(0.075, 0.82, 0.165, 1);
} */
.menuS {
    position: absolute;
    width: 30px;
    height: 30px;
    /* background: #fff; */
    border-radius: 70px;
    z-index: -1;
    transition: transform 0.5s, width 0.5s, height 0.5s;
    transition-delay: 1s, 0.5s, 0.5s;
    transition-timing-function: cubic-bezier(0.075, 0.82, 0.165, 1);
}

.menuToggleS.active~.menuS {
    /* width: 270px;
    height: 70px; */
    z-index: 1;
    transform: translateY(-80px);
    transition-delay: 0s, 0.5s, 0.5s;
    /* box-shadow: 0 15px 25px rgba(0, 0, 0, 0.1); */
}

.profileToggleS.active~.menuS {
    z-index: 1;
    transform: translateY(-80px);
    transition-delay: 0s, 0.5s, 0.5s;
}


.menuToggleSTeams.active~.menuS {
    /* width: 270px;
    height: 70px; */
    z-index: 1;
    transform: translateY(-80px);
    transition-delay: 0s, 0.5s, 0.5s;
    /* box-shadow: 0 15px 25px rgba(0, 0, 0, 0.1); */
}

.menuS::before {
    content: '';
    position: absolute;
    width: 16px;
    height: 16px;
    /* background: #fff; */
    left: calc(50% - 8px);
    bottom: 4px;
    transform: rotate(45deg);
    border-radius: 2px;
    transition: 0.5s;
}

.menuToggleS.active~.menuS::before {
    transition-delay: 0.5s;
    bottom: -6px;
}
.profileToggleS.active~.menuS::before {
    transition-delay: 0.5s;
    bottom: -6px;
}


.menuToggleSTeams.active~.menuS::before {
    transition-delay: 0.5s;
    bottom: -6px;
}

.menuS ul {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 90px;
    gap: 30px;
    padding: unset;
}

.menuS ul li {
    list-style: none;
    cursor: pointer;
    opacity: 0;
    visibility: hidden;
    transform: translateY(-30px);
    transition: 0.25s;
    transition-delay: calc(0s + var(--i));
}

.menuToggleS.active~.menuS ul li {
    opacity: 1;
    visibility: visible;
    transform: translateY(0px);
    transition-delay: calc(0.75s + var(--i));
}

.profileToggleS.active~.menuS ul li {
    opacity: 1;
    visibility: visible;
    transform: translateY(0px);
    transition-delay: calc(0.75s + var(--i));
}


.menuToggleSTeams.active~.menuS ul li {
    opacity: 1;
    visibility: visible;
    transform: translateY(0px);
    transition-delay: calc(0.75s + var(--i));
}

.menuS ul button {
    list-style: none;
    cursor: pointer;
    opacity: 0;
    visibility: hidden;
    transform: translateY(-30px);
    transition: 0.25s;
    transition-delay: calc(0s + var(--i));
}

.menuToggleS.active~.menuS ul button {
    opacity: 1;
    visibility: visible;
    transform: translateY(0px);
    transition-delay: calc(0.75s + var(--i));
}

.profileToggleS.active~.menuS ul button {
    opacity: 1;
    visibility: visible;
    transform: translateY(0px);
    transition-delay: calc(0.75s + var(--i));
}



.menuToggleSTeams.active~.menuS ul button {
    opacity: 1;
    visibility: visible;
    transform: translateY(0px);
    transition-delay: calc(0.75s + var(--i));
}

.menuS ul li a {
    display: block;
    font-size: 2.2em;
}

.ref-form {
    display: inline-flex;
}

.reff-bttn {
    background-color: #db8e0f;
    color: white !important;
    border: none;
    font-size: 14px;
    box-shadow: unset;
    margin-left: 4px;
}

.form-reg {
    /* max-width: 300px; */
    /* margin: 10px auto; */
    padding: 20px 20px;
    background: #fbfbfb;
    border-radius: 8px;
    box-shadow: 2px 2px 5px #00000014;
}

.congrats-abs {
    width: 100%;
    position: absolute;
    bottom: 0px;
    left: 0px;
    right: 0px;
    top: 791px;
}

.congrats-logo {
    width: 100%;
    /* position: absolute; */
    /* bottom: 0px;
    left: 0px;
    right: 0px;
    top: 678px; */
}

.form-reg h1 {
    margin: 0 0 30px 0;
    text-align: center;
}

.freeFormGenerator label {
    margin-top: 10px;
}

.form-reg input[type="text"],
.freeFormGenerator input[type="text"],
.form-reg input[type="password"],
.form-reg input[type="date"],
.form-reg input[type="datetime"],
.form-reg input[type="email"],
.form-reg input[type="number"],
.form-reg input[type="search"],
.form-reg input[type="tel"],
.form-reg input[type="time"],
.form-reg input[type="url"],
.form-reg textarea,
.form-reg select {
    background: rgba(255, 255, 255, 0.1);
    border: none;
    font-size: 16px;
    height: auto;
    margin: 0;
    outline: 0;
    padding: 10px 15px;
    width: 100%;
    background-color: #e8eeef;
    color: #8a97a0;
    box-shadow: 0 1px 0 rgba(0, 0, 0, 0.03) inset;
    margin-bottom: 15px;
    border-radius: 5px;
}

.form-reg input[type="radio"],
.form-reg input[type="checkbox"] {
    margin: 0 4px 8px 0;
}

.form-reg.multiselectSpace input[type="checkbox"] {
    margin-right: 10px;
}

.form-reg select {
    padding: 6px;
    height: 45px;
    border-radius: 4px;
    font-size: 14px;
    color: #384047;
}

.form-reg button {
    padding: 10px 10px 10px 10px;
    color: #FFF;
    background-color: #da8d0f;
    font-size: 15px;
    text-align: center;
    font-style: normal;
    border-radius: 5px;
    width: 100%;
    border: 1px solid #da8d0f;
    border-width: 1px 1px 3px;
    box-shadow: 0 -1px 0 rgb(255 255 255 / 10%) inset;
    margin-bottom: 10px;
    font-weight: 600;
    margin-top: 10px;
}

.form-reg fieldset {
    margin-bottom: 15px;
    border: none;
}

.form-reg legend {
    font-size: 1.4em;
    margin-bottom: 10px;
}

.form-reg label {
    display: block;
    margin-bottom: 8px;
}

.form-reg label.light {
    font-weight: 300;
    display: inline;
}

.form-reg .number {
    background-color: #5fcf80;
    color: #fff;
    height: 30px;
    width: 30px;
    display: inline-block;
    font-size: 0.8em;
    margin-right: 4px;
    line-height: 30px;
    text-align: center;
    text-shadow: 0 1px 0 rgba(255, 255, 255, 0.2);
    border-radius: 100%;
}






.form-reg-participate h1 {
    margin: 0 0 30px 0;
    text-align: center;
}

.form-reg-participate input[type="text"],
.form-reg-participate input[type="password"],
.form-reg-participate input[type="date"],
.form-reg-participate input[type="datetime"],
.form-reg-participate input[type="email"],
.form-reg-participate input[type="number"],
.form-reg-participate input[type="search"],
.form-reg-participate input[type="tel"],
.form-reg-participate input[type="time"],
.form-reg-participate input[type="url"],
.form-reg-participate textarea,
.form-reg-participate select {
    background: rgba(255, 255, 255, 0.1);
    border: none;
    font-size: 16px;
    height: auto;
    margin: 0;
    outline: 0;
    padding: 10px 15px;
    width: 100%;
    background-color: #e8eeef;
    color: #8a97a0;
    box-shadow: 0 1px 0 rgba(0, 0, 0, 0.03) inset;
    margin-bottom: 15px;
    border-radius: 5px;
}

.form-reg-participate input[type="radio"],
.form-reg-participate input[type="checkbox"] {
    margin: 0 4px 8px 0;
}

.form-reg-participate.multiselectSpace input[type="checkbox"] {
    margin-right: 10px;
}

.form-reg-participate select {
    padding: 6px;
    height: 45px;
    border-radius: 4px;
    font-size: 14px;
    color: #384047;
}

.form-reg-participate button {
    padding: 10px 10px 10px 10px;
    color: #FFF;
    /* background-color: #da8d0f; */
    font-size: 15px;
    text-align: center;
    font-style: normal;
    border-radius: 5px;
    /* width: 100%; */
    /* border: 1px solid #da8d0f; */
    /* border-width: 1px 1px 3px; */
    box-shadow: 0 -1px 0 rgb(255 255 255 / 10%) inset;
    margin-bottom: 10px;
    font-weight: 600;
    margin-top: 10px;
}

.form-reg-participate fieldset {
    margin-bottom: 15px;
    border: none;
}

.form-reg-participate legend {
    font-size: 1.4em;
    margin-bottom: 10px;
}

.form-reg-participate label {
    display: block;
    margin-bottom: 8px;
}

.form-reg-participate label.light {
    font-weight: 300;
    display: inline;
}

.form-reg-participate .number {
    background-color: #5fcf80;
    color: #fff;
    height: 30px;
    width: 30px;
    display: inline-block;
    font-size: 0.8em;
    margin-right: 4px;
    line-height: 30px;
    text-align: center;
    text-shadow: 0 1px 0 rgba(255, 255, 255, 0.2);
    border-radius: 100%;
}






.filecard {
    border-radius: 10px;
    box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.3);
    width: 100%;
    height: 260px;
    background-color: #ffffff;
    padding: 10px 10px 40px;
}

.filecard-participate {
    border-radius: 10px;
    box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.3);
    width: 75%;
    /* height: 260px; */
    background-color: #ffffff;
    padding: 10px 10px 40px;
}

.freeFormGenerator .filecard-participate {
    width: 100% !important;
    min-width: 320px !important;
    pointer-events: none;
    cursor: not-allowed;
}

.freeFormGenerator .filecard-participate button {
    margin-top: 10px !important;
    margin-bottom: 10px !important;
}

.freeFormGenerator p a {
    pointer-events: none;
    cursor: not-allowed;
}

.filecardupcoming {
    border-radius: 10px;
    box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.3);
    /* width: 70%;
    height: 80px; */
    background-color: #ffffff;
    /* padding: 10px 10px 40px; */
    padding: 10px;
    font-size: 12px;
    text-align: center;
}

.filecardrewardredeemedcert {
    border-radius: 10px;
    /* box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.3); */
    /* width: 70%;
    height: 80px; */
    background-color: #ffffff;
    /* padding: 10px 162px 1px 5px; */
    padding: 10px;
    font-size: 12px;
    text-align: center;
    max-width: 100%;
}

.filecard h3 {
    font-size: 22px;
    font-weight: 600;
}

.filecard .drop_box {
    margin: 2px 0;
    padding: 0px 10px 14px 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    border: 3px dotted #a3a3a3;
    border-radius: 5px;
}

.filecard .drop_box h4 {
    font-size: 16px;
    font-weight: 400;
    color: #2e2e2e;
}

.filecard .drop_box p {
    margin-top: 10px;
    margin-bottom: 20px;
    font-size: 12px;
    color: #a3a3a3;
}

.filecard .btn {
    text-decoration: none;
    background-color: #db8d10;
    color: #ffffff;
    padding: 5px 20px;
    border: none;
    outline: none;
    transition: 0.3s;
}





.filecard-participate h3 {
    font-size: 22px;
    font-weight: 600;
}

.filecard-participate .drop_box {
    margin: 2px 0;
    padding: 0px 10px 14px 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    border: 3px dotted #a3a3a3;
    border-radius: 5px;
}

.filecard-participate .drop_box h4 {
    font-size: 16px;
    font-weight: 400;
    color: #2e2e2e;
}

.filecard-participate .drop_box p {
    margin-top: 10px;
    margin-bottom: 20px;
    font-size: 12px;
    color: #a3a3a3;
}

.filecard-participate .btn {
    text-decoration: none;
    background-color: #db8d10;
    color: #ffffff;
    padding: 5px 20px;
    border: none;
    outline: none;
    transition: 0.3s;
}








.filecardupcoming .btn {
    text-decoration: none;
    background-color: #db8d10;
    color: #ffffff;
    padding: 5px 20px;
    border: none;
    outline: none;
    transition: 0.3s;
}

.filecardrewardredeemedcert .btn {
    text-decoration: none;
    background-color: #db8d10;
    color: #ffffff;
    /* padding: 5px 20px; */
    border: none;
    outline: none;
    transition: 0.3s;
}

.filecard .btn:hover {
    text-decoration: none;
    background-color: #ffffff;
    color: #005af0;
    padding: 10px 20px;
    border: none;
    outline: 1px solid #010101;
}

.filecard-participate .btn:hover {
    text-decoration: none;
    background-color: #ffffff;
    color: #005af0;
    padding: 10px 20px;
    border: none;
    outline: 1px solid #010101;
}

.filecardupcoming .btn:hover {
    text-decoration: none;
    background-color: #ffffff;
    color: #005af0;
    padding: 10px 20px;
    border: none;
    outline: 1px solid #010101;
}

.filecardrewardredeemedcert .btn:hover {
    text-decoration: none;
    background-color: #ffffff;
    color: #005af0;
    padding: 10px 20px;
    border: none;
    outline: 1px solid #010101;
}

.filecard .form input {
    margin: 10px 0;
    width: 100%;
    background-color: #e2e2e2;
    border: none;
    outline: none;
    padding: 12px 20px;
    border-radius: 4px;
}

.filecard-participate .form input {
    margin: 10px 0;
    width: 100%;
    background-color: #e2e2e2;
    border: none;
    outline: none;
    padding: 12px 20px;
    border-radius: 4px;
}

.filecardupcoming .form input {
    margin: 10px 0;
    width: 100%;
    background-color: #e2e2e2;
    border: none;
    outline: none;
    padding: 12px 20px;
    border-radius: 4px;
}

.filecardrewardredeemedcert .form input {
    margin: 10px 0;
    /* width: 100%; */

    background-color: #e2e2e2;
    border: none;
    outline: none;
    padding: 12px 20px;
    border-radius: 4px;
}

.filecard .drop_box h4 {
    font-size: 16px;
    font-weight: 400;
    color: #2e2e2e;
}

.filecardupcoming h4 {
    font-size: 14px;
    font-weight: 400;
    color: #2e2e2e;
}

.filecard-participate .drop_box h4 {
    font-size: 16px;
    font-weight: 400;
    color: #2e2e2e;
}

.filecardrewardredeemedcert h4 {
    font-size: 14px;
    font-weight: 400;
    color: #2e2e2e;
}


@media screen and (min-width: 480px) {

    .form-reg {
        /* max-width: 480px; */
    }


}

@media only screen and (min-width: 630px) {
    .hide-on-desk {
        display: none;
    }


}

@media only screen and (max-width: 630px) {

    img.reward-image {
        width: 100%;
        height: 200px !important;
        object-fit: cover;
    }

    img.reward-imagehm {
        width: 100%;
        height: 200px !important;
        object-fit: cover;
    }

    .mob-space {
        /* padding-top: 60px; */
    }

    .mob-space1 {
        padding-top: 20px !important;
    }

    .mob-space-share {
        padding-top: 20px;
    }

    /* reward mob poits sec stars here */
    a.story-signin {
        font-size: 10px;
    }

    a.story-post-reg {
        font-size: 10px;
    }

    .campaigns-leaders-re a img {
        width: 35px !important;
        height: 35px !important;

        margin-top: 4px;
    }

    .progressing-a-campaigns .content-col-1 h6 {
        color: black;
        margin-bottom: 2px;
        font-size: 14px;
    }

    h4.site-title {
        color: black;
        font-size: 20px;
    }

    h4.site-title-camp-direct {
        color: black;
        font-size: 16px;
    }

    .t-reward-wrap {
        display: inline-flex;
        margin-top: 20px !important;
        margin-bottom: 20px;
    }

    .t-reward-wrap .col-two span {
        font-size: 14px !important;
    }

    .t-reward-wrap .col-two h5 {
        color: #01539d;
        font-size: 14px;
    }

    .overall-wrap {
        display: inline-flex;
        margin-top: 20px !important;
        margin-bottom: 20px;
    }

    .reward-top-sec {
        padding: 20px 0px !important;
    }

    .overall-wrap .col-two span {
        font-size: 14px !important;
    }

    .re-points-wrap .p1 span {
        color: #ffffff !important;
        font-size: 25px !important;
    }

    .re-points-wrap .p2 span {
        color: #ffffff !important;
        font-size: 25px !important;
    }

    .re-points-wrap .p1 {
        display: flex;
    }

    .re-points-wrap .p2 {
        display: flex;
    }

    .per-main-wrap {
        /* display: flex; */
        display: inline-block;
        text-align: center;
    }

    .per-img .one1 {
        /* width: 50px!important; */
        margin-top: 2px;
        /* height: 50px!important; */
    }

    .person-r-points {
        /* text-align: left!important;
        display: inline-flex; */
        text-align: center !important;
        display: block;
    }

    .re-points-wrap .p1 img {
        width: 35px;
        margin-top: 0px !important;
        height: 35px;
    }

    .re-points-wrap .p2 img {
        width: 35px;
        margin-top: 0px !important;
        height: 35px;
    }

    .per-main-name {
        /* color: white !important; */
        font-size: 14px;
        margin-left: 5px;
        margin-top: 6px;
    }

    .overall-wrap .col-two h5 {
        color: #01539d;
        font-size: 14px;
    }


    .order-on-mob-2 {
        -ms-flex-order: 2;
        order: 2;
    }

    .hide-on-mobile {
        display: none;
    }

    .testi-sl .owl-nav {
        display: none;
    }

    .testi-content h2 {
        font-size: 23px;
        color: black;
    }

    .testi-desc {
        width: 100%;
        margin-left: 10px;
    }

    img.test-per-img {
        width: 80px !important;
        height: 80px;
    }

    .succ-story-wrap {
        padding: 35px 10px;
    }

    .succ-story-badge-wrap {
        padding: 35px 10px;
    }

    .testi-img {
        display: none;
    }

    .testimonial-wrap {
        padding: 10px 30px;
    }

    .banner-left-content {
        text-align: center !important;
    }

    .item.slide1 p {
        color: white;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        text-overflow: ellipsis;
        overflow: hidden;
        margin-bottom: 15px;
        height: 60px;
    }

    .item.slideaboutus1 p {
        color: white;
        display: -webkit-box;
        /* -webkit-line-clamp: 2; */
        -webkit-box-orient: vertical;
        text-overflow: ellipsis;
        /* overflow: hidden; */
        margin-bottom: 15px;
    }

    .item.slideaboutus2 p {
        color: white;
        display: -webkit-box;
        /* -webkit-line-clamp: 2; */
        -webkit-box-orient: vertical;
        text-overflow: ellipsis;
        /* overflow: hidden; */
        margin-bottom: 15px;
        /* height: 60px; */
    }

    .item.slideaboutus3 p {
        color: white;
        display: -webkit-box;
        /* -webkit-line-clamp: 2; */
        -webkit-box-orient: vertical;
        text-overflow: ellipsis;
        /* overflow: hidden; */
        margin-bottom: 15px;
        /* height: 60px; */
    }

    .slider-img img {
        width: 100%;
        height: 266px;
    }

    /* .top-banner-space
    {
        margin-top: 75px!important;
    } */
    .nav-tabs .nav-link {
        font-size: 10px;
        padding: 7px 5px;
        margin: 2px !important;
    }

    /* #animateLine i {
        display:none;
    } */
    a.streak-view-more {
        margin-top: 12px;
        padding: 7px 15px;
        font-size: 14px;
        font-weight: 600;
        display: inline-block;
    }

    .more-p-btn {
        background-color: #01539D;
        color: white !important;
        padding: 8px 10px;
        border-radius: 5px;
        display: inline-block;
        margin-top: 10px;
        position: relative;
        font-size: 11px;
    }

    .filecard {
        /* border-radius: 10px;
        box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.3);
        width: 100%; */
        height: 186px;
        /* background-color: #ffffff;
        padding: 10px 10px 40px; */
    }

    .filecard-participate {
        /* border-radius: 10px;
        box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.3);
        width: 100%; */
        /* height: 186px; */
        /* height: 186px; */
        width: 100%;
        /* background-color: #ffffff;
        padding: 10px 10px 40px; */
    }
}

button,
a {
    cursor: pointer;
}

.nav-tabs .nav-link {
    border: 1px solid #d9d9d9;
    border-top-left-radius: 0.25rem;
    border-top-right-radius: 0.25rem;
    margin: 3px;
    border-radius: 5px !important;
}

.footer-section .footer-section-2 {
    width: 50%;
    background: #e1e1e1 !important;
    flex-grow: 8;
    padding: 20px;
}

.impact-sec p {
    color: black;
    ;
}

.impact-sec {
    margin-top: 50px;
}

.item.slide1 h2 {
    font-size: 40px;
}

p.text {
    color: black;
}

a.more {
    color: #db8e0f;
}

a.less {
    color: #db8d10;
}

.padd-0 {
    padding: 0px;
}

/*search*/
form.form-inline.form-inline.search.animated-search {
    visibility: hidden;
}

.campaign-tabz li a {
    display: flex;
}

.campaign-tabz li a span {
    margin-top: 2px;
    margin-left: 4px;
}

.reward-top-sec {
    background-image: linear-gradient(#da8d0f, white);
    padding: 45px 0px;
    /* margin-top: -20px; */
}

img.reward-image {
    width: 100%;
    /* height: auto; */
    object-fit: cover;
    /* max-height: 197px; */
}

img.reward-imagehm {
    width: 100%;
    /* height: 150px; */
    /* height:auto; */
    object-fit: cover;
    max-height: 230px;
    min-height: 220px;
}

.landingPageRewardWrapper img.reward-image {
    max-height: 153px;
}

.pointsPageRewardWrapper img.reward-image {
    max-height: 148px;
}

.landingPageRewardWrapper img.reward-imagehm {
    max-height: 153px;
}

.pointsPageRewardWrapper img.reward-imagehm {
    max-height: 148px;
}

@media only screen and (max-width: 992px) and (min-width: 768px) {
    .hide-on-spec {
        display: none;
    }

    .reg-banner {

        /* height: 65vh; */
        height: auto;
    }

    .add-full-width {
        max-width: 100%;
        flex: 100%;
    }

    .item.slide1 {
        background-image: linear-gradient(to right, #db8e0f, white);
        padding: 0px 0px;
        height: auto;
    }

    .item.slideaboutus1 {
        background-image: linear-gradient(to right, #db8e0f, white);
        padding: 15px;
        height: 45vh;
    }

    .item.slideaboutus2 {
        background-image: linear-gradient(to right, #db8e0f, white);
        padding: 0px 0px;
        /* height: 45vh; */
    }

    .item.slideaboutus3 {
        background-image: linear-gradient(to right, #db8e0f, white);
        padding: 0px 0px;
        /* height: 45vh; */
    }
}

@media only screen and (max-width: 768px) and (min-width: 630px) {
    .reg-banner {
        background-image: linear-gradient(to right, #db8e0f, #ffd999);
        height: 50vh;
    }


}

@media only screen and (max-width: 768px) and (min-width: 380px) {

    /* .reg-banner {
        background-image: linear-gradient(to right, #db8e0f , #ffd999);
        height: 50vh;
    } */
    .navigationS {
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;
        /* margin-top: 90px; */
        margin-top: 40px;
        margin-bottom: 41px;
    }
    
}

@media (max-width: 600px) {
    .profilenavigationS {
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-top: 10px;
        margin-bottom:10px;
    }
    .shareIconWrapper {
        display: flex !important;
        justify-content: center;
        align-items: center;
    }
}


@media (max-width: 768px) {
    .reg-banner {
        background-image: linear-gradient(to right, #db8e0f, #ffd999);
        /* height: 40vh; */
        height: auto;
    }

    .campaign-title {
        color: black;
        font-size: 15px;
        padding-top: 8px;
        padding-bottom: 0px;
        padding-left: 3px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }

    a.hash-styles {
        background-color: #DAE8FD;
        color: #3082F7 !important;
        padding: 2px 8px;
        border-radius: 3px;
        font-size: 11px;
        margin-bottom: 2px;
        display: inline-block;
    }

    a.hash-styles-sdg {
        background-color: #3082F7;
        color: white !important;
        padding: 2px 8px;
        border-radius: 3px;
        font-size: 13px;
        margin-bottom: 2px;
        display: inline-block;
    }

    a.hash-styles-goal {
        padding: 2px 0px;
        border-radius: 3px;
        margin-bottom: 0px;
        display: inline-block;
        font-size: 13px;
    }

    .responsive-container span {
        font-size: 12px;
    }

    .responsive-container-chip span {
        font-size: 10px;
        margin-right: 2px;
    }

    .campaign-time-title {
        color: black;
        font-size: 12px;
    }


}

@media only screen and (max-width: 1200px) {
    .testi-sl .owl-nav {
        position: absolute;
        top: 0px;
        right: 70px;
    }
}

@media only screen and (max-width: 992px) {
    .hide-on-spec {
        display: none;
    }

    .add-full-width {
        max-width: 100%;
        flex: 100%;
    }
}

.reward-details .card-body {
    height: 90px;
    overflow-y: scroll;
}

.item.slide1 p {
    color: white;
    display: -webkit-box;
    -webkit-line-clamp: 4;
    -webkit-box-orient: vertical;
    text-overflow: ellipsis;
    overflow: hidden;
    height: 82px;
}

.item.slideaboutus1 p {
    color: white;
    display: -webkit-box;
    /* -webkit-line-clamp: 4; */
    -webkit-box-orient: vertical;
    text-overflow: ellipsis;
    /* overflow: hidden; */
}

.item.slideaboutus2 p {
    color: white;
    display: -webkit-box;
    /* -webkit-line-clamp: 4; */
    -webkit-box-orient: vertical;
    text-overflow: ellipsis;
    /* overflow: hidden; */
}

.item.slideaboutus3 p {
    color: white;
    display: -webkit-box;
    /* -webkit-line-clamp: 4; */
    -webkit-box-orient: vertical;
    text-overflow: ellipsis;
    /* overflow: hidden; */
}

.progressing-a-campaigns {
    border: 1px solid #dfdfdf;
    padding: 14px 12px;
    border-radius: 6px;
    margin-top: 10px;
    margin-right: 5px;
}

.progressing-a-campaigns .content-col-1 h6 {
    color: black;
    margin-bottom: 2px;
}

.progressing-a-campaigns .content-col-1 span {
    font-size: 12px;
    color: #6f6f6f;
}

.campaigns-leaders-re a img {
    width: 43px;
    height: 43px;
    border-radius: 50%;
    border: 2px solid lightgrey;
}

.campaigns-leaders-re {
    display: flex;
}

/* .campaigns-leaders-re a:nth-child(2){
    z-index: 3;
} */
.campaigns-leaders-re a:nth-child(3) {
    margin-left: -16px;
}

.campaigns-leaders-re a:nth-child(4) {
    margin-left: -16px;
    z-index: 1;
}

.campaigns-leaders-re a:nth-child(5) {
    margin-left: -16px;
    z-index: 0;
}

.campaigns-leaders-re a:hover {
    transform: scale(1.2);
}

.chm .campaigns-leaders-re a img {
    width: 34px;
    height: 33px;
    border-radius: 50%;
    border: 2px solid lightgrey;
    object-fit: cover;
}

.explore-more {
    background-color: #01539d;
    color: white !important;
    padding: 6px 10px;
    display: inline-block;
    border-radius: 4px;
}

.changemaker-content h5 {
    color: black;
    font-size: 16px;
    margin-top: 25px;
}

span.chm-span {
    display: block;
    margin: 5px 0px;
    color: black;
}

.lead-changemaker-wrap {
    border: 1px solid #f0f0f0;
    border-radius: 5px;
    padding: 15px;
    box-shadow: 1px 1px 2px #0000001a;
}

.lead-changemaker-wrap .img-wrap img {
    width: 70%;
    margin: 0px auto;
    display: block;
}

.com-task h6 {
    font-size: 15px;
    color: black;
}

.com-task h6 span {
    color: green;
}

.leader-b-table {
    width: 100%;
    border: 1px solid #f2f2f2;
    
}

.leader-b-table th {
    text-align: inherit;
    padding: 14px 6px;
    background-color: #F2F2F2;
    font-weight: 600;
    color: #5f5f5f;
    z-index: 100;
    
}

.leader-b-table td {
    text-align: inherit;
    padding: 9px 6px;
    background-color: white;
    border-bottom: 1px solid #f0f0f0;
    color: #5f5f5f;
}

.top-player-box img {
    width: 40px;
    border-radius: 50%;
    height: 40px;
    border: 2px solid #d7d7d7;
}

.leaderboard-left {
    background-color: #01539d;
    height: 100%;
    width: 100%;
    border-radius: 25px 0px 0px 25px;
}

.leaderboard-left .img {
    width: 135px;
    border: 4px solid #b3b3b3;
    border-radius: 50%;
}

.leaderboard-left span {
    display: block;
    color: white;
}

.leaderboard-left .text-center {
    padding-top: 65px;
}

.leaderboard-left h5 {

    color: white;
}

.leaderboard-jointeam {
    background-color: #01539d;
    height: 100%;
    width: 100%;
    border-radius: 25px 0px 0px 25px;
}

.leaderboard-jointeam img {
    width: 200px;
    border: 4px solid #b3b3b3;
    border-radius: 50%;
    height: 200px
}

.leaderboard-jointeam span {
    display: block;
    color: white;
}

.leaderboard-jointeam .text-center {
    padding-top: 65px;
}

.leaderboard-jointeam h5 {

    color: white;
}

.t-reward-wrap .col-one img {
    background-color: white;
    border-radius: 50%;
    padding: 6px;
    width: 60px;
    border: 2px solid #c9c9c9;
    margin-right:5px;
}

.t-reward-wrap .col-two span {
    color: black;
    font-weight: 500;
    display: inline-block;
    margin-top: 7px;
    font-size: 18px;
}

.t-reward-wrap .col-two h5 {
    color: #01539d;
    font-weight: 700;
}

.t-reward-wrap .col-two {
    text-align: left;
}

.t-reward-wrap {
    display: inline-flex;
    margin-top: 50px;
}

.per-img {
    position: relative;
}

.per-img .two2 {
    width: 37px;
    position: absolute;
    right: 115px;
    top: 80px;
}

.person-r-points {
    text-align: center;
}

.re-points-wrap {
    display: inline-flex;
}

.re-points-wrap .p1 span {
    color: #01539d;
    font-size: 30px;
    font-weight: 600;
    display: inline-block;
}

.re-points-wrap .p1 img {
    width: 35px;
    margin-top: -12px;
}

.re-points-wrap .p2 span {
    color: #01539d;
    font-size: 30px;
    font-weight: 600;
    display: inline-block;
}

.re-points-wrap .p2 img {
    width: 35px;
    margin-top: -12px;
}

.re-points-wrap .p1 {
    margin-right: 23px;
}

.overall-wrap .col-one img {
    background-color: white;
    border-radius: 50%;
    padding: 6px;
    width: 60px;
    border: 2px solid #c9c9c9;
    margin-right:5px;
}

.overall-wrap .col-two span {
    color: black;
    font-weight: 500;
    display: inline-block;
    margin-top: 7px;
    font-size: 18px;
}

.overall-wrap .col-two h5 {
    color: #01539d;
    font-weight: 700;
}

.overall-wrap {
    display: inline-flex;
    margin-top: 50px;
}

.overall-wrap .col-two {
    text-align: left;
}

.per-main-name {
    color: black;
    font-weight: 500;
}

/* scrollbar */
.u-custom-scrollbar::-webkit-scrollbar {
    width: 8px;
    /* width of the entire scrollbar */
}

.u-custom-scrollbar::-webkit-scrollbar-track {
    background: transparent;
    /* color of the tracking area */
}

.u-custom-scrollbar::-webkit-scrollbar-thumb {
    background-color: #db8e0f;
    /* color of the scroll thumb */
    border-radius: 20px;
    /* roundness of the scroll thumb */
    border: 3px solid transparent;
    /* creates padding around scroll thumb */
}

.u-custom-scrollbar {
    max-height: 385px;
    overflow-y: scroll;
    overflow-x: hidden;
}










.u-custom-mypoints-scrollbar {
    scrollbar-color: initial;
}
.u-custom-mypoints-scrollbar {
    max-height: 385px;
    overflow-y: scroll;
    overflow-x: hidden;
}
.u-custom-mypoints-scrollbar::-webkit-scrollbar {
    width: 4px;
    /* width of the entire scrollbar */
}











/* scrollbar */
.u-custom-scrollbar-one::-webkit-scrollbar {
    width: 8px;
    /* width of the entire scrollbar */
}

.u-custom-scrollbar-one::-webkit-scrollbar-track {
    background: transparent;
    /* color of the tracking area */
}

.u-custom-scrollbar-one::-webkit-scrollbar-thumb {
    background-color: #db8e0f;
    /* color of the scroll thumb */
    border-radius: 20px;
    /* roundness of the scroll thumb */
    border: 3px solid transparent;
    /* creates padding around scroll thumb */
}

.u-custom-scrollbar-one {
    max-height: 385px;
    overflow-y: scroll;
    overflow-x: hidden;
}

.u-custom-scrollbar-one-camp::-webkit-scrollbar {
    width: 8px;
    /* width of the entire scrollbar */
}

.u-custom-scrollbar-one-camp::-webkit-scrollbar-track {
    background: transparent;
    /* color of the tracking area */
}

.u-custom-scrollbar-one-camp::-webkit-scrollbar-thumb {
    background-color: #db8e0f;
    /* color of the scroll thumb */
    border-radius: 20px;
    /* roundness of the scroll thumb */
    border: 3px solid transparent;
    /* creates padding around scroll thumb */
}

.u-custom-scrollbar-one-camp {
    max-height: 570px;
    overflow-y: scroll;
    overflow-x: hidden;
}

.u-custom-scrollbar-two::-webkit-scrollbar {
    width: 8px;
    /* width of the entire scrollbar */
}

.u-custom-scrollbar-two::-webkit-scrollbar-track {
    background: transparent;
    /* color of the tracking area */
}

.u-custom-scrollbar-two::-webkit-scrollbar-thumb {
    background-color: #db8e0f;
    /* color of the scroll thumb */
    border-radius: 20px;
    /* roundness of the scroll thumb */
    border: 3px solid transparent;
    /* creates padding around scroll thumb */
}

.u-custom-scrollbar-two {
    max-height: 270px;
    overflow-y: scroll;
    overflow-x: hidden;
}

.u-custom-scrollbar-for-comm::-webkit-scrollbar {
    width: 4px;
    /* width of the entire scrollbar */
}

.u-custom-scrollbar-for-comm::-webkit-scrollbar-track {
    background: transparent;
    /* color of the tracking area */
}

.u-custom-scrollbar-for-comm::-webkit-scrollbar-thumb {
    background-color: #db8e0f;
    /* color of the scroll thumb */
    border-radius: 20px;
    /* roundness of the scroll thumb */
    border: 3px solid transparent;
    /* creates padding around scroll thumb */
}

.u-custom-scrollbar-for-comm {
    max-height: 100px;
    overflow-y: scroll;
    overflow-x: hidden;
}




.impact-sec-counts {
    padding: 20px;
    border-radius: 5px;
    text-align: center;
    background: linear-gradient(to bottom, #db8d0f, #ffffff);
    color: white;
}

.impact-sec-counts span {
    min-height: 47px;
    display: block;
}

.impact-sec-counts h5 {
    font-weight: 600;
    display: inline-block;
    background-color: #01539d;
    padding: 4px 8px;
    margin-top: 2px;
    border-radius: 4px;
    box-shadow: 1px 1px 3px #0000007a;

}

.overall-impact-progress {
    background-color: #01539d !important;
    border-color: #01539d;
}

.impact-sec-counts .progress-head {
    margin-top: 20px;
    color: #01539d;
    font-size: 14px;
}

.impact-sec-counts .progress-text {
    color: #01539d;
    font-size: 12px;
    margin-top: 7px;
    text-align: left;
}

.left {
    text-align: left;
}

.list-achieved {
    color: #01539d;
    font-weight: 500;
    margin-top: 16px;
    list-style: circle;
}

.overall-status-progress .events a {

    color: #000000 !important;

}

.impacts-wrap {
    background-color: whitesmoke;
    padding: 15px;
    border-radius: 5px;
    margin-bottom: 15px;
}

.impacts-wrap .sec-1 {
    display: flex;
}

.impacts-wrap .one i {
    width: 50px;
    height: 50px;
    background-color: green;
    color: white;
    text-align: center;
    font-size: 22px;
    line-height: 50px;
    border-radius: 50%;
    margin-right: 10px;
}

.impacts-wrap .two h6 {
    color: #525252;
    font-size: 12px;
}

.campaign-title-new {
    color: #464646;
    font-size: 16px;
    padding-top: 8px;
    padding-bottom: 0px;
    padding-left: 3px;
    font-weight: 500;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.p-bar-new h6 {
    margin-bottom: 2px;
    color: #01539d;
    font-weight: 600;
    font-size: 14px;
    margin-top: 10px;
}

.logged-cam-detai {
    margin-top: 20px;
    padding: 5px 20px;
}

.logged-cam-detai p {
    color: #515151;
}

.campaigns-wrap-new .earn-upto-div {
    position: relative;
    box-shadow: 1px 1px 4px #00000021;
    width: 100px;
    border-radius: 5px;
    text-align: center;
    margin: 0px auto;
    padding-top: 50px;
    margin-top: 30px;
    margin-bottom: 15px;
    padding-bottom: 8px;
    background-color: #F9F9F9;
    font-size: 12px;
}

.campaigns-wrap-new .progress {
    height: 12px;
    margin-top: 6px;
}

.search-wrapper.searchWrapper .chip {
    white-space: normal;
}

.star-rating {

    display: flex;
    flex-direction: row-reverse;
    font-size: 1.5em;
    justify-content: space-around;
    /* padding: 0 0.2em; */
    text-align: center;
    width: 5em;
    margin: 0px auto;
}

.star-rating input {
    display: none;
}

.star-rating label {
    color: #919191;
    cursor: pointer;
}

.star-rating :checked~label {
    color: #f90;
}

.star-rating label:hover,
.star-rating label:hover~label {
    color: #fc0;
}

.cam-curr-point {
    background-color: #01539d;
    color: white;
    width: 100px;
    padding: 7px;
    margin: 0px auto;
    border-radius: 5px;
    font-size: 25px;
}

.points-rank span {
    color: white;
    margin-bottom: 5px;
    display: inline-block;
}

.aft-log-acc #toggleAccordion h5 {
    font-size: 14px;
    font-weight: 400;
    color: black;
}

.aft-log-acc div#toggleAccordion {
    margin-top: 0px;
}

.collapsed .flaticon-plus-1:before {
    content: "\e943";
}

.changeIcon.collapsed .fa-plus:before {
    content: "\f067";
}

.changeIcon .fa-plus:before {
    content: "";
}

.changeIcon.collapsed .fa-minus:before {
    content: "";
}

.changeIcon .fa-minus:before {
    content: "\f068";
}

.changeIcon .icon {
    cursor: pointer;
}

@media (max-width: 767px) {
    .sign-content h3 {
        font-size: 40px;
    }

    /* .wizard .nav-tabs > li a i{
		display: none;
	} */
    .signup-logo-header .navbar-toggle {
        margin: 0;
        margin-top: 8px;
    }

    .signup-logo-header .logo_area {
        margin-top: 0;
    }

    .signup-logo-header .header-flex {
        display: block;
    }
}

a.campaign-signin {
    display: none;
}

.wizard .nav-tabs {
    position: relative;
    margin-bottom: 0;
    border-bottom-color: transparent;
}

.wizard>div.wizard-inner {
    position: relative;
    margin-bottom: 38px;
    text-align: center;
}

.connecting-line {
    height: 2px;
    background: #e0e0e0;
    position: absolute;
    width: 75%;
    margin: 0 auto;
    left: 0;
    right: 0;
    top: 15px;
    z-index: 1;
}

.wizard .nav-tabs>li.active>a,
.wizard .nav-tabs>li.active>a:hover,
.wizard .nav-tabs>li.active>a:focus {
    color: #555555;
    cursor: default;
    border: 0;
    border-bottom-color: transparent;
}

span.round-tab {
    width: 30px;
    height: 30px;
    line-height: 30px;
    display: inline-block;
    border-radius: 50%;
    background: #fff;
    z-index: 2;
    position: absolute;
    left: 0;
    text-align: center;
    font-size: 16px;
    color: #0e214b;
    font-weight: 500;
    border: 1px solid #ddd;
}

span.round-tab i {
    color: #555555;
}

.wizard li.active span.round-tab {
    background: #db8d10;
    color: #fff;
    border-color: #db8e0f;
}

.wizard li.active span.round-tab i {
    color: #5bc0de;
}

.wizard .nav-tabs>li.active>a i {
    color: #db8e0f;
}

.wizard .nav-tabs>li {
    width: 25%;
}

.wizard li:after {
    content: " ";
    position: absolute;
    left: 46%;
    opacity: 0;
    margin: 0 auto;
    bottom: 0px;
    border: 5px solid transparent;
    border-bottom-color: red;
    transition: 0.1s ease-in-out;
}



.wizard .nav-tabs>li a {
    width: 30px;
    height: 30px;
    margin: 20px auto;
    border-radius: 100%;
    padding: 0;
    background-color: transparent;
    position: relative;
    top: 0;
}

.wizard .nav-tabs>li a i {
    position: absolute;
    top: -15px;
    font-style: normal;
    font-weight: 400;
    white-space: nowrap;
    left: 50%;
    transform: translate(-50%, -50%);
    font-size: 12px;
    font-weight: 700;
    color: #000;
    left: 12px;
}

.wizard .nav-tabs>li a:hover {
    background: transparent;
}

.wizard .tab-pane {
    position: relative;
    padding-top: 0px;
}


.wizard h3 {
    margin-top: 0;
}

.prev-step,
.next-step {
    font-size: 13px;
    padding: 8px 24px;
    border: none;
    border-radius: 4px;
    margin-top: 30px;
}

.next-step {
    background-color: #0db02b;
}

.skip-btn {
    background-color: #cec12d;
}

.step-head {
    font-size: 20px;
    text-align: center;
    font-weight: 500;
    margin-bottom: 20px;
}

.term-check {
    font-size: 14px;
    font-weight: 400;
}

.custom-file {
    position: relative;
    display: inline-block;
    width: 100%;
    height: 40px;
    margin-bottom: 0;
}

.custom-file-input {
    position: relative;
    z-index: 2;
    width: 100%;
    height: 40px;
    margin: 0;
    opacity: 0;
}

.custom-file-label {
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    z-index: 1;
    height: 40px;
    padding: .375rem .75rem;
    font-weight: 400;
    line-height: 2;
    color: #495057;
    background-color: #fff;
    border: 1px solid #ced4da;
    border-radius: .25rem;
}

.custom-file-label::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    z-index: 3;
    display: block;
    height: 38px;
    padding: .375rem .75rem;
    line-height: 2;
    color: #495057;
    content: "Browse";
    background-color: #e9ecef;
    border-left: inherit;
    border-radius: 0 .25rem .25rem 0;
}

.signup-step-container {
    padding: 55px 0px;
    padding-bottom: 0px;
}

.footer-link {
    margin-top: 30px;
}

.all-info-container {}

.list-content {
    margin-bottom: 10px;
}

.list-content a {
    padding: 10px 15px;
    width: 100%;
    display: inline-block;
    background-color: #f5f5f5;
    position: relative;
    color: #565656;
    font-weight: 400;
    border-radius: 4px;
}

.list-content a[aria-expanded="true"] i {
    transform: rotate(180deg);
}

.list-content a i {
    text-align: right;
    position: absolute;
    top: 15px;
    right: 10px;
    transition: 0.5s;
}

.form-control[disabled],
.form-control[readonly],
fieldset[disabled] .form-control {
    background-color: #fdfdfd;
}

.list-box {
    padding: 10px;
}

.signup-logo-header .logo_area {
    width: 200px;
}

.signup-logo-header .nav>li {
    padding: 0;
}

.signup-logo-header .header-flex {
    display: flex;
    justify-content: center;
    align-items: center;
}

.list-inline li {
    display: inline-block;
}

.pull-right {
    float: right;
}

#content {
    margin-top: 70px !important;

}

.overall-status-progress .events-content {
    margin: 0px 0px !important;
}

.won-r {
    padding: 3px 8px;
    position: absolute;
    top: 0px;
    right: 0px;
    background-color: #01539d;
    font-size: 12px;
    color: white !important;
    border-radius: 3px;
}

.re-po-left {
    display: flex;
}

.re-po-left img {
    width: 40px !important;
    height: 50px !important;
}

.re-po-left .pts h5 {
    display: flex;
    align-items: center;
    justify-content: center;
    color: black;
    font-weight: 600;
    font-size: 17px;
    margin-bottom: 0px;
}

.re-po-left .pts span {
    font-size: 11px;
    color: #292929;
}

.re-po-left .pts {

    margin-top: 5px;

}

.re-po-right a {
    display: block;
    float: right;
    background-color: #db8e11;
    color: white !important;
    font-size: 12px;
    padding: 3px 8px;
    border-radius: 3px;
    margin-top: 10px;
}

.re-po-right-direct a {
    display: block;
    float: right;
    background-color: gray;
    color: white !important;
    font-size: 12px;
    padding: 3px 8px;
    border-radius: 3px;
    margin-top: 10px;
    cursor: default;
}

.re-po-right-landing a {
    display: block;
    float: right;
    background-color: gray;
    color: white !important;
    font-size: 12px;
    padding: 3px 8px;
    border-radius: 3px;
    margin-top: 10px;
    cursor: default;
}

.re-po1-right a {
    /* display: block; */
    float: right;
    /* background-color: #db8e11; */
    /* color: #007bff!important; */
    color: blue;
    font-size: 14px;
    padding: 3px 8px;
    border-radius: 3px;
    margin-top: 10px;
    text-transform: none;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    /* font-style:oblique; */


}

.re-po1-right button {
    max-width: 100%;
}

.re-po2-right a {
    display: block;
    /* float: right; */
    background-color: gray;
    color: white !important;
    font-size: 12px;
    padding: 3px 8px;
    border-radius: 3px;
    margin-top: 10px;
}

.sponserWrapper div {
    white-space: nowrap;
    overflow: auto;
    width: 100%; 
}

.sponserMarketplaceWrapper div {
    white-space: nowrap;
    overflow: auto;
    width: 100%;
}

.sponserHomeWrapper div {
    white-space: nowrap;
    overflow: auto;
    width: 100%;
    scrollbar-width: thin;
    /* height: "10px"; */
    /* scrollbar-color: #888 #f1f1f1;  */
}

.sponserTeamWrapper div {
    white-space: nowrap;
    overflow: auto;
    /* width: 100%; */
    scrollbar-width: thin;
    /* height: "10px"; */
    /* scrollbar-color: #888 #f1f1f1;  */
}




.sponserHomeWrapper div img {
    display: initial !important;
}

.sponserTeamWrapper div img {
    display: initial !important;
}



.re-po2-center a {
    display: block;
    /* float: right; */
    text-align: center;
    background-color: gray;
    color: white !important;
    font-size: 12px;
    padding: 3px 8px;
    border-radius: 3px;
    margin-top: 10px;
}

.expiry-txt {
    position: absolute;
    top: 80px;
    /* left: 50px; */
    background-color: white;
    text-align: center;
    width: 100%;
}

.redeem-pts {
    border: 1px solid white;
    border-radius: 5px;
    padding: 2px 8px;
    background-color: #ffffff40;
    min-height:59px;
    max-height: 59px;
}

.redeem-pts1 {
    border: 1px solid white;
    border-radius: 5px;
    /* padding: 2px 8px; */
    background-color: #ffffff40;
}

.aft-log-acc .widget-content-area {
    padding: 0px 20px;
}

.comm-wrap img {
    width: 45px;
    height: 45px;
    border: 1px solid #ffffff;
    border-radius: 50%;
    padding: 5px;
    background-color: white;
    box-shadow: 1px 1px #c8c8c8;
}

.comm-wrap {
    display: flex;
    border: 1px solid #f1f1f1;
    margin-bottom: 8px;
    padding: 5px 8px;
    border-radius: 30px;
    background-color: white;
    box-shadow: 1px 1px #0000001a;
}

.comm-wrap span {
    color: black;
    margin-left: 6px;
    font-size: 12px;
    margin-top: 5px;
}

.crea-comm-title {
    color: #5a5a5a;
    font-size: 15px;
    margin-top: 13px;
}

.impacts-wrap .two span {
    color: #1a1a1a;
    font-weight: 500;
    font-size: 14px;
}

.cam-commun {
    width: 75px !important;
    display: block !important;
    margin: 0px auto;
    box-shadow: 1px 1px black;
    border-radius: 50%;
    padding: 10px;
}

.cam-commun-text {
    font-size: 13px;
    color: #505050;
    margin-top: 10px;
    display: block;
}

.margin-bottom-10 {
    margin-bottom: 10px;
}

.pp-point {
    background-color: #01539d;
    color: white;
    width: 100px;
    padding: 7px;
    margin: 0px auto;
    border-radius: 5px;
    font-size: 25px;
}

.pp-p-text {
    color: white;
    margin-bottom: 5px;
    display: inline-block;
}

.p-apprstatus {
    float: right;
    background-color: green;
    color: white !important;
    font-size: 13px;
    padding: 2px 8px;
    border-radius: 3px;
}

.p-regstatus {
    float: right;
    background-color: orange;
    color: white !important;
    font-size: 13px;
    padding: 2px 8px;
    border-radius: 3px;
}

.p-notregstatus {
    float: right;
    background-color: gray;
    color: white !important;
    font-size: 13px;
    padding: 2px 8px;
    border-radius: 3px;
}

.p-rejstatus {
    float: right;
    background-color: red;
    color: white !important;
    font-size: 13px;
    padding: 2px 8px;
    border-radius: 3px;
}

@media screen and (min-width: 768px) and (max-width: 1100px) {
    .hide-way-image {
        display: none;
    }

    .add-full-way {
        max-width: 100%;
        flex: 100%;
    }
}

@media screen and (min-width: 550px) and (max-width: 768px) {
    .way-ahead-img {
        display: none !important;
    }
}

.comm-backg {
    padding: 20px;
    border-radius: 5px;
    text-align: center;
    background: linear-gradient(to bottom, #db8d0f, #dc901561);
    color: white;
}

.campaign-sl .owl-nav button span {
    font-size: 50px;
    line-height: 32px;
    color: black;
}

.campaign-sl .owl-nav button {
    position: absolute;
    height: 40px;
    width: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #fff;
    border-radius: 50%;
    opacity: 1;
    margin-top: auto;
    margin-bottom: auto;
    box-shadow: -2px 1px 10px #bdbdbd;
    top: calc(17% - 20px);
    cursor: pointer;
}

.campaign-sl .owl-prev {
    left: -25px;
}

.campaign-sl .owl-next {
    right: -25px;
}

.par-now {
    background-color: #db8e12;
    color: white !important;
    padding: 8px 20px;
    border-radius: 5px;
}

ul#countdown {
    display: flex;
    padding: 0px;
    text-align: center;
    width: 100%;
}

ul#countdown .label,
.label {
    font-size: 10px;
    color: black;
}

ul#countdownearn {
    display: flex;
    padding: 0px 0px 0px 0px;
    text-align: center;
    width: 100%;
}

ul#countdownearn .label {
    font-size: 10px;
    color: black;
}

li#hours .number,
.number {
    font-size: 20px;
    box-shadow: 1px 1px 2px #00000012;
    padding: 2px;
    margin: 2px;
    color: black;
}

.campaign-time-title {
    margin-left: 0px;
}

li#minutes .number {
    font-size: 20px;
    box-shadow: 1px 1px 2px #00000012;
    padding: 2px;
    margin: 2px;
    color: black;
}

li#seconds .number {
    font-size: 20px;
    box-shadow: 1px 1px 2px #00000012;
    padding: 2px;
    margin: 2px;
    color: black;
}

li#seconds {
    width: 33.33%;
}

li#minutes {
    width: 33.33%;
}

li#hours {
    width: 33.33%;
}

li#hours .numberhome {
    font-size: 20px;
    color: black;
    padding: 2px;
    margin: 2px;
}

li#minutes .numberhome {
    font-size: 20px;
    color: black;
    padding: 2px;
    margin: 2px;
}

li#seconds .numberhome {
    font-size: 20px;
    color: black;
    padding: 2px;
    margin: 2px;
}

a.par-now-new {
    background-color: #db8d0f;
    color: white !important;
    font-size: 8px;
    padding: 0px 2px;
    border-radius: 3px;
    margin-bottom: 10px;
    display: inline-block;
}

a.par-now-new-camp-direct {
    background-color: #db8d0f;
    color: white !important;
    font-size: 18px;
    padding: 0px 2px;
    border-radius: 3px;
    margin-bottom: 10px;
    display: inline-block;
}

a.par-now-new-subscribe {
    color: white !important;
    font-size: 12px;
    padding: 6px 10px;
    padding: 0px 2px;
    border-radius: 3px;
    margin-bottom: 10px;
    display: inline-block;
}

.won-re-img {
    position: absolute;
    top: 5px;
    right: 5px;
    width: 50px !important;
    height: 50px;
    border-radius: 10%;
    box-shadow: 0px 0px 4px #0000005c;
    object-fit: contain;
    background-color: white;
    animation: blinkRedeemGlow 1.5s infinite;
}

.aft-log-acc #creativeAccordion h5 {
    font-size: 14px;
    color: black;
    font-weight: 400;
}

.aft-log-acc #creativeAccordion .icon {
    /* display: inline-flex; */
    padding: 15px 8px;
    color: #fff;
    background-color: #e1a139b5;
    border-bottom-left-radius: 4px;
    border-top-left-radius: 4px;
    width: 30px;

}

.aft-log-acc #creativeAccordion .card-header {
    padding: 0px;
    margin-bottom: 0;
    background-color: rgba(0, 0, 0, .03);
    border-bottom: 0px;
}

.aft-log-acc #creativeAccordion .card {

    border: unset;
    border-radius: 0.25rem;
}

.par-btn-here {
    margin-top: 20px;
}

.timer-here {
    /* margin-top: 40px; */
    margin-top: 0px;

}

.timer-here-earn {
    margin-top: 40px;
    /* float: left; */
}

@media only screen and (max-width: 660px) {
    .par-btn-here {
        margin-top: 10px;
    }

    a.par-now-new {
        background-color: #db8d0f;
        color: white !important;
        font-size: 8px;
        padding: 0px 2px;
        border-radius: 3px;
        margin-bottom: 10px;
        display: inline-block;
    }

    a.par-now-new-subscribe {
        /* background-color: #db8d0f; */
        color: white !important;
        font-size: 8px;
        padding: 0px 2px;
        border-radius: 3px;
        margin-bottom: 10px;
        display: inline-block;
    }

    .customFullWidth .a .par-now-new-camp-direct {
        background-color: #db8d0f;
        color: white !important;
        font-size: 10px;
        padding: 0px 2px;
        border-radius: 3px;
        margin-bottom: 10px;
        display: inline-block;
    }

    li#hours .numberhome {
        font-size: 15px;
        color: black;
        padding: 2px;
        margin: 2px;
    }

    li#minutes .numberhome {
        font-size: 15px;
        color: black;
        padding: 2px;
        margin: 2px;
    }

    li#seconds .numberhome {
        font-size: 15px;
        color: black;
        padding: 2px;
        margin: 2px;
    }

    li#hours .number,
    .number {
        font-size: 15px;
        box-shadow: 1px 1px 2px #00000012;
        padding: 2px;
        margin: 2px;
        color: black;
    }

    li#minutes .number {
        font-size: 15px;
        box-shadow: 1px 1px 2px #00000012;
        padding: 2px;
        margin: 2px;
        color: black;
    }

    li#seconds .number {
        font-size: 15px;
        box-shadow: 1px 1px 2px #00000012;
        padding: 2px;
        margin: 2px;
        color: black;
    }

    .selfregistertitle {
        font-size: 16px;
    }

    .selfregistertitle-back {
        font-size: 12px;
        padding-left: 3px;
    }

    .registrationreview-tab {
        margin-top: 50px;
    }

    /* .participatenowbutton {
        font-size: 10px;
        background-color: #db8d0f;
        padding: 6px;
        color: white;
        border-color: #db8d0f;
        text-decoration: none;
        
    } */


}

/* .participatenowbutton {
    font-size: 12px;
    background-color: #db8d0f;
    padding: 6px;
    color: white;
    border-color: #db8d0f;
    text-decoration: none;
    
} */

.selfregistertitle-back {
    font-size: 14px;
    padding-left: 3px;
}

.btn-light-primary {
    padding: 4px 25px;
    background-color: #FFF;
    color: #333;
    border-radius: 30px;
    border: solid 1px #dde8f7;
    box-shadow: 0px 0px 15px 1px rgba(113, 106, 202, 0.2);
}

.btn-light-primary:hover {
    color: #fff;
    background-color: #3232b7;
    border: solid 1px transparent;
}

.btn.btn-delete {
    background: #07e0c4;
    color: #fff;
    padding: 2px 12px;
    border-radius: 2px;
    box-shadow: none;
}

.btn.btn-edit {
    background: #6156ce;
    color: #fff;
    padding: 2px 12px;
    border-radius: 2px;
    box-shadow: none;
}

.timeline .timeline-badge.circle1 {
    background: #ff3743;
    color: #fff;
}

.timeline .timeline-badge.circle2 {
    background: #3862f5;
    color: #fff;
}

.timeline .timeline-badge.circle3 {
    background: #08ddc1;
    color: #fff;
}

.timeline .timeline-badge.circle4 {
    background: #6156ce;
    color: #fff;
}

.timeline div.timeline-badge i {
    vertical-align: middle;
}

.timeline div.timeline-badge img {
    width: 51px;
    border-radius: 50%;
}


/* -------------------------------- 
  
  Modules - reusable parts of our design
  
  -------------------------------- */
.cd-container {
    /* this class is used to give a max-width to the element it is applied to, and center it horizontally when it reaches that max-width */
    width: 90%;
    max-width: 1170px;
    margin: 0 auto;
}

.cd-container::after {
    /* clearfix */
    content: '';
    display: table;
    clear: both;
}

/* -------------------------------- 
  
  Main components 
  
  -------------------------------- */
#cd-timeline {
    position: relative;
    padding: 2em 0;
    margin-top: 2em;
    margin-bottom: 2em;
}

#cd-timeline::before {
    /* this is the vertical line */
    content: '';
    position: absolute;
    top: 0;
    left: 18px;
    height: 100%;
    width: 3px;
    background: #dde8f7;
}

@media only screen and (min-width: 1170px) {
    #cd-timeline {
        margin-top: 3em;
        margin-bottom: 3em;
    }

    #cd-timeline::before {
        left: 50%;
        margin-left: -2px;
    }
}

.cd-timeline-block {
    position: relative;
    margin: 2em 0;
}

.cd-timeline-block:after {
    content: "";
    display: table;
    clear: both;
}

.cd-timeline-block:first-child {
    margin-top: 0;
}

.cd-timeline-block:last-child {
    margin-bottom: 0;
}

@media only screen and (min-width: 1170px) {
    .cd-timeline-block {
        margin: 4em 0;
    }

    .cd-timeline-block:first-child {
        margin-top: 0;
    }

    .cd-timeline-block:last-child {
        margin-bottom: 0;
    }
}

.cd-timeline-img {
    position: absolute;
    top: 0;
    left: 0;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    box-shadow: 0 0 0 4px white, inset 0 2px 0 rgba(0, 0, 0, 0.08), 0 3px 0 4px rgba(0, 0, 0, 0.05);
}

.cd-timeline-img img {
    display: block;
    width: 24px;
    height: 24px;
    position: relative;
    left: 50%;
    top: 50%;
    margin-left: -12px;
    margin-top: -12px;
}

.cd-timeline-img img.img_circle {
    display: block;
    width: 100%;
    height: auto;
    position: relative;
    left: 0%;
    top: 0%;
    margin-left: 0px;
    margin-top: 0px;
    border-radius: 50%;
}

.cd-timeline-img.cd-picture {
    background: #75ce66;
}

.cd-timeline-img.cd-movie {
    background: #c03b44;
}

.cd-timeline-img.cd-location {
    background: #f0ca45;
}

@media only screen and (min-width: 1170px) {
    .cd-timeline-img {
        width: 80px;
        height: 80px;
        left: 50%;
        margin-left: -42px;
        /* Force Hardware Acceleration in WebKit */
        -webkit-transform: translateZ(0);
        -webkit-backface-visibility: hidden;
    }

    .cssanimations .cd-timeline-img.is-hidden {
        visibility: hidden;
    }

    .cssanimations .cd-timeline-img.bounce-in {
        visibility: visible;
        -webkit-animation: cd-bounce-1 0.6s;
        -moz-animation: cd-bounce-1 0.6s;
        animation: cd-bounce-1 0.6s;
    }
}

@-webkit-keyframes cd-bounce-1 {
    0% {
        opacity: 0;
        -webkit-transform: scale(0.5);
    }

    60% {
        opacity: 1;
        -webkit-transform: scale(1.2);
    }

    100% {
        -webkit-transform: scale(1);
    }
}

@-moz-keyframes cd-bounce-1 {
    0% {
        opacity: 0;
        -moz-transform: scale(0.5);
    }

    60% {
        opacity: 1;
        -moz-transform: scale(1.2);
    }

    100% {
        -moz-transform: scale(1);
    }
}

@keyframes cd-bounce-1 {
    0% {
        opacity: 0;
        -webkit-transform: scale(0.5);
        -moz-transform: scale(0.5);
        -ms-transform: scale(0.5);
        -o-transform: scale(0.5);
        transform: scale(0.5);
    }

    60% {
        opacity: 1;
        -webkit-transform: scale(1.2);
        -moz-transform: scale(1.2);
        -ms-transform: scale(1.2);
        -o-transform: scale(1.2);
        transform: scale(1.2);
    }

    100% {
        -webkit-transform: scale(1);
        -moz-transform: scale(1);
        -ms-transform: scale(1);
        -o-transform: scale(1);
        transform: scale(1);
    }
}

.cd-timeline-content {
    position: relative;
    margin-left: 60px;
    border-radius: 0.25em;
    padding: 1em;
}

.cd-timeline-content:after {
    content: "";
    display: table;
    clear: both;
}

.cd-timeline-content h2 {
    color: #303e49;
    font-size: 20px;
}

.cd-timeline-content p,
.cd-timeline-content .cd-read-more,
.cd-timeline-content .cd-date {
    font-size: 13px;
}

.cd-timeline-content .cd-read-more,
.cd-timeline-content .cd-date {
    display: inline-block;
}

.cd-timeline-content p {
    margin: 1em 0;
    line-height: 1.6;
}

.cd-timeline-content .cd-read-more {
    float: right;
    padding: .8em 1em;
    background: #acb7c0;
    color: white;
    border-radius: 0.25em;
}

.no-touch .cd-timeline-content .cd-read-more:hover {
    background-color: #bac4cb;
}

.cd-timeline-content .cd-date {
    float: left;
    padding: .8em 0;
    opacity: .7;
}

.cd-timeline-content::before {
    content: '';
    position: absolute;
    top: 16px;
    right: 100%;
    height: 0;
    width: 0;
    border: 7px solid transparent;
    border-right: 7px solid #f4f4f4;
}

.bg-danger {
    background-color: #ffffff !important;
    color: #3b3f5c;
}

.bg-primary {
    background-color: #ffffff !important;
    color: #3b3f5c;
}

.bg-approved {
    background-color: #ffffff !important;
    color: #3b3f5c;
}

.bg-success {
    background-color: #ffffff !important;
    color: #3b3f5c;
}

.bg-info {
    background-color: #ffffff !important;
    color: #3b3f5c;
}

@media only screen and (min-width: 1170px) {
    .cd-timeline-content {
        margin-left: 0;
        padding: 1.2em;
        width: 43%;
    }

    .cd-timeline-content::before {
        top: 24px;
        left: 100%;
        border-color: transparent;
        border-left-color: #f4f4f4;
    }

    .cd-timeline-content .cd-read-more {
        float: left;
    }

    .cd-timeline-content .cd-date {
        position: absolute;
        width: 100%;
        left: 132%;
        top: 16px;
        font-size: 15px;
        color: #3b3f5c;
        font-weight: 600;
    }

    .cd-timeline-block:nth-child(even) .cd-timeline-content {
        float: right;
    }

    .cd-timeline-block:nth-child(even) .cd-timeline-content::before {
        top: 24px;
        left: auto;
        right: 100%;
        border-color: transparent;
        border-right-color: #f4f4f4;
    }

    .cd-timeline-block:nth-child(even) .cd-timeline-content .cd-read-more {
        float: right;
    }

    .cd-timeline-block:nth-child(even) .cd-timeline-content .cd-date {
        left: auto;
        right: 132%;
        text-align: right;
    }

    .cssanimations .cd-timeline-content.is-hidden {
        visibility: hidden;
    }

    .cssanimations .cd-timeline-content.bounce-in {
        visibility: visible;
        -webkit-animation: cd-bounce-2 0.6s;
        -moz-animation: cd-bounce-2 0.6s;
        animation: cd-bounce-2 0.6s;
    }
}

@media only screen and (min-width: 1170px) {

    /* inverse bounce effect on even content blocks */
    .cssanimations .cd-timeline-block:nth-child(even) .cd-timeline-content.bounce-in {
        -webkit-animation: cd-bounce-2-inverse 0.6s;
        -moz-animation: cd-bounce-2-inverse 0.6s;
        animation: cd-bounce-2-inverse 0.6s;
    }
}

.cd-timeline-content.bg-danger::before {
    border-left-color: #24ccda;
}

.cd-timeline-content.bg-danger h2 {
    color: #000;
}

.cd-timeline-content.bg-danger .cd-read-more {
    background-color: #24ccda;
    color: #fff;
    padding: 0.5em 1em;
    text-align: center;
    border-radius: 100px;
    box-shadow: 0px 0px 15px 1px rgba(113, 106, 202, 0.2);
}

.cd-timeline-content.bg-danger .cd-read-more:hover {
    box-shadow: 0px 3px 25px 0px rgba(113, 106, 202, 0.2);
}

.cd-timeline-block:nth-child(2n) .cd-timeline-content.bg-primary::before {
    border-right-color: #6156ce;
}

.cd-timeline-content.bg-primary h2 {
    color: #000;
}

.cd-timeline-content.bg-primary .cd-read-more {
    background-color: #6156ce;
    color: #fff;
    padding: 0.5em 1em;
    text-align: center;
    border-radius: 100px;
}

.cd-timeline-content.bg-primary .cd-read-more:hover {
    box-shadow: 0px 3px 25px 0px rgba(113, 106, 202, 0.2);
}

.cd-timeline-content.bg-success::before {
    border-left-color: #08ddc1;
}

.cd-timeline-content.bg-success h2 {
    color: #000;
}

.cd-timeline-content.bg-success .cd-read-more {
    background-color: #08ddc1;
    color: #fff;
    padding: 0.5em 1em;
    text-align: center;
    border-radius: 100px;
}

.cd-timeline-content.bg-success .cd-read-more:hover {
    box-shadow: 0px 3px 25px 0px rgba(113, 106, 202, 0.2);
}

.cd-timeline-block:nth-child(2n) .cd-timeline-content.bg-info::before {
    border-right-color: #e95f2b;
}

.cd-timeline-content.bg-info h2 {
    color: #000;
}

.cd-timeline-content.bg-info .cd-read-more {
    background-color: #e95f2b;
    color: #fff;
    padding: 0.5em 1em;
    text-align: center;
    border-radius: 100px;
}

.cd-timeline-content.bg-info .cd-read-more:hover {
    box-shadow: 0px 3px 25px 0px rgba(113, 106, 202, 0.2);
}

.cd-timeline-content.bg-warning::before {
    border-left-color: #f28b21;
}

.cd-timeline-content.bg-warning h2 {
    color: #fff;
}

.cd-timeline-content.bg-warning .cd-read-more {
    background-color: #fff;
    color: #555;
    padding: 0.5em 1em;
    border-radius: 100px;
}

.cd-timeline-block:nth-child(2n) .cd-timeline-content.bg-inverse::before {
    border-right-color: #494949;
}

.cd-timeline-content.bg-inverse h2 {
    color: #fff;
}

.cd-timeline-content.bg-inverse .cd-read-more {
    background-color: #fff;
    color: #555;
    padding: 0.5em 1em;
    border-radius: 100px;
}

@-webkit-keyframes cd-bounce-2 {
    0% {
        opacity: 0;
        -webkit-transform: translateX(-100px);
    }

    60% {
        opacity: 1;
        -webkit-transform: translateX(20px);
    }

    100% {
        -webkit-transform: translateX(0);
    }
}

@-moz-keyframes cd-bounce-2 {
    0% {
        opacity: 0;
        -moz-transform: translateX(-100px);
    }

    60% {
        opacity: 1;
        -moz-transform: translateX(20px);
    }

    100% {
        -moz-transform: translateX(0);
    }
}

@keyframes cd-bounce-2 {
    0% {
        opacity: 0;
        -webkit-transform: translateX(-100px);
        -moz-transform: translateX(-100px);
        -ms-transform: translateX(-100px);
        -o-transform: translateX(-100px);
        transform: translateX(-100px);
    }

    60% {
        opacity: 1;
        -webkit-transform: translateX(20px);
        -moz-transform: translateX(20px);
        -ms-transform: translateX(20px);
        -o-transform: translateX(20px);
        transform: translateX(20px);
    }

    100% {
        -webkit-transform: translateX(0);
        -moz-transform: translateX(0);
        -ms-transform: translateX(0);
        -o-transform: translateX(0);
        transform: translateX(0);
    }
}

@-webkit-keyframes cd-bounce-2-inverse {
    0% {
        opacity: 0;
        -webkit-transform: translateX(100px);
    }

    60% {
        opacity: 1;
        -webkit-transform: translateX(-20px);
    }

    100% {
        -webkit-transform: translateX(0);
    }
}

@-moz-keyframes cd-bounce-2-inverse {
    0% {
        opacity: 0;
        -moz-transform: translateX(100px);
    }

    60% {
        opacity: 1;
        -moz-transform: translateX(-20px);
    }

    100% {
        -moz-transform: translateX(0);
    }
}

@keyframes cd-bounce-2-inverse {
    0% {
        opacity: 0;
        -webkit-transform: translateX(100px);
        -moz-transform: translateX(100px);
        -ms-transform: translateX(100px);
        -o-transform: translateX(100px);
        transform: translateX(100px);
    }

    60% {
        opacity: 1;
        -webkit-transform: translateX(-20px);
        -moz-transform: translateX(-20px);
        -ms-transform: translateX(-20px);
        -o-transform: translateX(-20px);
        transform: translateX(-20px);
    }

    100% {
        -webkit-transform: translateX(0);
        -moz-transform: translateX(0);
        -ms-transform: translateX(0);
        -o-transform: translateX(0);
        transform: translateX(0);
    }
}

/* -------------------------------- 
  
  Main Components 
  
  -------------------------------- */
ol,
ul {
    list-style: none;
}

.cd-horizontal-timeline {
    opacity: 0;
    margin: 2em auto;
    -webkit-transition: opacity 0.2s;
    -moz-transition: opacity 0.2s;
    transition: opacity 0.2s;
}

.cd-horizontal-timeline::before {
    /* never visible - this is used in jQuery to check the current MQ */
    content: 'mobile';
    display: none;
}

.cd-horizontal-timeline.loaded {
    /* show the timeline after events position has been set (using JavaScript) */
    opacity: 1;
}

.cd-horizontal-timeline .timeline {
    position: relative;
    height: 100px;
    width: 100%;
    /* max-width: 800px; */
    margin: 0 auto;
}

.cd-horizontal-timeline .events-wrapper {
    position: relative;
    height: 100%;
    margin: 0 40px;
    overflow: hidden;
}

.cd-horizontal-timeline .events-wrapper::after,
.cd-horizontal-timeline .events-wrapper::before {
    /* these are used to create a shadow effect at the sides of the timeline */
    content: '';
    position: absolute;
    z-index: 2;
    top: 0;
    height: 100%;
    width: 20px;
}

.cd-horizontal-timeline .events-wrapper::before {
    left: 0;
    background-image: -webkit-linear-gradient(left, #f8f8f8, rgba(248, 248, 248, 0));
    background-image: linear-gradient(to right, #ffffff00, rgb(255 255 255 / 0%));
}

.cd-horizontal-timeline .events-wrapper::after {
    right: 0;
    background-image: -webkit-linear-gradient(right, #f8f8f8, rgba(248, 248, 248, 0));
    background-image: linear-gradient(to left, #ffffff00, rgba(248, 248, 248, 0));
}

.cd-horizontal-timeline .events {
    /* this is the grey line/timeline */
    position: absolute;
    z-index: 1;
    left: 0;
    top: 49px;
    height: 2px;
    /* width will be set using JavaScript */
    background: #dde8f7;
    -webkit-transition: -webkit-transform 0.4s;
    -moz-transition: -moz-transform 0.4s;
    transition: transform 0.4s;
}

.cd-horizontal-timeline .filling-line {
    /* this is used to create the green line filling the timeline */
    position: absolute;
    z-index: 1;
    left: 0;
    top: 0;
    height: 100%;
    width: 100%;
    background-color: #01539c;
    -webkit-transform: scaleX(0);
    -moz-transform: scaleX(0);
    -ms-transform: scaleX(0);
    -o-transform: scaleX(0);
    transform: scaleX(0);
    -webkit-transform-origin: left center;
    -moz-transform-origin: left center;
    -ms-transform-origin: left center;
    -o-transform-origin: left center;
    transform-origin: left center;
    -webkit-transition: -webkit-transform 0.3s;
    -moz-transition: -moz-transform 0.3s;
    transition: transform 0.3s;
}

.cd-horizontal-timeline .events a {
    position: absolute;
    bottom: 0;
    z-index: 2;
    text-align: center;
    font-size: 15px;
    padding-bottom: 20px;
    text-transform: uppercase;
    color: #888ea8;
    /* fix bug on Safari - text flickering while timeline translates */
    -webkit-transform: translateZ(0);
    -moz-transform: translateZ(0);
    -ms-transform: translateZ(0);
    -o-transform: translateZ(0);
    transform: translateZ(0);
}

.cd-horizontal-timeline .events a::after {
    /* this is used to create the event spot */
    content: '';
    position: absolute;
    left: 50%;
    right: auto;
    -webkit-transform: translateX(-50%);
    -moz-transform: translateX(-50%);
    -ms-transform: translateX(-50%);
    -o-transform: translateX(-50%);
    transform: translateX(-50%);
    bottom: -7px;
    height: 18px;
    width: 18px;
    border-radius: 50%;
    border: 3px solid #dde8f7;
    background-color: #fff;
    -webkit-transition: background-color 0.3s, border-color 0.3s;
    -moz-transition: background-color 0.3s, border-color 0.3s;
    transition: background-color 0.3s, border-color 0.3s;
}

.no-touch .cd-horizontal-timeline .events a:hover::after {
    background-color: #4073FF;
    background-image: linear-gradient(135deg, #667eea 0%, #764ba2 100%);
    z-index: 100;
    border: 3px solid #ffffffa3;
}

.cd-horizontal-timeline .events a.selected {
    pointer-events: none;
}

.cd-horizontal-timeline .events a.selected::after {
    background-color: #01539c;
    background-image: linear-gradient(135deg, #01539c 0%, #764ba2 100%);
    z-index: 100;
    border: 3px solid #ffffffa3;
}

.cd-horizontal-timeline .events a.older-event::after {
    border-color: #dde8f7;
}

@media only screen and (min-width: 1100px) {
    .cd-horizontal-timeline {
        margin: 0em auto;
    }

    .cd-horizontal-timeline::before {
        /* never visible - this is used in jQuery to check the current MQ */
        content: 'desktop';
    }
}

.cd-timeline-navigation a {
    /* these are the left/right arrows to navigate the timeline */
    position: absolute;
    z-index: 1;
    top: 68%;
    bottom: auto;
    -webkit-transform: translateY(-50%);
    -moz-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    -o-transform: translateY(-50%);
    transform: translateY(-50%);
    height: 30px;
    width: 30px;
    border-radius: 50%;
    border: 2px solid #dde8f7;
    /* replace text with an icon */
    overflow: hidden;
    color: transparent;
    text-indent: 100%;
    white-space: nowrap;
    -webkit-transition: border-color 0.3s;
    -moz-transition: border-color 0.3s;
    transition: border-color 0.3s;
}

.cd-timeline-navigation a::after {
    /* arrow icon */
    content: '';
    position: absolute;
    height: 16px;
    width: 16px;
    left: 50%;
    top: 50%;
    bottom: auto;
    right: auto;
    -webkit-transform: translateX(-50%) translateY(-50%);
    -moz-transform: translateX(-50%) translateY(-50%);
    -ms-transform: translateX(-50%) translateY(-50%);
    -o-transform: translateX(-50%) translateY(-50%);
    transform: translateX(-50%) translateY(-50%);
    background: url(assets/cd-arrow.svg) no-repeat 0 0;
}

.cd-timeline-navigation a.prev {
    left: 0;
    -webkit-transform: translateY(-50%) rotate(180deg);
    -moz-transform: translateY(-50%) rotate(180deg);
    -ms-transform: translateY(-50%) rotate(180deg);
    -o-transform: translateY(-50%) rotate(180deg);
    transform: translateY(-50%) rotate(180deg);
}

.cd-timeline-navigation a.next {
    right: 0;
}

.no-touch .cd-timeline-navigation a:hover {
    border-color: #4073FF;
}

.cd-timeline-navigation a.inactive {
    cursor: not-allowed;
}

.cd-timeline-navigation a.inactive::after {
    background-position: 0 -16px;
}

.no-touch .cd-timeline-navigation a.inactive:hover {
    border-color: #dfdfdf;
}

.cd-horizontal-timeline .events-content {
    position: relative;
    width: 100%;
    margin: 2em 0;
    overflow: hidden;
    -webkit-transition: height 0.4s;
    -moz-transition: height 0.4s;
    transition: height 0.4s;
}

.cd-horizontal-timeline .events-content li {
    position: absolute;
    z-index: 1;
    width: 100%;
    left: 0;
    top: 0;
    -webkit-transform: translateX(-100%);
    -moz-transform: translateX(-100%);
    -ms-transform: translateX(-100%);
    -o-transform: translateX(-100%);
    transform: translateX(-100%);
    padding: 0 5%;
    opacity: 0;
    -webkit-animation-duration: 0.4s;
    -moz-animation-duration: 0.4s;
    animation-duration: 0.4s;
    -webkit-animation-timing-function: ease-in-out;
    -moz-animation-timing-function: ease-in-out;
    animation-timing-function: ease-in-out;
}

.cd-horizontal-timeline .events-content li.selected {
    /* visible event content */
    position: relative;
    z-index: 2;
    opacity: 1;
    -webkit-transform: translateX(0);
    -moz-transform: translateX(0);
    -ms-transform: translateX(0);
    -o-transform: translateX(0);
    transform: translateX(0);
}

.cd-horizontal-timeline .events-content li.enter-right,
.cd-horizontal-timeline .events-content li.leave-right {
    -webkit-animation-name: cd-enter-right;
    -moz-animation-name: cd-enter-right;
    animation-name: cd-enter-right;
}

.cd-horizontal-timeline .events-content li.enter-left,
.cd-horizontal-timeline .events-content li.leave-left {
    -webkit-animation-name: cd-enter-left;
    -moz-animation-name: cd-enter-left;
    animation-name: cd-enter-left;
}

.cd-horizontal-timeline .events-content li.leave-right,
.cd-horizontal-timeline .events-content li.leave-left {
    -webkit-animation-direction: reverse;
    -moz-animation-direction: reverse;
    animation-direction: reverse;
}

.cd-horizontal-timeline .events-content li>* {
    max-width: 800px;
    margin: 0 auto;
}

.cd-horizontal-timeline .events-content li img {
    width: 60px;
    border-radius: 50%;
    display: inline-block;
}

.cd-horizontal-timeline .events-content h2 {
    font-weight: bold;
    font-size: 1.3rem;
    font-weight: 500;
    font-size: 1.1rem;
    line-height: 1.2;
    color: #3b3f5c;
}

.cd-horizontal-timeline .events-content em {
    display: block;
    font-style: initial;
    margin: 0px auto;
    color: #888ea8;
}

.cd-horizontal-timeline .events-content em::before {
    content: '- ';
}

.cd-horizontal-timeline .events-content p {
    font-size: 1rem;
    color: #3b3f5c;
}

.cd-horizontal-timeline .events-content em,
.cd-horizontal-timeline .events-content p {
    line-height: 1.6;
}

@media only screen and (min-width: 768px) {
    .cd-horizontal-timeline .events-content h2 {
        font-size: 17px;
        color: #3b3f5c;
        margin-bottom: 2px;
    }

    .cd-horizontal-timeline .events-content em {
        font-size: 12px;
        color: #888ea8;
    }

    .cd-horizontal-timeline .events-content p {
        text-align: justify;
        font-size: 14px;
        margin-top: 15px;
        color: #3b3f5c;
        line-height: 1.7;
    }
}

@-webkit-keyframes cd-enter-right {
    0% {
        opacity: 0;
        -webkit-transform: translateX(100%);
    }

    100% {
        opacity: 1;
        -webkit-transform: translateX(0%);
    }
}

@-moz-keyframes cd-enter-right {
    0% {
        opacity: 0;
        -moz-transform: translateX(100%);
    }

    100% {
        opacity: 1;
        -moz-transform: translateX(0%);
    }
}

@keyframes cd-enter-right {
    0% {
        opacity: 0;
        -webkit-transform: translateX(100%);
        -moz-transform: translateX(100%);
        -ms-transform: translateX(100%);
        -o-transform: translateX(100%);
        transform: translateX(100%);
    }

    100% {
        opacity: 1;
        -webkit-transform: translateX(0%);
        -moz-transform: translateX(0%);
        -ms-transform: translateX(0%);
        -o-transform: translateX(0%);
        transform: translateX(0%);
    }
}

@-webkit-keyframes cd-enter-left {
    0% {
        opacity: 0;
        -webkit-transform: translateX(-100%);
    }

    100% {
        opacity: 1;
        -webkit-transform: translateX(0%);
    }
}

@-moz-keyframes cd-enter-left {
    0% {
        opacity: 0;
        -moz-transform: translateX(-100%);
    }

    100% {
        opacity: 1;
        -moz-transform: translateX(0%);
    }
}

@keyframes cd-enter-left {
    0% {
        opacity: 0;
        -webkit-transform: translateX(-100%);
        -moz-transform: translateX(-100%);
        -ms-transform: translateX(-100%);
        -o-transform: translateX(-100%);
        transform: translateX(-100%);
    }

    100% {
        opacity: 1;
        -webkit-transform: translateX(0%);
        -moz-transform: translateX(0%);
        -ms-transform: translateX(0%);
        -o-transform: translateX(0%);
        transform: translateX(0%);
    }
}

@media(max-width: 992px) {
    .cd-timeline-content.bg-danger::before {
        border-left-color: #fff;
        border-right-color: #24ccda;
    }

    .cd-timeline-content.bg-success::before {
        border-left-color: #fff;
        border-right-color: #08ddc1;
    }

    .cd-timeline-content.bg-warning::before {
        border-left-color: #fff;
        border-right-color: #f28b21;
    }

}

@media(min-width: 993px) and (max-width: 1024px) {
    .cd-timeline-content.bg-danger::before {
        border-left-color: #fff;
        border-right-color: #ec2330;
    }

    .cd-timeline-content.bg-success::before {
        border-left-color: #fff;
        border-right-color: #1ad271;
    }

    .cd-timeline-content.bg-warning::before {
        border-left-color: #fff;
        border-right-color: #f28b21;
    }
}


/* -------------------------------- 
  
  Horizontal Timeline with Icon 
  
  -------------------------------- */
/* ol, ul {
    list-style: none;
  } */
.cd-horizontal-timeline.cd-icon-horizontal-timeline {
    opacity: 0;
    margin: 2em auto;
    -webkit-transition: opacity 0.2s;
    -moz-transition: opacity 0.2s;
    transition: opacity 0.2s;
}

.cd-horizontal-timeline.cd-icon-horizontal-timeline::before {
    /* never visible - this is used in jQuery to check the current MQ */
    content: 'mobile';
    display: none;
}

.cd-horizontal-timeline.cd-icon-horizontal-timeline.loaded {
    /* show the timeline after events position has been set (using JavaScript) */
    opacity: 1;
}

.cd-horizontal-timeline.cd-icon-horizontal-timeline .timeline {
    position: relative;
    height: 193px;
    width: 90%;
    max-width: 800px;
    margin: 0 auto;
}

.cd-horizontal-timeline.cd-icon-horizontal-timeline .events-wrapper {
    position: relative;
    height: 100%;
    margin: 0 40px;
    overflow: hidden;
}

.cd-horizontal-timeline.cd-icon-horizontal-timeline .events-wrapper::after,
.cd-horizontal-timeline.cd-icon-horizontal-timeline .events-wrapper::before {
    /* these are used to create a shadow effect at the sides of the timeline */
    content: '';
    position: absolute;
    z-index: 2;
    top: 0;
    height: 100%;
    width: 20px;
}

.cd-horizontal-timeline.cd-icon-horizontal-timeline .events-wrapper::before {
    left: 0;
    background-image: -webkit-linear-gradient(left, #f8f8f8, rgba(248, 248, 248, 0));
    background-image: linear-gradient(to right, #f8f8f8, rgba(248, 248, 248, 0));
}

.cd-horizontal-timeline.cd-icon-horizontal-timeline .events-wrapper::after {
    right: 0;
    background-image: -webkit-linear-gradient(right, #f8f8f8, rgba(248, 248, 248, 0));
    background-image: linear-gradient(to left, #f8f8f8, rgba(248, 248, 248, 0));
}

.cd-horizontal-timeline.cd-icon-horizontal-timeline .events {
    /* this is the grey line/timeline */
    position: absolute;
    z-index: 1;
    left: 0;
    top: 110px;
    height: 2px;
    /* width will be set using JavaScript */
    background: #dde8f7;
    -webkit-transition: -webkit-transform 0.4s;
    -moz-transition: -moz-transform 0.4s;
    transition: transform 0.4s;
}

.cd-horizontal-timeline.cd-icon-horizontal-timeline .filling-line {
    /* this is used to create the green line filling the timeline */
    position: absolute;
    z-index: 1;
    left: 0;
    top: 0;
    height: 100%;
    width: 100%;
    background-color: #3232b7;
    -webkit-transform: scaleX(0);
    -moz-transform: scaleX(0);
    -ms-transform: scaleX(0);
    -o-transform: scaleX(0);
    transform: scaleX(0);
    -webkit-transform-origin: left center;
    -moz-transform-origin: left center;
    -ms-transform-origin: left center;
    -o-transform-origin: left center;
    transform-origin: left center;
    -webkit-transition: -webkit-transform 0.3s;
    -moz-transition: -moz-transform 0.3s;
    transition: transform 0.3s;
}

.cd-horizontal-timeline.cd-icon-horizontal-timeline .events img {
    display: block;
    width: 60px;
}

.cd-horizontal-timeline.cd-icon-horizontal-timeline .events a {
    position: absolute;
    bottom: 0;
    z-index: 2;
    text-align: center;
    font-size: 15px;
    padding-bottom: 20px;
    text-transform: uppercase;
    color: #888ea8;
    /* fix bug on Safari - text flickering while timeline translates */
    -webkit-transform: translateZ(0);
    -moz-transform: translateZ(0);
    -ms-transform: translateZ(0);
    -o-transform: translateZ(0);
    transform: translateZ(0);
}

.cd-horizontal-timeline.cd-icon-horizontal-timeline .events a::after {
    /* this is used to create the event spot */
    content: '';
    position: absolute;
    left: 50%;
    right: auto;
    -webkit-transform: translateX(-50%);
    -moz-transform: translateX(-50%);
    -ms-transform: translateX(-50%);
    -o-transform: translateX(-50%);
    transform: translateX(-50%);
    bottom: -7px;
    height: 18px;
    width: 18px;
    border-radius: 50%;
    border: 3px solid #dde8f7;
    background-color: #fff;
    -webkit-transition: background-color 0.3s, border-color 0.3s;
    -moz-transition: background-color 0.3s, border-color 0.3s;
    transition: background-color 0.3s, border-color 0.3s;
}

.no-touch .cd-horizontal-timeline.cd-icon-horizontal-timeline .events a:hover::after {
    background-color: #4073FF;
    background-image: linear-gradient(135deg, #667eea 0%, #764ba2 100%);
    z-index: 100;
    border: 3px solid #ffffffa3;
}

.cd-horizontal-timeline.cd-icon-horizontal-timeline .events a.selected {
    pointer-events: none;
}

.cd-horizontal-timeline.cd-icon-horizontal-timeline .events a.selected::after {
    background-color: #4073FF;
    background-image: linear-gradient(135deg, #667eea 0%, #764ba2 100%);
    z-index: 100;
    border: 3px solid #ffffffa3;
}

.cd-horizontal-timeline.cd-icon-horizontal-timeline .events a.older-event::after {
    border-color: #dde8f7;
}

@media only screen and (min-width: 1100px) {
    .cd-horizontal-timeline.cd-icon-horizontal-timeline {
        margin: 6em auto;
    }

    .cd-horizontal-timeline.cd-icon-horizontal-timeline::before {
        /* never visible - this is used in jQuery to check the current MQ */
        content: 'desktop';
    }
}

.cd-timeline-navigation a {
    /* these are the left/right arrows to navigate the timeline */
    position: absolute;
    z-index: 1;
    top: 68%;
    bottom: auto;
    -webkit-transform: translateY(-50%);
    -moz-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    -o-transform: translateY(-50%);
    transform: translateY(-50%);
    height: 30px;
    width: 30px;
    border-radius: 50%;
    border: 2px solid #dde8f7;
    /* replace text with an icon */
    overflow: hidden;
    color: transparent;
    text-indent: 100%;
    white-space: nowrap;
    -webkit-transition: border-color 0.3s;
    -moz-transition: border-color 0.3s;
    transition: border-color 0.3s;
}

.cd-timeline-navigation a::after {
    /* arrow icon */
    content: '';
    position: absolute;
    height: 16px;
    width: 16px;
    left: 50%;
    top: 50%;
    bottom: auto;
    right: auto;
    -webkit-transform: translateX(-50%) translateY(-50%);
    -moz-transform: translateX(-50%) translateY(-50%);
    -ms-transform: translateX(-50%) translateY(-50%);
    -o-transform: translateX(-50%) translateY(-50%);
    transform: translateX(-50%) translateY(-50%);
    /* background: url(img/cd-arrow.svg) no-repeat 0 0; */
    background: url(assets/cd-arrow.svg) no-repeat 0 0;
}

.cd-timeline-navigation a.prev {
    left: 0;
    -webkit-transform: translateY(-50%) rotate(180deg);
    -moz-transform: translateY(-50%) rotate(180deg);
    -ms-transform: translateY(-50%) rotate(180deg);
    -o-transform: translateY(-50%) rotate(180deg);
    transform: translateY(-50%) rotate(180deg);
}

.cd-timeline-navigation a.next {
    right: 0;
}

.no-touch .cd-timeline-navigation a:hover {
    border-color: #4073FF;
}

.cd-timeline-navigation a.inactive {
    cursor: not-allowed;
}

.cd-timeline-navigation a.inactive::after {
    background-position: 0 -16px;
}

.no-touch .cd-timeline-navigation a.inactive:hover {
    border-color: #dfdfdf;
}

.cd-horizontal-timeline.cd-icon-horizontal-timeline .events-content {
    position: relative;
    width: 100%;
    margin: 2em 0;
    overflow: hidden;
    -webkit-transition: height 0.4s;
    -moz-transition: height 0.4s;
    transition: height 0.4s;
}

.cd-horizontal-timeline.cd-icon-horizontal-timeline .events-content li {
    position: absolute;
    z-index: 1;
    width: 100%;
    left: 0;
    top: 0;
    -webkit-transform: translateX(-100%);
    -moz-transform: translateX(-100%);
    -ms-transform: translateX(-100%);
    -o-transform: translateX(-100%);
    transform: translateX(-100%);
    padding: 0 5%;
    opacity: 0;
    -webkit-animation-duration: 0.4s;
    -moz-animation-duration: 0.4s;
    animation-duration: 0.4s;
    -webkit-animation-timing-function: ease-in-out;
    -moz-animation-timing-function: ease-in-out;
    animation-timing-function: ease-in-out;
}

.cd-horizontal-timeline.cd-icon-horizontal-timeline .events-content li.selected {
    /* visible event content */
    position: relative;
    z-index: 2;
    opacity: 1;
    -webkit-transform: translateX(0);
    -moz-transform: translateX(0);
    -ms-transform: translateX(0);
    -o-transform: translateX(0);
    transform: translateX(0);
}

.cd-horizontal-timeline.cd-icon-horizontal-timeline .events-content li.enter-right,
.cd-horizontal-timeline.cd-icon-horizontal-timeline .events-content li.leave-right {
    -webkit-animation-name: cd-enter-right;
    -moz-animation-name: cd-enter-right;
    animation-name: cd-enter-right;
}

.cd-horizontal-timeline.cd-icon-horizontal-timeline .events-content li.enter-left,
.cd-horizontal-timeline.cd-icon-horizontal-timeline .events-content li.leave-left {
    -webkit-animation-name: cd-enter-left;
    -moz-animation-name: cd-enter-left;
    animation-name: cd-enter-left;
}

.cd-horizontal-timeline.cd-icon-horizontal-timeline .events-content li.leave-right,
.cd-horizontal-timeline.cd-icon-horizontal-timeline .events-content li.leave-left {
    -webkit-animation-direction: reverse;
    -moz-animation-direction: reverse;
    animation-direction: reverse;
}

.cd-horizontal-timeline.cd-icon-horizontal-timeline .events-content li>* {
    max-width: 800px;
    margin: 0 auto;
}

.cd-horizontal-timeline.cd-icon-horizontal-timeline .events-content h2 {
    font-weight: bold;
    font-size: 1.3rem;
    font-weight: 500;
    line-height: 1.2;
    color: #3b3f5c;
}

.cd-horizontal-timeline.cd-icon-horizontal-timeline .events-content em {
    display: block;
    font-style: initial;
    margin: 10px auto;
    color: #888ea8;
}

.cd-horizontal-timeline.cd-icon-horizontal-timeline .events-content em::before {
    content: '- ';
}

.cd-horizontal-timeline.cd-icon-horizontal-timeline .events-content p {
    font-size: 1rem;
    color: #3b3f5c;
}

.cd-horizontal-timeline.cd-icon-horizontal-timeline .events-content em,
.cd-horizontal-timeline.cd-icon-horizontal-timeline .events-content p {
    line-height: 1.6;
}

.part-stat {
    display: flex;
    margin: 8px 0px;
}

.stat-fl-2 {
    width: 50%;
    text-align: right;
}

.stat-fl-1 {
    width: 50%;
}

.partici-star-scroll::-webkit-scrollbar {
    width: 8px;
    /* width of the entire scrollbar */
}

.partici-star-scroll::-webkit-scrollbar-track {
    background: transparent;
    /* color of the tracking area */
}

.partici-star-scroll::-webkit-scrollbar-thumb {
    background-color: #db8e0f;
    /* color of the scroll thumb */
    border-radius: 20px;
    /* roundness of the scroll thumb */
    border: 3px solid transparent;
    /* creates padding around scroll thumb */
}

.partici-star-scroll {
    min-height: 29px;
    overflow-y: scroll;
    overflow-x: hidden;
    height: 66px;
}

span.part-date {
    font-size: 12px;
    font-weight: 600;
}

/* .p-status{
    background-color: green;
    color: white!important;
    font-size: 13px;
    padding: 2px 8px;
    border-radius: 3px;
} */
@media only screen and (min-width: 768px) {
    .cd-horizontal-timeline.cd-icon-horizontal-timeline .events-content h2 {
        font-size: 1.3rem;
        color: #3b3f5c;
    }

    .cd-horizontal-timeline.cd-icon-horizontal-timeline .events-content em {
        font-size: 1rem;
        color: #888ea8;
    }

    .cd-horizontal-timeline.cd-icon-horizontal-timeline .events-content p {
        text-align: justify;
        font-size: 16px;
        margin-top: 15px;
        color: #3b3f5c;
        line-height: 1.7;
    }
}

@-webkit-keyframes cd-enter-right {
    0% {
        opacity: 0;
        -webkit-transform: translateX(100%);
    }

    100% {
        opacity: 1;
        -webkit-transform: translateX(0%);
    }
}

@-moz-keyframes cd-enter-right {
    0% {
        opacity: 0;
        -moz-transform: translateX(100%);
    }

    100% {
        opacity: 1;
        -moz-transform: translateX(0%);
    }
}

@keyframes cd-enter-right {
    0% {
        opacity: 0;
        -webkit-transform: translateX(100%);
        -moz-transform: translateX(100%);
        -ms-transform: translateX(100%);
        -o-transform: translateX(100%);
        transform: translateX(100%);
    }

    100% {
        opacity: 1;
        -webkit-transform: translateX(0%);
        -moz-transform: translateX(0%);
        -ms-transform: translateX(0%);
        -o-transform: translateX(0%);
        transform: translateX(0%);
    }
}

@-webkit-keyframes cd-enter-left {
    0% {
        opacity: 0;
        -webkit-transform: translateX(-100%);
    }

    100% {
        opacity: 1;
        -webkit-transform: translateX(0%);
    }
}

@-moz-keyframes cd-enter-left {
    0% {
        opacity: 0;
        -moz-transform: translateX(-100%);
    }

    100% {
        opacity: 1;
        -moz-transform: translateX(0%);
    }
}

@keyframes cd-enter-left {
    0% {
        opacity: 0;
        -webkit-transform: translateX(-100%);
        -moz-transform: translateX(-100%);
        -ms-transform: translateX(-100%);
        -o-transform: translateX(-100%);
        transform: translateX(-100%);
    }

    100% {
        opacity: 1;
        -webkit-transform: translateX(0%);
        -moz-transform: translateX(0%);
        -ms-transform: translateX(0%);
        -o-transform: translateX(0%);
        transform: translateX(0%);
    }
}

.timeline1:before {
    width: 0px;
}

.timeline {
    list-style: none;
    padding: 20px 0 20px;
    position: relative;
}

.timeline:before {
    top: 0;
    bottom: 0;
    position: absolute;
    content: " ";
    width: 3px;
    background-color: #dde8f7;
    left: 50%;
    margin-left: -1.5px;
}

hr {
    border-top: 1px solid #e9ecef;
}

.timeline1:before {
    width: 0px;
}

.timeline>li {
    margin-bottom: 20px;
    position: relative;
}

.timeline>li:before,
.timeline>li:after {
    content: " ";
    display: table;
}

.timeline>li:after {
    clear: both;
}

.timeline>li:before,
.timeline>li:after {
    content: " ";
    display: table;
}

.timeline>li:after {
    clear: both;
}

.timeline>li>.timeline-panel {
    width: 46%;
    float: left;
    border: 1px solid #e6ecf5;
    border-radius: 2px;
    padding: 20px;
    position: relative;
}

.timeline>li>.timeline-panel:before {
    position: absolute;
    top: 26px;
    right: -15px;
    display: inline-block;
    border-top: 15px solid transparent;
    border-left: 15px solid transparent;
    border-right: 0 solid transparent;
    border-bottom: 15px solid transparent;
    content: " ";
}

.timeline>li>.timeline-panel:after {
    position: absolute;
    top: 27px;
    right: -14px;
    display: inline-block;
    border-top: 14px solid transparent;
    border-left: 14px solid transparent;
    border-right: 0 solid transparent;
    border-bottom: 14px solid transparent;
    content: " ";
}

.timeline>li>.timeline-badge {
    color: #fff;
    width: 50px;
    height: 50px;
    line-height: 50px;
    font-size: 1.4em;
    text-align: center;
    position: absolute;
    top: 16px;
    left: 50%;
    margin-left: -25px;
    z-index: 100;
    border-top-right-radius: 50%;
    border-top-left-radius: 50%;
    border-bottom-right-radius: 50%;
    border-bottom-left-radius: 50%;
}

.timeline>li.timeline-inverted>.timeline-panel {
    float: right;
}

.timeline>li.timeline-inverted>.timeline-panel:before {
    border-left-width: 0;
    border-right-width: 15px;
    left: -15px;
    right: auto;
}

.timeline>li.timeline-inverted>.timeline-panel:after {
    border-left-width: 0;
    border-right-width: 14px;
    left: -14px;
    right: auto;
}

.timeline-badge.primary {
    background-color: #2e6da4 !important;
}

.timeline-badge.success {
    background-color: #3f903f !important;
}

.timeline-badge.warning {
    background-color: #f0ad4e !important;
}

.timeline-badge.danger {
    background-color: #d9534f !important;
}

.timeline-badge.info {
    background-color: #5bc0de !important;
}

.timeline-title {
    margin-top: 0;
    color: #3b3f5c;
    font-size: 1.5rem;
}

.timeline-body .btn-group button i {
    vertical-align: sub;
    font-size: 15px;
}

.timeline-body .btn-group div.dropdown-menu {
    padding-left: 7px;
    padding-right: 7px;
}

.dropdown-menu.show {
    border-color: #e9ecef;
    box-shadow: 0px 0px 15px 1px rgba(113, 106, 202, 0.2);
}

.timeline-body .btn-group button.dropdown-toggle:after {
    color: #fff;
}

.timeline-body .btn-group div.dropdown-menu a.dropdown-item:focus,
.timeline-body .btn-group div.dropdown-menu a.dropdown-item:hover {
    color: #16181b;
    text-decoration: none;
    background-color: #e9ecef;
}

.dropdown-item.active,
.timeline-body .btn-group div.dropdown-menu a.dropdown-item.active,
.timeline-body .btn-group div.dropdown-menu a.dropdown-item:active {
    color: #16181b;
    text-decoration: none;
    background-color: #e9ecef;
}

.timeline-body>p,
.timeline-body>ul {
    margin-bottom: 0;
}

.timeline-body>p+p {
    margin-top: 5px;
}

@media (max-width: 767px) {
    ul.timeline:before {
        left: 40px;
    }

    ul.timeline>li>.timeline-panel {
        width: calc(100% - 90px);
        width: -moz-calc(100% - 90px);
        width: -webkit-calc(100% - 90px);
    }

    ul.timeline>li>.timeline-badge {
        left: 15px;
        margin-left: 0;
        top: 16px;
    }

    ul.timeline>li>.timeline-panel {
        float: right;
    }

    ul.timeline>li>.timeline-panel:before {
        border-left-width: 0;
        border-right-width: 15px;
        left: -15px;
        right: auto;
    }

    ul.timeline>li>.timeline-panel:after {
        border-left-width: 0;
        border-right-width: 14px;
        left: -14px;
        right: auto;
    }
}

.form-reg .rdrCalendarWrapper button.rdrNextPrevButton {
    display: block;
    width: 24px;
    height: 24px;
    margin: 0 0.833em;
    padding: 0;
    border: 0;
    border-radius: 5px;
    background: #EFF2F7;
}

.form-reg .rdrCalendarWrapper button.rdrDay {
    background: transparent;
    /* -webkit-user-select: none;
    -moz-user-select: none;
    user-select: none; */
    border: 0;
    padding: 0;
    line-height: 3.000em;
    height: 3em;
    width: 44px;
    text-align: center;
    color: #1d2429;
    border-radius: 0;
}

.menuToggleS.active~.menuS::before {
    transition-delay: 0.5s;
    bottom: -6px;
}


.profileToggleS.active~.menuS::before {
    transition-delay: 0.5s;
    bottom: -6px;
}


.menuToggleSTeams.active~.menuS::before {
    transition-delay: 0.5s;
    bottom: -6px;
}

.main-content {
    position: relative;
}

.customOwlNav .owl-theme .owl-nav {
    position: absolute;
    top: 9%;
    left: -45px;
    right: -45px;
}

.customOwlNav.landingPage .owl-theme .owl-nav {
    top: 16%;
}

.customOwlNav .owl-prev,
.customOwlNav .owl-next {
    position: absolute;
    height: 100px;
    color: inherit;
    background: none;
    border: none;
    z-index: 19;
    width: 50px;
    height: 50px;
}

.customOwlNav .owl-prev i,
.customOwlNav .owl-next i {
    font-size: 2.5rem;
    /* color: #cecece; */
}

.customOwlNav .owl-theme .owl-prev {
    /* left: 0; */
    left: 7px;

}

.customOwlNav .owl-theme .owl-next {
    right: 7px;
}

html,
body {
    height: 100%;
    overflow-x: hidden;
}

.search-wrapper.searchWrapper .chip {
    white-space: normal;
}

.card-header span.icon {
    display: table-cell;
}

.card-header span.text.ml-2 {
    display: table-cell;
    vertical-align: middle;
    padding-left: 7px;
}

.card-header span[role="menu"] {
    display: inline-block;
    width: 100%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    position: relative;
    padding-right: 16px;
}

.card-header span[role="menu"] .flaticon-down-arrow {
    margin-left: 2px;
    position: absolute;
    right: 0px;
    top: 1px;
}

/* market place starts here */

.form-market-wrap form {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;

}

.form-market-wrap h6 {
    color: white;
    font-size: 20px;
    font-weight: 600;
}

.form-market-wrap {
    padding: 35px;
    background-color: rgb(219 142 15);
    float: left;
    width: 100%;
    margin-bottom: 20px;
}

.form-market-wrap form select {
    height: 52px;
    border-radius: 0;
    padding: 0 80px 0 40px;
    color: #72728c;
    font-size: 13px;
    font-weight: 600;
    border: none;
    margin-right: 10px;
}





.form-reg-wrap form {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;

}

.form-reg-wrap h6 {
    color: white;
    font-size: 20px;
    font-weight: 600;
}

.form-reg-wrap {
    padding: 17px;
    background-color: rgb(219 142 15);
    float: left;
    width: 100%;
    margin-bottom: 20px;
}

.form-reg-wrap form select {
    height: 52px;
    border-radius: 0;
    padding: 0 80px 0 40px;
    color: #72728c;
    font-size: 13px;
    font-weight: 600;
    border: none;
    margin-right: 10px;
}







.market-search {
    min-width: 180px;
    height: 52px;
    background-color: #1c4980;
    border-radius: 0;
    padding: 0 15px;
    line-height: 50px;
    color: #fff;
    font-weight: 600;
    font-size: 14px;
    -webkit-transition-duration: 500ms;
    transition-duration: 500ms;
}

.camp-report {
    min-width: 180px;
    height: 52px;
    /* background-color: #1c4980; */
    background-color: #fff;
    /* color:#f28800; */
    border-radius: 0;
    padding: 0 15px;
    line-height: 50px;
    color: #fff;
    font-weight: 600;
    font-size: 14px;
    -webkit-transition-duration: 500ms;
    transition-duration: 500ms;
    margin-left: 20px;
}

.camp-report-gen {
    min-width: 180px;
    height: 52px;
    background-color: #1c4980;
    /* background-color: #fff;  */
    /* color:#f28800; */
    border-radius: 0;
    padding: 0 15px;
    line-height: 50px;
    color: #fff;
    font-weight: 600;
    font-size: 14px;
    -webkit-transition-duration: 500ms;
    transition-duration: 500ms;
    margin-left: 20px;
}

@media (min-width: 320px) and (max-width: 767px) {
    .form-market-wrap form {
        display: block;
        margin-bottom: 5px;
    }

    .item.slideaboutus1 {
        background-image: linear-gradient(to right, #db8e0f, #db8e0f);
    }

    .item.slideaboutus1 p {
        text-align: left;
    }

    .item.slideaboutus1,
    .item.slideaboutus2,
    .item.slideaboutus3 {
        margin-left: 0px;
    }

    .form-market-wrap form select {
        padding: 0 50px 0 20px;
        margin-right: 0;
        margin-bottom: 10px;
        width: 100%;
    }

    .campaign-title,
    .campaign-title-new {
        overflow: visible !important;
        white-space: inherit;
    }

    .re-po1-right a {
        overflow: visible !important;
        white-space: inherit;
    }

    .selectedContainer {
        width: 100%;
        margin-right: 0px;
        margin-bottom: 10px;
    }

    .searchContentWrapper {
        display: block !important;
        margin-bottom: 10px;
    }

    .succ-story-details p {
        -webkit-line-clamp: initial !important;
    }

}

@media (min-width: 280px) and (max-width: 1400px) {
    .campaigns-details .customHalfWidth {
        /* flex:0 0 50%; */
        flex: 0 0 47%;
        max-width: 100%;
    }

    .campaigns-details .customFullWidth {
        flex: 0 0 100%;
        max-width: 100%;
    }
}

.formMarketPlace .css-1fdsijx-ValueContainer,
.formMarketPlace .css-3w2yfm-ValueContainer {
    height: 52px !important;
    max-height: 52px;
    overflow: visible;
    overflow-x: auto;

}

/* .formMarketPlace .formMarketPlace {
    height: 52px !important;
    max-height: 52px;
    overflow: visible;
    overflow-x: auto;
    
} */
.camp-report-gen {
    margin-bottom: 5px;
}

.sponserWrapper div img {
    margin-right: 10px;
    margin-bottom: 5px;
}

.sponserWrapper {
    padding: 0px 20px;
    min-height: 96px;
    max-height:96px;
}


.sponserMarketplaceWrapper div img {
    margin-right: 10px;
    margin-bottom: 5px;
}

.sponserMarketplaceWrapper {
    padding: 0px 20px;
    /* min-height: 96px; */
    max-height:96px;
}



.sponserHomeWrapper div img {
    margin-right: 10px;
    margin-bottom: 5px;
}

.sponserHomeWrapper::-webkit-scrollbar {
    padding: 0px 20px;
    min-height: 80px;
    min-width: 125px;
    height: 5px;
    width: 4px;
}

.sponserTeamWrapper div img {
    margin-right: 10px;
    margin-bottom: 5px;
}

.sponserTeamWrapper::-webkit-scrollbar {
    padding: 0px 20px;
    min-height: 80px;
    min-width: 125px;
    height: 5px;
    width: 4px;
}



form,
.content-mrg {
    float: left;
    width: 100%;
}


.fixed-bottom-sec-mob.text-center {
    background-color: white;
    position: fixed;
    bottom: 0px;
    left: -1px;
    width: 100%;
    /* z-index: 999; */
    z-index: 18;
    padding: 10px;
    opacity: 0.9;
    box-shadow: 0px 2px 15px 6px rgb(0 0 0 / 10%);
}

.popUpWrapper i,.fixed-bottom-sec-mob i {
    font-size: 21px;
    background-color: #db8d0f;
    color: white;
    border-radius: 50%;
    height: 40px;
    width: 40px;
    text-align: center;
    line-height: 40px;
}

.popUpWrapper h6,.fixed-bottom-sec-mob h6 {
    margin-bottom: 0px;
    font-size: 14px;
    font-weight: 600;
}

.flaticon-home-line:before {
    content: "\f13a";
    font-size: 22px;
    color: orange;
    /* Set the icon color */
    -webkit-text-stroke: 2px #db8d0f;
    /* Webkit browsers (Safari, Chrome) */
    text-stroke: 2px #db8d0f;
    /* Standard property for the stroke */
}

.flaticon-search:before {
    content: "\f193";
}

.flaticon-chat-bubble:before {
    content: "\f151";
}

.flaticon-users:before {
    content: "\f216";
}

.flaticon-add:before {
    content: "\f067";
}

.flaticon-notes-1:before {
    content: "\f1eb";
    font-size: 22px;
    color: orange;
    vertical-align: middle;
}

.flaticon-notes-1.customAlign:before {
    content: "\f1eb";
    font-size: 17px;
    color: orange;
}

.flaticon-home-fill-x:before {
    /* content: "\e9f3"; */
    content: "\f13a";
    /* content: "\f2a4"; */
    font-size: 17px;
    color: orange;
    vertical-align: middle;
}

.flaticon-home-fill-x.customAlign:before {
    /* content: "\e9f3"; */
    content: "\f13a";
    /* content: "\f2a4"; */
    font-size: 17px;
    color: orange;
    vertical-align: initial;
}

.rewardHeaderIcon {
    width: 30px;
    border: 3px solid #fff;
    border-radius: 18px;
    position: relative;
    bottom: 2px;
}

.disabledIcon {
    opacity: 0.6;
}

.MuiSelect-selectMenu {
    border: 1px solid rgba(0, 0, 0, 0.26) !important;
}

/* .sidebar-menu li a {
    max-width: 248px;
    z-index: 1001;
} */
/* .sidebar-menu ul  {
    
    z-index: 1001;
} */
.noImage {
    margin-top: 13px
}

.noImage:before {
    color: #ccc;
}

.marginWrapper {
    margin-top: 30px;
    margin-bottom: 30px;
}

.sliderContentWrap img {
    max-height: 300px;
}

.whiteBg {
    background: #fff;
    padding: 15px;
    padding-top: 63px;
    padding-bottom: 63px;
}

.tableContainer th,
.tableContainer td {
    font-family: 'Roboto', sans-serif;
    width: 130px;
}

.tableContainer td {
    font-weight: normal;
}

.customizedTableWidth {
    table-layout: fixed;
}

.extendContent {
    width: 300px !important;
}

.tableContainer th:nth-child(4),
.tableContainer td:nth-child(4),
.tableContainer th:nth-child(8),
.tableContainer td:nth-child(8),
.tableContainer th:nth-child(9),
.tableContainer td:nth-child(9) {
    width: 124px;
}

.tableContainer th:nth-child(10),
.tableContainer td:nth-child(10) {
    width: 125px;
}

.tableContainer th:nth-child(7),
.tableContainer td:nth-child(7) {
    width: 110px;
}

.customizedOrgTable {
    table-layout: fixed;
}

.customizedOrgTable td,
.customizedOrgTable td span {
    width: 110px;
    text-align: left;
}

.customizedOrgTable td:nth-child(1),
.customizedOrgTable td:nth-child(1) span {
    width: 50px;
    min-width: 50px;
    max-width: 50px;
}

.customizedOrgTable td {
    padding-left: 10px;
    padding-right: 10px
}

.customizedOrgTable td:nth-child(2),
.customizedOrgTable td:nth-child(2) span {
    width: 150px;
}

.customizedOrgTable td:nth-child(3),
.customizedOrgTable td:nth-child(3) span {
    width: 400px;
}

/* .customizedOrgTable td:nth-child(7), .customizedOrgTable td:nth-child(7) span {
    width:200px;
} */

.truncateTwoLine {
    --max-lines: 3;
    position: relative;
    max-height: calc(1.4rem * 2);
    overflow: hidden;
    padding-right: 1rem;
}

.truncateTwoLine {
    text-align: justify !important;
}

.truncateTwoLine::before {
    position: absolute;
    content: " ";
    bottom: 0;
    right: 0;
    width: 52px;
    height: 20px;
    background-image: linear-gradient(to right, white, white);
    opacity: 0.7;
}

.truncateTwoLine::after {
    content: "";
    position: absolute;
    right: 0;
    width: 1rem;
    height: 1rem;
    background: white;
}

.customizedOrgTable td span {
    display: inline-block;
}

.truncateTwoLine.fullSpan {
    /* display: inline-block; */
    width: 100%;
}

.width200 {
    width: 200px;
}

.borderWrapper {
    border: 1px solid #000;
    padding: 20px 15px 1px 15px;
}

.hasScreen .owl-carousel.owl-drag .owl-item {
    display: block;
}

.Charts_geospatial-item_legend--top---l7saC {
    overflow: auto !important;
}

.underlined-text {
    text-decoration: underline;
}

.bold-text {
    font-weight: 800;
}

.highlight-text {
    font-weight: 800;
    background: yellow;
}

.subscribeCampaignList .item {
    margin-bottom: 20px;
    float: left;
    max-height:
}

a.par-now-new-subscribe button.unsubscribe {
    background-color: #808080;
}

a.par-now-new-subscribe button.subscribe {
    background-color: #db8d0f;
}

.createCampaignPage .css-3w2yfm-ValueContainer,
.createCampaignPage .css-1dyz3mf {
    max-height: 54px;
    overflow: auto !important;
}

.createCampaignPage .css-4ccn04-control:hover,
.createCampaignPage .css-4ccn04-control,
.createCampaignPage .css-4ccn04-control:focus {
    border-color: transparent !important;
    box-shadow: 0px 3px 6px #00000029 !important;
}

.createCampaignPage .css-k4ctsj-control {
    box-shadow: 0px 3px 6px #00000029;
    border: 1px solid #ccc !important;
}

.createCampaignPage .searchWrapper {
    max-height: 54px;
    overflow: auto;
}

.createCampaignPage fieldset {
    border: none !important;
    box-shadow: 0px 3px 6px #00000029 !important;
}

.createCampaignPage .menuDropDown fieldset {
    box-shadow: none !important;
}

.onboardUser fieldset {
    border: none !important;
    box-shadow: none !important;
}

.userPerformanceReport td {
    min-width: 200px !important;
    max-width: 300px !important;
    width: auto !important;
}

.manageUser td {
    /* max-width:190px !important;
    width: auto !important;
    word-wrap: break-word; */
    padding-left: 5px;
    padding-right: 5px;
}

.menuDropDown input {
    border: none;
    width: auto;
    opacity: 1;
    bottom: auto;
    margin-left: 8px;
    font-size: 17px;
}

.menuDropDownTeams input {
    border: none;
    opacity: 1;
    bottom: auto;
    margin-left: 13px;
    font-size: 17px;
        
}

.menuDropDownSelfRegister input {
    border: none;
    opacity: 1;
    bottom: auto;
    margin-left: 13px;
    font-size: 17px;
    width: 90%;
        
}

.menuDropDownParticipate input {
    border: none;
    /* width:auto; */
    opacity: 1;
    bottom: auto;
    margin-left: 12px;
    font-size: 17px;
    background-color: #e8eeef;
    /* margin-bottom: 20px; */
}

.custom-multiselect .checkbox-container {
    margin-right: 8px;
    /* Adjust this value to add space */
}

.scrollable-container {
    max-height: 200px;
    overflow-y: auto;
}

.selectedContainer {
    margin-right: 10px;
}


/* .css-13cymwt-control div:first-child,
.css-t3ipsp-control div:first-child {
    overflow-x: auto;
    max-height: 52px;
} */

.owl-carousel .owl-item {
    user-select: auto !important;
}

/* Example: Making text selectable within the Owl Carousel */
.owl-carousel .owl-item .owl-item-text {
    user-select: auto !important;
}

.owl-carousel {
    z-index: inherit !important;
    /* or a value appropriate for your layout */
    overflow: visible !important;
    /* or auto, depending on your design */
}

.css-13cymwt-control,
.css-t3ipsp-control {
    min-height: 52px !important;
    max-height: 52px !important;
}

/* .secondaryWrapper div:first-child {
    margin-left:0px !important;
    width:100% !important;
} */

.secondaryWrapper .single-select-chip-wrapper:first-child {
    margin-left: 0px !important;
    width: 100% !important;
}

.secondaryWrapper>div:first-child {
    margin-left: 0px !important;
    width: 100% !important;
    border: 1px dashed #000 !important;
}

.secondaryWrapper.errorMessage>div:first-child {
    border: 1px dashed red !important;
}

.single-select-chip-wrapper .custom-chip {
    width: 0 !important;
}

.ql-editor ul,
.ql-editor ol {
    margin: 0;
    /* Reset margin */
    padding: 0;
    /* Reset padding */
}

ul {
    list-style: disc;
}

ol {
    list-style: decimal;
}

.errorOverlay {
    bottom: 30% !important;
}

.fade.modal,
.fade.modal-backdrop {
    display: none !important;
}

.fade.modal.show,
.fade.modal-backdrop.show {
    display: block !important;
}

.modal-open {
    overflow-y: auto !important;
}

.contCover:hover svg {
    display: block !important;
}

.dragDropWrapper ul {
    list-style: none;
}

.css-yf8vq0-MuiSelect-nativeInput {
    width: "fit-content";
}

.message-container {
    background-color: #fadb5f;
    padding: 20px;
    border-radius: 5px;
    margin: 20px;
    text-align: center;
}

.message-container p {
    color: #555;
}

.wrapperClassName.rdw-editor-wrapper {
    pointer-events: auto !important;
}

.customEditorClass {
    border: 1px solid #ddd;
    padding: 10px;
    border-radius: 4px;
    /* min-height: 300px; */
    background-color: #f9f9f9;
}

.customWrapperClass {
    /* margin: 20px 0; */
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
}

.slick-track {
    margin-left: 0 !important;
}

.createTeamPage .searchTeamWrapper {
    max-height: 100px;
    overflow: auto;
}

.multiSelectContainer .searchWrapper {
    /* height:0px; */
    min-height: 55px;
    border: 1px solid #ccc;
}

.multiSelectContainer.error .searchWrapper {
    min-height: 55px;
    border: 1px solid #FF0000; /* Error border */
}

.searchWrapper {
    min-height: 40px
}

.addMembersButton {
    min-width: 120px !important;
    font-size: 12px !important;
    background-color: #1976d2;
    padding: 6px 16px;
}

@media (min-width: 600px) {
    .addMembersButton {
        font-size: inherit !important;
    }
}



footer ul {
    display: block;
    margin-left: 25px;
    margin-right: 25px;
    margin-bottom: 0px;
}

footer ul li {
    display: inline-block;
}

footer ul li.privacyPolicy {
    float: left;
    font-size: 15px;
}

footer ul li.termsCondition {
    float: right;
    font-size: 15px;
    margin-right: 240px;
}

.onOrange {
    color: #000;
    text-decoration: underline;
}

.onOrange:hover {
    color: #db8d0f;
}

.marginLeft15 {
    margin-left: 15px;
    margin-right: 30px;
    text-align: justify;
    margin-bottom: 30px;
}

.marginLeft15 h3 {
    margin-bottom: 30px;
}

.marginLeft15 h4 {
    margin-bottom: 20px;
}

.landingPageWrapper {
    width: 100% !important;

}

.share-buttons {
    display: flex;
    flex-direction: column;
    /* align-items: flex-start; */
}

.share-buttons-header {
    display: flex;
    justify-content: flex-end;
    width: 100%;
}

.close-button {
    margin-bottom: 10px;
}

.share-buttons-icons {
    display: flex;
    flex-direction: column;
    gap: 10px;
   
}

/* @media (max-width: 600px) {
    .share-buttons {
      width: 100%;
      max-width: 300px;
      margin: 0 auto;
      padding: 20px;
      
    }
  
    .share-buttons-header {
      width: 100%;
    }
  
    .share-buttons-icons {
      flex-direction: column;
      gap: 10px; 
    }
    
  }  */
.clickable-link {
    text-decoration: none;
    /* color: #007BFF; */
    color: white;
    margin-left: 8px;
    cursor: pointer;
    font-weight: bold;
}

.clickable-link:hover,
.clickable-link:focus {
    color: #0056b3;
    text-decoration: underline;
}

.small-avatar {
    width: 80px;
    height: 80px;
}

.large-avatar {
    width: 130px;
    height: 130px;
}

.landingPageWrapper footer {
    width: 100% !important;
}

.createTeamPage input[name="campaignGoal"],
.createTeamPage input[name="campaignGoal"]:focus {
    cursor: not-allowed;
    outline: none;
}


/* .MuiAvatar-root {
    width: 60px;
    height: 60px;
    border-radius:0vb;
  } */


.profile-card {
    display: flex;
    align-items: stretch;
    flex-wrap: wrap; /* Allows wrapping for small screens */
}

.profile-photo, .profile-info {
    display: flex;
    align-items: center;
    padding: 8px; /* Adds padding for spacing */
}

.profile-photo {
    margin-right: 16px;
}

.large-avatar, .small-avatar {
    width: 48px;
    height: 48px;
}

.profile-timestamp,
.profile-hours-ago,
.profile-message {
    margin: 5px 0;
}


.snack-edit-campaign {
    position: absolute;
    left: 0;
    top: 100px;
    right: 0;
    display: block !important;
    margin: auto;
    width: 300px;
}

.highlightOption {
    background: none !important;
}
.optionContainer li {
    color:#000;
}
.highlightOption:hover {
    background: #0096fb !important;
    color: #fff !important;
}






.jointeamcontainer {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
    padding: 20px;
    border: 1px solid #ccc;
  }
  
  .jointeamcolumn {
    flex: 1;
    text-align: center;
    padding: 10px;
  }
  
  .jointeamtimestamp {
    text-align: right;
  }
  
  .jointeamaccept-button {
    background-color: rgb(22, 166, 84);
    color: white;
    border: none;
    padding: 10px 20px;
    cursor: pointer;
    border-radius: 5px;
    font-weight: 600;
  }
  
  .jointeamreject-button {
    background-color: #a62e2e;
    color: white;
    border: none;
    padding: 10px 20px;
    cursor: pointer;
    border-radius: 5px;
    font-weight: 600;
  }
  .backWrapper span,.backWrapper i {
     color:rgb(25, 118, 210) !important;
     fill:rgb(25, 118, 210) !important;
  }
  .containers footer {
    /* width:calc(100% - 250px) !important; */
    width:calc(100% - 250px);
  }
  .containers.expand footer,.containers.emailOtp footer {
    width:100% !important;
  }
  .containers.expand footer, .landingPageWrapper footer, .footerFullWidth footer {
    left:0;
  }
  .containers.expand footer ul li.termsCondition, .landingPageWrapper footer ul li.termsCondition, .footerFullWidth footer ul li.termsCondition {
    margin-right:0 !important;
  }
  .sidebar~.containers.expand {
    width:100%;
  }
  @media (max-width: 768px) {
    .jointeamcontainer {
      flex-direction: column;
      align-items: flex-start;
    }
    
    .jointeamtimestamp {
      text-align: left;
    }
  
    .jointeamcolumn {
      width: 100%;
      text-align: left;
      padding: 5px;
    }
  
    .jointeamaccept-button, .jointeamreject-button {
      width: 100%;
      padding: 15px;
      margin-top: 10px;
    }
  }

  .team-menu {
    position: fixed;
    bottom: 75px; 
    left: 0;
    right: 0;
    background-color: #fff;
    border: 1px solid #ccc;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    z-index: 1001;
    padding: 10px;
    border-radius: 13px;
    /* transform: translateY(100%); */
    transition: transform 0.3s ease-out;
    
  }
  /* .team-menu {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: #fff;
    box-shadow: 0 -1px 5px rgba(0, 0, 0, 0.1);
    z-index: 1000;
    padding: 10px 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    transform: translateY(100%);
    transition: transform 0.3s ease-out;
} */
  
  .team-menu div {
    padding: 10px;
    cursor: pointer;
    display: flex;
    align-items: center;
  }
  
  .team-menu div:hover {
    background-color: #f0f0f0;
  }
  
  .team-menu i {
    margin-right: 10px;
  }
  
  .clickable-icon {
    position: relative;
  }
  .teamoverlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.5); 
    z-index: 1000;
  }
  .memberWrapper {
    position: relative;
    top: -4px;
    /* background: orange; */
    border: 1px solid orange;
    border-radius: 16px;
    font-size: 12px;
    right: 10px;
    color: #000;
    background: #fff;
    height: 27px;
    display: inline-block;
    padding: 4px 6px;
    padding-top: 5px;
  }
  .peopleIcon
  {
    width: 25px !important;
    height: 15px;
    bottom: 5px;
    position: relative;
    margin-left: 8px;
    fill:orange !important;
    margin-left: 3px !important;
  }
  .memberCount
  {
    position:relative;
    bottom:11px;
    font-size:15px;
    font-weight: bold;
  }
  .logoWrap {
        text-align: center;
        position: relative;
        left: 30px;
  }

  /* .slide-up {
    animation: slide-up 0.7s ease-out;
} */
.slide-down {
    transform: translateY(100%);
    animation: slide-down 0.9s ease-out;
}
.slide-up {
    transform: translateY(0);
    animation: slide-up 0.9s ease-out;
}
.blockWrapperContainer {
    text-align:center;
}

@keyframes slide-up {
    from {
        transform: translateY(100%);
    }
    to {
        transform: translateY(0);
    }
}


/* MetricsDashboard.module.css */
.teamcampcontainer {
    padding: 20px;
    background-color: #f9f9f9;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  }
  
  .teamcampheader {
    /* text-align: center; */
    margin-bottom: 20px;
  }
  
  .teamcamptitle {
    /* font-size: 2rem; */
    color: #333;
  }
  
  .teamcamplist {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
    padding: 0;
    margin: 0;
    justify-content: center;
    overflow-x: auto; 
  overflow-y: hidden; 
  }
  
  .teamcamplistItem {
    display: flex;
    /* flex-direction: column; */
    align-items: center;
    gap: 10px;
    padding: 20px;
    background: linear-gradient(135deg, #ff7e5f, #feb47b);
    border: none;
    border-radius: 12px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    font-size: 1.2rem;
    color: #fff;
    text-align: center;
    width: 300px;
    transition: transform 0.3s ease, box-shadow 0.3s ease;
    font-family: 'Roboto', sans-serif;
  }

  .teamDetailsRow {
    display: flex;
    align-items: center;
    gap: 10px;
  }

  .lockIcon svg {
  /* font-size: 16px; */
  color: red;
}

  
  .teamcamplistItem:hover {
    transform: translateY(-5px);
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
  }
  
  
  .teamcampmetrics {
    display: flex;
    justify-content: space-around;
    margin-top: 20px;
  }
  
  .teamcampmetric {
    background-color: #fff;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    text-align: center;
    font-size: 1.2rem;
    color: #555;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  .teamcampchartContainer {
    margin-top: 40px;
    background-color: #fff;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  }
  
  .teamcamploading {
    text-align: center;
    font-size: 1.5rem;
    color: #888;
  }
  
  .teamcampmetric-label {
    font-size: 1.2rem;
    color: #333;
    margin-bottom: 10px;
  }
  
  .teamcampmetric-value {
    font-size: 2rem;
    color: #555;
  }

  @media (max-width: 768px) {
    .teamcamplist {
      flex-direction: column;
      overflow-x: hidden; 
      overflow-y: auto; 
      align-items: center;
    }
  }

  .custom-tooltip {
    background-color: #fff;
    border: 1px solid #ccc;
    padding: 10px;
    font-size: 14px;
  }
  
  .custom-tooltip p {
    margin: 0;
    color: #333;
  }

  .teamfaq-container {
    max-width: 800px;
    margin: 0 auto;
    padding: 20px;
    font-family: Arial, sans-serif;
    /* margin-top: 65px; */
  }
  
  /* Heading style */
  .teamfaq-container h1 {
    text-align: center;
    margin-bottom: 20px;
  }
  
  /* Individual FAQ item */
  .teamfaq-item {
    margin-bottom: 20px;
  }
  
  /* FAQ question style */
  .teamfaq-item h2 {
    font-size: 18px;
    color: #333;
    margin-bottom: 5px;
  }
  
  /* FAQ answer style */
  .teamfaq-item p {
    font-size: 16px;
    text-align: justify;
  }

  @keyframes SelfRegisterGetOTPpopUp {
    from {
      transform: translateY(100%);
      opacity: 0;
    }
    to {
      transform: translateY(0);
      opacity: 1;
    }
  }

  .selfRegisterGetOTpopUpContainer {
    position: relative;
    bottom: 142px;
    width: 100%;
    /* padding: 60px 20px; */
    padding: 46px;
    background-color: wheat;
    box-shadow: 0 -2px 10px rgba(0, 0, 0, 0.1);
    display: flex;
    justify-content: center;
    animation: SelfRegisterGetOTPpopUp 0.5s ease-out;
    border-radius: 10px;
  }

  .selfregister-infoText {
    display: block;
    font-size: 14px; 
    font-weight: bold; 
    color: #333; 
    padding: 8px 0; 
    margin-bottom: 10px; 
    text-align: left; 
    width: 100%; 
    margin-left: 10px;
  }
  
  @media (max-width: 768px) {
    .selfregister-infoText {
      font-size: 12px; 
    }
  }


.multiSelectContainer input {

    margin-top: 10px !important; 
    margin-left: 8px;
    
}


input::placeholder {
    color: #757575 !important;
  }

.MuiMenuItem-root {
display: flex;
/* align-items: center; */
}
.carousel-item {
    display: inline-block;
    padding: 0 10px; 
    box-sizing: border-box;
}

.carousel-image {
    width: auto;
    height: 45px;
    border-radius: 8px;
    display: block;
    margin: 0 auto; 
}
.slick-prev, .slick-next {
    margin-right: 9px;
}

.slick-prev {
    margin-left: 9px;
}

.slick-next {
    right: 10px; /* Adjust right position */
   
    background-size: 50%; /* Adjust icon size */
    background-position: center;
    background-repeat: no-repeat;
}

/* OTPInput.css */
.otp-field {
    width: 2rem;
    height: 2rem;
    border: 1px solid #ddd;
    border-radius: 4px;
    text-align: center;
    font-size: 1.5rem;
    margin: 0.5rem;
  }
  .otp-field:focus {
    border-color: #3f51b5;
    outline: none;
  }
  .otp-input-container {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 0.5rem; /* Adjust the spacing between inputs as needed */
  }
  
  .otp-input-container input {
    width: 2rem; /* Adjust the width of the input field */
    height: 2rem; /* Adjust the height of the input field */
    border: 1px solid #ddd; /* Light grey border for the input field */
    border-radius: 4px; /* Rounded corners for the input field */
    text-align: center; /* Center text within the input field */
    font-size: 1.5rem; /* Font size for the input field */
    margin: 0; /* Remove any default margin */
    padding: 0; /* Remove any default padding */
    outline: none; /* Remove default outline */
    box-shadow: none; /* Remove any default box-shadow */
  }
  
  .otp-input-container input:focus {
    border-color: #3f51b5; /* Change border color when input is focused */
    box-shadow: 0 0 0 2px rgba(63, 81, 181, 0.3); /* Optional: Add a focus shadow effect */
  }
  
  .otp-input-container input::-webkit-inner-spin-button,
  .otp-input-container input::-webkit-outer-spin-button {
    -webkit-appearance: none; /* Remove spin buttons in number inputs for Webkit browsers */
    margin: 0; /* Remove margin */
  }
  
  .otp-input-container input[type='number'] {
    -moz-appearance: textfield; /* Remove spin buttons for Firefox */
  }
.askIconWrapper {
    width: 40px;
    height: 40px;
    background: #db8d0f;
    vertical-align: middle;
    border-radius: 20px;
    margin: 0 auto;
}
.askIconWrapper svg
{
    font-size: 21px;
    top: 9px;
    position: relative;
    fill:#fff;
}
.notification-image {
    border-radius: 8px;
    width: 50px !important;
    height: 50px !important;
    flex-direction: row;
  }
  .MuiTablePagination-selectLabel { 
     margin-bottom: 0px;
  }
  
  .MuiTablePagination-toolbar {	
    padding-left: 0px !important;
  }
  
  .MuiTablePagination-displayedRows {	
    margin-bottom: 0px;
  }
  
  .slide-enter {
    opacity: 0;
    transform: translateX(100%);
  }
  
  .slide-enter-active {
    opacity: 1;
    transform: translateX(0);
    transition: opacity 300ms, transform 300ms;
  }
  
  .slide-exit {
    opacity: 1;
    transform: translateX(0);
  }
  
  .slide-exit-active {
    opacity: 0;
    transform: translateX(-100%);
    transition: opacity 300ms, transform 300ms;
  }

  .custom-shield-icon {
    z-index: 1; 
    /* position: relative; */
}

.boxContainerWrapperDesktop {
    white-space: nowrap;
    /* width: 100%; */
    overflow-x: auto;
    display: flex;
    flex-wrap: nowrap;
    scrollbar-width: thin;
  }
  .boxContainerWrapperDesktop {
    /* Optional scrollbar styling */
    scrollbar-width: thin; /* Firefox */
  }
  
  .boxContainerWrapperDesktop::-webkit-scrollbar {
    height: 8px;
  }
  
  .boxContainerWrapperDesktop::-webkit-scrollbar-thumb {
    background-color: #888;
    border-radius: 10px;
  }

  .stickyPosition {
    position: sticky;
    z-index: 1;
  }
  thead.stickyPosition {
    top:0;
  }
  tfoot.stickyPosition {
    bottom:0;
    background:#fff;
    float: left;
    width: 100%;
  }
  .footerPagination > div {
    float:left;
  }
  .footerPagination {
    overflow: visible !important;;
  }
  .stickyPosition .footerPagination .MuiTablePagination-actions {
    background: #fff;
    float: left;
    width: 100%;
    padding-top: 5px;
    padding-bottom: 5px;
  }

  .blink_text{	
    animation-name:blink;
    animation-duration:2s;
    animation-timing-function:ease-in;
    animation-iteration-count:Infinite;
    }
    .broadcastLink:hover {
        fill:#db8d0f;
    }
    .optionName span {
        white-space: normal;
    }
  
  @keyframes blink{
    0%{color:black;}
    50%{color:white;}
    100%{color:black;}
  }

  @keyframes blinkRedeemGlow {
    0% {
      box-shadow: 0 0 3px 3px rgba(255, 165, 0, 0.8);
    }
    50% {
      box-shadow: 0 0 10px 5px rgba(255, 165, 0, 1); 
    }
    100% {
      box-shadow: 0 0 3px 3px rgba(255, 165, 0, 0.8); 
    }
  }

  @keyframes blinkchat {
    0% { opacity: 1; }
    50% { opacity: 0.5; }
    100% { opacity: 1; }
  }
  
  .blinkchat {
    animation: blink 1s infinite;
  }

  .buttonContainerChat {
    display: flex;
    flex-direction: row;
    gap: 10px;
  }
  
  @media (max-width: 600px) {
    .buttonContainerChat {
      flex-direction: column;
    }
  }