html, body {
    font-family: 'Roboto', sans-serif;
    padding: 0;
    margin: 0;
  }
  
  #root {
    height: 100vh;
    background:#fff;
  }
  
  * {
    box-sizing: border-box;
  }

.joinOuterContainer {
    display: flex;
    justify-content: center;
    text-align: center;
    /* height: 50vh; */
    align-items: center;
    /* background-color: #1A1A1D; */
  }
  .joinOuterContainerEmail {
    display: flex;
    justify-content: center;
    text-align: center;
    /* height: 50vh; */
    align-items: center;
    /* background-color: #1A1A1D; */
  }
  
  .joinInnerContainer {
    width: 20%;
  }

  .emailOTP {
    width: 20%;
  }
  
  .joinInput {
    border-radius: 0;
    padding: 15px 20px;
    width: 100%;
    margin-top: 10px;
    letter-spacing: 2.23em;
  }

  .joinInputPhoneNo {
    border-radius: 0;
    padding: 15px 20px;
    /* width: 100%; */
    max-width:240px;
    min-width:240px;
    margin-top: 10px;
    margin-bottom: 10px;
    /* letter-spacing: 2.23em; */
    letter-spacing: 0.5em;
  }
  
  .heading {
    color: white;
    font-size: 2.5em;
    /* padding-bottom: 10px; */
    border-bottom: 2px solid white;
  }
  
  .button {
      color: #fff !important;
      text-transform: uppercase;
      text-decoration: none;
      background: #2979FF;
      padding: 8px;
      border-radius: 5px;
      display: inline-block;
      border: none;
      /* width: 100%; */
      width: 82%;
      margin-bottom: 10px;
      margin-top:10px
      
  }
  
  .mt-20 {
    margin-top: 20px;
  }
  
  @media (min-width: 320px) and (max-width: 480px) {
    .joinOuterContainer {
      height: 100%;
    }
    /* .joinOuterContainerEmail {
      height: 100%;
    } */
  
    .joinInnerContainer {
      width: 90%;
    }

    .emailOTP {
      width: 100%;
    }
    
    .button {
      width: 50%
    }
  
  }
  
  button:focus {
    outline: 0;
  }